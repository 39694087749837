const TREND_CHART = {
    "Trends_data": [

        {
            "id": 1,
            "label": "Spend",
            "param": "spend",
            
        },

        {
            "id": 2,
            "label": "Revenue",
            "param": "revenue"
        },
        {
            "id": 3,
            "label": "Orders",
            "param": "orders"
        },

        {
            "id": 4,
            "label": "Impressions",
            "param": "impressions"
        },
        {
            "id": 5,
            "label": 'Clicks', 
            "param": 'Clicks'
        },
        {
            "id": 6,
            "label": 'CPA', 
            "param": 'CPA'
        },

        {
            "id": 7,
            "label": 'ROAS',
            "param": 'ROAS'
        },

        {
            "id": 8,
            "label": 'AOV',
            "param": 'AOV'
        },

        {
            "id": 9,
            "label": 'CTR',
            "param": 'CTR'
        },

        {
            "id": 10,
            "label": 'CPM',
            "param": 'CPM'
        },

        {
            "id": 11,
            "label": 'CPC',
            "param": 'CPC'
        },
        {
            "id": 12,
            "label": 'ACOS',
            "param": 'ACOS'
        },

        {
            "id": 13,
            "label": 'RPC',
            "param": 'RPC'
        },

        {
            "id": 14,
            "label": 'CPO',
             "param": 'CPO'
        },

        {
            "id": 15,
            "label": 'Spend %', 
            "param": 'Spend_perc'
        },

        {
            "id": 16,
            "label": 'Revenue %',
            "param": 'Revenue_perc'
        },

        {
            "id": 17,
            "label": 'CR %',
            "param": 'CR_per'
        },

        {
            "id": 18,
            "label": 'CC',
            "param": 'CC'
        },

    ]

}
export default TREND_CHART