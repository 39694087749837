import React, { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import Search from "./search";
//import { colourOptions } from "./docs/data";

const { MenuList, ValueContainer, SingleValue, Placeholder } = components;

const CustomMenuList = ({ selectProps, ...props }) => {
  const { onInputChange, inputValue, onMenuInputFocus } = selectProps;

  // Copied from source
  const ariaAttributes = {
    "aria-autocomplete": "list",
    "aria-label": selectProps["aria-label"],
    "aria-labelledby": selectProps["aria-labelledby"]
  };

  return (
    <div>
      {/* <input
        style={{
          width: "100%",
          boxSizing: "border-box",
          padding: 10,
          border: "none",
          borderBottom: "1px solid lightgrey"
        }}
        autoCorrect="off"
        autoComplete="off"
        spellCheck="false"
        type="text"
        // value={inputValue}
        onChange={(e) =>
          console.log("SEARCH___", e.target.value)
        }
        onMouseDown={(e) => {
          e.stopPropagation();
          e.target.focus();
        }}
        onTouchEnd={(e) => {
          e.stopPropagation();
          e.target.focus();
        }}
        onFocus={onMenuInputFocus}
        placeholder="Search..."
        {...ariaAttributes}
      /> */}
      {/* <Search /> */}
      <MenuList {...props} selectProps={selectProps} />
    </div>
  );
};

// Set custom `SingleValue` and `Placeholder` to keep them when searching
const CustomValueContainer = ({ children, selectProps, ...props }) => {
  const commonProps = {
    cx: props.cx,
    clearValue: props.clearValue,
    getStyles: props.getStyles,
    getValue: props.getValue,
    hasValue: props.hasValue,
    isMulti: props.isMulti,
    isRtl: props.isRtl,
    options: props.options,
    selectOption: props.selectOption,
    setValue: props.setValue,
    selectProps,
    theme: props.theme
  };

  return (
    <ValueContainer {...props} selectProps={selectProps}>
      {React.Children.map(children, (child) => {
        return child ? (
          child
        ) : props.hasValue ? (
          <SingleValue
            {...commonProps}
            isFocused={selectProps.isFocused}
            isDisabled={selectProps.isDisabled}
          >
            {selectProps.getOptionLabel(props.getValue()[0])}
          </SingleValue>
        ) : (
          <Placeholder
            {...commonProps}
            key="placeholder"
            isDisabled={selectProps.isDisabled}
            data={props.getValue()}
          >
            {selectProps.placeholder}
          </Placeholder>
        );
      })}
    </ValueContainer>
  );
};

const SingleSelect = ({ selectname, metrics, selectedDropDownMetrics, index, defaultMetrics, classes }) => {
  const containerRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const getLabel = defaultMetrics.map((data) => data.label)


  const onDomClick = (e) => {
    let menu = containerRef?.current?.querySelector(".select__menu");

    if (
      !containerRef.current.contains(e.target) ||
      !menu ||
      !menu.contains(e.target)
    ) {
      setIsFocused(false);
      setInputValue("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", onDomClick);

    return () => {
      document.removeEventListener("mousedown", onDomClick);
    };
  }, []);

  const isOptionDisabled = (option, val) => {
    // console.log("defaultMetrics---", defaultMetrics.some((item) => item.label === option.label))
    return defaultMetrics.some((item) => item.label === option.label)
  };

  // console.log("isOptionDisabled---", isOptionDisabled)
  return (
    <div ref={containerRef}>
      <Select
        className={`basic-single`}
        placeholder={selectname}
        value={metrics.filter(option => option.label === selectname)}
        classNamePrefix={`select`}
        name="color"
        options={metrics}
        isOptionDisabled={isOptionDisabled}
        components={{
          MenuList: CustomMenuList,
          ValueContainer: CustomValueContainer
        }}
        inputValue={inputValue}
        isSearchable={false}
        onMenuInputFocus={() => setIsFocused(true)}
        onChange={(val) => { selectedDropDownMetrics(val, index, classes); setIsFocused(false) }}
        onInputChange={(val) => setInputValue(val)}
        {...{
          menuIsOpen: isFocused || undefined,
          isFocused: isFocused || undefined
        }}
      />
    </div>
  );
};

export default SingleSelect;
