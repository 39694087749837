import icons from 'currency-icons';
import upArrow from 'src/assets/images/common/up-green.svg';
import DownGreen from 'src/assets/images/common/down-green.svg';
import UpRed from 'src/assets/images/common/up-red.svg';
import downArrow from 'src/assets/images/common/down.svg';
import GrayDash from 'src/assets/images/common/gray-dash.svg';
import { isThisYear } from 'date-fns';
import DOMPurify from 'dompurify';


export const formatZeroIndexCharAsCapitalize = (label) => {
  if (label != null && label != '') return label.trimStart().charAt(0).toUpperCase() + label.slice(1);
  return '';
};

export const generateUTCtimestamp = () => {
  // Get the current UTC date
  const currentDate = new Date();

  // Extract individual components (year, month, day, hour, minute, second) from the UTC date
  const year = currentDate.getUTCFullYear();
  const month = currentDate.getUTCMonth() + 1; // Months are zero-based, so we add 1
  const day = currentDate.getUTCDate();
  const hours = currentDate.getUTCHours();
  const minutes = currentDate.getUTCMinutes();
  const seconds = currentDate.getUTCSeconds();

    // Format the UTC date as a string
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}Z`;
}

export const convertSecondsToString = (clientSpentSeconds) => {
    const hours = Math.floor(clientSpentSeconds / 3600);
    const minutes = Math.floor((clientSpentSeconds - hours * 3600) / 60);
    const seconds = clientSpentSeconds - hours * 3600 - minutes * 60;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    if (hours == 0 && formattedMinutes == 0 && formattedSeconds == 0) return `---`;
    if (hours == 0 && formattedMinutes == 0 && formattedSeconds > 0) return `${formattedSeconds} sec`;
    if (hours == 0) return `${formattedMinutes} min`;
    return `${hours} hr ${formattedMinutes} min`;
  }

  export const makeFriendly = (num, column_type) => {
    const getProfile = JSON.parse(localStorage.getItem('selectedProfileId'));
    const intlFormat = (num) => {
      return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
    };
    if (!num) return 0;
    if (column_type === 'percentage') return Number(num) >= 10 ? parseInt(num) + '%' : (Math.round(num * 100) / 100).toFixed(2) + '%';
    if (column_type === 'decimal')
      return num >= 10
        ? Number(parseInt(num)).toLocaleString('en-' + getProfile.countryCode)
        : Number(num).toLocaleString('en-' + getProfile.countryCode); // parseInt(num)
    if (column_type === 'number') return Number(parseInt(num)).toLocaleString('en-' + getProfile.countryCode);
    if (num >= 1000000000) return intlFormat(parseInt(num) / 1000000000) + 'B';
    if (num >= 1000000) return intlFormat(parseInt(num) / 1000000) + 'M';
    // if (num >= 1000) return intlFormat(num / 1000) + 'K';
    return intlFormat(num);
  };
  

  export const customNumberFormatter = (num, column_type, currency = 0) => {

    const getProfile = JSON.parse(localStorage.getItem('selectedProfileId'));
    const currentCode = currency === 1 ? icons[getProfile?.currencyCode]?.symbol : '';
  
    const intlFormat = (num) => {
      return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
    };


    let value = num

    if (!value && column_type === 'percentage') return 0.00 + '%';

    if (!value) return 0;

    if (column_type === 'percentage') return Number(value) >= 10 ? (Math.round(value * 100) / 100).toFixed(2) + '%' : (Math.round(value * 100) / 100).toFixed(2) + '%';

    if (column_type === 'decimal' && currency === 1)
      return num >= 99
        ? currentCode + Number(parseInt(num)).toLocaleString('en-' + getProfile.countryCode)
        : currentCode + Number(num).toFixed(2).toLocaleString('en-' + getProfile.countryCode);

    if (column_type === 'decimal')
      return num >= 99
        ? Number(parseInt(num)).toLocaleString('en-' + getProfile.countryCode)
        : Number(num).toFixed(2).toLocaleString('en-' + getProfile.countryCode); // parseInt(num)

    if (column_type === 'number') 
    return currentCode + Number(parseInt(num)).toLocaleString('en-' + getProfile.countryCode);
    if (num >= 1000000000) return currentCode + intlFormat(parseInt(num) / 1000000000) + 'B';
    if (num >= 1000000) return currentCode + intlFormat(parseInt(num) / 1000000) + 'M';
    // if (num >= 1000) return intlFormat(num / 1000) + 'K';
    return currentCode + intlFormat(parseInt(num));
  };
  
  export const formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
  
    return [year, month, day].join('-');
  };

  export const GetDeltaClass = (deltaInfo, classes = []) => {
    if(deltaInfo && 'percentage' in deltaInfo && (deltaInfo.percentage === 0 || isNaN(deltaInfo?.percentage))) {
      return 'gray-staus'
    }
    if(deltaInfo && 'highLightColor' in deltaInfo && deltaInfo.highLightColor != null && deltaInfo.highLightColor != '' && (deltaInfo.highLightColor === 'red' || deltaInfo.highLightColor === 'green')) {
      return `${deltaInfo.highLightColor}-staus`
    }
    if(deltaInfo && 'growth' in deltaInfo && deltaInfo.growth == 'high') return classes.length > 0 ? classes[0] : 'green-staus'
    if(deltaInfo && 'growth' in deltaInfo && deltaInfo.growth == 'low') return classes.length > 0 ? classes[1] : 'red-staus'
    return classes.length > 0 ? classes[0] : 'green-staus'
  }
  

export const getHighLightColor = (metricItem) => {
    if(metricItem && 'percentage' in metricItem && (metricItem.percentage === 0 || isNaN(metricItem.percentage))) {
      return 'gray-shde'
    }
    if(metricItem && 'highLightColor' in metricItem && (metricItem.highLightColor === 'green' | metricItem.highLightColor === 'red')) {
      return metricItem.highLightColor === 'green' ? "green-shde" : "red-shde"
    }
    if(metricItem && 'growth' in metricItem && metricItem.growth === 'high') return "green-shde"
    if(metricItem && 'growth' in metricItem && metricItem.growth !== 'high') return "red-shde"
    return "green-shde"
  }

  export const getImageHighLight = (metricItem) => {
    if(metricItem && 'highLightColor' in metricItem && (metricItem.highLightColor === 'green' | metricItem.highLightColor === 'red')) {
      if(metricItem.highLightColor === 'green') {
        if(metricItem && 'growth' in metricItem && metricItem.growth === 'high') return upArrow
        if(metricItem && 'growth' in metricItem && metricItem.growth !== 'high') return DownGreen
        return upArrow
      }
      if(metricItem.highLightColor === 'red') {
        if(metricItem && 'growth' in metricItem && metricItem.growth === 'high') return UpRed
        if(metricItem && 'growth' in metricItem && metricItem.growth !== 'high') return downArrow
        return UpRed
      }
    }
    if(metricItem && 'growth' in metricItem && metricItem.growth === 'high') return upArrow
    if(metricItem && 'growth' in metricItem && metricItem.growth !== 'high') return downArrow
    return upArrow
  }

export const getDateFormat = (format, date) => {

  if(isThisYear(new Date(date))) return format
  return format + ' - yy'

}


export const copyContent = async (contentValue, returnType = '')  => {
    
  let content = contentValue
    
    const isAsin = String(contentValue).indexOf('asin="') > -1 || String(contentValue).indexOf('asin-expanded="') > -1
    if(isAsin) {
      const match = content.match(/"([^"]+)"/);
      if (match) {
        content = String(match[1]).toUpperCase();
      }
    }
    if(returnType == 'value') return content

    if (document.hasFocus()) {
      navigator.clipboard
        .writeText(content)
        .then(() => {
          console.log('Text copied to clipboard');
        })
        .catch((err) => {
          console.error('Could not copy text: ', err);
        });
    } else {
      console.error('Document is not focused');
    }
    
}

export const sanitizeValue = (input) => {
  return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const sanitizeHtml = (input) => {
   // Escape special characters in the input string
   return DOMPurify.sanitize(input);
};