import React, { useState, useContext } from 'react';
import BugDark from 'src/assets/images/common/bug.svg';
import BugLight from 'src/assets/images/common/bug-light.svg';
import DownIcon from 'src/assets/images/common/down_gray.svg';
import { Modal, Row, Col } from 'react-bootstrap';
import Input from 'src/components/Form/Input';
import ReactRichEditor from 'src/components/RichEditor';
import { ThemeContext } from 'src/providers/themeProvider';

export default function bugModal({ header = 0, classes = '', handleMouseEnter = () => {} }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const BugIcon = () => {
    const [theme] = useContext(ThemeContext);
    return <img src={theme.theme === 'DARK' ? BugLight : BugDark} alt="bug" className={`wid-15 cursor-pointer ${header ? '' : 'target_bug'} ${classes ? classes : ''}`} onClick={handleShow}/>;
  };

  if(process.env.REACT_APP_BUG_ENABLE !== 1) return null;

  return (
    <React.Fragment>
      <div className={header === 1 ? "bug_header" : "bug_box"} onMouseEnter={handleMouseEnter}>
        <BugIcon />
        <BugModal show={show} handleClose={handleClose} />
      </div>
    </React.Fragment>
  );
}

function BugModal({ show, handleClose }) {
  const [showPriority, setShowPriority] = useState(false);
  const [showAssignee, setShowAssignee] = useState(false);
  return (
    <>
      <Modal className="asin_modal m-w-50" show={show} onHide={handleClose}>
        <Modal.Header className="align-items-center">
          <Modal.Title>
            <h2 className="f-14 f-w-600">Create Issue</h2>
          </Modal.Title>
          <span class="cursor-pointer f-18" onClick={handleClose}>
            &#x2715;
          </span>
        </Modal.Header>
        <Modal.Body className="modal_sch">
          <section>
            <Row>
              {/* SUMMARY */}
              <Col sm={12} className="mb-2">
                <label className="f-w-500 text-dark f-14">Summary</label>
              </Col>
              <Col sm={12} className="mb-3">
                <Input />
              </Col>
              {/* SUMMARY */}

              {/* DESCRIPTION */}
              <Col sm={12} className="mb-2">
                <label className="f-w-500 text-dark f-14">Description</label>
              </Col>
              <Col sm={12} className="mb-3">
                <ReactRichEditor />
              </Col>
              {/* DESCRIPTION */}

              {/* PRIORITY */}
              <Col sm={12} className="mb-2">
                <label className="f-w-500 text-dark f-14">Priority</label>
              </Col>
              <Col sm={12} className="mb-3 position-relative">
                <div className="form-field cursor-pointer d-flex justify-content-between" onClick={() => setShowPriority(!showPriority)}>
                  Medium
                  <img src={DownIcon} alt="select" />
                </div>
                {showPriority && (
                  <section className="addcat_modal cus_select p-2">
                    <ul>
                      <li className="active">Small</li>
                      <li>Medium</li>
                      <li>Hard</li>
                    </ul>
                  </section>
                )}
              </Col>
              {/* PRIORITY */}

              {/* ENVIRONMENT */}
              <Col sm={12} className="mb-2">
                <label className="f-w-500 text-dark f-14">Environment</label>
              </Col>
              <Col sm={12} className="mb-3">
              <Input values={"WebApp"} readonly={1} />
              </Col>
              {/* ENVIRONMENT */}

              {/* ATTACHMENT */}
              <Col sm={12} className="mb-2">
                <label className="f-w-500 text-dark f-14">Attachment</label>
              </Col>
              <Col sm={12} className="mb-3">
                <Input types="file" />
              </Col>
              {/* ATTACHMENT */}

              {/* ASIGNEE */}
              <Col sm={12} className="mb-2">
                <label className="f-w-500 text-dark f-14">Assignee</label>
              </Col>
              <Col sm={12} className="mb-3">
              <div className="form-field cursor-pointer d-flex justify-content-between" onClick={() => setShowAssignee(!showAssignee)}>
                  Siddhant Sharma
                  <img src={DownIcon} alt="select" />
                </div>
                {showAssignee && (
                  <section className="addcat_modal cus_select p-2">
                    <ul>
                      <li className="active">Vipul Joshi</li>
                      <li>Namrata</li>
                      <li>Karuppasamy</li>
                    </ul>
                  </section>
                )}
              </Col>
              {/* ASIGNEE */}
            </Row>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <section className="d-flex align-items-center justify-content-end w-100">
            <div className="d-flex align-items-center py-2">
              <p className="cls-btn cursor-pointer rounded-4 f-14 px-4 py-btn f-w-600 mr-4 f-202226" onClick={handleClose}>
                Cancel
              </p>
              <p className={`bg-blue rounded-4 f-14 px-4 py-btn f-w-600 cursor-pointer text-white mt-0 ml-3`}>Submit</p>
            </div>
          </section>
        </Modal.Footer>
      </Modal>
    </>
  );
}
