import React, { useContext, useEffect, useState } from 'react';
import context from 'react-bootstrap/esm/AccordionContext';
import { Link } from 'react-router-dom';
import ErrorImg from 'src/assets/images/common/no-data.svg'
import { ThemeContext, ThemeProvider } from "src/providers/themeProvider";


function EmptyMessage({ message }) {
    const [theme] = useContext(ThemeContext)

    console.log("themethemetheme", theme.theme)
    console.log("message", message)
    
    return (
        <section className="h-fitsh d-flex flex-column align-items-center justify-content-center f-15 f-w-500">
             <img src={ErrorImg} alt="error-message" width="350" />

            {message === "user_management" &&
                <div className="wid-950 meg-screen text-center l-27"> You have no active profiles connected to your account, click here to
                    <section className="d-flex align-items-center justify-content-center mt-3">
                        <p className="d-flex"> <Link to='/profile' className="mx-1 link_button">activate your profile</Link> or </p>
                        <Link to='/addaccount' className="mx-1 link_button">add an account</Link>
                    </section>
                </div>
            }
            {message === "dashboard" &&
                <div className="wid-950 meg-screen text-center l-27"> You have no active profiles connected to your account, click here to 
                    <section className="d-flex align-items-center justify-content-center mt-3">
                        <p className="d-flex align-items-center justify-content-center"><Link to='/profile' className="mx-1 link_button">activate your profile</Link> or</p>
                        <p className="d-flex align-items-center justify-content-center mt-0"><Link to='/addaccount' className="mx-1 link_button">add an account</Link></p>
                    </section>
                </div>
            }
            {message === "keywordCategory" &&
                <div className="wid-950 meg-screen text-center l-27">You cant Access to view this page, click here to go to
                    <section className="d-flex align-items-center justify-content-center mt-3">
                        <p className="d-flex align-items-center justify-content-center"><Link to='/profile' className="mx-1 link_button">profile</Link></p>
                    </section>  
                </div>
            }
            {message === "categorization" &&
                <div className="wid-950 meg-screen text-center l-27">You do not have the permissions to access categorization. Please request your admin to upgrade your role to a manager, click here to go to
                    <section className="d-flex align-items-center justify-content-center mt-3">
                        <p className="d-flex align-items-center justify-content-center"><Link to='/profile' className="mx-1 link_button">profile</Link></p>
                    </section>
                </div>
            }
            {message === "accountSummary" &&
                <div className="wid-950 meg-screen text-center l-27">Account Summary Page is available only when Seller Central has been activated. Please visit profiles to activate seller central to <br />
                    <section className="d-flex align-items-center justify-content-center mt-3">
                        <p className="d-flex align-items-center justify-content-center mr-1"><Link to='/profile' className="mx-1 link_button">click here go to profile</Link> or</p>
                        <p className="d-flex align-items-center justify-content-center mt-0 pt-0"><Link to='/amazon/targeting' className="mx-1 link_button">view advertising dashboard</Link></p>
                    </section>
                </div>
            }
            {message === "snapshotSummary" &&
                <div className="wid-950 meg-screen text-center l-27">Snapshot Page is available only when Seller Central / Vendor Central has been activated. <br /> Please activate Seller Central / Vendor Central in <Link to='/profile' className="link_button">Profile</Link> section or <Link to='/amazon/targeting' className="link_button">View advertising dashboard</Link>
                    {/* <section className="d-flex align-items-center justify-content-center mt-3">
                        <p className="d-flex align-items-center justify-content-center mr-1"><Link to='/profile' className="mx-1 link_button">click here go to profile</Link> or</p>
                        <p className="d-flex align-items-center justify-content-center mt-0 pt-0"><Link to='/amazon/targeting' className="mx-1 link_button">view advertising dashboard</Link></p>
                    </section> */}
                </div>
            }
            
            {message === "sellerSales" &&

                <div className="wid-950 meg-screen text-center l-27"> You have no active profiles connected to your account, click here to 
                    <section className="d-flex align-items-center justify-content-center mt-3">
                        <p className="d-flex align-items-center justify-content-center"><Link to='/profile' className="mx-1 link_button">activate your profile</Link> or</p>
                        <p className="d-flex align-items-center justify-content-center mt-0"><Link to='/add-accout' className="mx-1 link_button">add an account</Link></p>
                    </section>    
                </div>
            }
            {message === "sellerCentral" &&
                <div className="wid-950 meg-screen text-center l-27"> You have no active Seller Central profiles connected to your account, click here to
                    <section className="d-flex align-items-center justify-content-center mt-3">
                        <p className="d-flex align-items-center justify-content-center"><Link to='/profile' className="mx-1 link_button">authorise Seller Central</Link>or</p>
                        <p className="d-flex align-items-center justify-content-center mt-0"><Link to='/addaccount' className="mx-1 link_button">add an account</Link></p>
                    </section>
                </div>
            }

            {message === "addaccount" &&
                <div className="wid-950 meg-screen text-center l-27">You cant Access to view this page, click here to go to
                    <section className="d-flex align-items-center justify-content-center mt-3">
                        <p className="d-flex align-items-center justify-content-center"><Link to='/profile' className="mx-1 link_button">profile</Link></p>
                    </section>  
                </div>
            }

            {message === 'yet-to-onboarded' && <div className="wid-950 meg-screen text-center l-27"> You have not connected you're ad account , click here to
                    <section className="d-flex align-items-center justify-content-center mt-3">
                        <p className="d-flex align-items-center justify-content-center mt-0"><Link to='/addaccount' className="mx-1 link_button">add an account</Link></p>
                    </section>
            </div>}

            {message === "FlipkartUnAuthorized" && <div className="wid-950 meg-screen text-center l-27">There is no seller report access available. Please contact <p> <i className="fas fa-envelope"></i> <a href="mailto:support@hectorai.live">support@hectorai.live</a> </p></div>}
        </section>
    )
}

export default EmptyMessage;