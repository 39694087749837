import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import BRAND_TAB_GROUP from 'src/store/account-charts.constant';
import RadioButton from 'src/components/Actions/radioButton';
import MetricsChart from '../../dashboards/metricsCharts';
import Lightloader from 'src/assets/images/common/chart-loader-white.svg';
import chartloader from 'src/assets/images/common/chart-loader.svg';
import { ThemeContext } from 'src/providers/themeProvider';
import useApi from 'src/hooks/useApi';
import services from 'src/api/service';

export default function AccountSummary({ dateSelected, crossFilter }) {
  const [chartInfo, setChartsInfo] = useState(BRAND_TAB_GROUP.details[0]);

  return (
    <React.Fragment>
      <Row className="mb-3">
        {BRAND_TAB_GROUP.details.map((option, detailIndex) => (
          <Col sm={4} key={'charts_' + detailIndex}>
            <section
              className={`h-100 bg-white px-3 py-4 d-flex align-items-center justify-content-between cursor-pointer cards-responsive position-relative ${
                option.checked ? 'border-primary' : 'border'
              }`}
              onClick={() => setChartsInfo(option)}
            >
              <h2 className="f-w-600 text-dark f-15">{option.title}</h2>
              <RadioButton classes="mt-radio" checked={chartInfo.id === option.id} id={'charts_element_' + option.id} radioname="acc-crd" />
            </section>
          </Col>
        ))}
      </Row>
      {BRAND_TAB_GROUP.details.filter((top_chart) => top_chart.id === chartInfo.id).map((top_chart, headIndex) => {
          return (
            <Card key={`head_chart_${headIndex}`}>
              <Row>
                {top_chart.charts.map((chart_row, chartIndex) => {
                  return (
                    <Col sm={6} key={`topchart_${chartIndex}`} className="mt-3">
                      <CustomChart key={"custom_chart_"+ chartIndex} top_chart={chart_row} dateSelected={dateSelected} crossFilter={crossFilter} />
                    </Col>
                  );
                })}
              </Row>
            </Card>
          );
      })}
    </React.Fragment>
  );
}

const CustomChart = ({ top_chart, dateSelected, colSm = 12, crossFilter }) => {
  let User = JSON.parse(localStorage.getItem('User'));
  const getProfile = JSON.parse(localStorage.getItem('selectedProfileId'));

  const [fetchFailed, setFetchFailed] = useState(1);
  const doCustomWidgetAPi = useApi(services.postService);
  const [chartData, setChartData] = useState({ series: [], categories: [] });

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  useEffect(() => {
    const crossFiltersData = crossFilter && crossFilter.length > 0 ? crossFilter.filter((crossRow) => crossRow.filters.length > 0) : [];
    doCustomWidgetAPi.request({
      url: top_chart.endpoint,
      payload: {
        profile_id: String(getProfile?.profileId),
        start_date: formatDate(dateSelected[0]),
        end_date: formatDate(dateSelected[1]),
        token: User.token,
        account_type: getProfile?.accountInfo?.type,
        role: getProfile?.accessLevel ? getProfile?.accessLevel : 'Admin',
        ...top_chart.payload,
        ...(crossFiltersData.length > 0 && { user_filters: crossFiltersData })
      }
    });
  }, [dateSelected, top_chart, crossFilter]);

  useEffect(() => {
    const { loading, data } = doCustomWidgetAPi;
    if (!loading && data && data.data && Object.keys(data.data).length > 0) {
      const response = data.data;
      if (response && response.series && response.categories) {
        setChartData({ series: response.series, categories: response.categories });
        setFetchFailed(1);
      }
    } else if (!loading) {
      setFetchFailed(0);
      setChartData({ series: [], categories: [] });
    }
  }, [doCustomWidgetAPi.loading]);

  const [typeOfMetric, setTypeOfMetric] = useState("")
  const [selectedSeries, setSelectedSeries] = useState([])
  const [noColor, setNoColor] = useState(0)

  return (
    <>
      {doCustomWidgetAPi.loading ? (
        <div className="d-flex align-items-center justify-content-center chart-overlay p-3 position-absolute z-2500 inset-0 h-100 f-w-700 text-dark">
          <Loader /> Loading...
        </div>
      ) : null}

      {!doCustomWidgetAPi.loading && !fetchFailed ? (
        <div className="d-flex align-items-center justify-content-center chart-overlay p-3 position-absolute z-2500 inset-0 h-100 f-w-700 text-dark">
          No Results Found
        </div>
      ) : null}

      <MetricsChart
        options={{
          series: chartData.series,
          categories: chartData.categories,
          colors: top_chart.colors,
          chart_type: top_chart.chart_type
        }}
        typeOfMetric={typeOfMetric} 
        selectedSeries={selectedSeries}
        noColor={noColor}
        metricView={1}
        xAxisDate={1}
        percentage={top_chart.percentage}
        logScaleVisibility={'logScale' in top_chart ? top_chart.logScale : 1}
        logScaleId={top_chart.id}
        tickPositions={'custom'}
      />
    </>
  );
};

const Loader = () => {
  const [theme] = useContext(ThemeContext);
  return <img src={theme.theme === 'DARK' ? Lightloader : chartloader} className="mr-1 hei-30 wid-30" />;
};
