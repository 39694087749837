import {
  format,
  addDays,
  isToday,
  subWeeks,
  startOfWeek,
  subDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfWeek,
  endOfMonth,
  addMonths,
  subMonths,
  getDay
} from 'date-fns';

const currentDate = new Date();

// Function to get the start and end dates for a specific week
const getWeekDates = (weekStartDate) => {
  const startDate = startOfWeek(weekStartDate);
  const endDate = endOfWeek(addDays(startDate, 6));
  return { startDate, endDate };
};

// Function to get the date ranges for the last N weeks excluding today
const getLastNWeeksRangeExcludingToday = (n) => {
  const dateRanges = [];

  for (let i = n - 1; i >= 0; i--) {
    const weekStartDate = subWeeks(currentDate, i);

    if (!isToday(weekStartDate)) {
      const { startDate, endDate } = getWeekDates(weekStartDate);
      dateRanges.push({ startDate, endDate });
    }
  }

  return dateRanges;
};

// Function to get the start and end dates for a specific month
const getMonthDates = (monthStartDate) => {
  const startDate = startOfMonth(monthStartDate);
  const endDate = endOfMonth(monthStartDate);
  return { startDate, endDate };
};

// Function to get the date ranges for the last N months
const getLastNMonthsRange = (n) => {
  const dateRanges = [];

  for (let i = n - 1; i >= 0; i--) {
    const monthStartDate = subMonths(currentDate, i);

    const { startDate, endDate } = getMonthDates(monthStartDate);
    dateRanges.push({ startDate, endDate });
  }

  return dateRanges;
};

const getWeekdayDates = (weekdayStartDate) => {
    const startDate = startOfDay(weekdayStartDate);
    const endDate = endOfDay(weekdayStartDate);
    return { startDate, endDate };
  };
  
  // Function to get the date ranges for the last N weekdays (excluding weekends)
  const getLastNWeekdaysRange = (n) => {
    const dateRanges = [];
  
    for (let i = n - 1; i >= 0; i--) {
      const weekdayStartDate = subDays(currentDate, i);
  
      // Skip weekends (Saturday and Sunday)
      if (getDay(weekdayStartDate) !== 0 && getDay(weekdayStartDate) !== 6) {
        const { startDate, endDate } = getWeekdayDates(weekdayStartDate);
        dateRanges.push({ startDate, endDate });
      }
    }
  
    return dateRanges;
  };

export const getlast7WeeksRange = async () => {
  const dates = await getLastNWeeksRangeExcludingToday(7);
  return [format(dates[0].startDate, 'yyyy-MM-dd'), format(dates[dates.length - 1].endDate, 'yyyy-MM-dd')];
};

export const getlast7MonthsRange = async () => {
  const dates = await getLastNMonthsRange(7);
  return [format(dates[0].startDate, 'yyyy-MM-dd'), format(dates[dates.length - 1].endDate, 'yyyy-MM-dd')];
};

export const getLast7WeekDaysRange = async () => {
    const dates = await getLastNWeekdaysRange(7);
    return [format(dates[0].startDate, 'yyyy-MM-dd'), format(dates[dates.length - 1].endDate, 'yyyy-MM-dd')];
  };