import React, { useRef, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import DemoMetrics from 'src/components/Charts/Highchart/config/demo-metrics';
import icons from 'currency-icons';
import moment from 'moment';
import { format } from 'date-fns';
import Checkbox from 'src/components/Form/checkbox';
import Button from 'src/components/Actions/Button';
// import exporting from "highcharts/modules/exporting";
// import exportData from "highcharts/modules/export-data";
import { customNumberFormatter } from 'src/utils/custom-formatters';
import downArrow from 'src/assets/images/common/down-red-v2.svg';
import upArrow from 'src/assets/images/common/up-green-v2.svg';
import DateFilter from 'src/components/Actions/dateFilter';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import useResponsiveHighcharts from './useResponsiveHighcharts';
import { kpiFormatter } from '../../targetting/formatter';

// exporting(Highcharts);
// exportData(Highcharts);


const MetricsChart = ({ options, optionalMetric, selectedMetrics, sellerSales, accountSummary, singleYAxis = 0, metricView = 0, percentage = 0, logScaleId = "", logScaleVisibility = 1, tickPositions = '', xAxisDate = 0, insights = 0, yAxisConfig = {}, customOptions = {}, flipkartNodeAPI = 0, noColor = 0, selectedSeries = [], typeOfMetric = '', customXAxis = 0, dateRangeType, setDateRangeType }) => {
  const ParseData = JSON.parse(localStorage.getItem('User'));
  const chartRef = useRef(null);
  const [hignChartPdf, setHighChartRef] = useState(null);
  const activeProfile = JSON.parse(localStorage.getItem('ActiveProfilesAMS'));
  const getProfile = window.location.href.indexOf('/flipkart') > -1 ? JSON.parse(localStorage.getItem('flipkartProfileId')) : JSON.parse(localStorage.getItem('selectedProfileId'));
  const [logScale, setLogScale] = useState(0);
  const [merticsData, setMerticsData] = useState({});

  const getValue = (label, value) => {

    const customFormatValue = kpiFormatter(value, label)
    return customFormatValue;   

    // const formats = flipkartNodeAPI === 1 ?
    // [
    //   { id: 1,"column_type": "number", "label": "Spend", "param": "Spend", "prefix_sign": "currency", "checked": true },
    //     { id: 2,"column_type": "number", "label": "Impressions", "param": "Impressions", "checked": true },
		// { id: 3,"column_type": "number", "label": "Clicks", "param": "Clicks", "checked": true },
    //     { id: 4,"column_type": "percentage", "label": "CTR", "param": "CTR", "checked": true },
		// { id: 5,"column_type": "decimal", "label": "CPC", "param": "CPC", "prefix_sign": 'currency', "checked": true },
		// { id: 6,"column_type": "decimal", "label": "CPM", "param": "CPM", "prefix_sign": 'currency', "checked": true },
		// { id: 7,"column_type": "number", "label": "Revenue", "param": "Revenue", "prefix_sign": 'currency', "checked": true },
		// { id: 8,"column_type": "number", "label": "Orders", "param": "Orders", "checked": true },
		// { id: 9,"column_type": "number", "label": "CPA", "param": "CPA", "prefix_sign": 'currency', "checked": true },
		// { id: 10,"column_type": "number", "label": "ROAS", "param": "ROAS", "checked": true },
    // { id: 11,"column_type": "number", "label": "AOV", "param": "AOV", "checked": true, "prefix_sign": "currency" },
		// { id: 12,"column_type": "percentage", "label": "ACOS", "param": "ACOS", "checked": true },
		// { id: 13,"column_type": "percentage", "label": "CR", "param": "CR", "checked": true },          
    // ]
    //  : [
    //   { column_type: 'number', label: 'Spend', param: 'Spend', prefix_sign: 'currency' },
    //   { column_type: 'number', label: 'Revenue', param: 'Revenue', prefix_sign: 'currency' },
    //   { column_type: 'number', label: 'Orders', param: 'Orders' },
    //   { column_type: 'number', label: 'Impressions', param: 'Impressions' },
    //   { column_type: 'number', label: 'Clicks', param: 'Clicks' },
    //   { column_type: 'decimal', label: 'CPA', param: 'CPA', prefix_sign: 'currency' },
    //   { column_type: 'decimal', label: 'ROAS', param: 'ROAS' },
    //   { column_type: 'decimal', label: 'AOV', param: 'AOV', prefix_sign: 'currency' },
    //   { column_type: 'percentage', label: 'CTR', param: 'CTR' },
    //   { column_type: 'decimal', label: 'CPM', param: 'CPM', prefix_sign: 'currency' },
    //   { column_type: 'decimal', label: 'CPC', param: 'CPC', prefix_sign: 'currency' },
    //   { column_type: 'percentage', label: 'ACOS', param: 'ACOS', postFix_sign: 'percentage' },
    //   { column_type: 'number', label: 'RPC', param: 'RPC' },
    //   { column_type: 'number', label: 'CPO', param: 'CPO', prefix_sign: 'currency' },
    //   { column_type: 'percentage', label: 'Spend %', param: 'Spend_perc', postFix_sign: 'percentage' },
    //   { column_type: 'percentage', label: 'Revenue %', param: 'Revenue_perc', postFix_sign: 'percentage' },
    //   { column_type: 'percentage', label: 'CR %', param: 'CR_per', postFix_sign: 'percentage' },
    //   { column_type: 'number', label: 'CC', param: 'CC' },
    //   { column_type: 'percentage', label: 'Orders %', param: 'Orders_perc' }
    // ];

    // const intlFormat = (num) => {
    //   return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
    // };

    // const getFormat = formats.find((metric) => metric.label === label);
    // if (getFormat) {
    //   const { column_type, prefix_sign, postFix_sign } = getFormat;
    //   let sign = '';
    //   if (prefix_sign) sign = icons[getProfile?.currencyCode ? getProfile?.currencyCode : 'INR']?.symbol;

    //   if (!value) return 0;
    //   if (column_type === 'percentage')
    //     return Number(value) >= 10 ? parseInt(value) + '%' : (Math.round(value * 100) / 100).toFixed(2) + '%';
    //   if (column_type === 'decimal' && prefix_sign === 'currency')
    //     return value >= 10
    //       ? sign + Number(parseInt(value)).toLocaleString(`en-${getProfile?.countryCode ? getProfile?.countryCode : 'IN'}`)
    //       : sign + Number(value).toFixed(2).toLocaleString(`en-${getProfile?.countryCode ? getProfile?.countryCode : 'IN'}`);
    //   if (column_type === 'decimal')
    //     return value >= 10
    //       ? Number(parseInt(value)).toLocaleString(`en-${getProfile?.countryCode ? getProfile?.countryCode : 'IN'}`)
    //       : Number(value).toFixed(2).toLocaleString(`en-${getProfile?.countryCode ? getProfile?.countryCode : 'IN'}`);
    //   if (column_type === 'number') 
    //   if (value >= 1000000000) return sign + intlFormat(value / 1000000000) + 'B'; //intlFormat(value / 1000000000) + 'B';
    //   if (value >= 1000000) return sign + intlFormat(value / 1000000) + 'M'; //intlFormat(value / 1000000) + 'M';
    //   if(value >= 100000) return intlFormat(value / 100000) + 'L'
    //   if(value >= 1000) return intlFormat(value / 1000) + 'T'

    //   return sign + Number(parseInt(value)).toLocaleString(`en-${getProfile?.countryCode ? getProfile?.countryCode : 'IN'}`);
    // } else {
    //   if(typeOfMetric === 'currency') {
    //     return customNumberFormatter(value, 'number', 1)
    //   }
    //   if (value >= 1000000000) return intlFormat(value / 1000000000) + 'B'; //intlFormat(value / 1000000000) + 'B';
    //   if (value >= 1000000) return intlFormat(value / 1000000) + 'M'; //intlFormat(value / 1000000) + 'M';
    //   if(value >= 100000) return intlFormat(value / 100000) + 'L'
    //   if(value >= 1000) return intlFormat(value / 1000) + 'T'

    //   return Number(value).toLocaleString(`en-${getProfile?.countryCode ? getProfile?.countryCode : 'IN'}`);

    // }
  };

  const getAxiosValue = (label, value) => {

    const customFormatValue = kpiFormatter(value, label)
    return customFormatValue;

    // const formats = flipkartNodeAPI === 1 ?
    //   [
    //     { id: 1,"column_type": "number", "label": "Spend", "param": "Spend", "prefix_sign": "currency", "checked": true },
    //       { id: 2,"column_type": "number", "label": "Impressions", "param": "Impressions", "checked": true },
    //   { id: 3,"column_type": "number", "label": "Clicks", "param": "Clicks", "checked": true },
    //       { id: 4,"column_type": "percentage", "label": "CTR", "param": "CTR", "checked": true },
    //   { id: 5,"column_type": "number", "label": "CPC", "param": "CPC", "prefix_sign": "currency", "checked": true },
    //   { id: 6,"column_type": "number", "label": "CPM", "param": "CPM", "prefix_sign": "currency", "checked": true },
    //   { id: 7,"column_type": "number", "label": "Revenue", "param": "Revenue", "prefix_sign": "currency", "checked": true },
    //   { id: 8,"column_type": "number", "label": "Orders", "param": "Orders", "checked": true },
    //   { id: 9,"column_type": "number", "label": "CPA", "param": "CPA", "prefix_sign": "currency", "checked": true },
    //   { id: 10,"column_type": "number", "label": "ROAS", "param": "ROAS", "checked": true },
    //   { id: 11,"column_type": "number", "label": "AOV", "param": "AOV", "checked": true, "prefix_sign": "currency" },
    //   { id: 12,"column_type": "percentage", "label": "ACOS", "param": "ACOS", "checked": true },
    //   { id: 13,"column_type": "percentage", "label": "CR", "param": "CR", "checked": true },          
    //   ]
    //  : [
    //   { column_type: 'number', label: 'Spend', param: 'Spend', prefix_sign: 'currency' },
    //   { column_type: 'number', label: 'Revenue', param: 'Revenue', prefix_sign: 'currency' },
    //   { column_type: 'number', label: 'Orders', param: 'Orders' },
    //   { column_type: 'number', label: 'Impressions', param: 'Impressions' },
    //   { column_type: 'number', label: 'Clicks', param: 'Clicks' },
    //   { column_type: 'decimal', label: 'CPA', param: 'CPA', prefix_sign: 'currency' },
    //   { column_type: 'decimal', label: 'ROAS', param: 'ROAS' },
    //   { column_type: 'decimal', label: 'AOV', param: 'AOV', prefix_sign: 'currency' },
    //   { column_type: 'percentage', label: 'CTR', param: 'CTR' },
    //   { column_type: 'decimal', label: 'CPM', param: 'CPM' },
    //   { column_type: 'decimal', label: 'CPC', param: 'CPC' },
    //   { column_type: 'percentage', label: 'ACOS', param: 'ACOS', postFix_sign: 'percentage' },
    //   { column_type: 'number', label: 'RPC', param: 'RPC' },
    //   { column_type: 'number', label: 'CPO', param: 'CPO', prefix_sign: 'currency' },
    //   { column_type: 'percentage', label: 'Spend %', param: 'Spend_perc', postFix_sign: 'percentage' },
    //   { column_type: 'percentage', label: 'Revenue %', param: 'Revenue_perc', postFix_sign: 'percentage' },
    //   { column_type: 'percentage', label: 'CR %', param: 'CR_per', postFix_sign: 'percentage' },
    //   { column_type: 'number', label: 'CC', param: 'CC' }
    // ];

    // const intlFormat = (num) => {
    //   return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
    // };

    // const getFormat = formats.find((metric) => metric.label === label);

    // if (getFormat) {
    //   const { column_type, prefix_sign, postFix_sign } = getFormat;
    //   let sign = '';
    //   if (prefix_sign) sign = icons[getProfile?.currencyCode ? getProfile?.currencyCode : 'INR']?.symbol;

    //   // if (!value) return 0;
    //   if (column_type === 'percentage')
    //     return Number(value) >= 99 ? parseInt(value) + '%' : Number(value).toFixed(2) + '%';
    //   if (column_type === 'decimal' && prefix_sign === 'currency')
    //     return value >= 99
    //       ? sign + Number(parseFloat(value)).toLocaleString(`en-${getProfile?.countryCode ? getProfile?.countryCode : 'IN'}`)
    //       : sign + parseFloat(value);
    //   if (column_type === 'decimal')
    //     return value >= 99
    //       ? Number(parseFloat(value)).toLocaleString(`en-${getProfile?.countryCode ? getProfile?.countryCode : 'IN'}`)
    //       : Number(value).toLocaleString(`en-${getProfile?.countryCode ? getProfile?.countryCode : 'IN'}`);
    //   if (column_type === 'number') 
    //   if (value >= 1000000000) return sign + intlFormat(value / 1000000000) + 'B'; //intlFormat(value / 1000000000) + 'B';
    //   if (value >= 1000000) return sign + intlFormat(value / 1000000) + 'M'; //intlFormat(value / 1000000) + 'M';
    //   if (value >= 100000) return sign +intlFormat(value / 100000) + 'L';
    //   if (value >= 1000) return sign +intlFormat(value / 1000) + 'T';

    //   return sign + Number(value).toLocaleString(`en-${getProfile?.countryCode ? getProfile?.countryCode : 'IN'}`);
    // } else {
    //   // if (value >= 1000000000) return intlFormat(value / 1000000000) + 'B'; //intlFormat(value / 1000000000) + 'B';
    //   // if (value >= 1000000) return intlFormat(value / 1000000) + 'M'; //intlFormat(value / 1000000) + 'M';
    //   // if (value >= 100000) return intlFormat(value / 100000) + 'L';
    //   // if (value >= 1000) return intlFormat(value / 1000) + 'T';
    //   return Number(value).toLocaleString(`en-${getProfile?.countryCode ? getProfile?.countryCode : 'IN'}`);
    // }
  };

  function formatDate(dateStr) {
    const date = moment(dateStr, 'MM/DD/YYYY');
    return date.format('MMM DD YYYY');
  }

  function formatDateArray(dateArray) {
    if(customXAxis === 1) return dateArray
    console.log("dateArray", { dateArray })
    // const formattedDates = dateArray.map((dateString) => {
    //   const date = new Date(dateString);
    //   const formattedDate = format(date, 'dd MMM');
    //   return formattedDate;
    // });
    return dateArray;
  }

  const exportToPDF = async () => {
    const chartNode = chartRef.current;

    if (chartNode) {
      html2canvas(chartNode).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('highcharts_export.pdf');
      });
    }
  };

  useEffect(() => {
    const { series, categories, colors, chart_type, prevSeries = [] } = options;
    if (series && categories && !sellerSales) {
      console.log('series', series);
      const demoMetrics = JSON.parse(JSON.stringify(DemoMetrics));

      if (chart_type && metricView === 1) {
        demoMetrics.chart.type = chart_type.type;
      }

      if(insights === 1) {
        demoMetrics.chart = { ...demoMetrics.chart, ...chart_type};
      }
      if(!noColor) {
      if (series.length === 1) {
        demoMetrics.colors = ['#0072f0'];
      } else if (series.length === 2) {
        demoMetrics.colors = ['#0072f0', '#fd8021'];
      } else if (series.length === 3) {
        demoMetrics.colors = ['#0072f0', '#fd8021', '#fe20a9'];
      } else if (series.length === 4) {
        demoMetrics.colors = ['#0072f0', '#fd8021', '#fe20a9', '#7cb442'];
      } else {
        demoMetrics.colors = [
          '#22B1C2',
          '#8EC63F',
          '#F07276',
          '#942B70',
          '#D7BE60',
          '#F37725',
          '#282830',
          '#4F2F21',
          '#234856',
          '#93341D',
          '#7D9151',
          '#646E87',
          '#3D0706'
        ];
      }

      if (metricView === 1 && colors) {
        demoMetrics.colors = colors;
      }
      }

      if(colors && Array.isArray(colors) && colors.length > 0) {
        demoMetrics.colors = colors
      }

      demoMetrics.xAxis.categories = metricView && !xAxisDate ? categories : formatDateArray(categories);
      demoMetrics.xAxis.minTickInterval = categories.length > 20 ? Math.ceil(categories.length / 20) : 0;

      if(insights === 1) {
        demoMetrics.xAxis.visible = false
      }

      const findMaxString = categories.reduce((max, str) => Math.max(max, str && str.length), 0);

      console.log('findMaxString', { findMaxString });

      if (tickPositions === 'custom') {
        demoMetrics.xAxis.tickPositions =
          categories.length > 4
            ? Array.from(Array(categories.length - 1 - 0 + 1).keys(), (i) => i + 0).filter(
                (_, index) =>
                  index === 0 ||
                  (index % 6 === 0 && Array.from(Array(categories.length - 1 - 0 + 1).keys(), (i) => i + 0).length - 2 !== index) ||
                  index === Array.from(Array(categories.length - 1 - 0 + 1).keys(), (i) => i + 0).length - 1
              )
            : Array.from(Array(categories.length - 1 - 0 + 1).keys(), (i) => i + 0);
      } else {

        const getCalculateIndex = (end = 0) => {
          if(end) {
            if(categories.length > 150) return 5
            return 2
          }
          if(categories.length > 180) return 12
          if(categories.length > 150) return 10
          if(categories.length > 120) return 8
          if(categories.length > 90) return 6
          if(categories.length > 60) return 5
          if(categories.length > 40) return 4
          if(categories.length > 30) return 2
          if(categories.length < 10) return 1
          return 2
        }
        
        if(categories.length < 10) {
          demoMetrics.xAxis.tickPositions = Array.from(Array(categories.length - 1 - 0 + 1).keys(), (i) => i + 0)
        } else {
          demoMetrics.xAxis.tickPositions = Array.from(Array(categories.length - 1 - 0 + 1).keys(), (i) => i + 0).filter(
            (_, index) =>
              index === 0 ||
              (index % getCalculateIndex() === 0 && Array.from(Array(categories.length - 1 - 0 + 1).keys(), (i) => i + 0).length - getCalculateIndex(1) !== index) ||
              index === Array.from(Array(categories.length - 1 - 0 + 1).keys(), (i) => i + 0).length - 1
          );
        }

        
      }

      // demoMetrics.xAxis.startOnTick = true;
      // demoMetrics.xAxis.endOnTick = true;

      demoMetrics.xAxis.labels = {
        useHTML: true,
        formatter: function () {
          // console.log('labelsformatter1', this);
          // Customize the last tick label
          if (this.isLast || this.isFirst || this.pos % 2 === 0 || categories.length < 10) {
            const formatLabels = (label) => {
              if(String(label).indexOf(',') > -1) {
                return String(label).split(',')[0]
              }
              if (!xAxisDate && metricView === 1 && findMaxString > 20) {
                let getLabel = String(label);
                const splitString = getLabel.split('-');
                if (splitString && splitString.length > 0) {
                  return splitString[0].replaceAll('2022', '22').replaceAll('2023', '23');
                }
                return label;
              }
              return label;
            };
            return demoMetrics.xAxis.categories && demoMetrics.xAxis.categories.length
              ? formatLabels(demoMetrics.xAxis.categories[this.pos])
              : '';
          } else {
            return '';
          }
        }
      };

      const getMinValue = (series, seriesIndex) => {
        console.log('getMinValue', { series, seriesIndex, min: Math.min(...series.data) });
        if (!Math.min(...series.data)) return 0;
        return Math.min(...series.data);
      };
      console.log("before-getSeries", series.concat(prevSeries))
      let seriesCopy = JSON.parse(JSON.stringify(series.concat(prevSeries)))
      if(selectedSeries && selectedSeries.length > 0 && selectedSeries.length != 3) {
        seriesCopy = seriesCopy.filter((row) => (selectedSeries.indexOf(row.name.replace("Prev: ", "")) > -1))
        console.log("after-getSeries", seriesCopy)
      }
      demoMetrics.series = seriesCopy.map((row_data, index) => ({
        name: row_data.name,
        data: row_data.data,
        yAxis: singleYAxis === 1 ? 0 : index,
        lineWidth: 3,
        states: {
                hover: {
                    enabled: true,
                    lineWidth: 4.5
                }
        },
        type: row_data.type ? row_data.type : 'line',
        ...(!noColor ? {colorIndex: index} : {}),
        ...(noColor && row_data.line ? {line: { ...row_data.line }, color: row_data.line.color, lineColor: row_data.line.lineColor, dashStyle: row_data.line.dashStyle, color: row_data.line.color, ...(row_data.line.dashStyle && row_data.line.dashStyle === 'Dash' ? { opacity: 0.5 } : { opacity: 1 })  } : {}),
        showInLegend: !noColor && (optionalMetric || metricView === 1) ? true : false,
        ...(metricView === 1 && {pointWidth: 30})
      }));

      const getColor = (index) => {
        console.log('demoMetrics.colors[index]', demoMetrics.colors[index]);
        return demoMetrics.colors[index];
      };

      demoMetrics.yAxis = series.map((row_data, index) => ({
        min: !noColor && logScale ? getMinValue(row_data, index) : 0,
        title: { text: '' },
        opposite: index % 2 === 1 ? true : false,
        labels: {
          style: { color: noColor && row_data.line && row_data.line.color ? row_data.line.color : getColor(index) }, // metricView === 1 ? "#969696" : 
          formatter: function () {
            let sign = ''
            if(typeOfMetric === 'currency' || row_data.name.toLowerCase().indexOf('revenue') > -1 || row_data.name.toLowerCase().indexOf('spend') > -1) {
              sign = icons[getProfile?.currencyCode ? getProfile?.currencyCode : 'INR']?.symbol
            }

            // console.log('value', getAxiosValue(row_data.name, this.value));
            // if (this.value > 1000) return Highcharts.numberFormat(this.value / 1000, 1) + "T";
            // return Highcharts.numberFormat(getValue(row_data.name, this.value), 0);
            if (metricView === 1) {
              const intlFormat = (num) => {
                return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
              };
              if (this.value >= 1000000000) return sign + intlFormat(this.value / 1000000000) + 'B'; //intlFormat(this.value / 1000000000) + 'B';
              if (this.value >= 1000000) return sign + intlFormat(this.value / 1000000) + 'M'; //intlFormat(this.value / 1000000) + 'M';
              if (this.value >= 100000) return sign + intlFormat(this.value / 100000) + 'L';
              if (this.value >= 1000) return sign + intlFormat(this.value / 1000) + 'T';
              return sign + this.value
            }
            return optionalMetric ? getAxiosValue(optionalMetric.label, this.value) : getAxiosValue(row_data.name, this.value);
          }
        },
        ...(yAxisConfig && { ...yAxisConfig})
      }));

      const maxChars =
        demoMetrics.series && demoMetrics.series.length > 0
          ? demoMetrics.series.reduce((e1, e2) => (e1.name.length > e2.name.length ? e1 : e2))
          : { name: '' };

      demoMetrics.tooltip = {
        useHTML: true,
        shared: true,
        shadow: true,
        backgroundColor: '#FFFFFF',
        borderWidth: 1,
        valueDecimals: 0,
        borderColor: '#hjg',
        borderRadius: 10,

        formatter: function () {
          console.log('formatter', this);
          var symbol = '';

          function symbolConvertor(getSymbol) {
            
            if (getSymbol === 'circle' || noColor) {
              return '<svg class="shapes-tip" viewBox="-10 -10 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13Z" fill="currentColor"/></svg>';
            } else if (getSymbol === 'diamond') {
              return '<svg class="shapes-tip" viewBox="-10 -10 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.9998 6.5L6.49978 13L0 6.5L6.5 0L12.9998 6.5Z" fill="currentColor"/>';
            } else if (getSymbol === 'square') {
              return '<svg class="shapes-tip" viewBox="-10 -10 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="11" height="11" fill="currentColor"/></svg>';
            } else if (getSymbol === 'triangle') {
              return '<svg class="shapes-tip" viewBox="-10 -10 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 13H0L6.5 0L13 13Z" fill="currentColor"/></svg>';
            } else if (getSymbol === 'triangle-down') {
              return '<svg class="shapes-tip" viewBox="-10 -10 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0H13L6.5 13L0 0Z" fill="currentColor"/></svg>';
            }

            return '<svg class="shapes-tip" viewBox="-10 -10 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13Z" fill="currentColor"/></svg>';
          }

          const getMetricFormatNumber = (pointY, pointName) => {

            const customFormatValue = kpiFormatter(pointY, pointName)
            return customFormatValue;   

            // if(metricView) {  
            //   if(typeOfMetric === 'currency' || pointName.toLowerCase().indexOf('revenue') > -1 || pointName.toLowerCase().indexOf('spend') > -1 || pointName.toLowerCase().indexOf('cpc') > -1 || pointName.toLowerCase().indexOf('cpm') > -1) {
            //     if(pointName.toLowerCase().indexOf('cpc') > -1 || pointName.toLowerCase().indexOf('cpm') > -1) return customNumberFormatter(pointY, 'decimal', 1)
            //     return customNumberFormatter(pointY, 'number', 1)
            //   } else if (pointName.toLowerCase().indexOf('aov') > -1 || pointName.toLowerCase().indexOf('roas') > -1 || pointName.toLowerCase().indexOf('acos') > -1) {
            //     return customNumberFormatter(pointY, 'decimal')
            //   } else if (pointName.toLowerCase().indexOf('%') > -1) {
            //     return customNumberFormatter(pointY, 'percentage')
            //   }
            // }
            // const intlFormat = (num) => {
            //   return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
            // };
            // if (pointY >= 1000000000) return intlFormat(pointY / 1000000000) + 'B'; //intlFormat(pointY / 1000000000) + 'B';
            // if (pointY >= 1000000) return intlFormat(pointY / 1000000) + 'M'; //intlFormat(pointY / 1000000) + 'M';
            // if (pointY >= 100000) return intlFormat(pointY / 100000) + 'L';
            // if (pointY >= 1000) return intlFormat(pointY / 1000) + 'T';

            // if (pointName && pointName.indexOf('%') > -1) return (Math.round(pointY * 100) / 100).toFixed(2) + '%';
            // return pointY;
          };

          const htmlString = `
          <div class="my-tool sponsore-box-size ${maxChars && maxChars.name && maxChars.name.length > 20 ? 'my-max-tool' : ''}">
            <span class="tool-date">${this.x}${customXAxis !== 1 && metricView !== 1 ? '' : ''}</span>
            <table>
              ${this.points.map(point => `
              <tr>
                <td>                  
                  <div class="text-dark lft-sd sponsor-tooltip-list">
                    <span class="point-shape" style="color: ${point.color}; text-align: center;">
                        ${symbolConvertor(point.series.symbol)}
                      </span>
                    ${point.series.name}
                  </div>
                  </td>
                  <td>
                  <div class="rht-sd">
                    ${
                      metricView === 1
                        ? (percentage === 1 ? `${(Math.round(point.y * 100) / 100).toFixed(2)}%` : getMetricFormatNumber(point.y, point.series.name))
                        : (optionalMetric ? getValue(optionalMetric.label, point.y) : getValue(point.series.name, point.y))
                    }
                  </div>
                </td>
                </tr>
              `).join('')}
            </table>
          </div>`;
          return htmlString;
        }
      };

      console.log('demoMetricsdemoMetrics', demoMetrics);
      console.log('merticsDatamerticsData', merticsData);

      setMerticsData(demoMetrics);
    } else if (sellerSales) {
      console.log('series', series);
      const demoMetrics = JSON.parse(JSON.stringify(DemoMetrics));

      if (series.length === 1) {
        demoMetrics.colors = ['rgb(46, 140, 184)'];
      } else if (series.length === 2) {
        demoMetrics.colors = ['rgb(46, 140, 184)', 'rgb(221, 119, 3)'];
      } else if (series.length === 3) {
        demoMetrics.colors = ['rgb(46, 140, 184)', 'rgb(221, 119, 3)', 'rgb(163, 49, 123)'];
      } else if (series.length === 4) {
        demoMetrics.colors = ['rgb(46, 140, 184)', 'rgb(221, 119, 3)', 'rgb(163, 49, 123)', 'rgb(88, 112, 33)'];
      } else if (series.length === 5) {
        demoMetrics.colors = ['rgb(46, 140, 184)', 'rgb(221, 119, 3)', 'rgb(163, 49, 123)', 'rgb(88, 112, 33)', '#284BDD'];
      }

      console.log('formatDate(categories-)', categories.length);

      demoMetrics.xAxis = {};

      demoMetrics.xAxis.categories = formatDateArray(categories);
      demoMetrics.xAxis.minTickInterval = categories.length > 20 ? Math.ceil(categories.length / 20) : 0;

      demoMetrics.xAxis.tickPositions = Array.from(Array(categories.length - 1 - 0 + 1).keys(), (i) => i + 0).filter(
        (_, index) =>
          index === 0 ||
          (index % 2 === 0 && Array.from(Array(categories.length - 1 - 0 + 1).keys(), (i) => i + 0).length - 2 !== index) ||
          index === Array.from(Array(categories.length - 1 - 0 + 1).keys(), (i) => i + 0).length - 1
      );

      demoMetrics.xAxis.labels = {
        formatter: function () {
          // Customize the last tick label
          if (this.isLast) {
            return demoMetrics.xAxis.categories && demoMetrics.xAxis.categories.length
              ? demoMetrics.xAxis.categories[demoMetrics.xAxis.categories.length - 1]
              : '';
          } else {
            return this.value;
          }
        }
      };

      demoMetrics.series = series.map((row_data, index) => ({
        name: row_data.name,
        data: row_data.data,
        yAxis: index,
        type: row_data.type,
        colorIndex: index,
        showInLegend: false
      }));

      const getMinValue = (series, seriesIndex) => {
        console.log('getMinValue', { series, seriesIndex, min: Math.min(...series.data) });
        if (!Math.min(...series.data)) return 0;
        return Math.min(...series.data);
      };

      const getColor = (index) => {
        console.log('demoMetrics.colors[index]', demoMetrics.colors[index]);
        return demoMetrics.colors[index];
      };

      demoMetrics.yAxis = series.map((row_data, index) => ({
        min: logScale ? getMinValue(row_data, index) : 0,
        title: { text: '' },
        opposite: index % 2 === 1 ? true : false,
        labels: {
          style: { color: getColor(index) },
          formatter: function () {
            // console.log('value', getAxiosValue(row_data.name, this.value));
            // if (this.value > 1000) return Highcharts.numberFormat(this.value / 1000, 1) + "T";
            // return Highcharts.numberFormat(getValue(row_data.name, this.value), 0);
            return optionalMetric ? getAxiosValue(optionalMetric.label, this.value) : getAxiosValue(row_data.name, this.value);
          }
        }
      }));

      demoMetrics.tooltip = {
        useHTML: true,
        shared: true,
        shadow: true,
        backgroundColor: '#FFFFFF',
        valueDecimals: 0,
        borderColor: '#hjg',
        borderRadius: 10,
        borderWidth: 1,

        formatter: function () {
          console.log('formatter', this);
          var symbol = '';

          function symbolConvertor(getSymbol) {
            if (getSymbol === 'circle') {
              return '<svg class="shapes-tip" viewBox="-10 -10 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13Z" fill="currentColor"/></svg>';
            } else if (getSymbol === 'diamond') {
              return '<svg class="shapes-tip" viewBox="-10 -10 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.9998 6.5L6.49978 13L0 6.5L6.5 0L12.9998 6.5Z" fill="currentColor"/>';
            } else if (getSymbol === 'square') {
              return '<svg class="shapes-tip" viewBox="-10 -10 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="11" height="11" fill="currentColor"/></svg>';
            } else if (getSymbol === 'triangle') {
              return '<svg class="shapes-tip" viewBox="-10 -10 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 13H0L6.5 0L13 13Z" fill="currentColor"/></svg>';
            } else if (getSymbol === 'triangle-down') {
              return '<svg class="shapes-tip" viewBox="-10 -10 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0H13L6.5 13L0 0Z" fill="currentColor"/></svg>';
            }
          }

          const htmlString = `<div class="my-tool"> <span class="tool-date">${
            this.x
          }</span><table class="tool-tab">${this.points
            .map(function (point) {
              return (
                `<tr><td  class="d-flex align-items-center justify-content-center"> <div class="point-shape" style="color: ${
                  point.color
                }; text-align: center;">${symbolConvertor(point.series.symbol)}</div> </td>` +
                `<td class="text-dark" style="font-size: 13px;font-weight: 500; padding-left: 10px;">${point.series.name}</td> ` +
                `<td style="font-weight: 700;font-size: 14px;">${getValue(point.series.name, point.y)}</td>` +
                '</tr>'
              );
            })
            .join('')}</table></div>`;
          return htmlString;
        }
      };

      setMerticsData(demoMetrics);
    } else if (accountSummary) {
      console.log('series', series);
      const demoMetrics = JSON.parse(JSON.stringify(DemoMetrics));

      if (series.length === 1) {
        demoMetrics.colors = ['rgb(46, 140, 184)'];
      } else if (series.length === 2) {
        demoMetrics.colors = ['rgb(46, 140, 184)', 'rgb(221, 119, 3)'];
      } else if (series.length === 3) {
        demoMetrics.colors = ['rgb(46, 140, 184)', 'rgb(221, 119, 3)', 'rgb(163, 49, 123)'];
      } else if (series.length === 4) {
        demoMetrics.colors = ['rgb(46, 140, 184)', 'rgb(221, 119, 3)', 'rgb(163, 49, 123)', 'rgb(88, 112, 33)'];
      } else if (series.length === 5) {
        demoMetrics.colors = ['rgb(46, 140, 184)', 'rgb(221, 119, 3)', 'rgb(163, 49, 123)', 'rgb(88, 112, 33)', '#284BDD'];
      }

      console.log('formatDate(categories-)', categories.length);

      demoMetrics.xAxis.categories = formatDateArray(categories);
      demoMetrics.xAxis.minTickInterval = categories.length > 20 ? Math.ceil(categories.length / 20) : 0;

      demoMetrics.series = series.map((row_data, index) => ({
        name: row_data.name,
        data: row_data.data,
        yAxis: index,
        type: row_data.type,
        colorIndex: index,
        showInLegend: false
      }));
      demoMetrics.yAxis = series.map((row_data, index) => ({
        min: 0,
        title: { text: '' },
        opposite: index === 0 || index === 1 ? false : true,
        labels: {
          style: {
            color:
              (index === 0 && 'rgb(46, 140, 184)') ||
              (index === 1 && 'rgb(221, 119, 3)') ||
              (index === 2 && 'rgb(163, 49, 123)') ||
              (index === 3 && 'rgb(88, 112, 33)')
          },
          formatter: function () {
            console.log('value', getAxiosValue(row_data.name, this.value));
            // if (this.value > 1000) return Highcharts.numberFormat(this.value / 1000, 1) + "T";
            // return Highcharts.numberFormat(getValue(row_data.name, this.value), 0);
            return this.value;
          }
        }
      }));

      demoMetrics.tooltip = {
        useHTML: true,
        shared: true,
        shadow: true,
        backgroundColor: '#FFFFFF',
        borderWidth: 0,
        valueDecimals: 0,
        borderColor: '#hjg',
        borderRadius: 10,
        borderWidth: 1,

        formatter: function () {
          console.log('formatter', this);
          var symbol = '';

          function symbolConvertor(getSymbol) {
            if (getSymbol === 'circle') {
              return '<svg class="shapes-tip" viewBox="-10 -10 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13Z" fill="currentColor"/></svg>';
            } else if (getSymbol === 'diamond') {
              return '<svg class="shapes-tip" viewBox="-10 -10 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.9998 6.5L6.49978 13L0 6.5L6.5 0L12.9998 6.5Z" fill="currentColor"/>';
            } else if (getSymbol === 'square') {
              return '<svg class="shapes-tip" viewBox="-10 -10 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="11" height="11" fill="currentColor"/></svg>';
            } else if (getSymbol === 'triangle') {
              return '<svg class="shapes-tip" viewBox="-10 -10 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 13H0L6.5 0L13 13Z" fill="currentColor"/></svg>';
            } else if (getSymbol === 'triangle-down') {
              return '<svg class="shapes-tip" viewBox="-10 -10 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0H13L6.5 13L0 0Z" fill="currentColor"/></svg>';
            }
          }

          const htmlString = `<div class="my-tool "> <span class="tool-date">${
            this.x
          }</span><table class="tool-tab">${this.points
            .map(function (point) {
              return (
                `<tr><td  class="d-flex align-items-center justify-content-center"> <div class="point-shape" style="color: ${
                  point.color
                }; text-align: center;">${symbolConvertor(point.series.symbol)}</div> </td>` +
                `<td style="color: black;font-size: 13px;font-weight: 500; padding-left: 10px;">${point.series.name}</td> ` +
                `<td style="font-weight: 700;font-size: 14px;">${getValue(point.series.name, point.y)}</td>` +
                '</tr>'
              );
            })
            .join('')}</table></div>`;
          return htmlString;
        }
      };

      setMerticsData(demoMetrics);
      setHighChartRef(null);
    }
  }, [options, options.series, options?.prevSeries, logScale, selectedSeries, typeOfMetric]);

  // if(metricView === 1) return <HighchartsReact highcharts={Highcharts} options={merticsData} />

  return (
    <div>
      <div className="mb-2 d-flex align-items-center justify-content-end w-100 py-3">
        {singleYAxis === 0 && logScaleVisibility === 1 ? (
          <Checkbox
            id={logScaleId != '' ? logScaleId : 'logScaleInput'}
            classes="f-5299f5 f-14 f-w-600 mr-3"
            label={'Log Scale'}
            checked={logScale}
            value={logScale}
            action={(event) => {
              setLogScale(event.target.checked ? 1 : 0);
            }}
          />
        ) : null}

        <DateFilter setDateRangeType={setDateRangeType} dateRangeType={dateRangeType} />
        
        {/* <Button
          classes="py-1 px-3 primary-btn f-13 f-w-600 mr-2 text-light align-items-end ml-3"
          action={async () => {
            exportToPDF();
          }}
          name="Download"
          disabled={false}
        >
        </Button> */}
      </div>
      <div>
        {merticsData && Object.keys(merticsData).length > 0 ? <ResponsiveChart merticsData={merticsData} /> : null}
      </div>
    </div>
  );
};

function ResponsiveChart({merticsData}) {
  const myRef = useResponsiveHighcharts({...merticsData})
  if(myRef) return (
    <HighchartsReact
      highcharts={Highcharts}
      options={merticsData}
      ref={myRef}
    />
  )
  return null
}

export default MetricsChart;
