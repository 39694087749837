import React from 'react'
import logo from 'src/assets/images/logo.png';
import { Link } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';

const Header = () => {

    const { isLoggedIn, user } = useAuth();

    return (
        <nav className="navbar landing-nav navbar-expand-lg navbar-light navbar-default navbar-fixed-top past-main" role="navigation">
            <div className="container">
                <a className="navbar-brand page-scroll bg-transparent" href="/">
                    <img src={logo} alt="Hector Logo" style={{ height: '2.4rem' }} />
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto" />
                    <ul className="navbar-nav my-2 my-lg-0">
                        <li className="nav-item">
                            {!isLoggedIn && <Link className="nav-link text-white f-w-700" to="/auth/signin">
                                Sign In
                            </Link>}
                            {isLoggedIn && <Link className="nav-link text-white" to="/sample-page"> Logged in, {user.fullName} </Link>}
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
export default Header