import React, { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import Group from './drag/Group';
import { Droppable, Draggable } from 'react-beautiful-dnd';

export default function TablesDrag({ tablesList, updateTablesList }) {
  // const [groups, setGroups] = useState([]);
  const [groupTitle, setGroupTitle] = useState('');

  // useEffect(() => {
  //   console.log('tablesList', tablesList);
  //   setGroups([...tablesList]);
  // }, [tablesList]);

  const onDragEnd = (result) => {
    // TODO: Implement logic for handling drag and drop
    console.log('onDragEnd', result);
    const { source, destination } = result;
    console.log('typeAcion', result.type);

    if (!destination || !source) {
      // source.droppableId !== destination.droppableId
      return;
    }

    const draggedIndex = source.index;
    const newIndex = destination.index;

    console.log({ draggedIndex, newIndex });

    if (result.type == 'GROUP') {
      const newGroups = Array.from(tablesList);
      const [removed] = newGroups.splice(result.source.index, 1);
      newGroups.splice(result.destination.index, 0, removed);
      updateTablesList([...newGroups]);
      console.log('newGroups', newGroups);
      updateTablesList([...newGroups]);
    } else {
      // Create a copy of the groups state array
      const newGroups = [...tablesList];

      // Get the group from which the item was dragged
      const groupIndex = parseInt(source.droppableId.split('-')[1]);
      const group = newGroups[groupIndex];

      // Remove the dragged item from its original position
      const [draggedItem] = group.table.splice(draggedIndex, 1);
      // Insert the dragged item into the new position within the same group
      group.table.splice(newIndex, 0, draggedItem);
      console.log('newGroups', newGroups);
      // setGroups(newGroups);
      updateTablesList([...newGroups]);
      updateTablesList([...newGroups])
    }
  };

  const onGroupUpdate = (event, eventOf, groupInfo, tableInfo) => {
    const {
      target: { checked }
    } = event;

    console.log('event', checked);

    if (eventOf == 'group') {
      updateTablesList(
        [...tablesList.map((item) => {
          if (item.group_name === groupInfo.group_name) {
            return {
              ...item,
              show: checked ? 'yes' : 'no',
              table: item.table?.map((table_item) => {
                return {
                  ...table_item,
                  show: checked ? 'yes' : 'no'
                };
              })
            };
          }
          return { ...item };
        })]
      );
    } else if (eventOf == 'table') {
      updateTablesList(
        [...tablesList.map((group_item) => {
          if (group_item.group_name === groupInfo.group_name) {
            return {
              ...group_item,
              table: group_item.table?.map((table_item) => {
                if (table_item?.table_id === tableInfo?.table_id) {
                  return {
                    ...table_item,
                    show: checked ? 'yes' : 'no'
                  };
                }
                return {
                  ...table_item
                };
              })
            };
          }
          return { ...group_item };
        })]
      );
    }
  };

  return (
    <React.Fragment>
      <DragDropContext onDragEnd={onDragEnd}>
        {/* {groups.map((group, index) => ( */}
        <Droppable key={`drop-master-group`} droppableId={`master-group`} type="GROUP">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className={`${snapshot.isDraggingOver ? 'drag-drop-box' : ''}`}>
              {tablesList.map((group, index) => (
                <Draggable
                  key={`drag-${group.group_id}`}
                  draggableId={group.group_id}
                  index={index}
                  type="GROUP"
                  isDragDisabled={groupTitle !== ''}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`${snapshot.isDragging ? 'dragging-item' : ''}`}
                    >
                      <Group
                        key={group.group_id}
                        title={group.group_name}
                        groupTitle={groupTitle}
                        setGroupTitle={setGroupTitle}
                        checkboxes={group.table}
                        groupInfo={group}
                        index={index}
                        keyId={'table_id'}
                        keyName={'table_name'}
                        titleHide={false}
                        childClasses="pl-35 f-10"
                        onGroupUpdate={onGroupUpdate}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </React.Fragment>
  );
}
