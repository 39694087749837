import Highcharts from 'highcharts';

const getValue = (label, value) => {

  const formats = [{ "column_type": "number", "label": "Spend", "param": "Spend" },
  { "column_type": "number", "label": "Revenue", "param": "Revenue" },
  { "column_type": "number", "label": "Orders", "param": "Orders" },
  { "column_type": "number", "label": "Impressions", "param": "Impressions" },
  { "column_type": "number", "label": "Clicks", "param": "Clicks" },
  { "column_type": "decimal", "label": "CPA", "param": "CPA" },
  { "column_type": "decimal", "label": "ROAS", "param": "ROAS" },
  { "column_type": "decimal", "label": "AOV", "param": "AOV" },
  { "column_type": "percentage", "label": "CTR", "param": "CTR" },
  { "column_type": "decimal", "label": "CPM", "param": "CPM" },
  { "column_type": "decimal", "label": "CPC", "param": "CPC" },
  { "column_type": "value", "label": "ACOS", "param": "ACOS" },
  { "column_type": "number", "label": "RPC", "param": "RPC" },
  { "column_type": "number", "label": "CPO", "param": "CPO" },
  { "column_type": "value", "label": "Spend %", "param": "Spend_perc" },
  { "column_type": "value", "label": "Rev%", "param": "Revenue_perc" },
  { "column_type": "value", "label": "CR %", "param": "CR_per" },
  { "column_type": "number", "label": "CC", "param": "CC" }]

  const intlFormat = (num) => {
    return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
  }

  const getFormat = formats.find((metric) => (metric.label === label));
  if (getFormat) {
    const { column_type } = getFormat
    if (!value) return 0;
    if (column_type === 'percentage') return Number(value) >= 10 ? parseInt(value) + '%' : (Math.round(value * 100) / 100).toFixed(2) + '%';
    if (column_type === 'decimal') return value >= 10 ? parseInt(value) : parseFloat(value);
    if (column_type === 'number')
      if (value >= 1000000000) return intlFormat(value / 1000000000) + ' B' //intlFormat(value / 1000000000) + 'B';
    if (value >= 1000000) return intlFormat(value / 1000000) + ' M' //intlFormat(value / 1000000) + 'M';
    return Number(value).toLocaleString('en-IN');
  }
}

const DemoMetrics = {
  chart: {
    type: 'column',
    style: {
      fontFamily: 'Amazon Ember, Arial, Helvetica, sans-serif'
  }
  },
  colors: [],
  title: {
    text: ' '
  },
  subtitle: {
    text: ' '
  },
  xAxis: {
    categories: [],
    crosshair: {
      width: 1,
      color: 'black'
  },
    labels: {
      useHTML: true,
      enabled: true,
      style: {
        width: '50px',
      },
      step: 1
    }
  },
  yAxis: [
    // {
    //   min: 0,
    //   title: {
    //     text: 'Clicks (mm)'
    //   },
    //   // tickPositions: [10, 100, 200, 300,400, 500],
    //   // tickAmount: 8,
    //   opposite: false,

    // },
    // {
    //   min: 0,
    //   title: {
    //     text: 'impression (mm)'
    //   },
    //   // tickPositions: [10, 100, 200, 300,400, 500],
    //   // tickAmount: 6,
    //   opposite: true,
    //   labels: {
    //     formatter: function () {
    //       if (this.value > 1000) return Highcharts.numberFormat(this.value / 1000, 1) + "k";
    //       return Highcharts.numberFormat(this.value, 0);
    //     },
    //   },
    // },

    // {
    //   min: 0,
    //   title: {
    //     text: 'Ad Services (mm)'
    //   },
    //   // tickPositions: [10, 100, 200, 300, 400, 500],
    //   opposite: false,
    // }

  ],


  tooltip: {

  },

  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      pointWidth: 50,
    },
    turboThreshold: 2000,
    series: {
      cropThreshold: 50,

      marker: {
        enabled: false,
        states: {
          c: {
            enabled: true
          }
        }
      }
    },

  },
  // legend: {
  //   layout: 'horizontal',
  //   //borderWidth: 0
  // },
  width: '100px',
  series: [

  ],
  accessibility: {
    enabled: true
  },
  scrollbar: {
    barBackgroundColor: 'gray',
    barBorderRadius: 7,
    barBorderWidth: 0,
    buttonBackgroundColor: 'gray',
    buttonBorderWidth: 0,
    buttonArrowColor: 'yellow',
    buttonBorderRadius: 7,
    rifleColor: 'yellow',
    trackBackgroundColor: 'white',
    trackBorderWidth: 1,
    trackBorderColor: 'silver',
    trackBorderRadius: 7,
    enabled: true
  },
}

export default DemoMetrics