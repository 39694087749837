import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import useWindowSize from '../../../../hooks/useWindowSize';
import { Link } from 'react-router-dom';
import { formatZeroIndexCharAsCapitalize } from 'src/utils/custom-formatters';
import { format, isDate } from 'date-fns';

const NavLeft = () => {
  const { type_rule } = useParams()
  const windowSize = useWindowSize();

  // const configContext = useContext(ConfigContext);
  // const { rtlLayout } = configContext.state;
  // let dropdownRightAlign = false;
  // if (rtlLayout) {
  //   dropdownRightAlign = true;
  // }

  let navItemClass = ['nav-item'];
  if (windowSize.width <= 575) {
    navItemClass = [...navItemClass, 'd-none'];
  }

  const [pageTitle, setPageTitle] = useState('');

  const getProfile = JSON.parse(localStorage.getItem('selectedProfileId'))
  const [activatedDate, setActivatedDate] = useState('')
  const isAmazonMarketingStream = window.location.href.indexOf('/marketing-stream') > -1

  console.log(getProfile, 'getProfileIdIDs')

  useEffect(() => {
    if (getProfile?.marketingStream && getProfile?.marketingStream?.datasets && Object.keys(getProfile?.marketingStream?.datasets).length > 0) {
      getConsolidatedNextDay(getProfile?.marketingStream?.datasets).then((response) => {
        setActivatedDate(isDate(response) ? new Date(response) : '')
      })
    }
  }, [])


  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const updateNavTitle = () => {
      if (
        location.pathname.indexOf('/summary/flipkart') > -1 ||
        location.pathname.indexOf('/summary/instamart') > -1 ||
        location.pathname.indexOf('/amazon/targeting') > -1 ||
        location.pathname.indexOf('/summary/myntra') > -1 ||
        location.pathname.indexOf('/summary/blinkit') > -1
      ) {
        setPageTitle('Targeting 360');
      } else if (location.pathname.includes('categorization')) {
        const isProduct = ['flipkart-categorization', 'instamart-categorization', 'myntra-categorization', 'blinkit-categorization'].some(
          (category) => location.pathname.includes(category)
        );
        const isTextual = location.pathname.includes('text-categorization');
        const isAsin = location.pathname.includes('categorization') && !isTextual && !isProduct;
        setPageTitle(isTextual ? 'Text Categorization' : isProduct ? 'Product Categorization' : isAsin ? 'ASIN Categorization' : '');
      } else if (location.pathname.indexOf('amazon-automated-rules') > -1) {
        const isCreatePage = window.location.href.includes('create');
        const isTypeRule =
          location.pathname.indexOf('budget') > -1
            ? 'Budget'
            : location.pathname.indexOf('status') > -1
            ? 'Status'
            : location.pathname.indexOf('bid') > -1
            ? 'Bid'
            : location.pathname.indexOf('placement') > -1
            ? 'Placement'
            : location.pathname.indexOf('searchterm') > -1
            ? 'Search term'
            : '';
        const title = isCreatePage ? `Automated Rules Creation - ${isTypeRule}` : `Automated Rules List - ${isTypeRule}`;
        setPageTitle(title);
      } else if (location.pathname.indexOf('/cpc/history') > -1) {
        setPageTitle('CPC History');
      } else if (location.pathname.indexOf('/blacklist') > -1) {
        setPageTitle('Blacklist');
      } else if (location.pathname.indexOf('/settings') > -1) {
        setPageTitle('Settings');
      } else if (location.pathname.indexOf('/account-settings') > -1) {
        setPageTitle('Account Billing Information');
      } else if (location.pathname.toLowerCase().indexOf('/add-profile') > -1) {
        setPageTitle('Select Profile to Add');
      } else if (location.pathname.toLowerCase().indexOf('/profile') > -1) {
        setPageTitle('Profiles');
      } else if (location.pathname.toLowerCase().indexOf('/amazon-user-management') > -1) {
        setPageTitle('User Management');
      } else if (location.pathname.indexOf('/admin/useraccountdetails') > -1) {
        setPageTitle('User Account Details');
      } else if (location.pathname.indexOf('snapshot') > -1) {
        setPageTitle('Snapshot');
      } else if (location.pathname.indexOf('/edit-profile') > -1) {
        setPageTitle('Edit Profile');
      } else if (location.pathname.indexOf('master-overview') > -1) {
        setPageTitle('Master Overview');
      } else if (location.pathname.indexOf('/custom/payments') > -1) {
        setPageTitle('Custom Payments');
      } else if (location.pathname.indexOf('/admin/coupon') > -1) {
        setPageTitle('Coupon Management');
      } else if (location.pathname.indexOf('/seller-sales-new') > -1) {
        setPageTitle('Seller Sales');
      } else if (location.pathname.indexOf('/admin/client-access-details') > -1) {
        setPageTitle('Client Access Details');
      } else if (location.pathname.indexOf('/admin/manual-transaction-details') > -1) {
        setPageTitle('Manual Transaction Details');
      } else if (location.pathname.indexOf('/amazon-hector-sov') > -1) {
        setPageTitle('Hector SOV');
      } else if (location.pathname.indexOf('user-activity') > -1) {
        setPageTitle('User Activity');
      } else if (location.pathname.indexOf('amazon-blacklist-keywords') > -1) {
        setPageTitle('Blacklist Keywords');
      } else if (location.pathname.indexOf('dayparting-performance') > -1) {
        setPageTitle('Hourly Performance');
      } else if (location.pathname.indexOf('dayparting-scheduler') > -1) {
        setPageTitle('Schedule Performance');
      } else if (location.pathname.indexOf('dayparting-history') > -1) {
        setPageTitle('Dayparting History');
      } else if (location.pathname.indexOf('dayparting-schedule-log') > -1) {
        setPageTitle('Dayparting Log');
      } else if (location.pathname.indexOf('bid-scheduler') > -1) {
        setPageTitle('Bid Scheduler');
      } else if (location.pathname.indexOf('amazon-audience-report') > -1) {
        setPageTitle('Audience Report');
      } else if (location.pathname.indexOf('amazon-create-audience') > -1) {
        setPageTitle('Create Audience');
      } else if(location.pathname.indexOf('amazon-dsp') > -1) {
        setPageTitle('Campaign Manager')
      } else if (location.pathname.indexOf('amazon-insights') > -1) {
        setPageTitle('Amazon Insights');
      } else if (location.pathname.indexOf('amazon-path-to-conversion') > -1) {
        setPageTitle('Path to Conversion');
      } else if (location.pathname.indexOf('amazon-history') > -1) {
        setPageTitle('Ad Account History');
      } else if (location.pathname.indexOf('/amazon/marketing-stream') > -1) {
        setPageTitle('Amazon Marketing Stream');
      } else if (location.pathname.indexOf('amazon-view-audience') > -1) {
        setPageTitle('View Audience');
      } else {
        setPageTitle('');
      }
    };

    setTimeout(() => {
      window.scrollTo(0, 0);
      updateNavTitle();
    }, 100);

    return () => {
      setPageTitle('');
    };
  }, [location.pathname]);

  return (
    <React.Fragment>
      <ListGroup as="ul" bsPrefix=" " className="navbar-nav mr-auto page-title-list">
        {/* <ListGroup.Item as="li" bsPrefix=" " className={navItemClass.join(' ')}></ListGroup.Item> */}
        <ListGroup.Item
          as="li"
          bsPrefix=" "
          className={
            pageTitle === 'Create Audience' || pageTitle === 'View Audience'  || pageTitle === 'Audience Creation' || pageTitle === 'Path to Conversion'
              ? 'nav-item d-flex'
              : 'nav-item'
          }
        >
          {/* <NavSearch windowWidth={windowSize.width} /> */}
          {pageTitle != '' ? (
            <>
              {(pageTitle === 'Create Audience' ||
                pageTitle === 'View Audience' ||
                pageTitle === 'Audience Creation' ||
                pageTitle === 'Path to Conversion') && (
                <Link
                  to={pageTitle === 'Create Audience' || pageTitle === 'View Audience' || pageTitle === 'Audience Creation' ? '/amazon-audience-report' : '/amazon-insights'}
                  className="position-relative amc-left-nav"
                >
                  <svg
                    fill="#000000"
                    height="18px"
                    width="18px"
                    version="1.1"
                    id="Layer_1"
                    viewBox="0 0 330 330"
                  >
                    <path
                      id="XMLID_29_"
                      d="M100.606,100.606L150,51.212V315c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15V51.212l49.394,49.394  C232.322,103.535,236.161,105,240,105c3.839,0,7.678-1.465,10.606-4.394c5.858-5.857,5.858-15.355,0-21.213l-75-75  c-5.857-5.858-15.355-5.858-21.213,0l-75,75c-5.858,5.857-5.858,15.355,0,21.213C85.251,106.463,94.749,106.463,100.606,100.606z"
                    />
                  </svg>
                </Link>
              )}
              <div className='d-flex align-items-center gap-10'>
                <h3 className="f-22 f-w-700 text-dark">{pageTitle}</h3>
                {isAmazonMarketingStream && activatedDate != '' ? <div className="d-flex align-items-center f-10 gap-8">
                  (<span className='f-w-600'>since data available from</span>
                  <span className='f-w-800'>{format(new Date(activatedDate), 'MMM dd, yyyy')}</span>)
                </div> : null}
              </div>

            </>
          ) : null}
        </ListGroup.Item>
      </ListGroup>
    </React.Fragment>
  );
};

const getConsolidatedNextDay = async (datasets) => {
  const getNextDay = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    return date;
  };

  const streamStartDates = Object.values(datasets).map(item => new Date(item.streamStartDate));
  const earliestStreamStartDate = new Date(Math.min(...streamStartDates));
  return getNextDay(earliestStreamStartDate)

}

export default NavLeft;
