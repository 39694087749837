import React, { useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import CopyIcon from 'src/assets/icons/CopyIconV2';

const Input = ({ id, label, classes, action, title, checked, value, name, disabled = 0, copyText = false }) => {
  const sectionRef = useRef(null);

  const handleMouseEnter = () => {
    if (sectionRef.current) {
      sectionRef.current.classList.add('show-Copy');
    }
  };

  const handleMouseLeave = () => {
    if (sectionRef.current) {
      sectionRef.current.classList.remove('show-Copy');
    }
  };
  return (
    <React.Fragment>
      {/* <div className={`form-group check-box ${classes}`}>
        <input type="checkbox" className="mr-2" checked={checked} id={id} value={value} onChange={action} />
        <label htmlFor={id}>{label}</label>
      </div> */}
    <section  
      ref={sectionRef}
      className={`d-flex align-items-center gap-4 ${checked ? 'checkedFontColor' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      >
      <label className={`checkbox ${classes}`} htmlFor={id}>
        <input type="checkbox" name={name} checked={checked} id={id} value={value} onChange={action} disabled={disabled} />
        <span className="checkmark"></span>
        {label && <span title={title?label:''} className="ml-2">{label}</span>}
      </label>
      {copyText ? <CopyAction label={label} /> : null}
      </section>
    </React.Fragment>
  );
};

export const CopyAction = ({label, customClass = 'copy_icn_v2'}) => {
  const copyRef = useRef(null)

  return (
    <IconButton
        aria-label="copy"
        size="small"
        ref={copyRef}
        onClick={(e) => {
          e.stopPropagation();
          copyRef.current.classList.add('animate');
          navigator.clipboard.writeText(label);
          setTimeout(() => {
            copyRef.current?.classList?.remove('animate');
          }, 500);
        }}
        className={customClass}
      >
      <CopyIcon width={15} height={15} />
    </IconButton>
  )
}

export default Input;
