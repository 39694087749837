import React, { createContext, useReducer, useContext } from 'react';


const User = JSON.parse(localStorage.getItem('User'))
const SideMenuStatus = localStorage.getItem('side-menu-status')


// Initial state for the context
const initialState = {
  userInfo: {
    fullName: User?.fullName || '',
    firstName: User?.firstName || '',
    phoneNumber: User?.phoneNumber || '',
    email: User?.email || '',
    token: User?.token || '',
    profileStatus: User?.profileStatus || false,
    flipkartProfileStatus: User?.flipkartProfileStatus || false,
    myntraProfileStatus: User?.myntraProfileStatus || false,
    blinkitProfileStatus: User?.blinkitProfileStatus || false,
    instamartProfileStatus : User?.instamartProfileStatus || false
  },
  selectedAmazon: {},
  selectedFlipkart: {},
  billingInfo: {
    durationId: "",
    endDate : "",
    isTrail: 0,
    message: "",
    nextPayment: "",
    numOfAdAccount: 1,
    numOfUserCount: 1,
    planName: "Startup",
    startDate: ""
  },
  lockStatus: 1,
  overlay: {},
  dateInfo: {}
};

// Define the reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_USER':
      return { ...state, userInfo: { ...state.userInfo, ...action.payload } };
    case 'UPDATE_BILLING_INFO':
      return { ...state, billingInfo: { ...state.billingInfo, ...action.payload } };
    case 'UPDATE_LOCK_STATUS':
      return { ...state, ...action.payload };
    case 'UPDATE_OVERLAY_MODAL':
      return { ...state, overlay: { ...state.overlay, ...action.payload } };
    case 'UPDATE_DATE_AVAILABLE':
      return { ...state, dateInfo: { ...action.payload } };
    default:
      return state;
  }
};

// Create the context
const CommonContext = createContext();

// Create a custom hook to use the context
const useCommonContext = () => {
  const context = useContext(CommonContext);
  if (!context) {
    throw new Error('useCommonContext must be used within a CommonProvider');
  }
  return context;
};

// Create the provider component
const CommonProvider = ({ children }) => {
  const [commonState, commonDispatch] = useReducer(reducer, initialState);

  return (
    <CommonContext.Provider value={{ commonState, commonDispatch }}>
      {children}
    </CommonContext.Provider>
  );
};

export { CommonProvider, useCommonContext };