import React, { createContext, useReducer, useContext } from 'react';


const selectedProfile = JSON.parse(localStorage.getItem('selectedProfileId'))

// Initial state for the context
const initialState = {
  selectedAmazonProfile: {
    ...( selectedProfile ? { ...selectedProfile } : {} )
  },
  amazonProfiles: [],
}

// Define the reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_SELECTED_AMAZON_PROFILE':
      return { ...state, selectedAmazonProfile: action.payload };
    case 'UPDATE_AMAZON_ACTIVE_PROFILES':
        return { ...state, amazonProfiles: action.payload };
        default:
      return state;
  }
};

// Create the context
const AmzContext = createContext();

// Create a custom hook to use the context
const useAmzContext = () => {
  const context = useContext(AmzContext);
  if (!context) {
    throw new Error('useAmzContext must be used within a AmazProvider');
  }
  return context;
};

// Create the provider component
const AmazProvider = ({ children }) => {
  const [amzState, amzDispatch] = useReducer(reducer, initialState);

  return (
    <AmzContext.Provider value={{ amzState, amzDispatch }}>
      {children}
    </AmzContext.Provider>
  );
};

export { AmazProvider, useAmzContext };