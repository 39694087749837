

export const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'INR',
});


// export default function USDollar()
//  new Intl.NumberFormat('en-US', {
//     style: 'currency',
//     currency: 'USD',
// });