import { startOfMonth, subMonths, endOfMonth, addDays, startOfDay, subDays, endOfDay, getHours, getMinutes } from 'date-fns';
import React, { createContext, useReducer, useContext, useEffect } from 'react';
import { zoneToData } from 'src/config/timezone';

// Initial state for the context

// console.log("checkData", addDays(new Date(), 0))
// console.log("checkDataTz", new Date(zoneToData(addDays(new Date(), 0))))

const hourFilterList = [
  { label: '12 AM - 1 AM', value: '00:00:00', checked: 1 },
  { label: '01 AM - 2 AM', value: '01:00:00', checked: 1 },
  { label: '02 AM - 3 AM', value: '02:00:00', checked: 1 },
  { label: '03 AM - 4 AM', value: '03:00:00', checked: 1 },
  { label: '04 AM - 5 AM', value: '04:00:00', checked: 1 },
  { label: '05 AM - 6 AM', value: '05:00:00', checked: 1 },
  { label: '06 AM - 7 AM', value: '06:00:00', checked: 1 },
  { label: '07 AM - 8 AM', value: '07:00:00', checked: 1 },
  { label: '08 AM - 9 AM', value: '08:00:00', checked: 1 },
  { label: '09 AM - 10 AM', value: '09:00:00', checked: 1 },
  { label: '10 AM - 11 AM', value: '10:00:00', checked: 1 },
  { label: '11 AM - 12 PM', value: '11:00:00', checked: 1 },
  { label: '12 PM - 1 PM', value: '12:00:00', checked: 1 },
  { label: '01 PM - 2 PM', value: '13:00:00', checked: 1 },
  { label: '02 PM - 3 PM', value: '14:00:00', checked: 1 },
  { label: '03 PM - 4 PM', value: '15:00:00', checked: 1 },
  { label: '04 PM - 5 PM', value: '16:00:00', checked: 1 },
  { label: '05 PM - 6 PM', value: '17:00:00', checked: 1 },
  { label: '06 PM - 7 PM', value: '18:00:00', checked: 1 },
  { label: '07 PM - 8 PM', value: '19:00:00', checked: 1 },
  { label: '08 PM - 9 PM', value: '20:00:00', checked: 1 },
  { label: '09 PM - 10 PM', value: '21:00:00', checked: 1 },
  { label: '10 PM - 11 PM', value: '22:00:00', checked: 1 },
  { label: '11 PM - 12 PM', value: '23:00:00', checked: 1 },
];


const authToken = JSON.parse(localStorage.getItem('User')) || {};
const isDemoLogin = process.env.REACT_APP_DEMO_EMAIL.indexOf(authToken?.email) > -1

const initialState = {
  dateRange: isDemoLogin && ( window.location.href.indexOf('snapshot') > -1 || window.location.href.indexOf('master-overview') > -1) ? {
    startDate: new Date(zoneToData(subDays(new Date(), 30))),
    endDate: new Date(zoneToData(subDays(new Date(), 1))),
    pastStartDate: new Date(zoneToData(subDays(new Date(), 60))),
    pastEndDate: new Date(zoneToData(subDays(new Date(), 31))),
    key: 'Last 30 Days',
    compare: 1
  } : window.location.href.indexOf('flipkart-snapshot') > -1 ? {
    startDate: new Date(zoneToData(startOfDay(subDays(new Date(), 7)))),
    endDate: new Date(zoneToData(addDays(new Date(), -1))),
    pastStartDate: new Date(zoneToData(startOfDay(subDays(new Date(), 14)))),
    pastEndDate: new Date(zoneToData(endOfDay(subDays(new Date(), 8)))),
    key: 'Last 7 Days',
    compare: 1
  } : window.location.href.indexOf('snapshot') > -1 ? {
    startDate: new Date(zoneToData(addDays(new Date(), -1))),
    endDate: new Date(zoneToData(addDays(new Date(), -1))),
    pastStartDate: new Date(zoneToData(addDays(new Date(), -2))),
    pastEndDate: new Date(zoneToData(addDays(new Date(), -2))),
    key: 'Yesterday',
    compare: 1
  } :  window.location.href.indexOf('master-overview') > -1 || window.location.href.indexOf('sov') > -1 ? {
    startDate: new Date(zoneToData(startOfDay(subDays(new Date(), 7)))),
    endDate: new Date(zoneToData(addDays(new Date(), -1))),
    pastStartDate: new Date(zoneToData(startOfDay(subDays(new Date(), 14)))),
    pastEndDate: new Date(zoneToData(endOfDay(subDays(new Date(), 8)))),
    key: 'Last 7 Days',
    compare: 1
  } :  window.location.href.indexOf('/amazon-dayparting-scheduler') > -1 || window.location.href.indexOf('/amazon-dayparting-performance') > -1 ? {
    startDate: new Date(zoneToData(subDays(new Date(), 30))),
    endDate: new Date(zoneToData(subDays(new Date(), 1))),
    pastStartDate: new Date(zoneToData(subDays(new Date(), 60))),
    pastEndDate: new Date(zoneToData(subDays(new Date(), 31))),
    key: 'Last 30 Days',
    compare: 1
  }: window.location.href.indexOf('/amazon-dsp') > -1 ? {
    startDate:  new Date(zoneToData(subDays(new Date(), 7))),
    endDate:  new Date(zoneToData(subDays(new Date(), 1))),
    pastStartDate:  new Date(zoneToData(subDays(new Date(), 14))),
    pastEndDate:  new Date(zoneToData(subDays(new Date(), 8))),
    key: 'Last 7 Days'
  } : window.location.href.indexOf('amazon/marketing-stream') > -1 ? {
    startDate: new Date(zoneToData(new Date())),
    endDate: new Date(zoneToData(new Date())),
    pastStartDate: new Date(zoneToData(subDays(new Date(), 1))),
    pastEndDate: new Date(zoneToData(subDays(new Date(), 1))),
    key: 'Today'
  } : {
    startDate: new Date(zoneToData(subDays(new Date(), 30))),
    endDate: new Date(zoneToData(subDays(new Date(), 1))),
    pastStartDate: new Date(zoneToData(subDays(new Date(), 60))),
    pastEndDate: new Date(zoneToData(subDays(new Date(), 31))),
    key: 'Last 30 Days'
  },
  hourFilter: hourFilterList,
  crossFilters: [],
  availableColumns: [],
  availableTables: {},
  clearCrossFilters: false,
  applyCrossFilters: false
}



// Define the reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_DATE_RANGE':
      return { ...state, dateRange: action.payload };
    case 'UPDATE_SOV_FILTERS':
      return { ...state, filters: action.payload };
    case 'UPDATE_CROSS_FILTERS':
      return { ...state, crossFilters: action.payload };
    case 'CLEAR_CROSS_FILTERS':
      return { ...state, clearCrossFilters: action.payload };
    case 'APPLY_CROSS_FILTERS':
      return { ...state, applyCrossFilters: action.payload };
    case 'UPDATE_AVAILABLE_COLUMNS':
      return { ...state, availableColumns: action.payload };
    case 'UPDATE_AVAILABLE_TABLES':
      return { ...state, availableTables: action.payload };
    case 'UPDATE_CALENDAR_DATES':
      return { ...state, dateRange: action.payload };
    case 'UPDATE_HOURS_FILTER':
      return { ...state, HoursFilter: action.payload };
    default:
      return state;
  }
};

// Create the context
const PayloadContext = createContext();

// Create a custom hook to use the context
const usePayloadContext = () => {
  const context = useContext(PayloadContext);
  if (!context) {
    throw new Error('usePayloadContext must be used within a PayloadProvider');
  }
  return context;
};

// Create the provider component
const PayloadProvider = ({ children }) => {

  const [payloadState, payloadDispatch] = useReducer(reducer, initialState);
  
  return (
    <PayloadContext.Provider value={{ payloadState, payloadDispatch }}>
      {children}
    </PayloadContext.Provider>
  );
};

export { PayloadProvider, usePayloadContext };

