import amazon from 'src/assets/images/common/amazon-white.svg';
import clientlist from 'src/assets/images/common/client-list.svg';
import coupendash from 'src/assets/images/common/coupen-dashboard.svg';
import dashboard from 'src/assets/images/common/dashboard.svg';
import flipkart from 'src/assets/images/common/flipkart-white.svg';
import settings from 'src/assets/images/common/settings-white.svg';
import automatedrules from 'src/assets/images/common/automated-rules.svg';
import blacklist from 'src/assets/images/common/blacklist.svg';
import harvesting from 'src/assets/images/common/harvesting.svg';
//import MasterOverviewIcon from 'src/assets/images/common/master-overview.svg';
import sellersales from 'src/assets/images/common/seller-sales.svg';
import snapshot from 'src/assets/images/common/snapshot-white.svg';
import useraccount from 'src/assets/images/common/user-account.svg';
import UserIcon from 'src/assets/images/common/user-input-menu.svg';
import usertimespend from 'src/assets/images/common/user-time-spend.svg';
import DaypartIcon from 'src/assets/images/common/daypart.svg';
import ClockIcon from 'src/assets/images/common/clock.svg';
import myntra from 'src/assets/images/myntra-white.svg';
import blinkit from 'src/assets/images/common/blinkit-logo.svg'
import { ReactComponent as SnapshotIcon } from 'src/assets/images/common/snapshot-white.svg';
import { ReactComponent as FlipkartIcon } from 'src/assets/images/common/flipkart-white.svg';
import { ReactComponent as AmazonIcon } from 'src/assets/images/common/amazon-white.svg';
import { ReactComponent as MasterOverviewIcon } from 'src/assets/images/common/master-overview.svg';
import { ReactComponent as MyntraIcon } from 'src/assets/images/myntra-white.svg';
import { ReactComponent as SettingsIcon } from 'src/assets/images/common/settings-white.svg';
import { ReactComponent as DaypartIconIcon } from 'src/assets/images/common/daypart.svg';
import { ReactComponent as UserActivityIcon } from 'src/assets/images/common/harvesting.svg';
import { ReactComponent as TimeSpendIcon } from 'src/assets/images/common/user-time-spend.svg';
import { ReactComponent as ClientListIcon } from 'src/assets/images/common/client-list.svg';
import { ReactComponent as UserAccountDetailsIcon } from 'src/assets/images/common/user-account.svg';
import { ReactComponent as CoupenIcons } from 'src/assets/images/common/coupen-dashboard.svg';
import { ReactComponent as CustomPaymentsIcon } from 'src/assets/images/common/seller-sales.svg';
import { ReactComponent as ClientAccessIcon } from 'src/assets/images/common/user-input-menu.svg';
import { ReactComponent as BlinkitIcon } from 'src/assets/images/common/blinkit-single.svg';
import { ReactComponent as DashboardIcon } from 'src/assets/images/common/ams-dash.svg';
import { ReactComponent as HectorGptIcon } from 'src/assets/images/common/chat.svg';
import instamart from 'src/assets/images/common/instamart-logo.svg'

const menuItems = {
  flipkartItems: [
    {
      id: 'navigation',
      title: ' ',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'flipkart-dashboard',
          title: 'Flipkart Dashboard',
          type: 'item',
          icon: flipkart,
          url: '/summary/flipkart'
        }
      ]
    }
  ],
  myntraItems: [
    {
      id: 'navigation',
      title: ' ',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'myntra-dashboard',
          title: 'Myntra Dashboard',
          type: 'item',
          icon: myntra,
          url: '/summary/myntra'
        }
      ]
    }
  ],
  blinkitItems: [
    {
      id: 'navigation',
      title: ' ',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'blinkit-dashboard',
          title: 'Blinkit Dashboard',
          type: 'item',
          icon: blinkit,
          url: '/summary/blinkit'
        }
      ]
    }
  ],
  instamartItems: [
    {
      id: 'navigation',
      title: ' ',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'instamart-dashboard',
          title: 'Instamart Dashboard',
          type: 'item',
          icon: instamart,
          url: '/summary/instamart'
        }
      ]
    }
  ],
  superAdminItems: [
    // {
    //   id: 'navigation',
    //   title: ' ',
    //   type: 'group',
    //   icon: 'icon-navigation',
    //   isProfile: 1,
    //   children: [
    //     {
    //       id: 'ams-dashboard',
    //       title: 'AMS Dashboard',
    //       type: 'item',
    //       icon: amazon,
    //       external: 1,
    //       url: '/amazon/targeting'
    //     },
    //     {
    //       id: 'snapshot-dashboard',
    //       title: 'Snapshot',
    //       type: 'item',
    //       icon: snapshot,
    //       external: 1,
    //       url: '/amazon-snapshot'
    //     },
    //     {
    //       id: 'master-overview-dashboard',
    //       title: 'Master Overview',
    //       type: 'item',
    //       icon: MasterOverviewIcon,
    //       external: 1,
    //       url: '/amazon-master-overview'
    //     },
    //     {
    //       id: 'amazon-dayparting-performance',
    //       title: 'Dayparting Performance',
    //       type: 'item',
    //       icon: DaypartIcon,
    //       external: 1,
    //       url: '/amazon-dayparting-performance'
    //     },
    //     {
    //       id: 'flipkart-dashboard',
    //       title: 'Flipkart Dashboard',
    //       type: 'item',
    //       icon: flipkart,
    //       external: 1,
    //       url: '/summary/flipkart'
    //     },
    //     {
    //       id: 'user-activity-dashboard',
    //       title: 'Widget',
    //       type: 'item',
    //       classes: 'nav-item',
    //       url: '/user-activity/dashboard',
    //       key: '10',
    //       icon: harvesting
    //     },
    //     {
    //       id: 'user-timespend-dashboard',
    //       title: 'Timespend',
    //       type: 'item',
    //       classes: 'nav-item',
    //       url: '/user-activity/timespend',
    //       key: '11',
    //       icon: usertimespend
    //     },
    //     {
    //       id: 'user-clients-dashboard',
    //       title: 'Clients',
    //       type: 'item',
    //       classes: 'nav-item',
    //       url: '/user-activity/clients',
    //       key: '12',
    //       icon: clientlist
    //     },
    //     {
    //       id: 'user-account-dashboard',
    //       title: 'User Account Details',
    //       type: 'item',
    //       classes: 'nav-item',
    //       url: '/admin/useraccountdetails',
    //       key: '12',
    //       icon: useraccount
    //     },
    //     {
    //       id: 'user-coupon-dashboard',
    //       title: 'Coupons',
    //       type: 'item',
    //       classes: 'nav-item',
    //       url: '/admin/coupons',
    //       key: '14',
    //       icon: coupendash
    //     },
    //     {
    //       id: 'user-custom-payments',
    //       title: 'Custom Payments',
    //       type: 'item',
    //       classes: 'nav-item',
    //       url: '/custom/payments',
    //       key: '19',
    //       icon: sellersales
    //     },
    //     {
    //       id: 'user-access-details',
    //       title: 'Client Access Details',
    //       type: 'item',
    //       classes: 'nav-item',
    //       url: '/admin/client-access-details',
    //       key: '29',
    //       icon: UserIcon
    //     }
    //   ]
    // }
    {
      title: 'Amazon',
      icon: <AmazonIcon />,
      items: [
        { 
          label: 'Targeting 360', 
          icon: <DashboardIcon />, 
          path: '/amazon/targeting' 
        },
        { 
          label: 'Snapshot', 
          icon: <SnapshotIcon />, 
          path: '/amazon-snapshot' 
        },
        { 
          label: 'Master Overview', 
          icon: <MasterOverviewIcon />, 
          path: '/amazon-master-overview' 
        },
        {
          label: 'Dayparting',
          icon: <DaypartIconIcon />,
          path: '/amazon-dayparting-performance'
        },
      ]
    },
    {
      title: 'Flipkart',
      icon: <FlipkartIcon />,
      items: [
        { 
          label: 'Targeting 360', 
          icon: <DashboardIcon />, 
          path: '/summary/flipkart' 
        },
        { 
          label: 'Snapshot', 
          icon: <SnapshotIcon />, 
          path: '/flipkart-snapshot' 
        },
        { 
          label: 'Master Overview', 
          icon: <MasterOverviewIcon />, 
          path: '/flipkart-master-overview' 
        }
      ]
    },
  ],
  items: [
    {
      id: 'navigation',
      title: ' ',
      type: 'group',
      icon: 'icon-navigation',
      isProfile: 1,
      children: [
        // {
        //   id: 'flipkart-dashboard',
        //   title: 'Flipkart Dashboard',
        //   type: 'item',
        //   icon: snapshot,
        //   external: 1,
        //   url: '/summary/flipkart'
        // },
        {
          id: 'ams-dashboard',
          title: 'AMS Dashboard',
          type: 'item',
          icon: amazon,
          external: 1,
          url: '/amazon/targeting'
        },
        {
          id: 'snapshot-dashboard',
          title: 'Snapshot',
          type: 'item',
          icon: snapshot,
          external: 1,
          url: '/amazon-snapshot'
        },
        {
          id: 'master-overview-dashboard',
          title: 'Master Overview',
          type: 'item',
          icon: MasterOverviewIcon,
          external: 1,
          url: '/amazon-master-overview'
        },
        {
          id: 'amazon-dayparting-performance',
          title: 'Dayparting Performance',
          type: 'item',
          icon: DaypartIcon,
          external: 1,
          url: '/amazon-dayparting-performance'
        },
      ]
    }
  ],
  amzAndFkItems: [
    {
      id: 'navigation',
      title: ' ',
      type: 'group',
      icon: 'icon-navigation',
      isProfile: 1,
      children: [
        {
          id: 'ams-dashboard',
          title: 'AMS Dashboard',
          type: 'item',
          icon: amazon,
          external: 1,
          url: '/amazon/targeting'
        },
        {
          id: 'snapshot-dashboard',
          title: 'Snapshot',
          type: 'item',
          icon: snapshot,
          external: 1,
          url: '/amazon-snapshot'
        },
        {
          id: 'master-overview-dashboard',
          title: 'Master Overview',
          type: 'item',
          icon: MasterOverviewIcon,
          external: 1,
          url: '/amazon-master-overview'
        },
        {
          id: 'amazon-dayparting-performance',
          title: 'Dayparting Performance',
          type: 'item',
          icon: DaypartIcon,
          external: 1,
          url: '/amazon-dayparting-performance'
        },
        {
          id: 'flipkart-dashboard',
          title: 'Flipkart Dashboard',
          type: 'item',
          icon: flipkart,
          external: 1,
          url: '/summary/flipkart'
        },
        // {
        //   id: 'amazon-dayparting-performance',
        //   title: 'Dayparting Performance',
        //   type: 'item',
        //   icon: DaypartIcon,
        //   external: 1,
        //   url: '/amazon-dayparting-performance'
        // },
        // {
        //   id: 'amazon-bid-scheduler',
        //   title: 'Bid Scheduler',
        //   type: 'item',
        //   icon: ClockIcon,
        //   external: 1,
        //   url: '/amazon-bid-scheduler'
        // },
        // {
        //   id: 'sov-dashboard',
        //   title: 'SOV Dashboard',
        //   type: 'item',
        //   icon: dashboard,
        //   external: 1,
        //   url: '/amazon-hector-sov'
        // },

        // {
        //   id: 'sov-settings',
        //   title: 'SOV Settings',
        //   type: 'item',
        //   icon: usertimespend,
        //   external: 1,
        //   url: '/settings/amazon'
        // }
      ]
    }
  ],
  newMenuItems: [
    {
      title: 'Amazon',
      icon: amazon,
      items: [
        { label: 'Snapshot', icon: snapshot },
        { label: 'Master Overview', icon: <MasterOverviewIcon /> }
      ]
    },
    {
      title: 'Flipkart',
      icon: flipkart,
      items: [
        { label: 'Snapshot', icon: snapshot },
        { label: 'Master Overview', icon: <MasterOverviewIcon /> }
      ]
    },
    {
      title: 'Myntra',
      icon: myntra,
      items: [
        { label: 'Snapshot', icon: snapshot },
        { label: 'Master Overview', icon: <MasterOverviewIcon /> }
      ]
    }
  ]
};

export default menuItems;
