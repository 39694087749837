import React, { useEffect, useState } from 'react'
import CheckBox from 'src/components/Form/checkbox';

export default function Brands({ list = [], level, selectedItems = [], updateSelectedItems, updateInCludeSBSpends, includeSBSpends }) {

    const [selectedList, setSelectedList] = useState(selectedItems?.find((item) => item.level == level)?.keywords || [])

    useEffect(() => {
        setSelectedList(selectedItems?.find((item) => item.level == level)?.keywords || [])
    }, [selectedItems])

    const handleSelectAll = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allBrandValues = list.map(brand => brand.value);
            setSelectedList(allBrandValues);
            const updatedSelectedItems = [...selectedItems];
            const levelIndex = updatedSelectedItems.findIndex(item => item.level === level);
            if (levelIndex !== -1) {
                updatedSelectedItems[levelIndex].keywords = allBrandValues;
            } else {
                updatedSelectedItems.push({ level: level, keywords: allBrandValues });
            }
            updateSelectedItems(updatedSelectedItems);
        } else {
            setSelectedList([]);
            const updatedSelectedItems = [...selectedItems];
            const levelIndex = updatedSelectedItems.findIndex(item => item.level === level);
            if (levelIndex !== -1) {
                updatedSelectedItems[levelIndex].keywords = [];
                updateSelectedItems(updatedSelectedItems);
            }
        }
    };
    

    return (
        <section>
            <h2 className='f-12 f-w-500 mb-2 f-101828'>Brand</h2>
            <CheckBox
                label="Include SB Spends"
                classes="f-5F6368 f-10 f-w-500 ml-3 check-sm no-wrap"
                checked={includeSBSpends}
                action={updateInCludeSBSpends}
            />

            {list?.length > 0 ? <div className='mt-3' style={{paddingLeft: '2px'}}>
            <CheckBox id="select_all_brand" label="Select All" classes="f-12 f-w-500 check-lg f-101828" checked={selectedList.length === list.length} action={handleSelectAll} />
            </div> : null}

            <ul className='global-filter-tabs-list-items'>
                {list?.map((brand, index) => {
                    return (
                        <li key={brand.value}>
                            <CheckBox id={brand.value} label={brand.label} classes="f-12 f-w-500 check-lg f-101828" checked={selectedList?.indexOf(brand.value) > -1} action={(event) => updateSelectedItems(event, level, brand)} />
                        </li>
                    )
                })}
                {list?.length == 0 ? <li className="text-dark f-10 f-w-500 hei-70 d-flex align-items-center justify-content-center no-wrap">No Brands available</li> : null}
            </ul>
        </section>
    )
}
