import React from 'react'

const ConsiderationIcon = () => {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.31836 13.182H30.2275M18.6365 18.6365H24.0911M18.6365 24.0911H21.3638M10.4547 9.09109H10.467M15.9093 9.09109H15.9215M13.182 13.182V30.2275M4.31836 17.2729C4.31836 11.1665 4.31836 8.112 6.21518 6.21518C8.112 4.31836 11.1652 4.31836 17.2729 4.31836C23.3793 4.31836 26.4338 4.31836 28.3306 6.21518C30.2274 8.112 30.2275 11.1652 30.2275 17.2729C30.2275 23.3793 30.2274 26.4338 28.3306 28.3306C26.4338 30.2274 23.3806 30.2275 17.2729 30.2275C11.1665 30.2275 8.112 30.2274 6.21518 28.3306C4.31836 26.4338 4.31836 23.3806 4.31836 17.2729Z" stroke="#A100BD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default ConsiderationIcon