import React, { useState, useEffect } from 'react';
import SettingsIcon from 'src/assets/images/common/settings.svg';
import CheckBox from 'src/components/Form/checkbox';
import RadioButton from 'src/components/Actions/radioButton';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import useApi from 'src/hooks/useApi';
import services from 'src/api/service';
import MetricsDrag from './metics-drag';
import TablesDrag from './tables-drag';
import { sweetAlertHandler } from 'src/views/pages/targetting/toaster';
import { Loader } from 'src/views/pages/dashboards';
import { useTargetingContext } from 'src/contexts/TargetingContext';

export default function TableSettings() {

  const { targetingState, targetingDispatch } = useTargetingContext()
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState('metrics');
  const [rotationAngle, setRotationAngle] = useState(0);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const profileDetails = localStorage.getItem('selectedProfileId');
  const profileId = profileDetails ? JSON.parse(profileDetails).profileId : null;

  const [metricsList, setMetricsList] = useState([]);
  const [tablesList, setTablesList] = useState([]);

  const updateDataToMetrics = (newData) => {
    setMetricsList([...newData])
  }

  const updateDataToTables = (newData) => {
    setTablesList([...newData])
  }

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleClick = (event) => {
    fetchTableListSetting.request({
      url: '/user-default/list-default-records',
      nodeAPI: 1,
      payload: {
        report: 'amazon-dsp',
      }
    });
    setAnchorEl(event.currentTarget);
    const newRotationAngle = rotationAngle + 40;
    setRotationAngle(anchorEl === 'null' ? -40 : newRotationAngle);
    setSelectedOption('metrics');
    if(targetingState?.dsp_columns && targetingState?.dsp_columns?.length > 0) {
      setMetricsList(targetingState?.dsp_columns)
    }
    if(targetingState?.dsp_table_groups && targetingState?.dsp_table_groups?.length > 0) {
      setTablesList(targetingState?.dsp_table_groups)
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchTableListSetting = useApi(services.postService);
  const saveTableListSetting = useApi(services.postService);
  const [apiSetingInfo, setApiSettingsInfo] = useState({}) 

  const tableConfigParam = 'dspTableListConfig'
  const metricsConfigParam = 'amazonDspMetrics'
  const saveTablesAndMetrics = () => {

      const masterMetricList = localStorage.getItem(metricsConfigParam) ? JSON.parse(localStorage.getItem(metricsConfigParam)) : {};
      if(masterMetricList) {
        masterMetricList[profileId] = metricsList
        localStorage.setItem(metricsConfigParam, JSON.stringify(masterMetricList))  
      }

      const masterTableList = localStorage.getItem(tableConfigParam) ? JSON.parse(localStorage.getItem(tableConfigParam)) : {};
      masterTableList[profileId] = tablesList
      localStorage.setItem(tableConfigParam, JSON.stringify(masterTableList))

      targetingDispatch({
        type: 'UPDATE_DSP_TABLE_GROUPS',
        payload: tablesList
      })

      targetingDispatch({
        type: 'UPDATE_DSP_TABLE_COLUMNS',
        payload: metricsList
      })

      saveTableListSetting.request({
        url: '/user-default/create-or-update-records',
        nodeAPI: 1,
        payload: {
          report: 'amazon-dsp',
          table: tablesList,
          metric: metricsList
        }
      })

  };
  
  useEffect(() => {

    const { loading, data } = saveTableListSetting

    if(!loading && data && data?.success == true) {
      setTimeout(() => {
        setDefaultView(0)
        handleClose()
        sweetAlertHandler({ type: 'alert', text: 'Default setting view updated !', showConfirmButton: true, action: () => {
          // setDefaultView(0)
          // handleClose()
        }})
      }, 100);
    }

  }, [saveTableListSetting.loading])

  useEffect(() => {
    const { loading, data } = fetchTableListSetting;
    if (!loading && data) {
      const response = { table: data?.result?.table || [], metric: data?.result?.metric || [] };
      setApiSettingsInfo(response)
    }
  }, [fetchTableListSetting.loading])

  const onUpdateSettings = () => {
    if(selectedOption === 'tables') {
      const masterTableList = localStorage.getItem(tableConfigParam) ? JSON.parse(localStorage.getItem(tableConfigParam)) : {};
      masterTableList[profileId] = tablesList
      targetingDispatch({
        type: 'UPDATE_DSP_TABLE_GROUPS',
        payload: tablesList
      })
      localStorage.setItem(tableConfigParam, JSON.stringify(masterTableList))
    } else if (selectedOption === 'metrics') {
      const masterMetricList = localStorage.getItem(metricsConfigParam) ? JSON.parse(localStorage.getItem(metricsConfigParam)) : {};
      masterMetricList[profileId] = metricsList
      targetingDispatch({
        type: 'UPDATE_DSP_TABLE_COLUMNS',
        payload: metricsList
      })
      localStorage.setItem(metricsConfigParam, JSON.stringify(masterMetricList))
    }
    handleClose()
  }

  const [defaultView, setDefaultView] = useState(0);
  const [metricListChangeDetect, setMetricChangeDetect] = useState(false);
  const [tableListChangeDetect, setTableListChangeDetect] = useState(false);
  const [isAllMetricsTurnedOff, setIsAllMetricsTunedOff] = useState(false);
  const [isAllTableTurnedOff, setIsAllTableTurnedOff] = useState(false);
 
  useEffect(() => {
    setMetricChangeDetect(
      JSON.stringify(metricsList) !== JSON.stringify(apiSetingInfo?.metric) ? true : JSON.stringify(metricsList) !== JSON.stringify(targetingState?.dsp_columns) ? true :false
    );
  }, [metricsList, apiSetingInfo?.metric, targetingState?.dsp_columns]);

  useEffect(() => {
    setTableListChangeDetect(
      JSON.stringify(tablesList) !== JSON.stringify(apiSetingInfo?.table) ? true : JSON.stringify(tablesList) !== JSON.stringify(targetingState?.dsp_table_groups) ? true : false
    );
  }, [tablesList, apiSetingInfo?.table, targetingState?.dsp_table_groups]);

  useEffect(() => {
    setIsAllMetricsTunedOff(
      selectedOption === "metrics" &&
        metricsList?.filter((item) => item.show === "yes").length === 0 ? true : selectedOption === "metrics" &&
        targetingState?.dsp_columns?.filter((item) => item.show === "yes").length === 0 ? true : false
    );
  }, [selectedOption, metricsList, targetingState?.dsp_columns]);

  useEffect(() => {
    setIsAllTableTurnedOff(
      selectedOption === "tables" &&
        tablesList?.filter(
          (item) => item.table.filter((item) => item.show === "yes").length > 0
        ).length === 0 ? true : selectedOption === "tables" &&
        targetingState?.dsp_table_groups?.filter(
            (item) => item.table.filter((item) => item.show === "yes").length > 0
          ).length === 0 ? true : false
    );
  }, [selectedOption, tablesList, targetingState?.dsp_table_groups]);

  return (
    <div>
      <IconButton aria-label="SettingsIcon" aria-describedby={id} onClick={handleClick}>
        <img
          src={SettingsIcon}
          alt="table-settings"
          className="cursor-pointer wid-20"
          style={{
            transform: `rotate(${rotationAngle}deg)`,
            transition: 'transform 0.3s ease'
          }}
        />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          style: {
            borderRadius: '8px',
            width: '250px',
            maxHeight: '500px',
            overflow: 'visible'
          }
        }}
      >
        <Box
          sx={{
            position: 'relative',
            zIndex: '-10',
            '&::before': {
              backgroundColor: '#4E4E4E',
              content: '""',
              display: 'block',
              position: 'absolute',
              width: 10,
              height: 10,
              top: -4,
              transform: 'rotate(45deg)',
              left: 'calc(93% - 6px)'
            }
          }}
        />
        {/* HEADER */}
        <div className="settings_table_header">
          Table Display Settings
          <span className="cursor-pointer f-14" onClick={handleClose}>
            &#x2715;
          </span>
        </div>
        {/* HEADER */}
        {/* DEFAULT VIEW */}
        {!fetchTableListSetting.loading && !saveTableListSetting.loading && (metricListChangeDetect || tableListChangeDetect) && !isAllMetricsTurnedOff && !isAllTableTurnedOff ? <div className="settings_default_view">
          <CheckBox label="Set my default view" action={(event) => {
              setDefaultView(event.target && event.target.checked === true ? 1 : 0)
          }} classes="f-10" />
          <p className={`save_button ${!defaultView || saveTableListSetting.loading || isAllMetricsTurnedOff || isAllTableTurnedOff ? 'disabled' : ''}`} onClick={saveTablesAndMetrics}>Save</p>
        </div> : null}
        {/* DEFAULT VIEW */}

        {fetchTableListSetting.loading || saveTableListSetting.loading ? <div className="d-flex align-items-center justify-content-center chart-overlay position-absolute hei-60 f-w-700 text-dark w-100 h-100">
          <Loader />
        </div> : null}

        {/* RADIO BUTTON SECTION */}
        <div className="d-flex align-items-center gap-20 px-3 pt-2 pb-2 bg-white">
          <RadioButton
            classes="f-10"
            title="Metrics"
            id="metrics"
            radioname="target_table_settings"
            value="metrics"
            action={handleRadioChange}
            checked={selectedOption === 'metrics'}
          />
          <RadioButton
            classes="f-10"
            title="Tables"
            id="tables"
            radioname="target_table_settings"
            value="tables"
            checked={selectedOption === 'tables'}
            action={handleRadioChange}
          />
        </div>
        {/* RADIO BUTTON SECTION */}

        {/* CONTENT SECTION */}
        <section className="max-hei-291 overflow-auto px-3 pb-2 bg-white">
          {selectedOption === 'metrics' ? <MetricsDrag metricsList={metricsList} updateMetricsList={updateDataToMetrics} /> : null}
          {selectedOption === 'tables' ? <TablesDrag tablesList={tablesList} updateTablesList={updateDataToTables} /> : null}
        </section>
        {/* CONTENT SECTION */}

        {/* FOOTER SECTION */}
        {selectedOption == 'metrics' ? <div className="settings_table_footer bg-white">
          <p className={`comple-btn w-fit p-2 f-12 ${fetchTableListSetting.loading || saveTableListSetting.loading ? 'disabled' : isAllMetricsTurnedOff ? 'disabled' : metricListChangeDetect ? '' : 'disabled'}`} onClick={onUpdateSettings}>Apply</p>
          <p className="cls-btn mt-0 p-2 f-12 f-w-400 rounded-4 cursor-pointer" onClick={handleClose}>Cancel</p>
        </div> : <div className="settings_table_footer bg-white">
          <p className={`comple-btn w-fit p-2 f-12 ${fetchTableListSetting.loading || saveTableListSetting.loading ? 'disabled' : isAllTableTurnedOff ? 'disabled' : tableListChangeDetect ? '' : 'disabled'}`} onClick={onUpdateSettings}>Apply</p>
          <p className="cls-btn mt-0 p-2 f-12 f-w-400 rounded-4 cursor-pointer" onClick={handleClose}>Cancel</p>
        </div>}
        {/* FOOTER SECTION */}
      </Popover>
    </div>
  );
}