import React from 'react';
import Highcharts from 'highcharts';
// import highcharts3d from 'highcharts/highcharts-3d';
import HighchartsReact from 'highcharts-react-official';
// highcharts3d(Highcharts);

const HighChartCard = ({ options }) => {
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default HighChartCard;
