import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom'
import { fetchProfile } from 'src/helper/client';
import useGetProfile from './useGetProfile';

const useLocationTracking = () => {
  const location = useLocation();
  const isInitialized = ReactGA?.isInitialized
  const analyticsRef = useRef(false)
  const User = JSON.parse(localStorage.getItem('User'));
  const [getProfile] = useGetProfile();
  const sessionRef = useRef(null)
  const sessionUrlRef = useRef(null)
  
  const generateUTCtimestamp = () => {
    const currentDate = new Date();
    const year = currentDate.getUTCFullYear();
    const month = currentDate.getUTCMonth() + 1;
    const day = currentDate.getUTCDate();
    const hours = currentDate.getUTCHours();
    const minutes = currentDate.getUTCMinutes();
    const seconds = currentDate.getUTCSeconds();
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}Z`;
  }

  const sendEvent = (category, label, value) => {
    try {
      // Hit PageView Screen
      console.log("OutPutWatchInit", {
        connected: isInitialized
      });
      const profileName = window.location.pathname + `?userId=${String(User.email).replace('@', '&')}&profileName=${getProfile.accountInfo.name}&profileId=${getProfile.profileId}&type=${category}&reportPage=${window.location.href.split("/")[window.location.href.split("/").length - 1].replace(/\b\w/g, (match) => match.toUpperCase())}&utcTime=${generateUTCtimestamp()}&role=${getProfile?.accessLevel ? getProfile?.accessLevel : 'Admin'}${sessionRef.current ? '&sessionId=' + sessionRef.current : ''}&userName=${[User.fullName].filter(value => value != '').join(' ')}`
      const hitTypeData = ReactGA.send({ hitType: "pageview", page: location.pathname, title: profileName });
      console.log("OutPutWatchPageView", hitTypeData)
      console.log("EventWatcher", { hitType: "pageview", page: location.pathname, title: profileName })
      // Hit PageView Custom Event
      const gtagData = ReactGA.gtag('event', 'Page View', {
        event_category: category,
        event_label: label,
        value: value,
      });
      console.log("OutPutWatchPageSpaceView", gtagData)
      console.log("CustomEventWatcher", {
        event_category: category,
        event_label: label,
        value: value,
      }) 
    } catch (error) {
      console.log("sendEventError", { error })
    }
  };

  const startWatching = () => {
    console.log("startWatching", getProfile)
    if (getProfile && getProfile.profileId && User && User.email && !analyticsRef.current && process.env.REACT_APP_GA_TRACK_ENABLED == 'YES') {
      sessionRef.current = `${format(new Date(generateUTCtimestamp()), 'yyyymmddhhmmss')}`
      sessionUrlRef.current = window.location.href
      const profileValues = {
        // userId: User.email,
        profileId: getProfile.profileId,
        // utcTime: generateUTCtimestamp(),
        role: getProfile?.accessLevel || 'Admin',
        userName: User.fullName,
        // sessionId: sessionRef.current
      };
      sendEvent('start-viewing', String(User.email).replace('@', '&'), JSON.stringify(profileValues));
      console.log(`Start Watching in ${sessionUrlRef.current}  -> ${sessionRef.current} - ${User.email} -> ${getProfile.profileId} -> ${format(new Date(generateUTCtimestamp()), 'yyyy mm dd, hh:mm:ss a')}`);
      analyticsRef.current = true;
    }
  };

  const stopWatching = () => {
    if (getProfile && getProfile.profileId && User && User.email && analyticsRef.current && sessionRef.current && process.env.REACT_APP_GA_TRACK_ENABLED == 'YES') {
      const profileValues = {
        // userId: User.email,
        profileId: getProfile.profileId,
        // utcTime: generateUTCtimestamp(),
        role: getProfile?.accessLevel || 'Admin',
        userName: User.fullName,
        // sessionId: sessionRef.current
      };
      sendEvent('stop-viewing', String(User.email).replace('@', '&'), JSON.stringify(profileValues));
      console.log(`Stop Watching in ${sessionUrlRef.current} -> ${sessionRef.current} - ${User.email} -> ${getProfile.profileId} -> ${format(new Date(generateUTCtimestamp()), 'yyyy mm dd, hh:mm:ss a')}`);
      analyticsRef.current = false;
    }
  };

  useEffect(() => {
    if(getProfile?.accountInfo?.name) {
      document.title = `${getProfile?.accountInfo?.name} - ${window.location.href.split("/")[window.location.href.split("/").length - 1].replace(/-/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase())}`
    }
  }, [getProfile])

  useEffect(() => {
    const handleFocus = () => {
      if (!analyticsRef.current) {
        startWatching();
      }
    };

    // Handle window blur event
    const handleBlur = () => {
      stopWatching();
    };

    // Handle window beforeunload event
    const handleBeforeUnload = () => {
      stopWatching();
    };

    console.log("getProfileuseEffectOut", getProfile)
    if(getProfile && getProfile.profileId && getProfile.accountInfo && User && User.email) {
      console.log("getProfileuseEffectIn", getProfile)
      document.title = `${getProfile.accountInfo.name} - ${window.location.href.split("/")[window.location.href.split("/").length - 1].replace(/-/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase())}`
      analyticsRef.current = false
      startWatching();
      window.addEventListener('focus', handleFocus);
      window.addEventListener('blur', handleBlur);
      window.addEventListener('beforeunload', handleBeforeUnload);
    }


    // Handle window focus event


    return () => {
      if(getProfile && getProfile.profileId && getProfile.accountInfo && User && User.email) {
        stopWatching()
        analyticsRef.current = false
        window.removeEventListener('focus', handleFocus);
        window.removeEventListener('blur', handleBlur);
        window.removeEventListener('beforeunload', handleBeforeUnload);
      }
    };
  }, [window.location.href, getProfile]);

};

export default useLocationTracking;
