
// const getCoulmns = (field_name, field_key) => {
// 	return [
// 		{ "column_type": "", "label": field_name, "param": field_key },
// 		{ "column_type": "number", "label": "Spend", "param": "spend" },
// 		{ "column_type": "number", "label": "Revenue", "param": "revenue" },
// 		{ "column_type": "number", "label": "Orders", "param": "orders" },
// 		{ "column_type": "number", "label": "Imp", "param": "imp" },
// 		{ "column_type": "number", "label": "Clicks", "param": "clicks" },
// 		{ "column_type": "comma", "label": "CPA", "param": "cpa" },
// 		{ "column_type": "decimal", "label": "ROAS", "param": "roas" },
// 		{ "column_type": "decimal", "label": "AOV", "param": "aov" },
// 		{ "column_type": "decimal", "label": "CTR", "param": "ctr" },
// 		{ "column_type": "number", "label": "CPM", "param": "cpm" },
// 		{ "column_type": "number", "label": "CPC", "param": "cpc" },
// 		{ "column_type": "percentage", "label": "ACOS", "param": "acos" },
// 		{ "column_type": "number", "label": "RPC", "param": "rpc" },
// 		{ "column_type": "number", "label": "CPO", "param": "cpo" },
// 		{ "column_type": "percentage", "label": "Spend%", "param": "spend_percent" },
// 		{ "column_type": "percentage", "label": "Rev%", "param": "rev_percent" },
// 		{ "column_type": "percentage", "label": "CR%", "param": "cr_percent" },
// 		{ "column_type": "number", "label": "CC", "param": "cc" }
// 	]
// }

// Percentage - CTR, ACOS, Spend %, Revenue %, Conversion Rate %

// Decimals - CPA, ROAS, CPM, CPC, 

// Whole numbers - Spend, Revenue, Orders, Impressions, Clicks, AOV, Campaign Count

const getProfile = JSON.parse(localStorage.getItem('selectedProfileId'));

export const DEFAULT_AMS_REPORT_COLUMNS = [
	{ id: 1,"column_type": "number", "label": "Spend", "param": "Spend", "prefix_sign": "currency", "checked": true },
	{ id: 2,"column_type": "number", "label": "Revenue", "param": "Revenue", "prefix_sign": "currency", "checked": true },
	{ id: 3,"column_type": "number", "label": "Orders", "param": "Orders", "checked": true },
	{ id: 4,"column_type": "number", "label": "Imp", "param": "Impressions", "checked": true },
	{ id: 5,"column_type": "number", "label": "Clicks", "param": "Clicks", "checked": true },
	{ id: 6,"column_type": "decimal", "label": "CPA", "param": "CPA", "prefix_sign": "currency", "checked": false },
	{ id: 7,"column_type": "decimal", "label": "ROAS", "param": "ROAS", "checked": false },
	{ id: 8,"column_type": "decimal", "label": "AOV", "param": "AOV", "prefix_sign": "currency", "checked": false },
	{ id: 9,"column_type": "percentage", "label": "CTR", "param": "CTR", "checked": false },
	{ id: 10,"column_type": "decimal", "label": "CPM", "param": "CPM", "checked": false },
	{ id: 11,"column_type": "decimal", "label": "CPC", "param": "CPC", "checked": false },
	{ id: 12,"column_type": "percentage", "label": "ACOS", "param": "ACOS", "checked": false },
	{ id: 13,"column_type": "number", "label": "RPC", "param": "RPC", "checked": false },
	{ id: 14,"column_type": "number", "label": "CPO", "param": "CPO", "prefix_sign": "currency", "checked": false },
	{ id: 15,"column_type": "percentage", "label": "Spend%", "param": "Spend_perc", "checked": false },
	{ id: 16,"column_type": "percentage", "label": "Rev%", "param": "Revenue_perc", "checked": false },
	{ id: 17,"column_type": "percentage", "label": "CR%", "param": "CR_perc", "checked": false },
	{ id: 18,"column_type": "number", "label": "CC", "param": "CC", "checked": false }
]

export const getCoulmnsAPi = (field_name, field_key, field_type = 'any', field_display = '') => {
	return [
		{ "column_type": "", "label": field_name, "param": field_key, "field_type": field_type, "field_display": field_display },
		...(getProfile && getProfile.profileId == "2351879915712439" && field_name == "Campaign Name" ? [{ id: 19,"column_type": "toggle", "label": "Active", "param": "campaign_state_change", "checked": true }] : []),
		...(getProfile && getProfile.profileId == "2351879915712439" && field_name == "Campaign Name" ? [{ id: 20,"column_type": "bid_change", "label": "Budget", "param": "campaign_budget_change", "checked": true }] : []),
	]
}

export const DEFAULT_ACCOUNT_REPORT_COLUMNS = [
	{ id: 1,"column_type": "number", "label": "TSales", "param": "TSales", "prefix_sign": "currency", "checked": true },
	{ id: 2,"column_type": "number", "label": "TOrders", "param": "TOrders", "prefix_sign": "currency", "checked": true },
	{ id: 3,"column_type": "number", "label": "Spend", "param": "Spend", "checked": true },
	{ id: 4,"column_type": "number", "label": "Imp", "param": "Impressions", "checked": true },
	{ id: 5,"column_type": "number", "label": "Clicks", "param": "Clicks", "checked": true },
	{ id: 6,"column_type": "number", "label": "AdSales", "param": "AdSales", "checked": false },
	{ id: 7,"column_type": "number", "label": "AdOrders", "param": "AdOrders", "checked": false },
	{ id: 8,"column_type": "percentage", "label": "CTR", "param": "CTR", "checked": false },
	{ id: 9,"column_type": "decimal", "label": "CPC", "param": "CPC", "checked": false },
	{ id: 10,"column_type": "decimal", "label": "CPM", "param": "CPM", "checked": false },
	{ id: 11,"column_type": "decimal", "label": "CPA", "param": "CPA", "prefix_sign": "currency", "checked": false },
	{ id: 12,"column_type": "percentage", "label": "CR%", "param": "CR", "checked": false },
	{ id: 13,"column_type": "decimal", "label": "Ad_ROAS", "param": "Ad_ROAS", "checked": false },
	{ id: 14,"column_type": "decimal", "label": "TROAS", "param": "TROAS", "checked": false },
	{ id: 15,"column_type": "decimal", "label": "AOV", "param": "AOV", "prefix_sign": "currency", "checked": false },
	{ id: 16,"column_type": "percentage", "label": "ACOS", "param": "ACOS", "checked": false },
	{ id: 17,"column_type": "percentage", "label": "TACOS%", "param": "TACOS", "checked": false },
	{ id: 18,"column_type": "number", "label": "CPO", "param": "CPO", "prefix_sign": "currency", "checked": false },
	{ id: 19,"column_type": "number", "label": "RPC", "param": "RPC", "checked": false },
	{ id: 20,"column_type": "number", "label": "ARR", "param": "ARR", "checked": false }

]

const getCoulmnsAPiAccountSummary = (field_name, field_key, field_type = 'any', field_display = '', imageIsAvailable = '') => {
	return [
		{ "column_type": "", "label": field_name, "param": field_key, "field_type": field_type, "field_display": field_display, "imageIsAvailable": imageIsAvailable },
	]
}


const TAB_GROUP = {
	"parent_tabs": [
		// {
		// 	"id": 1,
		// 	"is_group": 0,
		// 	"title": "Account Summary",
		// 	"title_aggregate": "account-summary",
		// 	"param_key": "account-Summary",
		// 	"url_update": "/account",
		// 	"heatMapEnabled": 0,
		// 	"prefer_tables": [
		// 		{
		// 			"table_name": "AccountSummary",
		// 			"table_id": "accountsummary",
		// 			"end_point": "/account-summary",
		// 			"payload": {
		// 				"start_date": "2023-02-01",
		// 				"end_date": "2023-02-15",
		// 				"aggregate": "AccountSummary"
		// 			},
		// 			"table_json": {
		// 				"aggregate": "AccountSummary",
		// 				"data_id": 'AccountSummary',
		// 				"cross_filter": "disabled",
		// 				"columns": getCoulmnsAPiAccountSummary('Account Summary', 'AccountSummary'),
		// 				"summary": [

		// 				],
		// 				"rows": [

		// 				]
		// 			},
		// 			"default_sort": {
		// 				"sort_type": "ASC",
		// 				"sort_column": "Spend",
		// 				"is_sort": false
		// 			}
		// 		},

		// 		{
		// 			"table_name": "Month",
		// 			"table_id": "Month",
		// 			"end_point": "/account-summary",
		// 			"payload": {
		// 				"start_date": "2023-02-01",
		// 				"end_date": "2023-02-15",
		// 				"aggregate": "Month"
		// 			},
		// 			"table_json": {
		// 				"aggregate": "Month",
		// 				"data_id": 'Month',
		// 				"columns": getCoulmnsAPiAccountSummary('Month', 'Month'),
		// 				"summary": [

		// 				],
		// 				"rows": [

		// 				]
		// 			},
		// 			"default_sort": {
		// 				"sort_type": "ASC",
		// 				"sort_column": "Spend",
		// 				"is_sort": false
		// 			}
		// 		},

		// 		{
		// 			"table_name": "Dates",
		// 			"table_id": "Targeting_types",
		// 			"end_point": "/account-summary",
		// 			"payload": {
		// 				"start_date": "2023-02-01",
		// 				"end_date": "2023-02-15",
		// 				"aggregate": "Daily"
		// 			},
		// 			"table_json": {
		// 				"aggregate": "Daily",
		// 				"data_id": 'Date',
		// 				"columns": getCoulmnsAPiAccountSummary('Dates', 'Date'),
		// 				"summary": [

		// 				],
		// 				"rows": [

		// 				]
		// 			},
		// 			"default_sort": {
		// 				"sort_type": "ASC",
		// 				"sort_column": "Spend",
		// 				"is_sort": false
		// 			}
		// 		},
		// 		{
		// 			"table_name": "Top 10 Ship City",
		// 			"table_id": "Top-10-Ship-City",
		// 			"end_point": "/account-summary",
		// 			"payload": {
		// 				"start_date": "2023-02-01",
		// 				"end_date": "2023-02-15",
		// 				"aggregate": "TopShipCity"
		// 			},
		// 			"table_json": {
		// 				"data_id": 'ship_city',
		// 				"pagination": "disabled",
		// 				"columns": getCoulmnsAPiAccountSummary('Top 10 Ship City', 'ship_city'),
		// 				"summary": [
		// 				],
		// 				"rows": [
		// 				]
		
		// 			},
		// 			"default_sort": {
		// 				"sort_type": "ASC",
		// 				"sort_column": "Spend",
		// 				"is_sort": false
		// 			}
		// 		},
		// 		{
		// 			"table_name": "Top 10 Asin's",
		// 			"table_id": "Top-10-Asin's",
		// 			"end_point": "/account-summary",
		// 			"payload": {
		// 				"start_date": "2023-02-01",
		// 				"end_date": "2023-02-15",
		// 				"aggregate": "TopAsin"
		// 			},
		// 			"table_json": {
		// 				"data_id": 'asin',
		// 				"pagination": "disabled",
		// 				"columns": getCoulmnsAPiAccountSummary("Top 10 Asin's", 'asin', '', '', 'yes'),
		// 				"summary": [
		// 				],
		// 				"rows": [
		// 				]
		// 			},
		// 			"default_sort": {
		// 				"sort_type": "ASC",
		// 				"sort_column": "Spend",
		// 				"is_sort": false
		// 			},
		// 			"asinInfoCard": 1
		// 		},
		// 	]
		// },
		// {
		// 	"id": 2,
		// 	"title": "All Campaigns",
		// 	"title_aggregate": "all-campaings-summary",
		// 	"param_key": "all-campaings-summary",
		// 	"heatMapEnabled": 1,
		// 	"url_update": "/all-campaigns",
		// 	"prefer_tables": [
		// 		{
		// 			"table_name": "Campaign Types",
		// 			"classNames": 'text-capitalize',
		// 			"table_id": "Campaign_Type",
		// 			"end_point": "/all-campaigns-summary",
		// 			"payload": {
		// 				"start_date": "2023-02-01",
		// 				"end_date": "2023-02-15",
		// 				"aggregate": "CampaignType"
		// 			},
		// 			"table_json": {
		// 				"aggregate": "CampaignType",
		// 				"data_id": 'Type',
		// 				"columns": getCoulmnsAPi('Campaign Types', 'Type'),
		// 				"summary": [
		// 				],
		// 				"rows": [
		// 				]

		// 			},
		// 			"default_sort": {
		// 				"sort_type": "ASC",
		// 				"sort_column": "Spend",
		// 				"is_sort": false
		// 			}
		// 		},
		// 		{
		// 			"table_name": "Targeting Types",
		// 			"table_id": "Targeting_types",
		// 			"end_point": "/all-campaigns-summary",
		// 			"payload": {
		// 				"start_date": "2023-02-01",
		// 				"end_date": "2023-02-15",
		// 				"aggregate": "TargetingType"
		// 			},
		// 			"table_json": {
		// 				"aggregate": "TargetingType",
		// 				"data_id": 'targetingType',
		// 				"columns": getCoulmnsAPi('Targeting types', 'targetingType'),
		// 				"summary": [
		// 				],
		// 				"rows": [
		// 				]
		// 			},
		// 			"default_sort": {
		// 				"sort_type": "ASC",
		// 				"sort_column": "Spend",
		// 				"is_sort": false
		// 			},
		// 		},
		// 		{
		// 			"table_name": "Targeting Category",
		// 			"table_id": "Targeting_Category",
		// 			"end_point": "/all-campaigns-summary",
		// 			"payload": {
		// 				"start_date": "2023-02-01",
		// 				"end_date": "2023-02-15",
		// 				"aggregate": "TargetingCategory"
		// 			},
		// 			"table_json": {
		// 				"aggregate": "TargetingCategory",
		// 				"data_id": 'CategoryType',
		// 				"columns": getCoulmnsAPi('Targeting Category', 'CategoryType'),
		// 				"summary": [
		// 				],
		// 				"rows": [
		// 				]
		// 			},
		// 			"default_sort": {
		// 				"sort_type": "ASC",
		// 				"sort_column": "Spend",
		// 				"is_sort": false
		// 			},
		// 		},
		// 		{
		// 			"table_name": "Ad Formats",
		// 			"table_id": "Ad_Formats",
		// 			"end_point": "/all-campaigns-summary",
		// 			"payload": {
		// 				"start_date": "2023-02-01",
		// 				"end_date": "2023-02-15",
		// 				"aggregate": "AdFormat"
		// 			},
		// 			"table_json": {
		// 				"aggregate": "AdFormat",
		// 				"data_id": 'AdFormats',
		// 				"columns": getCoulmnsAPi('Ad Formats', 'AdFormats'),
		// 				"rows": [
		// 				],
		// 				"summary": [
		// 				]
		// 			},
		// 			"default_sort": {
		// 				"sort_type": "ASC",
		// 				"sort_column": "Spend",
		// 				"is_sort": false
		// 			},
		// 		},
		// 		{
		// 			"table_name": "Campaign Name",
		// 			"table_id": "campaign_name",
		// 			"end_point": "/all-campaigns-summary",
		// 			"payload": {
		// 				"start_date": "2023-02-01",
		// 				"end_date": "2023-02-15",
		// 				"aggregate": "CampaignName"
		// 			},
		// 			"table_json": {
		// 				"aggregate": "CampaignName",
		// 				"data_id": 'campaignName',
		// 				"search": "enable",
		// 				"expand_row": "enable",
		// 				"pagination": "enabled",
		// 				"columns": getCoulmnsAPi('Campaign Name', 'campaignName'),
		// 				"summary": [
		// 				],
		// 				"rows": [
		// 				]
		// 			},
		// 			"default_sort": {
		// 				"sort_type": "ASC",
		// 				"sort_column": "Spend",
		// 				"is_sort": false
		// 			},
		// 		},
		// 		{
		// 			"table_name": "Ad Group Name",
		// 			"table_id": "Ad_group_name",
		// 			"end_point": "/all-campaigns-summary",
		// 			"payload": {
		// 				"start_date": "2023-02-01",
		// 				"end_date": "2023-02-15",
		// 				"aggregate": "adGroupName"
		// 			},
		// 			"table_json": {
		// 				"aggregate": "adGroupName",
		// 				"data_id": 'adGroupName',
		// 				"search": "enable",
		// 				"expand_row": "enable",
		// 				"pagination": "enabled",
		// 				"columns": getCoulmnsAPi('Ad Group Name', 'adGroupName'),
		// 				"summary": [
		// 				],
		// 				"rows": [
		// 				]
		// 			},
		// 			"default_sort": {
		// 				"sort_type": "ASC",
		// 				"sort_column": "Spend",
		// 				"is_sort": false
		// 			},
		// 		},
		// 		{
		// 			"table_name": "Day of week",
		// 			"table_id": "Week_Day",
		// 			"end_point": "/all-campaigns-summary",
		// 			"payload": {
		// 				"start_date": "2023-02-01",
		// 				"end_date": "2023-02-15",
		// 				"aggregate": "Weekday"
		// 			},
		// 			"table_json": {
		// 				"aggregate": "Weekday",
		// 				"data_id": 'Weekday',
		// 				"columns": getCoulmnsAPi('Day of week', 'Weekday'),
		// 				"summary": [
		// 				],
		// 				"rows": [
		// 				]
		// 			},
		// 		},
		// 		{
		// 			"table_name": "Daily",
		// 			"table_id": "Daily",
		// 			"end_point": "/all-campaigns-summary",
		// 			"payload": {
		// 				"start_date": "2023-02-01",
		// 				"end_date": "2023-02-15",
		// 				"aggregate": "Daily"
		// 			},
		// 			"table_json": {
		// 				"aggregate": "Daily",
		// 				"data_id": 'Date',
		// 				"pagination": "enabled",
		// 				"columns": getCoulmnsAPi('Daily', 'Date', 'Date'),
		// 				"summary": [
		// 				],
		// 				"rows": [
		// 				]

		// 			},
		// 			"default_sort": {
		// 				"sort_type": "ASC",
		// 				"sort_column": "Daily",
		// 				"is_sort": false
		// 			}
		// 		},
		// 		{
		// 			"table_name": "Week",
		// 			"table_id": "Week",
		// 			"end_point": "/all-campaigns-summary",
		// 			"payload": {
		// 				"start_date": "2023-02-01",
		// 				"end_date": "2023-02-15",
		// 				"aggregate": "Weekly"
		// 			},
		// 			"table_json": {
		// 				"aggregate": "Weekly",
		// 				"data_id": 'Week',
		// 				"expand_row": "enable",
		// 				"columns": getCoulmnsAPi('Weekly', 'Week', 'Date'),
		// 				"summary": [
		// 				],
		// 				"rows": [
		// 				]

		// 			},
		// 			"default_sort": {
		// 				"sort_type": "ASC",
		// 				"sort_column": "Weekly",
		// 				"is_sort": false
		// 			}
		// 		},
		// 		{
		// 			"table_name": "Monthly",
		// 			"table_id": "Monthly",
		// 			"end_point": "/all-campaigns-summary",
		// 			"payload": {
		// 				"start_date": "2023-02-01",
		// 				"end_date": "2023-02-15",
		// 				"aggregate": "Monthly"
		// 			},
		// 			"table_json": {
		// 				"aggregate": "Monthly",
		// 				"data_id": 'Month',
		// 				"columns": getCoulmnsAPi('Monthly', 'Month', 'Date'),
		// 				"summary": [
		// 				],
		// 				"rows": [
		// 				]

		// 			},
		// 			"default_sort": {
		// 				"sort_type": "ASC",
		// 				"sort_column": "Monthly",
		// 				"is_sort": false
		// 			}
		// 		},

		// 	]

		// },
		{
			"id": 3,
			"is_group": 0,
			"title": "Targeting",
			"title_aggregate": "targeting-summary",
			"param_key": "targeting-summary",
			"heatMapEnabled": 1,
			"url_update": "/targeting",
			"prefer_tables": [

				{
					"table_name": "Targeting Types",
					"table_id": "Targeting_types",
					"end_point": "/targeting-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "TargetingType",
						"summary": "Targeting"
					},
					"table_json": {
						"aggregate": "TargetingType",
						"data_id": 'TargetingType',
						"trend_chart": "enable",
						"columns": getCoulmnsAPi('Targeting Types', 'TargetingType'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					},
				},
				{
					"table_name": "Targeting Category",
					"table_id": "Targeting_Category",
					"end_point": "/targeting-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "TargetingCategory",
						"summary": "Targeting"
					},
					"table_json": {
						"aggregate": "TargetingCategory",
						"data_id": 'CategoryType',
						"trend_chart": "enable",
						"columns": getCoulmnsAPi('Targeting Category', 'CategoryType'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					},
				},
				{
					"table_name": "Campaign Types",
					"classNames": 'text-capitalize',
					"table_id": "CampaignTypes",
					"end_point": "/targeting-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "CampaignType",
						"summary": "Targeting"
					},
					"table_json": {
						"aggregate": "CampaignType",
						"data_id": 'Type',
						"trend_chart": "enable",
						"columns": getCoulmnsAPi('Campaign Types', 'Type'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					},
				},
				{
					"table_name": "Ad Formats",
					"table_id": "Ad_Formats",
					"end_point": "/targeting-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "AdFormat",
						"summary": "Targeting"
					},
					"table_json": {
						"aggregate": "AdFormat",
						"data_id": 'AdFormats',
						"trend_chart": "enable",
						"columns": getCoulmnsAPi('Ad Formats', 'AdFormats'),
						"rows": [

						],
						"summary": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					},
				},
				{
					"table_name": "Targeting Text",
					"table_id": "Targeting Text",
					"end_point": "/targeting-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "TargetingText",
						"summary": "Targeting"
					},
					"table_json": {
						"aggregate": "TargetingText",
						"data_id": 'targetingText',
						"trend_chart": "enable",
						"expand_row": "enable",
						"pagination": "enabled",
						"columns": getCoulmnsAPi('Targeting Text', 'targetingText'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					},
				},

				{
					"table_name": "Match Type",
					"table_id": "MatchType",
					"end_point": "/targeting-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "MatchType",
						"summary": "Targeting"
					},
					"table_json": {
						"aggregate": "MatchType",
						"data_id": 'matchType',
						"trend_chart": "enable",
						"columns": getCoulmnsAPi('Match Type', 'matchType'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					},
				},
				// {
				// 	"table_name": "Keyword Types",
				// 	"table_id": "Keyword Types",
				// 	"end_point": "/targeting-summary",
				// 	"payload": {
				// 		"start_date": "2023-02-01",
				// 		"end_date": "2023-02-15",
				// 		"aggregate": "KeywordTypes"
				// 	},
				// 	"table_json": {
				// 		"data_id": 'keywordText',
				// 		"columns": getCoulmnsAPi('Keyword Types', 'keywordText'),
				// 		"summary": [

				// 		],
				// 		"rows": [

				// 		]

				// 	}
				// },
				// {
				// 	"table_name": "Bidding Type",
				// 	"table_id": "Bidding_Type",
				// 	"end_point": "/targeting-summary",
				// 	"payload": {
				// 		"start_date": "2023-02-01",
				// 		"end_date": "2023-02-15",
				// 		"aggregate": "BiddingType"
				// 	},
				// 	"table_json": {
				// 		"data_id": 'BiddingType',
				// 		"columns": getCoulmnsAPi('Bidding Type', 'BiddingType'),
				// 		"summary": [

				// 		],
				// 		"rows": [

				// 		]

				// 	}
				// },
				// {
				// 	"table_name": "Categories",
				// 	"table_id": "Categories",
				// 	"end_point": "/targeting-summary",
				// 	"payload": {
				// 		"start_date": "2023-02-01",
				// 		"end_date": "2023-02-15",
				// 		"aggregate": "Categories"
				// 	},
				// 	"table_json": {
				// 		"data_id": 'Categories',
				// 		"columns": getCoulmnsAPi('Categories', 'Categories'),
				// 		"summary": [

				// 		],
				// 		"rows": [

				// 		]

				// 	}
				// },

				{
					"table_name": "Campaign Name",
					"table_id": "campaign_name",
					"end_point": "/targeting-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "CampaignName",
						"summary": "Targeting"
					},
					"table_json": {
						"aggregate": "CampaignName",
						"data_id": 'campaignName',
						"trend_chart": "enable",
						"search": "enable",
						"trend_chart": "enable",
						"expand_row": "enable",
						"pagination": "enabled",
						"columns": getCoulmnsAPi('Campaign Name', 'campaignName'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					},
				},
				{
					"table_name": "Ad Group Name",
					"table_id": "Ad_group_name",
					"end_point": "/targeting-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "adGroupName",
						"summary": "Targeting"
					},
					"table_json": {
						"aggregate": "adGroupName",
						"data_id": 'adGroupName',
						"search": "enable",
						"trend_chart": "enable",
						"expand_row": "enable",
						"pagination": "enabled",
						"columns": getCoulmnsAPi('Ad Group Name', 'adGroupName'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					},
				},
				{
					"table_name": "Brand",
					"table_id": "brand",
					"end_point": "/targeting-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Brand"
					},
					"table_json": {
						"aggregate" : "Brand",
						"data_id": 'type_id',
						"categorizationLink": process.env.REACT_APP_URL + "/text-categorization",
						"columns": getCoulmnsAPi('Brand/Comp/Generic', 'brand_id', 'any', 'brand_name'),
						"summary": [
		
						],
						"rows": [
		
						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},
		
				{
					"table_name": "Category",
					"table_id": "Category",
					"end_point": "/targeting-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Category"
					},
					"table_json": {
						"aggregate": "Category",
						"data_id": 'category_id',
						"categorizationLink": process.env.REACT_APP_URL + "/text-categorization",
						"columns": getCoulmnsAPi('Category', 'category_id', 'any', 'category_name'),
						"summary": [
		
						],
						"rows": [
		
						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},
		
				{
					"table_name": "Sub Category",
					"table_id": "Sub_Category",
					"end_point": "/targeting-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Subcategory"
					},
					"table_json": {
						"aggregate": "Subcategory",
						"data_id": 'subcategory_id',
						"categorizationLink": process.env.REACT_APP_URL + "/text-categorization",
						"columns": getCoulmnsAPi('Sub Category', 'subcategory_id', 'any', 'subcategory_name'),
						"summary": [
		
						],
						"rows": [
		
						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},

				// {
				// 	"table_name": "Custom Label",
				// 	"table_id": "Custom_Label",
				// 	"end_point": "/targeting-summary",
				// 	"payload": {
				// 		"start_date": "2023-02-01",
				// 		"end_date": "2023-02-15",
				// 		"aggregate": "CustomLabel"
				// 	},
				// 	"table_json": {
				// 		"aggregate": "Custom_Label",
				// 		"data_id": 'label_id',
				// 		"categorizationLink": process.env.REACT_APP_URL + "/text-categorization",
				// 		"columns": getCoulmnsAPi('Custom Label', 'label_id', 'any', 'label_name'),
				// 		"summary": [
		
				// 		],
				// 		"rows": [
		
				// 		]
				// 	},
				// 	"default_sort": {
				// 		"sort_type": "ASC",
				// 		"sort_column": "Spend",
				// 		"is_sort": false
				// 	}
				// },

		
				{
					"table_name": "Day of week",
					"table_id": "Week_Day",
					"end_point": "/targeting-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Weekday",
						"summary": "Targeting"
					},
					"table_json": {
						"aggregate": "Weekday",
						"data_id": 'Weekday',
						"trend_chart": "disable",
						"columns": getCoulmnsAPi('Day of week', 'Weekday'),
						"summary": [

						],
						"rows": [
						]
					},
					// "default_sort": {
					// 	"sort_type": "ASC",
					// 	"sort_column": "Spend",
					// 	"is_sort": false
					// },
				},
				{
					"table_name": "Daily",
					"table_id": "Daily",
					"end_point": "/targeting-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Daily"
					},
					"table_json": {
						"aggregate": "Daily",
						"data_id": 'Date',
						"trend_chart": "disable",
						"pagination": "enabled",
						"columns": getCoulmnsAPi('Daily', 'Date', 'Date'),
						"summary": [
						],
						"rows": [
						]

					},
					// "default_sort": {
					// 	"sort_type": "ASC",
					// 	"sort_column": "Daily",
					// 	"is_sort": false
					// }
				},
				{
					"table_name": "Weekly",
					"table_id": "weekly",
					"end_point": "/targeting-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Weekly"
					},
					"table_json": {
						"aggregate": "Weekly",
						"data_id": 'Week',
						"trend_chart": "disable",
						"expand_row": "enable",
						"columns": getCoulmnsAPi('Weekly', 'Week', 'Date'),
						"summary": [
						],
						"rows": [
						]

					},
					// "default_sort": {
					// 	"sort_type": "ASC",
					// 	"sort_column": "Weekly",
					// 	"is_sort": false
					// }
				},
				{
					"table_name": "Monthly",
					"table_id": "monthly",
					"end_point": "/targeting-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Monthly"
					},
					"table_json": {
						"aggregate": "Monthly",
						"data_id": 'Month',
						"trend_chart": "disable",
						"columns": getCoulmnsAPi('Monthly', 'Month', 'Date'),
						"summary": [
						],
						"rows": [
						]

					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Monthly",
						"is_sort": false
					}
				},
				{
					"table_name": "LookbackPeriod",
					"table_id": "Lookback_period",
					"end_point": "/targeting-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "LookbackPeriod"
					},
					"table_json": {
						"aggregate": "LookbackPeriod",
						"data_id": 'LookbackPeriod',
						"search": "enable",
						"columns": getCoulmnsAPi('Lookback Period', 'LookbackPeriod'),
						"summary": [
						],
						"rows": [
						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					},
				},
			]
		},
		{
			"id": 4,
			"is_group": 0,
			"title": "Search Term",
			"title_aggregate": "search-term-summary",
			"param_key": "search-term-summary",
			"url_update": "/search-term",
			"heatMapEnabled": 1,
			"prefer_tables": [

				{
					"table_name": "Search Term Types",
					"table_id": "Search_Term_Types",
					"end_point": "/search-term-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "SearchTermTypes"
					},
					"table_json": {
						"aggregate": "SearchTermTypes",
						"data_id": 'ST_Types_ASIN_KWD',
						"columns": getCoulmnsAPi('Search Term Types', 'ST_Types_ASIN_KWD'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},
				// {
				// 	"table_name": "Search Term Category",
				// 	"table_id": "Search_Term_Category",
				// 	"end_point": "/search-term-summary",
				// 	"payload": {
				// 		"start_date": "2023-02-01",
				// 		"end_date": "2023-02-15",
				// 		"aggregate": "SearchTermCategory"
				// 	},
				// 	"table_json": {
				// 		"data_id": 'Search Term Category',
				// 		"columns": getCoulmnsAPi('Search Term Category', 'Search Term Category'),
				// 		"summary": [

				// 		],
				// 		"rows": [

				// 		]
				// 	}
				// },
				{
					"table_name": "Search Term",
					"table_id": "Search_term",
					"end_point": "/search-term-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "SearchTerm"
					},
					"table_json": {
						"aggregate": "SearchTerm",
						"data_id": 'query',
						"pagination": "enabled",
						"columns": getCoulmnsAPi('Search Term', 'query'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},
				{
					"table_name": "Targeting Types",
					"table_id": "Targeting_types",
					"end_point": "/search-term-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "TargetingType"
					},
					"table_json": {
						"aggregate": "TargetingType",
						"data_id": 'TargetingType',
						"columns": getCoulmnsAPi('Targeting Types', 'TargetingType'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},
				// {
				// 	"table_name": "Brand/Comp/NB - ST",
				// 	"table_id": "Brand/Comp/NB - ST",
				// 	"end_point": "/search-term-summary",
				// 	"payload": {
				// 		"start_date": "2023-02-01",
				// 		"end_date": "2023-02-15",
				// 		"aggregate": "Brand/Comp/NB - ST"
				// 	},
				// 	"table_json": {
				// 		"data_id": 'Brand/Comp/NB - ST',
				// 		"columns": getCoulmnsAPi('Brand/Comp/NB - ST', 'Brand/Comp/NB - ST'),
				// 		"summary": [

				// 		],
				// 		"rows": [

				// 		]
				// 	}
				// },
				{
					"table_name": "Match Type",
					"table_id": "Match Type",
					"end_point": "/search-term-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "MatchType"
					},
					"table_json": {
						"aggregate": "MatchType",
						"data_id": 'matchType',
						"columns": getCoulmnsAPi('Match Type', 'matchType'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},
				{
					"table_name": "Type",
					"classNames": 'text-capitalize',
					"table_id": "Type",
					"end_point": "/search-term-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Type"
					},
					"table_json": {
						"aggregate": "Type",
						"data_id": 'Type',
						"columns": getCoulmnsAPi('Campaign Type', 'Type'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},

				{
					"table_name": "Ad Formats",
					"table_id": "Ad_Formats",
					"end_point": "/search-term-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "AdFormat"
					},
					"table_json": {
						"aggregate": "AdFormat",
						"data_id": 'AdFormats',
						"columns": getCoulmnsAPi('Ad Formats', 'AdFormats'),
						"rows": [

						],
						"summary": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},
				// {
				// 	"table_name": "Keyword Text",
				// 	"table_id": "Keyword Text",
				// 	"end_point": "/search-term-summary",
				// 	"payload": {
				// 		"start_date": "2023-02-01",
				// 		"end_date": "2023-02-15",
				// 		"aggregate": "KeywordText"
				// 	},
				// 	"table_json": {
				// 		"aggregate" : "KeywordText",
				// 		"data_id": 'keywordText',
				// 		"columns": getCoulmnsAPi('Keyword Text', 'keywordText'),
				// 		"summary": [

				// 		],
				// 		"rows": [

				// 		]
				// 	},
				// 	"default_sort": {
				// 		"sort_type": "ASC",
				// 		"sort_column": "Spend",
				// 		"is_sort": false
				// 	}
				// },

				// {
				// 	"table_name": "Brand/NB/Comp - Kwd",
				// 	"table_id": "Brand/NB/Comp - Kwd",
				// 	"end_point": "/search-term-summary",
				// 	"payload": {
				// 		"start_date": "2023-02-01",
				// 		"end_date": "2023-02-15",
				// 		"aggregate": "Brand/NB/Comp - Kwd"
				// 	},
				// 	"table_json": {
				// 		"data_id": 'Brand/NB/Comp - Kwd',
				// 		"columns": getCoulmnsAPi('Brand/NB/Comp - Kwd', 'Brand/NB/Comp - Kwd'),
				// 		"summary": [

				// 		],
				// 		"rows": [

				// 		]
				// 	}
				// },
				{
					"table_name": "Campaign Name",
					"table_id": "campaign_name",
					"end_point": "/search-term-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "CampaignName"
					},
					"table_json": {
						"aggregate": "CampaignName",
						"data_id": 'campaignName',
						"search": "enable",
						"expand_row": "enable",
						"pagination": "enabled",
						"columns": getCoulmnsAPi('Campaign Name', 'campaignName'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},
				{
					"table_name": "Ad Group Name",
					"table_id": "Ad_group_name",
					"end_point": "/search-term-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "adGroupName"
					},
					"table_json": {
						"aggregate": "adGroupName",
						"data_id": 'adGroupName',
						"search": "enable",
						"expand_row": "enable",
						"pagination": "enabled",
						"columns": getCoulmnsAPi('Ad Group Name', 'adGroupName'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},
				{
					"table_name": "Brand",
					"table_id": "brand",
					"end_point": "/search-term-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Brand"
					},
					"table_json": {
						"aggregate" : "Brand",
						"data_id": 'type_id',
						"categorizationLink": process.env.REACT_APP_URL + "/text-categorization",
						"columns": getCoulmnsAPi('Brand/Comp/Generic', 'brand_id', 'any', 'brand_name'),
						"summary": [
		
						],
						"rows": [
		
						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},
		
				{
					"table_name": "Category",
					"table_id": "Category",
					"end_point": "/search-term-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Category"
					},
					"table_json": {
						"aggregate": "Category",
						"data_id": 'category_id',
						"categorizationLink": process.env.REACT_APP_URL + "/text-categorization",
						"columns": getCoulmnsAPi('Category', 'category_id', 'any', 'category_name'),
						"summary": [
		
						],
						"rows": [
		
						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},
		
				{
					"table_name": "Sub Category",
					"table_id": "Sub_Category",
					"end_point": "/search-term-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Subcategory"
					},
					"table_json": {
						"aggregate": "Subcategory",
						"data_id": 'subcategory_id',
						"categorizationLink": process.env.REACT_APP_URL + "/text-categorization",
						"columns": getCoulmnsAPi('Sub Category', 'subcategory_id', 'any', 'subcategory_name'),
						"summary": [
		
						],
						"rows": [
		
						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},

				// {
				// 	"table_name": "Custom Label",
				// 	"table_id": "Custom_Label",
				// 	"end_point": "/search-term-summary",
				// 	"payload": {
				// 		"start_date": "2023-02-01",
				// 		"end_date": "2023-02-15",
				// 		"aggregate": "CustomLabel"
				// 	},
				// 	"table_json": {
				// 		"aggregate": "Custom_Label",
				// 		"data_id": 'label_id',
				// 		"categorizationLink": process.env.REACT_APP_URL + "/text-categorization",
				// 		"columns": getCoulmnsAPi('Custom Label', 'label_id', 'any', 'label_name'),
				// 		"summary": [
		
				// 		],
				// 		"rows": [
		
				// 		]
				// 	},
				// 	"default_sort": {
				// 		"sort_type": "ASC",
				// 		"sort_column": "Spend",
				// 		"is_sort": false
				// 	}
				// },

		
				{
					"table_name": "Day of Week",
					"table_id": "Day of Week",
					"end_point": "/search-term-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Weekday"
					},
					"table_json": {
						"aggregate": "Weekday",
						"data_id": 'Weekday',
						"columns": getCoulmnsAPi('Day of Week', 'Weekday'),
						"summary": [

						],
						"rows": [

						]
					}
				},
				{
					"table_name": "Daily",
					"table_id": "Daily",
					"end_point": "/search-term-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Daily"
					},
					"table_json": {
						"aggregate": "Daily",
						"data_id": 'Date',
						"pagination": "enabled",
						"columns": getCoulmnsAPi('Daily', 'Date', 'Date'),
						"summary": [
						],
						"rows": [
						]

					},
					// "default_sort": {
					// 	"sort_type": "ASC",
					// 	"sort_column": "Daily",
					// 	"is_sort": false
					// }
				},
				{
					"table_name": "Weekly",
					"table_id": "Weekly",
					"end_point": "/search-term-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Weekly"
					},
					"table_json": {
						"aggregate": "Weekly",
						"data_id": 'Week',
						"expand_row": "enable",
						"columns": getCoulmnsAPi('Weekly', 'Week', 'Date'),
						"summary": [
						],
						"rows": [
						]

					},
					// "default_sort": {
					// 	"sort_type": "ASC",
					// 	"sort_column": "Weekly",
					// 	"is_sort": false
					// }
				},
				{
					"table_name": "Monthly",
					"table_id": "Monthly",
					"end_point": "/search-term-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "monthly"
					},
					"table_json": {
						"aggregate": "Monthly",
						"data_id": 'Month',
						"columns": getCoulmnsAPi('Monthly', 'Month', 'Date'),
						"summary": [
						],
						"rows": [
						]

					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Monthly",
						"is_sort": false
					}
				},

			]
		},
		{
			"id": 5,
			"is_group": 0,
			"title": "Placement Summary",
			"title_aggregate": "placement-summary",
			"param_key": "placement-summary",
			"heatMapEnabled": 1,
			"url_update": "/placement",
			"prefer_tables": [

				{
					"table_name": "Placement Type",
					"table_id": "Placement Type",
					"end_point": "/placement-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "PlacementType"
					},
					"table_json": {
						"aggregate": "PlacementType",
						"data_id": 'placement',
						"expand_row": "enable",
						"columns": getCoulmnsAPi('Placement Type', 'placement'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},

				{
					"table_name": "CampaignName",
					"table_id": "CampaignName",
					"end_point": "/placement-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "CampaignName"
					},
					"table_json": {
						"aggregate": "CampaignName",
						"data_id": 'campaignName',
						"search": "enable",
						"expand_row": "enable",
						"pagination": "enabled",
						"columns": getCoulmnsAPi('Campaign Name', 'campaignName'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},
				{
					"table_name": "Type",
					"classNames": 'text-capitalize',
					"table_id": "Type",
					"end_point": "/placement-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Type"
					},
					"table_json": {
						"aggregate": "Type",
						"data_id": 'Type',
						"columns": getCoulmnsAPi('Campaign Type', 'Type'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},
				{
					"table_name": "Day of Week",
					"table_id": "Day of Week",
					"end_point": "/placement-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Weekday"
					},
					"table_json": {
						"aggregate": "Weekday",
						"data_id": 'Weekday',
						"columns": getCoulmnsAPi('Day of Week', 'Weekday'),
						"summary": [

						],
						"rows": [

						]
					}
				},

				{
					"table_name": "Bidding Strategy",
					"table_id": "Bidding Strategy",
					"end_point": "/placement-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "BiddingStrategy"
					},
					"table_json": {
						"aggregate": "BiddingStrategy",
						"data_id": 'biddingStrategy',
						"expand_row": "enable",
						"columns": getCoulmnsAPi('Bidding Strategy', 'biddingStrategy'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},
				{
					"table_name": "Daily",
					"table_id": "Daily",
					"end_point": "/placement-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Daily"
					},
					"table_json": {
						"aggregate": "Daily",
						"data_id": 'Date',
						"pagination": "enabled",
						"columns": getCoulmnsAPi('Daily', 'Date', 'Date'),
						"summary": [
						],
						"rows": [
						]

					},
					// "default_sort": {
					// 	"sort_type": "ASC",
					// 	"sort_column": "Daily",
					// 	"is_sort": false
					// }
				},
				{
					"table_name": "Weekly",
					"table_id": "Weekly",
					"end_point": "/placement-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Weekly"
					},
					"table_json": {
						"aggregate": "Weekly",
						"data_id": 'Week',
						"expand_row": "enable",
						"columns": getCoulmnsAPi('Weekly', 'Week', 'Date'),
						"summary": [
						],
						"rows": [
						]

					},
					// "default_sort": {
					// 	"sort_type": "ASC",
					// 	"sort_column": "Weekly",
					// 	"is_sort": false
					// }
				},
				{
					"table_name": "Monthly",
					"table_id": "Monthly",
					"end_point": "/placement-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Monthly"
					},
					"table_json": {
						"aggregate": "Monthly",
						"data_id": 'Month',
						"columns": getCoulmnsAPi('Monthly', 'Month', 'Date'),
						"summary": [
						],
						"rows": [
						]

					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Monthly",
						"is_sort": false
					}
				},


			]
		},
		{
			"id": 6,
			"is_group": 0,
			"title": "Advertised Product Summary",
			"title_aggregate": "advertised-product-summary",
			"param_key": "advertised-product-summary",
			"url_update": "/advertised-product",
			"heatMapEnabled": 1,
			"prefer_tables": [

				{
					"table_name": "placement_summary",
					"table_id": "placement summary",
					"end_point": "/advertised-product-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "CampaignName"
					},
					"table_json": {
						"aggregate": "CampaignName",
						"data_id": 'campaignName',
						"search": "enable",
						"expand_row": "enable",
						"pagination": "enabled",
						"columns": getCoulmnsAPi('Campaign Name', 'campaignName'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},

				{
					"table_name": "Type",
					"classNames": 'text-capitalize',
					"table_id": "Type",
					"end_point": "/advertised-product-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "CampaignType"
					},
					"table_json": {
						"aggregate": "CampaignType",
						"data_id": 'Type',
						"columns": getCoulmnsAPi('Campaign Type', 'Type'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},
				{
					"table_name": "Day of Week",
					"table_id": "Day of Week",
					"end_point": "/advertised-product-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Weekday"
					},
					"table_json": {
						"aggregate": "Weekday",
						"data_id": 'Weekday',
						"columns": getCoulmnsAPi('Day of Week', 'Weekday'),
						"summary": [

						],
						"rows": [

						]
					}
				},

				{
					"table_name": "Ad Formats",
					"table_id": "Ad_Formats",
					"end_point": "/advertised-product-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "AdFormat"
					},
					"table_json": {
						"aggregate": "AdFormat",
						"data_id": 'AdFormats',
						"columns": getCoulmnsAPi('Ad Formats', 'AdFormats'),
						"rows": [

						],
						"summary": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},

				{
					"table_name": "Advertised ASINs",
					"table_id": "Advertised ASINs",
					"end_point": "/advertised-product-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "AdvertisedASINs"
					},
					"table_json": {
						"aggregate": "AdvertisedASINs",
						"data_id": 'AdvertisedASINs',
						"pagination": "enabled",
						"columns": getCoulmnsAPi('Advertised ASINs', 'AdvertisedASINs'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},
				{
					"table_name": "Targeting Types",
					"table_id": "Targeting_types",
					"end_point": "/advertised-product-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "TargetingType"
					},
					"table_json": {
						"aggregate": "TargetingType",
						"data_id": 'targetTypes',
						"columns": getCoulmnsAPi('Targeting Types', 'targetTypes'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},
				

				// {
				// 	"table_name": "Custom Label",
				// 	"table_id": "Custom_Label",
				// 	"end_point": "/advertised-product-summary",
				// 	"payload": {
				// 		"start_date": "2023-02-01",
				// 		"end_date": "2023-02-15",
				// 		"aggregate": "CustomLabel"
				// 	},
				// 	"table_json": {
				// 		"aggregate": "Custom_Label",
				// 		"data_id": 'label_id',
				// 		"categorizationLink": process.env.REACT_APP_URL + "/categorization",
				// 		"columns": getCoulmnsAPi('Custom Label', 'label_id', 'any', 'label_name'),
				// 		"summary": [
		
				// 		],
				// 		"rows": [
		
				// 		]
				// 	},
				// 	"default_sort": {
				// 		"sort_type": "ASC",
				// 		"sort_column": "Spend",
				// 		"is_sort": false
				// 	}
				// },

				// {
				// 	"table_name": "Variation",
				// 	"table_id": "Variation",
				// 	"end_point": "/advertised-product-summary",
				// 	"payload": {
				// 		"start_date": "2023-02-01",
				// 		"end_date": "2023-02-15",
				// 		"aggregate": "Variation"
				// 	},
				// 	"table_json": {
				// 		"aggregate": "Variation",
				// 		"data_id": 'variation_id',
				// 		"columns": getCoulmnsAPi('Variation', 'variation_id', 'any', 'variation_name'),
				// 		"summary": [

				// 		],
				// 		"rows": [

				// 		]
				// 	},
				// 	"default_sort": {
				// 		"sort_type": "ASC",
				// 		"sort_column": "Spend",
				// 		"is_sort": false
				// 	}
				// },

				// {
				// 	"table_name": "Pack of Size",
				// 	"table_id": "Pack_of_Size",
				// 	"end_point": "/advertised-product-summary",
				// 	"payload": {
				// 		"start_date": "2023-02-01",
				// 		"end_date": "2023-02-15",
				// 		"aggregate": "Packsize"
				// 	},
				// 	"table_json": {
				// 		"aggregate": "Packsize",
				// 		"data_id": 'packsize_id',
				// 		"columns": getCoulmnsAPi('Pack of Size', 'packsize_id', 'any', 'packsize_name'),
				// 		"summary": [

				// 		],
				// 		"rows": [

				// 		]
				// 	},
				// 	"default_sort": {
				// 		"sort_type": "ASC",
				// 		"sort_column": "Spend",
				// 		"is_sort": false
				// 	}
				// },

				// {
				// 	"table_name": "Campaign StartDate",
				// 	"table_id": "Campaign StartDate",
				// 	"end_point": "/advertised-product-summary",
				// 	"payload": {
				// 		"start_date": "2023-02-01",
				// 		"end_date": "2023-02-15",
				// 		"aggregate": "CampaignStartDate"
				// 	},
				// 	"table_json": {
				// 		"aggregate" : "CampaignStartDate",
				// 		"data_id": 'startDate',
				// 		"columns": getCoulmnsAPi('Campaign StartDate', 'startDate'),
				// 		"summary": [

				// 		],
				// 		"rows": [

				// 		]
				// 	}
				// },

				{
					"table_name": "Ad Group Name",
					"table_id": "Ad_group_name",
					"end_point": "/advertised-product-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "adGroupName"
					},
					"table_json": {
						"aggregate": "adGroupName",
						"data_id": 'adGroupName',
						"search": "enable",
						"expand_row": "enable",
						"pagination": "enabled",
						"columns": getCoulmnsAPi('Ad Group Name', 'adGroupName'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},
				{
					"table_name": "Daily",
					"table_id": "Daily",
					"end_point": "/advertised-product-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Daily"
					},
					"table_json": {
						"aggregate": "Daily",
						"data_id": 'Date',
						"pagination": "enabled",
						"columns": getCoulmnsAPi('Daily', 'Date', 'Date'),
						"summary": [
						],
						"rows": [
						]

					},
					// "default_sort": {
					// 	"sort_type": "ASC",
					// 	"sort_column": "Daily",
					// 	"is_sort": false
					// }
				},
				{
					"table_name": "Weekly",
					"table_id": "Weekly",
					"end_point": "/advertised-product-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Weekly"
					},
					"table_json": {
						"aggregate": "Weekly",
						"data_id": 'Week',
						"expand_row": "enable",
						"columns": getCoulmnsAPi('Weekly', 'Week', 'Date'),
						"summary": [
						],
						"rows": [
						]

					},
					// "default_sort": {
					// 	"sort_type": "ASC",
					// 	"sort_column": "Weekly",
					// 	"is_sort": false
					// }
				},
				{
					"table_name": "Monthly",
					"table_id": "Monthly",
					"end_point": "/advertised-product-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Monthly"
					},
					"table_json": {
						"aggregate": "Monthly",
						"data_id": 'Month',
						"columns": getCoulmnsAPi('Monthly', 'Month', 'Date'),
						"summary": [
						],
						"rows": [
						]

					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Monthly",
						"is_sort": false
					}
				},
				{
					"table_name": "Brand",
					"table_id": "brand",
					"end_point": "/advertised-product-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Brand"
					},
					"table_json": {
						"aggregate": "Brand",
						"data_id": 'brand_id',
						"categorizationLink": process.env.REACT_APP_URL + "/categorization",
						"columns": getCoulmnsAPi('Brand', 'brand_id', 'any', 'brand_name'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},

				{
					"table_name": "Category",
					"table_id": "Category",
					"end_point": "/advertised-product-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Category"
					},
					"table_json": {
						"aggregate": "Category",
						"data_id": 'category_id',
						"categorizationLink": process.env.REACT_APP_URL + "/categorization",
						"columns": getCoulmnsAPi('Category', 'category_id', 'any', 'category_name'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},

				{
					"table_name": "Sub Category",
					"table_id": "Sub_Category",
					"end_point": "/advertised-product-summary",
					"payload": {
						"start_date": "2023-02-01",
						"end_date": "2023-02-15",
						"aggregate": "Subcategory"
					},
					"table_json": {
						"aggregate": "Subcategory",
						"data_id": 'subcategory_id',
						"categorizationLink": process.env.REACT_APP_URL + "/categorization",
						"columns": getCoulmnsAPi('Sub Category', 'subcategory_id', 'any', 'subcategory_name'),
						"summary": [

						],
						"rows": [

						]
					},
					"default_sort": {
						"sort_type": "ASC",
						"sort_column": "Spend",
						"is_sort": false
					}
				},
			]
		},

	],
}

export default TAB_GROUP