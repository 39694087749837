import React, { useEffect, useState, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import MixedOptions from 'src/components/Charts/Highchart/config/line-basic-chart.constant';
import DemoMetrics from 'src/components/Charts/Highchart/config/demo-metrics';
import { Col } from 'react-bootstrap';
import chartloader from 'src/assets/images/common/chart-loader.svg';
import icons from 'currency-icons'
import moment from 'moment';
import { format } from 'date-fns';


const Charts = ({ options, doTrendChartApi }) => {
    const getProfile = JSON.parse(localStorage.getItem('selectedProfileId'));

    const [merticsData, setMerticsData] = useState({})



    const getValue = (label, value) => {

        const formats = [{ column_type: 'number', label: 'Spend', param: 'Spend', prefix_sign: 'currency' },
        { column_type: 'number', label: 'Revenue', param: 'Revenue', prefix_sign: 'currency' },
        { column_type: 'number', label: 'Orders', param: 'Orders' },
        { column_type: 'number', label: 'Impressions', param: 'Impressions' },
        { column_type: 'number', label: 'Clicks', param: 'Clicks' },
        { column_type: 'decimal', label: 'CPA', param: 'CPA', prefix_sign: 'currency' },
        { column_type: 'decimal', label: 'ROAS', param: 'ROAS' },
        { column_type: 'decimal', label: 'AOV', param: 'AOV', prefix_sign: 'currency' },
        { column_type: 'percentage', label: 'CTR', param: 'CTR' },
        { column_type: 'decimal', label: 'CPM', param: 'CPM', prefix_sign: 'currency' },
        { column_type: 'decimal', label: 'CPC', param: 'CPC', prefix_sign: 'currency' },
        { column_type: 'value', label: 'ACOS', param: 'ACOS' },
        { column_type: 'number', label: 'RPC', param: 'RPC' },
        { column_type: 'number', label: 'CPO', param: 'CPO', prefix_sign: 'currency' },
        { column_type: 'value', label: 'Spend %', param: 'Spend_perc' },
        { column_type: 'value', label: 'Revenue %', param: 'Revenue_perc' },
        { column_type: 'value', label: 'CR %', param: 'CR_per' },
        { column_type: 'number', label: 'CC', param: 'CC' }
        ]

        const intlFormat = (num) => {
            return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
        }

        const getFormat = formats.find((metric) => (metric.label === label));
        if (getFormat) {
            const { column_type, prefix_sign } = getFormat
            let sign = ''
            if (prefix_sign) sign = icons[getProfile?.currencyCode]?.symbol

            if (!value) return 0;
            if (column_type === 'percentage') return Number(value) >= 10 ? parseInt(value) + '%' : (Math.round(value * 100) / 100).toFixed(2) + '%';
            if (column_type === 'decimal') return value >= 10 ? parseInt(value) : parseFloat(value);
            if (column_type === 'number')
                if (value >= 1000000000) return intlFormat(value / 1000000000) + 'B' //intlFormat(value / 1000000000) + 'B';
            if (value >= 1000000) return intlFormat(value / 1000000) + 'M' //intlFormat(value / 1000000) + 'M';
            // if(value >= 100000) return intlFormat(value / 100000) + 'L'
            // if(value >= 1000) return intlFormat(value / 1000) + 'T'

            return sign + Number(value).toLocaleString('en-IN');
        }
    }

    const getAxiosValue = (label, value) => {

        const formats = [{ column_type: 'number', label: 'Spend', param: 'Spend', prefix_sign: 'currency' },
        { column_type: 'number', label: 'Revenue', param: 'Revenue', prefix_sign: 'currency' },
        { column_type: 'number', label: 'Orders', param: 'Orders' },
        { column_type: 'number', label: 'Impressions', param: 'Impressions' },
        { column_type: 'number', label: 'Clicks', param: 'Clicks' },
        { column_type: 'decimal', label: 'CPA', param: 'CPA', prefix_sign: 'currency' },
        { column_type: 'decimal', label: 'ROAS', param: 'ROAS' },
        { column_type: 'decimal', label: 'AOV', param: 'AOV', prefix_sign: 'currency' },
        { column_type: 'percentage', label: 'CTR', param: 'CTR' },
        { column_type: 'decimal', label: 'CPM', param: 'CPM', prefix_sign: 'currency' },
        { column_type: 'decimal', label: 'CPC', param: 'CPC', prefix_sign: 'currency' },
        { column_type: 'value', label: 'ACOS', param: 'ACOS' },
        { column_type: 'number', label: 'RPC', param: 'RPC' },
        { column_type: 'number', label: 'CPO', param: 'CPO', prefix_sign: 'currency' },
        { column_type: 'value', label: 'Spend %', param: 'Spend_perc' },
        { column_type: 'value', label: 'Revenue %', param: 'Revenue_perc' },
        { column_type: 'value', label: 'CR %', param: 'CR_per' },
        { column_type: 'number', label: 'CC', param: 'CC' }
        ]

        const intlFormat = (num) => {
            return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
        }

        const getFormat = formats.find((metric) => (metric.label === label));
        if (getFormat) {
            const { column_type, prefix_sign } = getFormat
            let sign = ''
            if (prefix_sign) sign = icons[getProfile?.currencyCode]?.symbol

            // if (!value) return 0;
            if (column_type === 'percentage') return Number(value) >= 10 ? parseInt(value) + '%' : (Math.round(value * 100) / 100).toFixed(2) + '%';
            if (column_type === 'decimal') return value >= 10 ? parseInt(value) : parseFloat(value);
            if (column_type === 'number')
                if (value >= 1000000000) return intlFormat(value / 1000000000) + 'B' //intlFormat(value / 1000000000) + 'B';
            if (value >= 1000000) return intlFormat(value / 1000000) + 'M' //intlFormat(value / 1000000) + 'M';
            if (value >= 100000) return intlFormat(value / 100000) + 'L'
            if (value >= 1000) return intlFormat(value / 1000) + 'T'

            return sign + Number(value).toLocaleString('en-IN');
        }
    }


    function formatDateArray(dateArray) {
        const formattedDates = dateArray.map(dateString => {
            const date = new Date(dateString);
            const formattedDate = format(date, 'MMM dd');
            return formattedDate;
        });
        return formattedDates;
    }


    useEffect(() => {
        const { series, categories } = options;
        if (series && categories) {
            console.log("series", series)
            const demoMetrics = JSON.parse(JSON.stringify(DemoMetrics));

            if (series.length === 1) {
                demoMetrics.colors = ['#31054A']
            } else if (series.length === 2) {
                demoMetrics.colors = ['#31054A', '#0E2457']
            } else if (series.length === 3) {
                demoMetrics.colors = ['#31054A', '#0E2457', '#FDC132']
            } else if (series.length === 4) {
                demoMetrics.colors = ['#31054A', '#0E2457', '#FDC132', "#282830"]
            } else if (series.length === 5) {
                demoMetrics.colors = ['#31054A', '#0E2457', '#FDC132', "#282830", "#4F2F21"]
            } else if (series.length === 6) {
                demoMetrics.colors = ['#31054A', '#0E2457', '#FDC132', "#282830", "#4F2F21", "#234856"]
            } else if (series.length === 7) {
                demoMetrics.colors = ['#31054A', '#0E2457', '#FDC132', "#282830", "#4F2F21", "#234856", "#93341D"]
            } else if (series.length === 8) {
                demoMetrics.colors = ['#31054A', '#0E2457', '#FDC132', "#282830", "#4F2F21", "#234856", "#93341D", "#7D9151"]
            } else if (series.length === 9) {
                demoMetrics.colors = ['#31054A', '#0E2457', '#FDC132', "#282830", "#4F2F21", "#234856", "#93341D", "#7D9151", "#646E87"]
            } else if (series.length === 10) {
                demoMetrics.colors = ['#31054A', '#0E2457', '#FDC132', "#282830", "#4F2F21", "#234856", "#93341D", "#7D9151", "#646E87", "#3D0706"]
            }
            console.log("formatDate(categories)", categories.length)
            demoMetrics.xAxis.categories = formatDateArray(categories)
            demoMetrics.xAxis.minTickInterval = categories.length > 20 ? Math.ceil(categories.length / 20) : 0

            demoMetrics.series = series.map((row_data, index) => ({ name: row_data.name, data: row_data.data, yAxis: index, type: row_data.type ? row_data.type : "line", colorIndex: index, showInLegend: true, }))
            demoMetrics.yAxis = series.map((row_data, index) => ({
                min: 0, title: { text: '' }, opposite:  false,
                labels: {
                    // style: { color: index === 0 && 'rgb(46, 140, 184)' || index === 1 && 'rgb(221, 119, 3)' || index === 2 && 'rgb(163, 49, 123)' || index === 3 && 'rgb(88, 112, 33)' },
                    formatter: function () {
                        console.log("value", getAxiosValue(row_data.name, this.value))
                        console.log("value", row_data.name, this.value)

                        // if (this.value > 1000) return Highcharts.numberFormat(this.value / 1000, 1) + "T";
                        // return Highcharts.numberFormat(getValue(row_data.name, this.value), 0);
                        return this.value
                    },
                },
            }))


            demoMetrics.tooltip = {
                useHTML: true,
                shared: true,
                shadow: true,
                backgroundColor: '#FFFFFF',
                borderWidth: 0,
                valueDecimals: 0,
                borderColor: '#hjg',
                borderRadius: 10,
                borderWidth: 1,

                formatter: function () {
                    console.log("formatter", this)
                    var symbol = '';

                    function symbolConvertor(getSymbol) {
                        if (getSymbol === 'circle') {
                            return '<svg class="shapes-tip" viewBox="-10 -10 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13Z" fill="currentColor"/></svg>';
                        }
                        else if (getSymbol === 'diamond') {
                            return '<svg class="shapes-tip" viewBox="-10 -10 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.9998 6.5L6.49978 13L0 6.5L6.5 0L12.9998 6.5Z" fill="currentColor"/>';
                        }
                        else if (getSymbol === 'square') {
                            return '<svg class="shapes-tip" viewBox="-10 -10 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="11" height="11" fill="currentColor"/></svg>';
                        }
                        else if (getSymbol === 'triangle') {
                            return '<svg class="shapes-tip" viewBox="-10 -10 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 13H0L6.5 0L13 13Z" fill="currentColor"/></svg>';
                        }
                        else if (getSymbol === 'triangle-down') {
                            return '<svg class="shapes-tip" viewBox="-10 -10 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0H13L6.5 13L0 0Z" fill="currentColor"/></svg>';
                        }
                    }


                    const htmlString = `<div class="my-tool"> <span class="tool-date">${this.x + ' ' + new Date().getFullYear()}</span><table class="tool-tab">${this.points.map(function (point) {
                        return `<tr><td  class="d-flex align-items-center justify-content-center"> <div class="point-shape" style="color: ${point.color}; text-align: center;">${symbolConvertor(point.series.symbol)}</div> </td>` +
                            `<td class="text-dark" style="font-size: 13px;font-weight: 500; padding-left: 10px;">${point.series.name}</td> `
                            + `<td style="font-weight: 700;font-size: 14px;">${getValue(point.series.name, point.y)}</td>` +
                            '</tr>'
                    }).join('')}</table></div>`
                    return htmlString

                },
            }

            setMerticsData(demoMetrics)
        }
    }, [options, options.series])


    console.log("merticsData NEW", merticsData)
    // {
    //     doTrendChartApi.loading &&
    //         <Col sm={12} className="d-flex align-items-center justify-content-center f-w-700 text-black">
    //             <img src={chartloader} alt="loader" className="mr-1 wid-30 hei-30" /> Loading...
    //         </Col>
    // }
    return (<HighchartsReact highcharts={Highcharts} options={merticsData} />)
    // return <HighchartsReact highcharts={Highcharts} options={MixedOptions} />
};

export default Charts;






    // useEffect(() => {
    //     const { series, categories } = options;
    //     if (series && categories) {
    //         console.log("series", series)
    //         const demoMetrics = JSON.parse(JSON.stringify(DemoMetrics));

    //         if (series.length === 1) {
    //             demoMetrics.colors = ['#31054A']
    //         } else if (series.length === 2) {
    //             demoMetrics.colors = ['#31054A', '#0E2457']
    //         } else if (series.length === 3) {
    //             demoMetrics.colors = ['#31054A', '#0E2457', '#FDC132']
    //         } else if (series.length === 4) {
    //             demoMetrics.colors = ['#31054A', '#0E2457', '#FDC132', "#282830"]
    //         } else if (series.length === 5) {
    //             demoMetrics.colors = ['#31054A', '#0E2457', '#FDC132', "#282830", "#4F2F21"]
    //         } else if (series.length === 6) {
    //             demoMetrics.colors = ['#31054A', '#0E2457', '#FDC132', "#282830", "#4F2F21", "#234856"]
    //         } else if (series.length === 7) {
    //             demoMetrics.colors = ['#31054A', '#0E2457', '#FDC132', "#282830", "#4F2F21", "#234856", "#93341D"]
    //         } else if (series.length === 8) {
    //             demoMetrics.colors = ['#31054A', '#0E2457', '#FDC132', "#282830", "#4F2F21", "#234856", "#93341D", "#7D9151"]
    //         } else if (series.length === 9) {
    //             demoMetrics.colors = ['#31054A', '#0E2457', '#FDC132', "#282830", "#4F2F21", "#234856", "#93341D", "#7D9151", "#646E87"]
    //         } else if (series.length === 10) {
    //             demoMetrics.colors = ['#31054A', '#0E2457', '#FDC132', "#282830", "#4F2F21", "#234856", "#93341D", "#7D9151", "#646E87", "#3D0706"]
    //         }

    //         demoMetrics.xAxis.categories = categories
    //         demoMetrics.series = series.map((row_data, index) => ({ name: row_data.name, data: row_data.data, yAxis: index, type: row_data.type ? row_data.type : "line", marker: { symbol: 'circle' }, colorIndex: index }))
    //         demoMetrics.yAxis = series.map((row_data, index) => ({
    //             min: 0, title: { text: row_data.name }, opposite: series.length - 1 === index ? false : true, labels: {
    //                 formatter: function () {
    //                     if (this.value > 1000) return Highcharts.numberFormat(this.value / 1000, 1) + "k";
    //                     return Highcharts.numberFormat(this.value, 0);
    //                 },
    //             },
    //         }))

    //         setMerticsData(demoMetrics)
    //     }
    // }, [options, options?.series])