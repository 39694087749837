import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';
import Box from "@mui/material/Box";
import { IconButton } from '@mui/material';

const CustomPopoverContext = React.createContext();
export const useCustomPopover = () => React.useContext(CustomPopoverContext);

function CustomPopover({ buttonTrigger, iconTrigger, popoverContent, anchorOrigin, transformOrigin, onButtonClick = '', style, buttonStyle }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (onButtonClick !== '') {
      onButtonClick();
    }
    console.log('Setting anchorEl:', event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    console.log('anchorEl Closing popover');
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <CustomPopoverContext.Provider value={{ handleClose }}>
        {buttonTrigger ? (
          <div aria-describedby={id} onClick={handleClick}>
            {buttonTrigger}
          </div>
        ) : null}
        {iconTrigger ? (
          <IconButton
            size="small"
            aria-describedby={id}
            aria-label="popover-trigger"
            onClick={handleClick}
            sx={buttonStyle}
          >
            {iconTrigger}
          </IconButton>
        ) : null}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          PaperProps={{style: style}}
        >
          <div>{popoverContent}</div>
        </Popover>
    </CustomPopoverContext.Provider>
  );
}

CustomPopover.propTypes = {
  // buttonLabel: PropTypes.string.isRequired,
  popoverContent: PropTypes.node.isRequired,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(['top', 'bottom', 'center', 'stretch']),
    horizontal: PropTypes.oneOf(['left', 'center', 'right']),
  }),
};

CustomPopover.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
};

export default CustomPopover;
