import React from 'react';

const NavIcon = ({ items, menuIcon }) => {
  let navIcons = false;
  if (items.icon) {
    navIcons = (
      <span className="pcoded-micon">
        {/* <i className={items.icon} /> */}
        <img src={items.icon} />
      </span>
    );
  }

  return <React.Fragment>{items.beta === 1 ? <b className='nav-beta'>(Beta)</b> : null}{navIcons}</React.Fragment>;
};

export default NavIcon;
