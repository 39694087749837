import React, { useState } from 'react'

export default function dateFilter({setDateRangeType, dateRangeType}) {
    return (
        <React.Fragment>
            <section className="dwmy d-flex align-items-center bg-white">
                {['day', 'week', 'month', 'year'].map((type) => (
                    <div key={type} className={`text-center dwmy-btn ${type === 'year' ? "right_border_none" : ''}`}>
                        <button
                            className={`rounded dwmy-text`}
                            onClick={() => setDateRangeType(type)}
                            style={{
                                backgroundColor: dateRangeType === type ? '#5932ea' : 'transparent',
                                color: dateRangeType === type ? 'white' : ''
                            }}
                        >
                            {type.charAt(0).toUpperCase()}
                        </button>
                    </div>
                ))}
            </section>
        </React.Fragment>
    )
}
