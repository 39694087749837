import { replaceString } from 'src/api/demo';

const { useEffect, useRef, useState } = require('react');

const useGetProfile = () => {
  const [profileRef, setProfileRef] = useState({});

  const updateProfileRef = () => {
    const authToken = JSON.parse(localStorage.getItem('User')) || {};

    const getProfile =
    String(window.location.href).indexOf('blinkit') > -1 ? 
    JSON.parse(localStorage.getItem('blinkitProfile'))
    :String(window.location.href).indexOf('instamart') > -1 ? 
    JSON.parse(localStorage.getItem('instamartProfile'))
    : String(window.location.href).indexOf('myntra') > -1 ? 
    JSON.parse(localStorage.getItem('myntraProfile'))
    : String(window.location.href).indexOf('flipkart') > -1
        ? JSON.parse(localStorage.getItem('flipkartProfile'))
        : JSON.parse(localStorage.getItem('selectedProfileId'));
    console.log("useGetProfile", getProfile)
    if (authToken && process.env.REACT_APP_DEMO_EMAIL && process.env.REACT_APP_DEMO_EMAIL.indexOf(authToken?.email) > -1) {
      const newProfile = JSON.parse(replaceString(JSON.stringify(getProfile)));
      setProfileRef({ ...newProfile });
    } else {
      setProfileRef({ ...getProfile });
    }
  };
  
  useEffect(() => {
    console.log("updateProfileRef")
    updateProfileRef()
    window.addEventListener('storage', updateProfileRef);
    return () => {
      window.removeEventListener('storage', updateProfileRef);
    };
  }, [window.location.href]);

  return [profileRef];
};

export default useGetProfile;
