import React, { useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import CheckBox from 'src/components/Form/checkbox';
import DownArrow from 'src/assets/images/common/collapse-down.svg';
import RightArrow from 'src/assets/images/common/collapse-right.svg';
import Dragicon from 'src/assets/images/common/drag.svg';
import IconButton from '@mui/material/IconButton';

const Group = ({ titleHide = false, title, checkboxes, index, keyId, keyName, groupTitle, setGroupTitle, childClasses, groupInfo = {}, onGroupUpdate = {}, onMetricUpdate = {} }) => {
  const isChildShow = groupTitle === title || title === 'Metrics' ? true : false
  return (
    <section className="d-flex flex-column w-100">
      {!titleHide ? (
        <div className="d-flex align-items-center gap-4 f-15 f-w-400 cursor-pointer text-dark py-1">
          <img style={{ float: 'right', margin: '0px' }} src={Dragicon} alt="draggable" className="wid-8" />
          <IconButton
            size="small"
            aria-label="collapseTableRow"
            onClick={(event) => {
              event.preventDefault();
              if (groupTitle === title) {
                setGroupTitle('');
              } else {
                setGroupTitle(title);
              }
            }}
          >
            <img src={groupTitle === title ? DownArrow : RightArrow} alt="table-collapse" />
          </IconButton>
          {/* <CheckBox id={title} label={groupInfo?.group_title} checked={checkboxes?.some((item) => item.show == 'yes')}  classes={`f-10 text-capitalize ${!checkboxes?.every((item) => item.show == 'yes') && checkboxes?.some((item) => item.show == 'yes') ? 'indet_check' : ''}`} style={{ padding: '5px' }} action={(event) => {
              onGroupUpdate(event, 'group', groupInfo, {})
            }} /> */}
          <h4 className="f-13 f-w-400" onClick={(event) => {
              event.preventDefault();
              if (groupTitle === title) {
                setGroupTitle('');
              } else {
                setGroupTitle(title);
              }
            }}>{groupInfo?.group_title}</h4>
        </div>
      ) : (
        <div className="d-flex align-items-center gap-4 f-15 f-w-400 cursor-pointer text-dark py-1">
          <CheckBox
            id={title}
            label={'Select All'}
            checked={checkboxes?.some((item) => item.show == 'yes')}
            classes={`f-10 label_wrap text-capitalize ${
              !checkboxes?.every((item) => item.show == 'yes') && checkboxes?.some((item) => item.show == 'yes') ? 'indet_check' : ''
            }`}
            style={{ padding: '5px' }}
            action={(event) => {
              onMetricUpdate(event, checkboxes);
            }}
          />
        </div>
      )}
      {isChildShow ? (
        <Droppable droppableId={`group-${index}`} direction="vertical">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps} className={`${snapshot.isDraggingOver ? 'drag-drop-box' : ''}`}>
              {!titleHide ? <ul className={`d-flex flex-column gap-2 ${childClasses}`} key={`select_all_${keyId}`}>
                <li className="d-flex align-items-center justify-content-between py-1">
                  <CheckBox
                    id={`select-all-${keyId}`}
                    label={'All'}
                    classes={`text-capitalize label_wrap ${!checkboxes?.every((item) => item.show == 'yes') && checkboxes?.some((item) => item.show == 'yes') ? 'indet_check' : ''}`}
                    checked={checkboxes?.some((item) => item.show == 'yes')}
                    style={{ padding: '5px' }}
                    action={(event) => {
                      onGroupUpdate(event, 'group', groupInfo, {})
                    }}
                  />
                  <img style={{ float: 'right', margin: '0px' }} src={Dragicon} alt="draggable" className="wid-8" />
                </li>
              </ul> : null}
              {checkboxes.map((checkbox, index) => (
                <ul className={`d-flex flex-column gap-2 ${childClasses}`} key={checkbox[keyId]}>
                  <Draggable key={checkbox[keyId]} draggableId={checkbox[keyName]} index={index}>
                    {(provided, snapshot) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{ ...provided.draggableProps.style }}
                        className={`d-flex align-items-center justify-content-between py-1 ${snapshot.isDragging ? 'dragging-item' : ''} ${window.location.href.indexOf('amazon') > -1 &&(checkbox[keyName]?.toLowerCase() == 'roas' || checkbox[keyName]?.toLowerCase() == 'acos %') ? 'display-none' : ''}`}
                      >
                        <CheckBox
                          id={checkbox[keyId]}
                          label={window.location.href.indexOf('amazon') > -1 && String(checkbox[keyName]).toLowerCase() == 'revenue' ? 'Ad Revenue' : checkbox[keyName]?.replace(/_/g, ' ')}
                          classes={`text-capitalize label_wrap ${!titleHide ? 'f-10' : 'f-10'}`}
                          checked={checkbox?.show === 'yes'}
                          style={{ padding: '5px' }}
                          action={(event) => {
                            if (!titleHide) {
                              onGroupUpdate(event, 'table', groupInfo, checkbox);
                            } else {
                              onMetricUpdate(event, checkbox);
                            }
                          }}
                        />
                        <img style={{ float: 'right', margin: '0px' }} src={Dragicon} alt="draggable" className="wid-8" />
                      </li>
                    )}
                  </Draggable>
                </ul>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      ) : null}
    </section>
  );
};

export default Group;
