import React, { useState, useEffect } from 'react';
import DownChevron from 'src/assets/icons/DownChevron';
import CustomPopover from './Popover/CustomPopover';
import RadioButton from 'src/components/Actions/radioButton';

const DropDownAmc = () => {
  const selectedProfileId = JSON.parse(localStorage.getItem('selectedProfileId'));
  const localProfileId = selectedProfileId?.ams?.groupedAccounts || [];
  
  const [selected, setSelected] = useState(selectedProfileId.accountInfo?.name);

  const handleSelection = () => {
    setSelected(selected);
  };

  return (
    <div>
      <CustomPopover
        buttonTrigger={
          <div className='d-flex align-items-center justify-content-between Update-Audience-dropdown gap-10'>
            <span className='Update-Audience-dropdown-btn f-w-500'>
             Ad Account - {selected}
            </span>
            <DownChevron style={{ width: '10px', height: '10px' }} />
          </div>
        }
        popoverContent={
          <div className="Update-Audience-dropdown-list">
            <li key={selectedProfileId.profileId} className='Update-Audience-dropdown-item'>
              <div className='d-flex align-items-center gap-2 Update-Audience-dropdown-container'>
                <RadioButton
                  radioSize={'15px'}
                  title={<span className='f-12'>{selectedProfileId.accountInfo?.name}</span>}
                  checked={selected === selectedProfileId.accountInfo?.name}
                  action={() => handleSelection(selectedProfileId.accountInfo?.name)}
                />
              </div>
            </li>
            {localProfileId.map(item => (
              <li key={item.profileId} className='Update-Audience-dropdown-item disabled'>
                <div className='d-flex align-items-center gap-2 Update-Audience-dropdown-container disabled'>
                  <RadioButton
                    classes={'Update-Audience-dropdown-radio'}
                    radioSize={'15px'}
                    title={<span className='f-12' style={{ fontWeight: '500 !important' }}>{item.accountInfo?.name}</span>}
                    checked={false}
                    action={() => handleSelection(item.accountInfo?.name)}
                  />
                </div>
              </li>
            ))}
            {/* <div className='update-audience-btn-container'>
              <button 
                className='cls-btn cursor-pointer rounded-4 f-10 py-2 px-3 f-w-600 f-202226'
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button 
                className='bg-blue disabled rounded-4 f-10 py-2 px-3 f-w-600 cursor-pointer text-white mt-0 ml-2'
                onClick={handleApply}
              >
                Apply
              </button>
            </div> */}
          </div>
        }
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      />
    </div>
  );
};

export default DropDownAmc;
