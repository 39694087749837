import React, { createContext, useReducer, useContext } from 'react';

// const TargetingFilters = JSON.parse(localStorage.getItem('targeting-filters')) || []
const SavedFilters = JSON.parse(localStorage.getItem('saved-filters')) || {}
const getProfile = JSON.parse(localStorage.getItem('selectedProfileId')) || {}

// Initial state for the context
const initialState = {
  filters: [],
  filter_item: {},
  attribution_filter: { totalAsin: true, levelType: 'total', selectedAsin: ['halo', 'product'] },
  attribution_filter_items: { totalAsin: true, levelType: 'total', selectedAsin: ['halo', 'product'] },
  saved_filters: SavedFilters,
  columns: [],
  dsp_columns: [],
  table_groups: [],
  dsp_table_groups: [],
  applied_filters: [],
  un_group_tables: []
};

// Define the reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FILTERS':
      return { ...state, filters: action.payload };
    case 'UPDATE_APPLY_FILTERS':
      return { ...state, applied_filters: action.payload };
    case 'UPDATE_ATTRIBUTION_FILTERS':
      return { ...state, attribution_filter: action.payload };
    case 'UPDATE_APPLY_ATTRIBUTION_FILTERS':
      return { ...state, attribution_filter_items: action.payload };
    case 'SAVE_FILTERS':
      return { ...state, saved_filters: action.payload };
    case 'UPDATE_FILTER_ITEM':
        return { ...state, filter_item: action.payload };  
    case 'UPDATE_TABLE_COLUMNS':
      return { ...state, columns: action.payload };  
    case 'UPDATE_DSP_TABLE_COLUMNS':
      return { ...state, dsp_columns: action.payload };  
    case 'UPDATE_TABLE_GROUPS':
      return { ...state, table_groups: action.payload };
    case 'UPDATE_DSP_TABLE_GROUPS':
      return { ...state, dsp_table_groups: action.payload };
    case 'UPDATE_UNGROUP_TABLE_GROUPS':
      return { ...state, un_group_tables: action.payload };  
    default:
      return state;
  }
};

// Create the context
const TargetingContext = createContext();

// Create a custom hook to use the context
const useTargetingContext = () => {
  const context = useContext(TargetingContext);
  if (!context) {
    throw new Error('useTargetingContext must be used within a TargetingProvider');
  }
  return context;
};

// Create the provider component
const TargetingProvider = ({ children }) => {
  const [targetingState, targetingDispatch] = useReducer(reducer, initialState);

  return <TargetingContext.Provider value={{ targetingState, targetingDispatch }}>{children}</TargetingContext.Provider>;
};

export { TargetingProvider, useTargetingContext };
