import React, { createContext, useReducer, useContext } from 'react';


const selectedProfile = JSON.parse(localStorage.getItem('myntraProfile'))

// Initial state for the context
const initialState = {
  selectedMyntraProfile: {
    ...( selectedProfile ? { ...selectedProfile } : {} )
  },
  myntraProfiles: [],
};

// Define the reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_SELECTED_MYNTRA_PROFILE':
      return { ...state, selectedMyntraProfile: action.payload };
    case 'UPDATE_MYNTRA_PROFILES':
        return { ...state, myntraProfiles: action.payload };
        default:
      return state;
  }
};

// Create the context
const MyntraContext = createContext();

// Create a custom hook to use the context
const useMyntraContext = () => {
  const context = useContext(MyntraContext);
  if (!context) {
    throw new Error('useMyntraContext must be used within a MyntraProvider');
  }
  return context;
};

// Create the provider component
const MyntraProvider = ({ children }) => {
  const [myntraState, myntraDispatch] = useReducer(reducer, initialState);

  return (
    <MyntraContext.Provider value={{ myntraState, myntraDispatch }}>
      {children}
    </MyntraContext.Provider>
  );
};

export { MyntraProvider, useMyntraContext };