import { useEffect, useMemo } from "react";


const useHeapTracker = () => {
    
    // Memoize URL checks to avoid recalculating on each render
    const isFlipkart = useMemo(() => window.location.href.includes('/flipkart'), [window.location.href]);
    const isInstamart = useMemo(() => window.location.href.includes('/instamart'), [window.location.href]);
    const isMyntra = useMemo(() => window.location.href.includes('/myntra'), [window.location.href]);
    const isBlinkit = useMemo(() => window.location.href.includes('/blinkit'), [window.location.href]);
    const isAmazon = useMemo(() => window.location.href.includes('/amazon'), [window.location.href]);

    // Memoize profile data from localStorage
    const authUser = useMemo(() => JSON.parse(localStorage.getItem('User')), []);
    const getProfile = useMemo(() => JSON.parse(localStorage.getItem('selectedProfileId')), []);
    const flipkartProfile = useMemo(() => JSON.parse(localStorage.getItem('flipkartProfile')), []);
    const myntraProfile = useMemo(() => JSON.parse(localStorage.getItem('myntraProfile')), []);
    const blinkitProfile = useMemo(() => JSON.parse(localStorage.getItem('blinkitProfile')), []);
    const instamartProfile = useMemo(() => JSON.parse(localStorage.getItem('instamartProfile')), []);

    // Memoize globalProfile to avoid unnecessary calculations
    const globalProfile = useMemo(() => {
        if (isFlipkart) return flipkartProfile;
        if (isAmazon) return getProfile;
        if (isMyntra) return myntraProfile;
        if (isInstamart) return instamartProfile;
        if (isBlinkit) return blinkitProfile;
        return {};
    }, [isFlipkart, isAmazon, isMyntra, isInstamart, isBlinkit]);

    useEffect(() => {

        console.log("globalProfile", globalProfile)

        if (window.heap) {
            console.log("window?.heap", window?.heap)
            window?.heap?.identify(authUser?.email)
            window.heap.addUserProperties({
              Name: authUser?.fullName,
              Profession: globalProfile?.accountInfo?.name,
            });
            console.log('HEAP --> User properties added to Heap');
          } else {
            console.error('HEAP --> is not ready yet');
          }


    }, [JSON.stringify(globalProfile)]); // Add dependencies if necessary

    return {
        authUser,
        globalProfile
    };
};

export default useHeapTracker;
