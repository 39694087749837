import React from 'react'

const AwarenessIcon = () => {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.1515 21.259V19.4701H32.8183V21.259H27.1515ZM29.0551 32.8184L24.5199 29.3499L25.6129 27.9331L30.1481 31.3999L29.0551 32.8184ZM25.4778 12.6583L24.3848 11.2415L28.92 7.77295L30.013 9.19149L25.4778 12.6583ZM6.90918 23.9422V16.7869H12.9145L19.19 10.3882V30.3409L12.9145 23.9422H6.90918ZM17.4356 14.7297L13.6619 18.5757H8.66183V22.1534H13.6619L17.4339 25.9994L17.4356 14.7297Z" fill="#A100BD"/>
    </svg>
  )
}

export default AwarenessIcon