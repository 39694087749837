import React from 'react';
import { Redirect } from 'react-router-dom';


import useAuth from '../../hooks/useAuth';

const AuthGuard = ({ children }) => {
  const ParseData = JSON.parse(localStorage.getItem('User'));
  const { isLoggedIn } = useAuth();

  if(ParseData && !ParseData.fullName) {
    localStorage.clear()
    return <Redirect to="/" />;
  }

  if (!isLoggedIn) {
    return <Redirect to="/" />;
    // return <Popper />
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthGuard;
