import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import logo from 'src/assets/images/common/logo-new-white.svg';
import Hectorlogo from 'src/assets/images/common/logo_v2.png';
import logoWhite from 'src/assets/images/common/white-logo.svg';
import { ConfigContext } from '../../../../contexts/ConfigContext';
import * as actionType from '../../../../store/actions';

const NavLogo = () => {
  const configContext = useContext(ConfigContext);
  const { collapseMenu } = configContext.state;
  const { dispatch } = configContext;

  let toggleClass = ['mobile-menu'];
  if (collapseMenu) {
    toggleClass = [...toggleClass, 'on'];
  }

  return (
    <React.Fragment>
      <div className="navbar-brand header-logo">
        <Link to="#" className="b-brand">
          <div className="b-bg">
            {/* <img src={logoWhite} alt="hector" height="15" className="icon-trending-up" /> */}
            <i className="feather icon-trending-up" />
          </div>
          <span className="ml-20 f-w-600 c-name">HECTOR</span>
          <span className="b-title"><img src={logo} alt="logo" className='wid-150' /></span>
        </Link>
        {/* <div className={toggleClass.join(' ')} id="mobile-collapse" onClick={() => dispatch({ type: actionType.COLLAPSE_MENU })}>
          <span />
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default NavLogo;
