import React, { createContext, useReducer, useContext } from 'react';


const selectedProfile = JSON.parse(localStorage.getItem('blinkitProfile'))

// Initial state for the context
const initialState = {
  selectedBlinkitProfile: {
    ...( selectedProfile ? { ...selectedProfile } : {} )
  },
  blinkitProfiles: [],
};

// Define the reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_SELECTED_BLINKIT_PROFILE':
      return { ...state, selectedBlinkitProfile: action.payload };
    case 'UPDATE_BLINKIT_PROFILES':
        return { ...state, blinkitProfiles: action.payload };
        default:
      return state;
  }
};

// Create the context
const BlinkitContext = createContext();

// Create a custom hook to use the context
const useBlinkitContext = () => {
  const context = useContext(BlinkitContext);
  if (!context) {
    throw new Error('useBlinkitContext must be used within a BlinkitProvider');
  }
  return context;
};

// Create the provider component
const BlinkitProvider = ({ children }) => {
  const [blinkitState, blinkitDispatch] = useReducer(reducer, initialState);

  return (
    <BlinkitContext.Provider value={{ blinkitState, blinkitDispatch }}>
      {children}
    </BlinkitContext.Provider>
  );
};

export { BlinkitProvider, useBlinkitContext };