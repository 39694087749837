import React, { useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';

import { AuthProvider } from './contexts/AuthContext';
//import { JWTProvider } from "./contexts/JWTContext";
//import { Auth0Provider } from "./contexts/Auth0Context";

import routes, { renderRoutes } from './routes';
import { BASENAME } from './config/constant';
import ReactGA from 'react-ga4';
import { CommonProvider } from './contexts/CommonContext';
import { AmazProvider } from './contexts/AmzContext';
import { FkProvider } from './contexts/FkContext';
import { MyntraProvider } from './contexts/MyntraContext';
import { BlinkitProvider } from './contexts/BlinkitContext';
import { BillingProvider } from './contexts/BillingContext';
import { ReportProvider } from './contexts/ReportContext';
import { PayloadProvider } from './contexts/PayloadContext';
import { TargetingProvider } from './contexts/TargetingContext';
import { SmartSearchProvider } from './contexts/SmartSearchContext';
import { DayPartingProvider } from './contexts/DayPartingContext';
import { SnapShotProvider } from './contexts/SnapShotContext';
import { InstaMartProvider } from './contexts/InstamartContext';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [pathname]);

  return null;
}

const App = () => {
  return (
    <React.Fragment>
      <Router basename={BASENAME}>
        <ScrollToTop />
        <AuthProvider>
          <BillingProvider>
            <CommonProvider>
              <DayPartingProvider>
                <SnapShotProvider>
                <TargetingProvider>
                  <SmartSearchProvider>
                    <ReportProvider>
                      <AmazProvider>
                        <FkProvider>
                          <InstaMartProvider>
                          <MyntraProvider>
                            <BlinkitProvider>
                              <PayloadProvider>
                                {renderRoutes(routes)}
                              </PayloadProvider>
                            </BlinkitProvider>
                          </MyntraProvider>
                          </InstaMartProvider>
                        </FkProvider>
                      </AmazProvider>
                    </ReportProvider>
                  </SmartSearchProvider>
                </TargetingProvider>
                </SnapShotProvider>
              </DayPartingProvider>
            </CommonProvider>
          </BillingProvider>
        </AuthProvider>
      </Router>
    </React.Fragment>
  );
};

export default App;
