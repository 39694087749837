import React, { useState } from 'react';
//import { Collapse } from 'react-bootstrap';
// ICONS
import upArrow from 'src/assets/images/common/up-green.svg';
import DownGreen from 'src/assets/images/common/down-green.svg';
import UpRed from 'src/assets/images/common/down-green.svg';
import downArrow from 'src/assets/images/common/down.svg';
import infoIcon from 'src/assets/images/common/info.svg';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import Dropdown from 'src/components/Form/search-input';
// import { Link } from 'react-router-dom';
import { Divide } from 'react-feather';
import { getHighLightColor, getImageHighLight } from 'src/utils/custom-formatters';

const Cards = ({
  metricItem,
  name,
  count,
  upstats,
  downstats,
  upcount,
  downcount,
  stackscard,
  addmore,
  children,
  classes,
  close,
  callback,
  closeCallBack,
  arrowKey,
  metrics,
  selectedDropDownMetrics,
  index,
  defaultMetrics
}) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [accordionKey, setAccordionKey] = useState(1);

    const [isClose, setIsClose] = useState(false)

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const popercontent = () => {
    return (
      <section>
        <h3>Content Head</h3>
        <p className="font-italic">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
        <a className="f-12 txt-blue cursor-pointer" onClick={toggleDrawer}>
          learn about {name}
        </a>
      </section>
    );
  };

  

  return (
    <React.Fragment>
      {stackscard && (
        <>
          <section
            className={`bg-white px-3 py-2 border d-flex align-items-center justify-content-between cursor-pointer cards-responsive position-relative ${classes}`}
          >
            <div>
              {/* <p className="mb-2 f-12 txt-truncate-100">{name}</p> */}
              <div className="d-flex align-items-center mb-2 select-crd">
                <Dropdown
                  selectname={name}
                  metrics={metrics}
                  selectedDropDownMetrics={selectedDropDownMetrics}
                  index={index}
                  defaultMetrics={defaultMetrics}
                  classes={classes}
                />
                {/* <Popover content={popercontent} placement="bottomLeft" className="popper-lg">
                            <img src={infoIcon} className="wid-14 ml-1 cursor-pointer" alt="info" />
                        </Popover> */}
                {/* <img src={infoIcon} className="wid-14 ml-1 cursor-pointer" alt="info" /> */}
              </div>
              <h2 className="f-w-600 text-dark f-15">{count}</h2>
            </div>
            {upcount !== 0 ? <div className={`d-flex align-items-center margin-stats p-2 ${getHighLightColor(metricItem)}`}>
              <img src={`${getImageHighLight(metricItem)}`} alt="increase-value" className="wid-12 mr-1" />
              <div className={`f-15 f-w-600 txt-${getHighLightColor(metricItem) === 'green-shde' ? 'green' : 'danger'}`}>{arrowKey === 'low' ? <span className={`d-flex align-items-center txt-${getHighLightColor(metricItem) === 'green-shde' ? 'green' : 'danger'}`}> {upcount}</span> : <span className={`d-flex align-items-center txt-${getHighLightColor(metricItem) === 'green-shde' ? 'green' : 'danger'}`}> {upcount} </span>}</div>
            </div> : <div className={`d-flex align-items-center margin-stats p-2 orange-shde`}>
              <div className={`f-18 f-w-600 txt-orange`}>---</div>
            </div>}
            <div className="crd-sensitive" onClick={callback}></div>
          </section>
        </>
      )}

      {addmore && (
        <section className="bg-white px-3 py-2 border-top border-bottom border-right d-flex align-items-center justify-content-center h-100 cursor-pointer position-relative">
          {children}
        </section>
      )}

      {/* {addmore && <Collapse in={accordionKey === 1}>
              <div className=" m-l-30 mb-4">
                <div id="accordion1" className="form-group f-16 l-base bg-blur p-3 rounded-8 txt-secondary f-14">
                  Collapse
                </div>
              </div>
            </Collapse>} */}

      <Drawer open={isOpen} onClose={toggleDrawer} direction="right" className="info-drawer" enableOverlay={false} size={400} zIndex={3000}>
        <div className="header d-flex align-items-center justify-content-between">
          Get Help
          <span onClick={() => setIsOpen(false)}>&#x2715;</span>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default Cards;
