import React, { useContext, useState } from "react";
import { THEME_TYPE } from "src/store/theme.js";
import {ThemeContext} from "src/providers/themeProvider";
import { Sun, Moon} from 'react-feather';

const ThemeSwitcher = () => {
  const [themeMode, setTheme] = useContext(ThemeContext);
  const [themeSettings, setThemesettings] = useState(true);
  const darkModeEnabled = localStorage.getItem("darkMode")

  const darkMode = () => {
    localStorage.setItem("darkMode", "true")
    setTheme({ theme:THEME_TYPE.DARK });
    setThemesettings(false)
    console.log("DARK MODE ENABLED")
  }

  const lightMode = () => {
    localStorage.setItem("darkMode", "false")
    setTheme({ theme: THEME_TYPE.LIGHT });
    setThemesettings(true)
    console.log("LIGHT MODE ENABLED")
    
  }

  return (
    <div className="switch-container">
      {themeSettings && (darkModeEnabled === "false") ?  <Moon size={18} className="themeicn cursor-pointer hover-theme" onClick={() => darkMode()} />
      :
      <Sun size={18} className="themeicn cursor-pointer hover-theme" onClick={() => lightMode()}/>
      }
      
    </div>
  );
};

export default ThemeSwitcher;