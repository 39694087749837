import { useEffect, useState } from 'react';

export default function useZoom() {
  const [zoom, setZoom] = useState(Math.ceil(window?.devicePixelRatio != 2 ? window?.devicePixelRatio * 50 : 100));

  useEffect(() => {
      var zoomLevel = window?.devicePixelRatio ? window?.devicePixelRatio : 2;        
      setZoom(Math.ceil(zoomLevel != 2 ? zoomLevel * 50 : 100))
  }, [window.devicePixelRatio]);

  return `zoom-${zoom}`;
}
