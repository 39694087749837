import moment from 'moment-timezone';

const isAmazon = window.location.href.indexOf('/amazon') > -1
const isFlipkart = window.location.href.indexOf('/flipkart') > -1
const isInstamart = window.location.href.indexOf('/instamart') > -1 
const isMyntra = window.location.href.indexOf('/myntra') > -1
const isBlinkit = window.location.href.indexOf('/blinkit') > -1

function formatWithTimezone(date, timeZone) {
    return new Intl.DateTimeFormat('en-US', {
      timeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(date);
}



export const zoneToData = (localDate) => {
    const globalIdentifier = isAmazon ? 'selectedProfileId' : isFlipkart ? 'flipkartProfile' : isMyntra ? 'myntraProfile' : isInstamart ? 'instamartProfile' : isBlinkit ? 'blinkitProfile' : ''
    const globalProfileDetails = globalIdentifier ? JSON.parse(localStorage.getItem(globalIdentifier)) : {}
    localDate = formatWithTimezone(new Date(localDate), 'Asia/Kolkata')
    if(!globalProfileDetails?.timezone) return localDate
    localDate = formatWithTimezone(new Date(localDate), globalProfileDetails?.timezone)
    return localDate
}

export const getLocalDate = () => {
  const globalIdentifier = isAmazon ? 'selectedProfileId' : isFlipkart ? 'flipkartProfile' : isMyntra ? 'myntraProfile' : isInstamart ? 'instamartProfile' : isBlinkit ? 'blinkitProfile' : ''
  const globalProfileDetails = globalIdentifier ? JSON.parse(localStorage.getItem(globalIdentifier)) : {}
  let localDate = formatWithTimezone(new Date(), 'Asia/Kolkata')
  if(!globalProfileDetails?.timezone) return localDate
  localDate = formatWithTimezone(new Date(), globalProfileDetails?.timezone)
  return localDate
}

export const getTimeZoneAbrv = () => {
  const globalIdentifier = isAmazon ? 'selectedProfileId' : isFlipkart ? 'flipkartProfile' : isMyntra ? 'myntraProfile' : isInstamart ? 'instamartProfile' : isBlinkit ? 'blinkitProfile' : ''
  const globalProfileDetails = globalIdentifier ? JSON.parse(localStorage.getItem(globalIdentifier)) : {}
  const timeZone = globalProfileDetails?.timezone ? globalProfileDetails?.timezone : 'Asia/Kolkata'
  if(timeZone === 'Asia/Dubai') return 'GST'
  if(timeZone === 'America/Sao_Paulo') return 'BRT'
  if(timeZone === 'Asia/Riyadh') return 'AST'
  const currentTimeInTimezone = moment().tz(timeZone);
  return currentTimeInTimezone.format('z')
}

export const getTimeZone = () => {
  const globalIdentifier = isAmazon ? 'selectedProfileId' : isFlipkart ? 'flipkartProfile' : isMyntra ? 'myntraProfile' : isInstamart ? 'instamartProfile' : isBlinkit ? 'blinkitProfile' : ''
  const globalProfileDetails = globalIdentifier ? JSON.parse(localStorage.getItem(globalIdentifier)) : {}
  const timeZone = globalProfileDetails?.timezone ? globalProfileDetails?.timezone : 'Asia/Kolkata'
  return timeZone
}
