export const getCoulmnsAPiOld = (field_name, field_key, field_type = 'any', field_display = '', field_condition = 0) => {
  return [
    // ...((field_name != 'Targeting' && field_name != 'Ad Group Name' && field_name != 'Campaign Name' && field_condition != 1)
    //   ? [
    //       {
    //         additionalClass: 'cus_wid',
    //         column_type: 'column_field',
    //         label: field_name,
    //         param: field_key,
    //         field_type: field_type,
    //         field_display: field_display,
    //         checked: true,
    //       }
    //     ]
    //   : []),
    // ...(field_name == 'Targeting' || field_condition == 1
    //   ? [
    //       { id: 19, column_type: 'active_view', label: 'Active', param: 'active_status', checked: true },
    //       {
    //         additionalClass: 'cus_wid col_fixed',
    //         column_type: 'column_field',
    //         label: field_name,
    //         param: field_key,
    //         field_type: field_type,
    //         field_display: field_display,
    //         checked: true
    //       },
    //       { id: 20, column_type: 'status_view', label: 'Status', param: 'status', checked: true },
    //       { id: 21, column_type: 'sug_bid_view', label: 'Sug. Bid', param: 'sug_bid', checked: true },
    //       { id: 22, column_type: 'bid_view', label: 'Bid', param: 'bid_amount', checked: true },
    //       { id: 23, column_type: 'TOS_IS_budget_view', label: 'TOS IS%', param: 'TOS_IS_perc', checked: true }
    //     ]
    //   : []),
    // ...(field_name == 'Campaign Name'
    //   ? [
    //       { id: 19, column_type: 'active_view', label: 'Active', param: 'active_status', checked: true, isTargeting: 1 },
    //       {
    //         additionalClass: 'cus_wid col_fixed',
    //         column_type: 'column_field',
    //         label: field_name,
    //         param: field_key,
    //         field_type: field_type,
    //         field_display: field_display,
    //         checked: true
    //       },
    //       { id: 21, column_type: 'status_view', label: 'Status', param: 'status', checked: true, isTargeting: 1 },
    //       { id: 22, column_type: 'start_date_view', label: 'Start Date', param: 'start_date', checked: true, isTargeting: 1 },
    //       { id: 23, column_type: 'budget_view', label: 'Budget', param: 'budget_amount', checked: true, isTargeting: 1 },
    //       { id: 24, column_type: 'TOS_IS_budget_change', label: 'TOS IS%', param: 'TOS_IS_perc', checked: true, isTargeting: 1 },
    //       { id: 25, column_type: 'PDP_IS_budget_change', label: 'PDP IS%', param: 'PDP_IS_perc', checked: true, isTargeting: 1 }
    //     ]
    //   : []),
    //   ...(field_name == 'Ad Group Name'
    //   ? [
    //       { id: 19, column_type: 'active_view', label: 'Active', param: 'active_status', checked: true, isTargeting: 1 },
    //       {
    //         additionalClass: 'wid-250 col_fixed',
    //         column_type: 'column_field',
    //         label: field_name,
    //         param: field_key,
    //         field_type: field_type,
    //         field_display: field_display,
    //         checked: true
    //       },
    //       { id: 21, column_type: 'status_view', label: 'Status', param: 'status', checked: true, isTargeting: 1 },
    //       { id: 22, column_type: 'number', label: 'Total Targets', param: 'start_date', checked: true, isTargeting: 1 },
    //       { id: 23, column_type: 'number', label: 'Products', param: 'budget_amount', checked: true, isTargeting: 1 },
    //       { id: 24, column_type: 'TOS_IS_budget_view', label: 'TOS IS%', param: 'TOS_IS_perc', checked: true, isTargeting: 1 }
    //     ]
    //   : []),
    {
      additionalClass: 'cus_wid',
      column_type: 'column_field',
      label: field_name,
      param: field_key,
      field_type: field_type,
      field_display: field_display,
      checked: true,
    },
    { id: 1, is_based_available: 1, column_type: 'number', label: 'Spend', param: 'Spend', prefix_sign: 'currency', checked: true },
    { id: 2, is_based_available: 1, column_type: 'number', label: 'Revenue', param: 'Revenue', prefix_sign: 'currency', checked: true },
    { id: 3, is_based_available: 1, column_type: 'number', label: 'Orders', param: 'Orders', checked: true },
    { id: 4, is_based_available: 1, column_type: 'number', label: 'Imp', param: 'Impressions', checked: true },
    { id: 5, is_based_available: 1, column_type: 'number', label: 'Clicks', param: 'Clicks', checked: true },
    { id: 6, is_based_available: 1, column_type: 'decimal', label: 'CPA', param: 'CPA', prefix_sign: 'currency', checked: true },
    { id: 7, is_based_available: 1, column_type: 'decimal', label: 'ROAS', param: 'ROAS', checked: true },
    { id: 8, is_based_available: 1, column_type: 'decimal', label: 'AOV', param: 'AOV', prefix_sign: 'currency', checked: true },
    { id: 9, is_based_available: 1, column_type: 'percentage', label: 'CTR', param: 'CTR', checked: true },
    { id: 10, is_based_available: 1, column_type: 'decimal', label: 'CPM', param: 'CPM', checked: true },
    { id: 11, is_based_available: 1, column_type: 'decimal', label: 'CPC', param: 'CPC', checked: true },
    { id: 12, is_based_available: 1, column_type: 'percentage', label: 'ACOS', param: 'ACOS', checked: true },
    { id: 13, is_based_available: 1, column_type: 'number', label: 'RPC', param: 'RPC', checked: true },
    { id: 14, is_based_available: 1, column_type: 'number', label: 'CPO', param: 'CPO', prefix_sign: 'currency', checked: true },
    { id: 15, is_based_available: 1, column_type: 'percentage', label: 'Spend%', param: 'Spend_perc', checked: true },
    { id: 16, is_based_available: 1, column_type: 'percentage', label: 'Rev%', param: 'Revenue_perc', checked: true },
    { id: 17, is_based_available: 1, column_type: 'percentage', label: 'CR%', param: 'CR_perc', checked: true },
    { id: 18, is_based_available: 1, column_type: 'number', label: 'CC', param: 'CC', checked: true }
  ];
};

const getCoulmnsAPiOldAccountSummary = (field_name, field_key, field_type = 'any', field_display = '', imageIsAvailable = '') => {
  return [
    {
      column_type: '',
      label: field_name,
      param: field_key,
      field_type: field_type,
      field_display: field_display,
      imageIsAvailable: imageIsAvailable
    }
  ];
};

const TAB_GROUP = {
  parent_tabs: [
    {
      id: 3,
      is_group: 0,
      title: 'Targeting',
      title_aggregate: 'targeting-summary',
      param_key: 'targeting-summary',
      heatMapEnabled: 1,
      url_update: '/targeting',
      prefer_tables: [
        
        {
          table_name: 'Campaign Types',
          classNames: 'text-capitalize',
          table_id: 'CampaignTypes',
          end_point: '/targeting-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'CampaignType',
            summary: 'Targeting'
          },
          table_json: {
            aggregate: 'CampaignType',
            data_id: 'Type',
            trend_chart: 'enable',
            columns: getCoulmnsAPiOld('Campaign Types', 'Type'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },

        {
          table_name: 'Targeting Types',
          table_id: 'Targeting_types',
          end_point: '/targeting-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'TargetingType',
            summary: 'Targeting'
          },
          table_json: {
            aggregate: 'TargetingType',
            data_id: 'TargetingType',
            trend_chart: 'enable',
            columns: getCoulmnsAPiOld('Targeting Types', 'TargetingType'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },

        {
          table_name: 'Audience Marketing',
          table_id: 'Audience_Marketing',
          end_point: '/targeting-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'AudienceMarketing',
            summary: 'Targeting'
          },
          table_json: {
            aggregate: 'AudienceMarketing',
            data_id: 'AudienceType',
            trend_chart: 'disable',
            columns: getCoulmnsAPiOld('Audience Marketing', 'AudienceType'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },

        {
          table_name: 'Sub Targeting Types',
          table_id: 'Targeting_Category',
          end_point: '/targeting-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'TargetingCategory',
            summary: 'Targeting'
          },
          table_json: {
            aggregate: 'TargetingCategory',
            data_id: 'CategoryType',
            trend_chart: 'enable',
            columns: getCoulmnsAPiOld('Sub Targeting Types', 'CategoryType'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },
        
        {
          table_name: 'Ad Formats',
          table_id: 'Ad_Formats',
          end_point: '/targeting-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'AdFormat',
            summary: 'Targeting'
          },
          table_json: {
            aggregate: 'AdFormat',
            data_id: 'AdFormats',
            trend_chart: 'enable',
            columns: getCoulmnsAPiOld('Ad Formats', 'AdFormats'),
            rows: [],
            summary: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },

        {
          table_name: 'Portfolio Name',
          table_id: 'Portfolio_Name',
          end_point: '/targeting-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'Portfolio_Name',
            summary: 'Targeting'
          },
          table_json: {
            aggregate: 'Portfolio_Name',
            data_id: 'Portfolio_Name',
            trend_chart: 'disable',
            columns: getCoulmnsAPiOld('Portfolio Name', 'Portfolio_Name'),
            rows: [],
            summary: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },

        {
          table_name: 'Campaign Name',
          table_id: 'campaign_name',
          end_point: '/targeting-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'CampaignName',
            summary: 'Targeting'
          },
          table_json: {
            aggregate: 'CampaignName',
            data_id: 'campaignName',
            trend_chart: 'enable',
            search: 'enable',
            trend_chart: 'enable',
            expand_row: 'enable',
            pagination: 'enabled',
            columns: getCoulmnsAPiOld('Campaign Name', 'campaignName'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },
        
        {
          table_name: 'Ad Group Name',
          table_id: 'Ad_group_name',
          end_point: '/targeting-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'adGroupName',
            summary: 'Targeting'
          },
          table_json: {
            aggregate: 'adGroupName',
            data_id: 'adGroupName',
            search: 'enable',
            trend_chart: 'enable',
            expand_row: 'enable',
            pagination: 'enabled',
            columns: getCoulmnsAPiOld('Ad Group Name', 'adGroupName'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },

        {
          table_name: 'Cost Types',
          table_id: 'Cos_Types',
          end_point: '/targeting-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'costTypes',
            summary: 'Targeting'
          },
          table_json: {
            aggregate: 'costTypes',
            data_id: 'costType',
            search: 'enable',
            trend_chart: 'disable',
            columns: getCoulmnsAPiOld('Cost Types', 'costType'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },


        {
          table_name: 'Targeting',
          table_id: 'Targeting_Text',
          end_point: '/targeting-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'TargetingText',
            summary: 'Targeting'
          },
          table_json: {
            aggregate: 'TargetingText',
            data_id: 'targetingText',
            trend_chart: 'enable',
            expand_row: 'enable',
            pagination: 'enabled',
            isHyperLink: 1,
            columns: getCoulmnsAPiOld('Targeting', 'targetingText'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },

        {
          table_name: 'Match Types',
          table_id: 'MatchType',
          end_point: '/targeting-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'MatchType',
            summary: 'Targeting'
          },
          table_json: {
            aggregate: 'MatchType',
            data_id: 'matchType',
            trend_chart: 'enable',
            columns: getCoulmnsAPiOld('Match Types', 'matchType'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },
        
        // TEXTUAL Categorization PLACE

        {
          table_name: 'LookbackPeriod',
          table_id: 'Lookback_period',
          end_point: '/targeting-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'LookbackPeriod'
          },
          table_json: {
            aggregate: 'LookbackPeriod',
            data_id: 'LookbackPeriod',
            search: 'enable',
            columns: getCoulmnsAPiOld('Lookback Period', 'LookbackPeriod'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },

        {
          table_name: 'Weekdays',
          table_id: 'Week_Day',
          end_point: '/targeting-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'Weekday',
            summary: 'Targeting'
          },
          table_json: {
            aggregate: 'Weekday',
            data_id: 'Weekday',
            trend_chart: 'disable',
            columns: getCoulmnsAPiOld('Weekdays', 'Weekday'),
            summary: [],
            rows: []
          }
          // "default_sort": {
          // 	"sort_type": "ASC",
          // 	"sort_column": "Spend",
          // 	"is_sort": false
          // },
        },

        {
          table_name: 'Months',
          table_id: 'monthly',
          end_point: '/targeting-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'Monthly'
          },
          table_json: {
            aggregate: 'Monthly',
            data_id: 'Month',
            trend_chart: 'disable',
            columns: getCoulmnsAPiOld('Months', 'Month', 'Date'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Monthly',
            is_sort: true
          }
        },

        {
          table_name: 'Weeks',
          table_id: 'weekly',
          end_point: '/targeting-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'Weekly'
          },
          table_json: {
            aggregate: 'Weekly',
            data_id: 'Week',
            trend_chart: 'disable',
            expand_row: 'enable',
            columns: getCoulmnsAPiOld('Weeks', 'Week', 'Date'),
            summary: [],
            rows: []
          }
          // "default_sort": {
          // 	"sort_type": "ASC",
          // 	"sort_column": "Weekly",
          // 	"is_sort": false
          // }
        },

        {
          table_name: 'Date',
          table_id: 'Daily',
          end_point: '/targeting-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'Daily'
          },
          table_json: {
            aggregate: 'Daily',
            data_id: 'Date',
            trend_chart: 'disable',
            pagination: 'enabled',
            columns: getCoulmnsAPiOld('Date', 'Date', 'Date'),
            summary: [],
            rows: []
          }
          // "default_sort": {
          // 	"sort_type": "ASC",
          // 	"sort_column": "Daily",
          // 	"is_sort": false
          // }
        },

        
        {
          table_name: 'Types of Keywords',
          table_id: 'brand',
          classNames: 'text-capitalize',
          end_point: '/targeting-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'Brand'
          },
          table_json: {
            aggregate: 'Brand',
            data_id: 'type_id',
            categorizationLink: process.env.REACT_APP_URL + '/text-categorization',
            columns: getCoulmnsAPiOld('Types of Keywords', 'brand_id', 'any', 'brand_name'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },

        {
          table_name: 'Category',
          table_id: 'Category',
          classNames: 'text-capitalize',
          end_point: '/targeting-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'Category'
          },
          table_json: {
            aggregate: 'Category',
            data_id: 'category_id',
            categorizationLink: process.env.REACT_APP_URL + '/text-categorization',
            columns: getCoulmnsAPiOld('Category', 'category_id', 'any', 'category_name'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },

        {
          table_name: 'Sub Category',
          table_id: 'Sub_Category',
          classNames: 'text-capitalize',
          end_point: '/targeting-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'Subcategory'
          },
          table_json: {
            aggregate: 'Subcategory',
            data_id: 'subcategory_id',
            categorizationLink: process.env.REACT_APP_URL + '/text-categorization',
            columns: getCoulmnsAPiOld('Sub Category', 'subcategory_id', 'any', 'subcategory_name'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },
        

      ]
    },
    {
      id: 4,
      is_group: 0,
      title: 'Search Term',
      title_aggregate: 'search-term-summary',
      param_key: 'search-term-summary',
      url_update: '/search-term',
      heatMapEnabled: 1,
      prefer_tables: [

        {
          table_name: 'Type',
          classNames: 'text-capitalize',
          table_id: 'Type',
          end_point: '/search-term-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'Type'
          },
          table_json: {
            aggregate: 'Type',
            data_id: 'Type',
            columns: getCoulmnsAPiOld('Campaign Types', 'Type'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },

        {
          table_name: 'Targeting Types',
          table_id: 'Targeting_types',
          end_point: '/search-term-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'TargetingType'
          },
          table_json: {
            aggregate: 'TargetingType',
            data_id: 'TargetingType',
            columns: getCoulmnsAPiOld('Targeting Types', 'TargetingType'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },

        {
          table_name: 'Search Term',
          table_id: 'Search_term',
          end_point: '/search-term-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'SearchTerm'
          },
          table_json: {
            aggregate: 'SearchTerm',
            data_id: 'query',
            pagination: 'enabled',
            columns: getCoulmnsAPiOld('Search Term', 'query'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },


        {
          table_name: 'Ad Formats',
          table_id: 'Ad_Formats',
          end_point: '/search-term-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'AdFormat'
          },
          table_json: {
            aggregate: 'AdFormat',
            data_id: 'AdFormats',
            columns: getCoulmnsAPiOld('Ad Formats', 'AdFormats'),
            rows: [],
            summary: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },


        {
          table_name: 'Portfolio Name',
          table_id: 'Portfolio_Name',
          end_point: '/search-term-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'Portfolio_Name'
          },
          table_json: {
            aggregate: 'Portfolio_Name',
            data_id: 'Portfolio_Name',
            columns: getCoulmnsAPiOld('Portfolio Name', 'Portfolio_Name'),
            rows: [],
            summary: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },


        {
          table_name: 'Campaign Name',
          table_id: 'campaign_name',
          end_point: '/search-term-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'CampaignName'
          },
          table_json: {
            aggregate: 'CampaignName',
            data_id: 'campaignName',
            search: 'enable',
            expand_row: 'enable',
            pagination: 'enabled',
            columns: getCoulmnsAPiOld('Campaign Name', 'campaignName'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },
        {
          table_name: 'Ad Group Name',
          table_id: 'Ad_group_name',
          end_point: '/search-term-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'adGroupName'
          },
          table_json: {
            aggregate: 'adGroupName',
            data_id: 'adGroupName',
            search: 'enable',
            expand_row: 'enable',
            pagination: 'enabled',
            columns: getCoulmnsAPiOld('Ad Group Name', 'adGroupName'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },


        {
          table_name: 'Keyword Text',
          table_id: 'Keyword_Text',
          end_point: '/search-term-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'Keyword_Text'
          },
          table_json: {
            aggregate: 'Keyword_Text',
            data_id: 'Keyword_Text',
            search: 'enable',
            expand_row: 'enable',
            columns: getCoulmnsAPiOld('Keyword Text', 'Keyword_Text'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },

        {
          table_name: 'Targeting',
          table_id: 'Targeting_Text',
          end_point: '/search-term-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'TargetingText',
          },
          table_json: {
            aggregate: 'TargetingText',
            data_id: 'targetingText',
            trend_chart: 'enable',
            expand_row: 'enable',
            pagination: 'enabled',
            columns: getCoulmnsAPiOld('Targeting', 'targetingText'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },

        {
          table_name: 'Match Type',
          table_id: 'Match Type',
          end_point: '/search-term-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'MatchType'
          },
          table_json: {
            aggregate: 'MatchType',
            data_id: 'matchType',
            columns: getCoulmnsAPiOld('Match Type', 'matchType'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },


        // TEXTUAL CATEGORIZATION PLACE


        {
          table_name: 'Weekdays',
          table_id: 'Weekdays',
          end_point: '/search-term-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'Weekday'
          },
          table_json: {
            aggregate: 'Weekday',
            data_id: 'Weekday',
            columns: getCoulmnsAPiOld('Weekdays', 'Weekday'),
            summary: [],
            rows: []
          }
        },


        {
          table_name: 'Months',
          table_id: 'Months',
          end_point: '/search-term-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'monthly'
          },
          table_json: {
            aggregate: 'Monthly',
            data_id: 'Month',
            columns: getCoulmnsAPiOld('Months', 'Month', 'Date'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Monthly',
            is_sort: true
          }
        },

        {
          table_name: 'Weeks',
          table_id: 'Weeks',
          end_point: '/search-term-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'Weekly'
          },
          table_json: {
            aggregate: 'Weekly',
            data_id: 'Week',
            expand_row: 'enable',
            columns: getCoulmnsAPiOld('Weeks', 'Week', 'Date'),
            summary: [],
            rows: []
          }
          // "default_sort": {
          // 	"sort_type": "ASC",
          // 	"sort_column": "Weekly",
          // 	"is_sort": false
          // }
        },

        {
          table_name: 'Date',
          table_id: 'Daily',
          end_point: '/search-term-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'Daily'
          },
          table_json: {
            aggregate: 'Daily',
            data_id: 'Date',
            pagination: 'enabled',
            columns: getCoulmnsAPiOld('Date', 'Date', 'Date'),
            summary: [],
            rows: []
          }
          // "default_sort": {
          // 	"sort_type": "ASC",
          // 	"sort_column": "Daily",
          // 	"is_sort": false
          // }
        },
        
        {
          table_name: 'Brand',
          table_id: 'brand',
          classNames: 'text-capitalize',
          end_point: '/search-term-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'Brand'
          },
          table_json: {
            aggregate: 'Brand',
            data_id: 'type_id',
            categorizationLink: process.env.REACT_APP_URL + '/text-categorization',
            columns: getCoulmnsAPiOld('Types of Keywords', 'brand_id', 'any', 'brand_name'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },

        {
          table_name: 'Category',
          table_id: 'Category',
          end_point: '/search-term-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'Category'
          },
          table_json: {
            aggregate: 'Category',
            data_id: 'category_id',
            categorizationLink: process.env.REACT_APP_URL + '/text-categorization',
            columns: getCoulmnsAPiOld('Category', 'category_id', 'any', 'category_name'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },

        {
          table_name: 'Sub Category',
          table_id: 'Sub_Category',
          end_point: '/search-term-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'Subcategory'
          },
          table_json: {
            aggregate: 'Subcategory',
            data_id: 'subcategory_id',
            categorizationLink: process.env.REACT_APP_URL + '/text-categorization',
            columns: getCoulmnsAPiOld('Sub Category', 'subcategory_id', 'any', 'subcategory_name'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },
        
        
      ]
    },
    {
      id: 5,
      is_group: 0,
      title: 'Placement Summary',
      title_aggregate: 'placement-summary',
      param_key: 'placement-summary',
      heatMapEnabled: 1,
      url_update: '/placement',
      prefer_tables: [
        {
          table_name: 'Campaign Types',
          classNames: 'text-capitalize',
          table_id: 'Type',
          end_point: '/placement-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'Type'
          },
          table_json: {
            aggregate: 'Type',
            data_id: 'Type',
            columns: getCoulmnsAPiOld('Campaign Types', 'Type'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },
        {
          table_name: 'Placement Types',
          table_id: 'Placement Type',
          end_point: '/placement-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'PlacementType'
          },
          table_json: {
            aggregate: 'PlacementType',
            data_id: 'placement',
            expand_row: 'enable',
            columns: getCoulmnsAPiOld('Placement Types', 'placement'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },

        {
          table_name: 'Bidding Strategy',
          table_id: 'Bidding Strategy',
          end_point: '/placement-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'BiddingStrategy'
          },
          table_json: {
            aggregate: 'BiddingStrategy',
            data_id: 'biddingStrategy',
            expand_row: 'enable',
            columns: getCoulmnsAPiOld('Bidding Strategy', 'biddingStrategy'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },

        {
          table_name: 'Portfolio Name',
          table_id: 'Portfolio_Name',
          end_point: '/placement-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'Portfolio_Name'
          },
          table_json: {
            aggregate: 'Portfolio_Name',
            data_id: 'Portfolio_Name',
            search: 'enable',
            expand_row: 'enable',
            columns: getCoulmnsAPiOld('Portfolio Name', 'Portfolio_Name'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },

        {
          table_name: 'CampaignName',
          table_id: 'CampaignName',
          end_point: '/placement-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'CampaignName'
          },
          table_json: {
            aggregate: 'CampaignName',
            data_id: 'campaignName',
            search: 'enable',
            expand_row: 'enable',
            pagination: 'enabled',
            columns: getCoulmnsAPiOld('Campaign Name', 'campaignName'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },

        {
          table_name: 'Ad Group Name',
          table_id: 'Ad_group_name',
          end_point: '/placement-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'adGroupName'
          },
          table_json: {
            aggregate: 'adGroupName',
            data_id: 'adGroupName',
            search: 'enable',
            expand_row: 'enable',
            pagination: 'enabled',
            columns: getCoulmnsAPiOld('Ad Group Name', 'adGroupName'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Spend',
            is_sort: true
          }
        },
        
        {
          table_name: 'Weekdays',
          table_id: 'Day of Week',
          end_point: '/placement-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'Weekday'
          },
          table_json: {
            aggregate: 'Weekday',
            data_id: 'Weekday',
            columns: getCoulmnsAPiOld('Weekdays', 'Weekday'),
            summary: [],
            rows: []
          }
        },

        {
          table_name: 'Months',
          table_id: 'Monthly',
          end_point: '/placement-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'Monthly'
          },
          table_json: {
            aggregate: 'Monthly',
            data_id: 'Month',
            columns: getCoulmnsAPiOld('Months', 'Month', 'Date'),
            summary: [],
            rows: []
          },
          default_sort: {
            sort_type: 'ASC',
            sort_column: 'Monthly',
            is_sort: true
          }
        },

        
        
        {
          table_name: 'Weeks',
          table_id: 'Weekly',
          end_point: '/placement-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'Weekly'
          },
          table_json: {
            aggregate: 'Weekly',
            data_id: 'Week',
            expand_row: 'enable',
            columns: getCoulmnsAPiOld('Weeks', 'Week', 'Date'),
            summary: [],
            rows: []
          }
          // "default_sort": {
          // 	"sort_type": "ASC",
          // 	"sort_column": "Weekly",
          // 	"is_sort": false
          // }
        },


        {
          table_name: 'Date',
          table_id: 'Daily',
          end_point: '/placement-summary',
          payload: {
            start_date: '2023-02-01',
            end_date: '2023-02-15',
            aggregate: 'Daily'
          },
          table_json: {
            aggregate: 'Daily',
            data_id: 'Date',
            pagination: 'enabled',
            columns: getCoulmnsAPiOld('Date', 'Date', 'Date'),
            summary: [],
            rows: []
          }
          // "default_sort": {
          // 	"sort_type": "ASC",
          // 	"sort_column": "Daily",
          // 	"is_sort": false
          // }
        },
        
      ]
    },
    {
      id: 6,
      is_group: 0,
      title: 'Advertised Product Summary',
      title_aggregate: 'advertised-product-summary',
      param_key: 'advertised-product-summary',
      url_update: '/advertised-product',
      heatMapEnabled: 1,
      prefer_tables: [
        {
          table_name: "Campaign Types",
          classNames: "text-capitalize",
          table_id: "Type",
          end_point: "/advertised-product-summary",
          payload: {
            start_date: "2023-02-01",
            end_date: "2023-02-15",
            aggregate: "CampaignType",
          },
          table_json: {
            aggregate: "CampaignType",
            data_id: "Type",
            columns: getCoulmnsAPiOld("Campaign Types", "Type"),
            summary: [],
            rows: [],
          },
          default_sort: {
            sort_type: "ASC",
            sort_column: "Spend",
            is_sort: true,
          },
        },
      
        {
          table_name: "Advertised ASINs",
          table_id: "Advertised ASINs",
          end_point: "/advertised-product-summary",
          payload: {
            start_date: "2023-02-01",
            end_date: "2023-02-15",
            aggregate: "AdvertisedASINs",
          },
          table_json: {
            aggregate: "AdvertisedASINs",
            data_id: "AdvertisedASINs",
            pagination: "enabled",
            columns: getCoulmnsAPiOld("Advertised ASINs", "AdvertisedASINs"),
            summary: [],
            rows: [],
          },
          default_sort: {
            sort_type: "ASC",
            sort_column: "Spend",
            is_sort: true,
          },
        },
      
        {
          table_name: "Product Titles",
          table_id: "Product_Titles",
          end_point: "/advertised-product-summary",
          payload: {
            start_date: "2023-02-01",
            end_date: "2023-02-15",
            aggregate: "ProductTitle",
          },
          table_json: {
            aggregate: "ProductTitle",
            data_id: "ProductTitle",
            pagination: "enabled",
            columns: getCoulmnsAPiOld("Product Titles", "ProductTitle"),
            summary: [],
            rows: [],
          },
          default_sort: {
            sort_type: "ASC",
            sort_column: "Spend",
            is_sort: true,
          },
        },
      
        {
          table_name: "Ad Formats",
          table_id: "Ad_Formats",
          end_point: "/advertised-product-summary",
          payload: {
            start_date: "2023-02-01",
            end_date: "2023-02-15",
            aggregate: "AdFormat",
          },
          table_json: {
            aggregate: "AdFormat",
            data_id: "AdFormats",
            columns: getCoulmnsAPiOld("Ad Formats", "AdFormats"),
            rows: [],
            summary: [],
          },
          default_sort: {
            sort_type: "ASC",
            sort_column: "Spend",
            is_sort: true,
          },
        },
      
        {
          table_name: "Portfolio Name",
          table_id: "Portfolio_Name",
          end_point: "/advertised-product-summary",
          payload: {
            start_date: "2023-02-01",
            end_date: "2023-02-15",
            aggregate: "Portfolio_Name",
          },
          table_json: {
            aggregate: "Portfolio_Name",
            data_id: "Portfolio_Name",
            columns: getCoulmnsAPiOld("Portfolio Name", "Portfolio_Name"),
            rows: [],
            summary: [],
          },
          default_sort: {
            sort_type: "ASC",
            sort_column: "Spend",
            is_sort: true,
          },
        },
      
        {
          table_name: "placement_summary",
          table_id: "placement summary",
          end_point: "/advertised-product-summary",
          payload: {
            start_date: "2023-02-01",
            end_date: "2023-02-15",
            aggregate: "CampaignName",
          },
          table_json: {
            aggregate: "CampaignName",
            data_id: "campaignName",
            search: "enable",
            expand_row: "enable",
            pagination: "enabled",
            columns: getCoulmnsAPiOld("Campaign Name", "campaignName"),
            summary: [],
            rows: [],
          },
          default_sort: {
            sort_type: "ASC",
            sort_column: "Spend",
            is_sort: true,
          },
        },
      
        {
          table_name: "Ad Group Name",
          table_id: "Ad_group_name",
          end_point: "/advertised-product-summary",
          payload: {
            start_date: "2023-02-01",
            end_date: "2023-02-15",
            aggregate: "adGroupName",
          },
          table_json: {
            aggregate: "adGroupName",
            data_id: "adGroupName",
            search: "enable",
            expand_row: "enable",
            pagination: "enabled",
            columns: getCoulmnsAPiOld("Ad Group Name", "adGroupName"),
            summary: [],
            rows: [],
          },
          default_sort: {
            sort_type: "ASC",
            sort_column: "Spend",
            is_sort: true,
          },
        },
      
        {
          table_name: "Weekdays",
          table_id: "Weekdays",
          end_point: "/advertised-product-summary",
          payload: {
            start_date: "2023-02-01",
            end_date: "2023-02-15",
            aggregate: "Weekday",
          },
          table_json: {
            aggregate: "Weekday",
            data_id: "Weekday",
            columns: getCoulmnsAPiOld("Weekdays", "Weekday"),
            summary: [],
            rows: [],
          },
        },
      
        {
          table_name: "Months",
          table_id: "Monthly",
          end_point: "/advertised-product-summary",
          payload: {
            start_date: "2023-02-01",
            end_date: "2023-02-15",
            aggregate: "Monthly",
          },
          table_json: {
            aggregate: "Monthly",
            data_id: "Month",
            columns: getCoulmnsAPiOld("Months", "Month", "Date"),
            summary: [],
            rows: [],
          },
          default_sort: {
            sort_type: "ASC",
            sort_column: "Monthly",
            is_sort: true,
          },
        },
        
        
        
        {
          table_name: "Weeks",
          table_id: "Weekly",
          end_point: "/advertised-product-summary",
          payload: {
            start_date: "2023-02-01",
            end_date: "2023-02-15",
            aggregate: "Weekly",
          },
          table_json: {
            aggregate: "Weekly",
            data_id: "Week",
            expand_row: "enable",
            columns: getCoulmnsAPiOld("Weeks", "Week", "Date"),
            summary: [],
            rows: [],
          },
          // "default_sort": {
          // 	"sort_type": "ASC",
          // 	"sort_column": "Weekly",
          // 	"is_sort": false
          // }
        },
      
        {
          table_name: "Date",
          table_id: "Daily",
          end_point: "/advertised-product-summary",
          payload: {
            start_date: "2023-02-01",
            end_date: "2023-02-15",
            aggregate: "Daily",
          },
          table_json: {
            aggregate: "Daily",
            data_id: "Date",
            pagination: "enabled",
            columns: getCoulmnsAPiOld("Date", "Date", "Date"),
            summary: [],
            rows: [],
          },
          // "default_sort": {
          // 	"sort_type": "ASC",
          // 	"sort_column": "Daily",
          // 	"is_sort": false
          // }
        },
        
        {
          table_name: "Brand",
          table_id: "brand",
          classNames: "text-capitalize",
          end_point: "/advertised-product-summary",
          payload: {
            start_date: "2023-02-01",
            end_date: "2023-02-15",
            aggregate: "Brand",
          },
          table_json: {
            aggregate: "Brand",
            data_id: "brand_id",
            categorizationLink: process.env.REACT_APP_URL + "/categorization",
            columns: getCoulmnsAPiOld("Brand", "brand_id", "any", "brand_name"),
            summary: [],
            rows: [],
          },
          default_sort: {
            sort_type: "ASC",
            sort_column: "Spend",
            is_sort: true,
          },
        },
      
        {
          table_name: "Category",
          table_id: "Category",
          classNames: "text-capitalize",
          end_point: "/advertised-product-summary",
          payload: {
            start_date: "2023-02-01",
            end_date: "2023-02-15",
            aggregate: "Category",
          },
          table_json: {
            aggregate: "Category",
            data_id: "category_id",
            classNames: "text-capitalize",
            categorizationLink: process.env.REACT_APP_URL + "/categorization",
            columns: getCoulmnsAPiOld("Category", "category_id", "any", "category_name"),
            summary: [],
            rows: [],
          },
          default_sort: {
            sort_type: "ASC",
            sort_column: "Spend",
            is_sort: true,
          },
        },
      
        {
          table_name: "Sub Category",
          table_id: "Sub_Category",
          classNames: "text-capitalize",
          end_point: "/advertised-product-summary",
          payload: {
            start_date: "2023-02-01",
            end_date: "2023-02-15",
            aggregate: "Subcategory",
          },
          table_json: {
            aggregate: "Subcategory",
            data_id: "subcategory_id",
            categorizationLink: process.env.REACT_APP_URL + "/categorization",
            columns: getCoulmnsAPiOld(
              "Sub Category",
              "subcategory_id",
              "any",
              "subcategory_name"
            ),
            summary: [],
            rows: [],
          },
          default_sort: {
            sort_type: "ASC",
            sort_column: "Spend",
            is_sort: true,
          },
        },
      ]
    }
  ]
};

export const METRICS_CONSTANT = [
  'Spend',
  'Revenue',
  'Orders',
  'Impressions',
  'Is_perc',
  'Clicks',
  'CPA',
  'ROAS',
  'AOV',
  'CTR',
  'CPM',
  'CPC',
  'ACOS',
  'RPC',
  'CPO',
  'Spend_perc',
  'Revenue_perc',
  'CR_perc',
  'CC'
];

const getCustomTableColumns = [
  // { id: 19, column_type: 'active_view', label: 'Active', param: 'active_status', checked: true },
  {
    additionalClass: 'cus_wid col_fixed',
    column_type: 'column_field',
    label: 'Targeting',
    param: 'targetingText',
    field_type: 'any',
    field_display: '',
    checked: true
  },
  // { id: 20, column_type: 'status_view', label: 'Status', param: 'status', checked: true },
  // { id: 32, column_type: 'text_view', label: 'Match Type', param: 'matchType', checked: true },
  // { id: 29, column_type: 'text_view', label: 'Campaign Name', param: 'campaignName', checked: true },
  // { id: 30, column_type: 'text_view', label: 'Ad Group Name', param: 'adGroupName', checked: true },
  // { id: 31, column_type: 'text_view', label: 'Campaign Type', param: 'Type', checked: true },
  // { id: 21, column_type: 'sug_bid_view', label: 'Sug. Bid', param: 'sug_bid', checked: true },
  // { id: 22, column_type: 'bid_view', label: 'Bid', param: 'bid_amount', checked: true },
  // { id: 23, column_type: 'percentage', label: 'TOS IS%', param: 'TOS_IS_perc', checked: true },
  { id: 1, is_based_available: 1, column_type: 'number', label: 'Spend', param: 'Spend', prefix_sign: 'currency', checked: true },
  { id: 2, is_based_available: 1, column_type: 'number', label: 'Revenue', param: 'Revenue', prefix_sign: 'currency', checked: true },
  { id: 3, is_based_available: 1, column_type: 'number', label: 'Orders', param: 'Orders', checked: true },
  { id: 4, is_based_available: 1, column_type: 'number', label: 'Imp', param: 'Impressions', checked: true },
  { id: 5, is_based_available: 1, column_type: 'number', label: 'Clicks', param: 'Clicks', checked: true },
  { id: 6, is_based_available: 1, column_type: 'decimal', label: 'CPA', param: 'CPA', prefix_sign: 'currency', checked: true },
  { id: 7, is_based_available: 1, column_type: 'decimal', label: 'ROAS', param: 'ROAS', checked: true },
  { id: 8, is_based_available: 1, column_type: 'decimal', label: 'AOV', param: 'AOV', prefix_sign: 'currency', checked: true },
  { id: 9, is_based_available: 1, column_type: 'percentage', label: 'CTR', param: 'CTR', checked: true },
  { id: 10, is_based_available: 1, column_type: 'decimal', label: 'CPM', param: 'CPM', checked: true },
  { id: 11, is_based_available: 1, column_type: 'decimal', label: 'CPC', param: 'CPC', checked: true },
  { id: 12, is_based_available: 1, column_type: 'percentage', label: 'ACOS', param: 'ACOS', checked: true },
  { id: 13, is_based_available: 1, column_type: 'number', label: 'RPC', param: 'RPC', checked: true },
  { id: 14, is_based_available: 1, column_type: 'number', label: 'CPO', param: 'CPO', prefix_sign: 'currency', checked: true },
  { id: 15, is_based_available: 1, column_type: 'percentage', label: 'Spend%', param: 'Spend_perc', checked: true },
  { id: 16, is_based_available: 1, column_type: 'percentage', label: 'Rev%', param: 'Revenue_perc', checked: true },
  { id: 17, is_based_available: 1, column_type: 'percentage', label: 'CR%', param: 'CR_perc', checked: true },
  { id: 18, is_based_available: 1, column_type: 'number', label: 'CC', param: 'CC', checked: true }
];

export const TARGETING_TEXT_OWN_CONSTANT = [
  {
    table_name: 'Targeting',
    table_id: 'Targeting',
    end_point: '/targeting-summary-details',
    payload: {
      start_date: '2023-02-01',
      end_date: '2023-02-15',
      aggregate: 'TargetingText',
      summary: 'Targeting'
    },
    table_json: {
      aggregate: 'TargetingText',
      data_id: 'keywordId',
      isHyperLink: 0,
      is_cross_disabled: 1,
      columns: getCustomTableColumns,
      summary: [],
      rows: []
    },
    default_sort: {
      sort_type: 'ASC',
      sort_column: 'Spend',
      is_sort: true
    }
  }
];

export const MODALS_TYPES = {
  'add_to_negative_same_ad_group': 'Add the keyword as Negative in same Ad Group',
  'add_to_negative_existing_campaign': 'Add to negative in the existing campaign',
  'add_to_blacklist': 'Add the keyword in the Blacklist'
}

export const DEFAULT_SORT_CONFIG = { sort_type: '', sort_column: '', is_sort: false };

export const CALENDAR_OPTIONS = [
  // { label: "Today" },
  { label: "Yesterday" },
  { label: "Last 7 Days" },
  { label: "Last 14 Days" },
  { label: "Last 30 Days" },
  { label: "This Week" },
  { label: "Last Week" },
  { label: "This Month" },
  { label: "Last Month" },
  { label: "This Year" },
  { label: "Last Year" },
  { label: "Custom" },
  // { label: "Last 30 Days" },
  // { label: "Year to Date" },
  // { label: "Last 7 Weeks" },
  // { label: "Last 7 Months" },
  // { label: "Last 7 Weekdays" },
  // { label: "Last 7 Weekends" },
  // { label: "Last 7 Same day" },
];



export default TAB_GROUP;




