import React from 'react';
import Breadcrumb from 'src/layouts/AdminLayout/Breadcrumb';
import Footer from 'src/views/landing/footer';
import Header from 'src/views/landing/header';

const AuthLayout = ({ children }) => {

    return (
        <React.Fragment>
            <div className="landing-page">
            <Breadcrumb />
            <div className="wrapper">
                <Header />
                <div id="banner-top" className="h-fix">
                    {children}
                </div>
                <Footer />
            </div>
            </div>
        </React.Fragment>

    )

}
export default AuthLayout