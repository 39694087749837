const BudgetRuleIcon = ({ fillColor = "black", ...rest }) => {
    return (
        <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg" { ...rest }>
            <path d="M27.6621 4.79688H3.91211C3.51843 4.79688 3.14087 4.64049 2.8625 4.36211C2.58412 4.08374 2.42773 3.70618 2.42773 3.3125C2.42773 2.91882 2.58412 2.54126 2.8625 2.26289C3.14087 1.98451 3.51843 1.82812 3.91211 1.82812H24.0996C24.3358 1.82812 24.5624 1.73429 24.7294 1.56727C24.8964 1.40024 24.9902 1.17371 24.9902 0.9375C24.9902 0.701292 24.8964 0.474757 24.7294 0.307733C24.5624 0.140708 24.3358 0.046875 24.0996 0.046875H3.91211C3.04601 0.046875 2.21539 0.390931 1.60296 1.00335C0.99054 1.61578 0.646484 2.4464 0.646484 3.3125V22.3125C0.646484 23.1786 0.99054 24.0092 1.60296 24.6216C2.21539 25.2341 3.04601 25.5781 3.91211 25.5781H27.6621C28.2133 25.5781 28.7418 25.3592 29.1316 24.9695C29.5213 24.5797 29.7402 24.0512 29.7402 23.5V6.875C29.7402 6.32385 29.5213 5.79527 29.1316 5.40554C28.7418 5.01582 28.2133 4.79688 27.6621 4.79688ZM27.959 23.5C27.959 23.5787 27.9277 23.6542 27.872 23.7099C27.8164 23.7656 27.7408 23.7969 27.6621 23.7969H3.91211C3.51843 23.7969 3.14087 23.6405 2.8625 23.3621C2.58412 23.0837 2.42773 22.7062 2.42773 22.3125V6.22039C2.88699 6.45612 3.39589 6.57876 3.91211 6.57812H27.6621C27.7408 6.57812 27.8164 6.6094 27.872 6.66508C27.9277 6.72075 27.959 6.79626 27.959 6.875V23.5ZM23.8027 14.5938C23.8027 14.8873 23.7157 15.1743 23.5526 15.4184C23.3895 15.6625 23.1576 15.8528 22.8864 15.9651C22.6152 16.0775 22.3167 16.1069 22.0288 16.0496C21.7408 15.9923 21.4763 15.851 21.2687 15.6434C21.0612 15.4358 20.9198 15.1713 20.8625 14.8833C20.8052 14.5954 20.8346 14.2969 20.947 14.0257C21.0593 13.7545 21.2496 13.5226 21.4937 13.3595C21.7378 13.1964 22.0248 13.1094 22.3184 13.1094C22.712 13.1094 23.0896 13.2658 23.368 13.5441C23.6463 13.8225 23.8027 14.2001 23.8027 14.5938Z" fill={fillColor} />
        </svg>

    );
};

export default BudgetRuleIcon;

