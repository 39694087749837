import React, { createContext, useReducer, useContext } from 'react';


// Initial state for the context
const initialState = {
  reports_loader: {},
  custom_sort_config: []
};

// Define the reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_REPORTS_LOADER':
      return { ...state, reports_loader: { ...state.reports_loader, ...action.payload } };
    case 'UPDATE_CUSTOM_SORT_CONFIG':
      return { ...state, custom_sort_config: action.payload };
    case 'CLEAR_CUSTOM_SORT_CONFIG':
      return { ...state, custom_sort_config: [] };
    case 'ADD_CUSTOM_SORT_CONFIG':
      return { ...state, custom_sort_config: state.custom_sort_config.concat(action.payload) };
    default:
      return state;
  }
};

// Create the context
const ReportContext = createContext();

// Create a custom hook to use the context
const useReportContext = () => {
  const context = useContext(ReportContext);
  if (!context) {
    throw new Error('useReportContext must be used within a ReportProvider');
  }
  return context;
};

// Create the provider component
const ReportProvider = ({ children }) => {
  const [reportState, reportDispatch] = useReducer(reducer, initialState);

  return (
    <ReportContext.Provider value={{ reportState, reportDispatch }}>
      {children}
    </ReportContext.Provider>
  );
};

export { ReportProvider, useReportContext };