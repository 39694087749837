import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'src/components/Actions/Button';
import { Row, Col, Card, Tab, OverlayTrigger, Tooltip, ListGroup, Form, FormControl, Collapse } from 'react-bootstrap';
import HighChartCard from 'src/components/Charts/Highchart';
import DemoMetrics from 'src/components/Charts/Highchart/config/demo-metrics';
import CardBox from './cards/cards';
import UsersTable from '../users/userstable/UserTable';
import TAB_GROUP, { DEFAULT_ACCOUNT_REPORT_COLUMNS, DEFAULT_AMS_REPORT_COLUMNS, getCoulmnsAPi } from 'src/store/targeting-summary.constant';
import AccountSummary from './accountChart';
import AccountCards from './accountCard';
import chartloader from 'src/assets/images/common/chart-loader.svg';
import icons from 'currency-icons';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { NavLink, useHistory } from 'react-router-dom';

import MetricsChart from './metricsCharts';
import DATE_RANGE from 'src/store/DateRange.constant';
import isAfter from 'date-fns/isAfter';
import useApi from 'src/hooks/useApi';
import service from 'src/api/service';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import { addDays, startOfWeek, subDays, endOfDay, startOfDay, startOfMonth, endOfWeek, endOfMonth, addMonths } from 'date-fns';

import Calender from 'src/assets/images/common/calender.svg';
import queryString from 'query-string';
import EmptyMessage from 'src/components/EmptyMessage';
import DatePicker from './datePicker';
import { Popover, Whisper, Toggle } from 'rsuite';
import Lightloader from 'src/assets/images/common/chart-loader-white.svg';
import { ThemeContext, ThemeProvider } from 'src/providers/themeProvider';
import TAB_GROUP_NEW from 'src/store/targeting-summary-new.constant';
import SearchBar from 'src/views/pages/dashboards/searchBar/index';
import { Settings } from 'react-feather';
import dragIcon from 'src/assets/images/common/drag.svg';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import Checkbox from 'src/components/Form/checkbox';
import { useCookies } from 'react-cookie';
import WaitingMessage from 'src/components/EmptyMessage/WaitingMessage';
import { CreateCrossFilter } from 'src/payload/cross-filter-payload';

const colors = ['active_one', 'active_two', 'active_three', 'active_four', 'active_five'];

export const Loader = () => {
  const [theme] = useContext(ThemeContext);
  return <img src={theme.theme === 'DARK' ? Lightloader : chartloader} className="mr-1 hei-30 wid-30" />;
};

function Dashboard() {

  const TABLE_GROUP_VALUES = window.location.href.indexOf('summary-new') > -1 ? TAB_GROUP_NEW : TAB_GROUP;
  const querryString = queryString.parse(window.location.search);
  const { report_id } = useParams();
  console.log('report_id', report_id);
  const User = JSON.parse(localStorage.getItem('User'));
  const getProfile = JSON.parse(localStorage.getItem('selectedProfileId'));
  const doMetricList = useApi(service.postService);
  const doMetricChart = useApi(service.postService);
  const doAccountMetricChart = useApi(service.postService);
  const [tabsInfo, setTabsInfo] = useState(TABLE_GROUP_VALUES);
  const [parentIndex, setParentIndex] = useState(report_id != null ? GetParentIndex(report_id) : 0);
  // const [parentIndex, setParentIndex] = useState(report_id != null ?  GetParentIndexWithoutAccountSummary(report_id) : 0);
  const [childIndex, setChildIndex] = useState(0);
  const [subChildIndex, setSubChildIndex] = useState(0);
  const [subTableRes, setSubTableRes] = useState([]);
  const [addmetric, setAddmetric] = useState(false);
  const [activebtn, setactivebtn] = useState(0);
  const [showcustomrange, setShowcustomrange] = useState(true);
  const [selecteddate, setselecteddate] = useState(false);
  const [metrics, setMetrics] = useState([]);
  const [defaultMetrics, setDefaultMetrics] = useState([]);
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [dateRange, setDateRange] = useState(DATE_RANGE?.date_range);
  const [selectedRange, setSelectedRange] = useState({ label: 'Last - 30 Days', short_lable: 'L-30-D', index: 4 });
  const [dateSelected, setDateSelected] = useState([
    subDays(new Date(), 30, -1).toLocaleString('en-us', { timeZone: getProfile?.timezone }),
    addDays(new Date(), -1).toLocaleString('en-us', { timeZone: getProfile?.timezone })
  ]);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [defaultDateForPicker, setDefaultDateForPicker] = useState([
    { startDate: dateFormat(dateSelected[0]), endDate: dateFormat(dateSelected[1]), key: 'selection' }
  ]);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [dashboardQuery, setDashboardQuery] = useState({ categories: [], series: [] });
  const [crossFilter, setCrossFilter] = useState([]);
  const [seriesUpdate, setSeriesUpdate] = useState([]);
  const [filtervalue, setfiltervalue] = useState('Select option...');
  const [accordionKey, setAccordionKey] = useState(1);
  const [showDatepicker, setShowdatepicker] = useState(false);
  const [tableTitle, setTableTitle] = useState('');
  const [parentIndexCopy, setParentIndexCopy] = useState(parentIndex);
  const [customTables, setCustomTables] = useState([]);
  const [asinCustomTables, setAsinCustomTables] = useState([]);
  const doListCustomTables = useApi(service.postService);
  const doListAsinCustomTables = useApi(service.postService);
  const [dynamicColumn, setDynamicColumn] = useState(false);
  const [columnsData, setColumnsData] = useState([]);
  const [reportColumns, setReportColumns] = useState([]);

  const handleRLDDChange = (reorderedItems) => {
    setColumnsData(reorderedItems);
  };

  const tableContent = (rowData, rowIndex) => {
    return (
      <div className="item-drp d-flex align-items-center justify-content-between cursor-move">
        <Checkbox
          label={rowData.label}
          id={'row_' + rowIndex}
          classes="cursor-pointer"
          checked={rowData.checked === true}
          action={(event) => {
            setColumnsData(
              columnsData.map((book, index) => {
                return {
                  ...book,
                  ...(rowIndex === index && { checked: event.target.checked ? true : false })
                };
              })
            );
          }}
        />
        <img src={dragIcon} alt="asin-product" className="wid-7 mr-2" />
      </div>
    );
  };

  let history = useHistory();

  const closeDatepicker = () => {
    setShowdatepicker(false);
    setselecteddate(false);
  };

  function GetParentIndexWithoutAccountSummary(report_id) {
    switch (report_id) {
      case 'all-campaigns':
        return 0;
        break;

      case 'targeting':
        return 1;
        break;

      case 'search-term':
        return 2;
        break;

      case 'placement':
        return 3;
        break;

      case 'advertised-product':
        return 4;
        break;

      default:
        break;
    }
  }

  function GetParentIndex(report_id) {
    const accountSummaryNew = process.env.REACT_APP_ACCOUNT_SUMMARY_ENABLE !== '1';

    switch (report_id) {
      case 'account':
        return 0;
        break;

      // case 'all-campaigns':
      //   return 1;
      //   break;

      case 'targeting':
        return accountSummaryNew ? 0 : 1;

      case 'search-term':
        return accountSummaryNew ? 1 : 2;

      case 'placement':
        return accountSummaryNew ? 2 : 3;

      case 'advertised-product':
        return accountSummaryNew ? 3 : 4;

      default:
        return 0;
        break;
    }
  }

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  function formatDateTwo(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // Format the date in 'dd-mm-yyyy' format
    const formattedDate = `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;

    return formattedDate;
  }

  function dateFormat(dateString) {
    console.log('date', dateString);
    const date = new Date(dateString);
    console.log('date--', date);
    return date;
  }

  const CrossFilter = (aggregate, rowData, type, table_id = '', column_name = '') => {
    console.log('CrossFilte', aggregate, rowData, type);
    console.log('CrossFilterParameter', { aggregate, rowData, type, table_id });

    const crossFilterCopy = JSON.parse(JSON.stringify(crossFilter));

    const isAggregateExist = crossFilter.some(
      (crossRow) =>
        (table_id === '' && crossRow.aggregate === aggregate) ||
        (table_id !== '' && crossRow.table_id === table_id && crossRow.aggregate === aggregate)
    );

    if (isAggregateExist) {
      const isFindAggregateIndex = crossFilterCopy.findIndex(
        (crossRow) =>
          (table_id === '' && crossRow.aggregate === aggregate) ||
          (table_id !== '' && crossRow.table_id === table_id && crossRow.aggregate === aggregate)
      );
      const crossRowData = crossFilterCopy[isFindAggregateIndex];

      if (type === 'true') {
        crossFilterCopy[isFindAggregateIndex] = {
          aggregate: aggregate,
          filters: Array.isArray(rowData) ? rowData : crossRowData.filters.concat([rowData]),
          ...(table_id !== '' && { table_id: table_id, }),
          ...(column_name !== '' && { column_name: column_name, })
        };
      } else if (type === 'false') {
        crossFilterCopy[isFindAggregateIndex] = {
          aggregate: aggregate,
          filters: Array.isArray(rowData) ? rowData : crossRowData.filters.filter((value) => value !== rowData),
          ...(table_id !== '' && { table_id: table_id }),
          ...(column_name !== '' && { column_name: column_name, })
        };
      }

      setCrossFilter(crossFilterCopy);
    } else if (!isAggregateExist) {
      setCrossFilter(
        crossFilter.concat([
          {
            aggregate: aggregate,
            filters: Array.isArray(rowData) ? rowData : [rowData],
            ...(table_id !== '' && { table_id: table_id }),
            ...(column_name !== '' && { column_name: column_name })
          }
        ])
      );
    }
  };

  const MerticList = () => {
    if (User && tableTitle !== '') {
      const crossFiltersData = CreateCrossFilter(crossFilter.filter((crossRow) => crossRow.filters.length > 0));
      console.log('getProfile', getProfile);
      doMetricList.request({
        url: `/metrics-list${window.location.href.indexOf('summary-new1') > -1 ? '-new' : ''}`,
        payload: {
          profile_id: String(getProfile?.profileId),
          report: tableTitle,
          account_type: getProfile?.accountInfo?.type,
          token: User.token,
          role: getProfile?.accessLevel ? getProfile?.accessLevel : 'Admin',
          // page: 'targeting-summary',
          start_date: formatDate(dateSelected[0]),
          end_date: formatDate(dateSelected[1]),
          ...(crossFiltersData.length > 0 && { user_filters: crossFiltersData })
        }
      });
    }
  };

  const MerticWidget = () => {
    console.log('selectedMetrics-->', selectedMetrics);
    if (selectedMetrics.length > 0) {
      const crossFiltersData = CreateCrossFilter(crossFilter.filter((crossRow) => crossRow.filters.length > 0));

      doMetricChart.request({
        url: `/metrics-widget${window.location.href.indexOf('summary-new1') > -1 ? '-new' : ''}`,
        payload: {
          profile_id: String(getProfile?.profileId),
          report: tableTitle,
          account_type: getProfile?.accountInfo?.type,
          metrics: selectedMetrics,
          role: getProfile?.accessLevel ? getProfile?.accessLevel : 'Admin',
          start_date: formatDate(dateSelected[0]),
          end_date: formatDate(dateSelected[1]),
          ...(crossFiltersData.length > 0 && { user_filters: crossFiltersData })
        }
      });
    }
  };
  console.log('tableTitle', tableTitle);

  const AccountSummaryMetricWidget = () => {
    // const crossFiltersData = crossFilter.filter((crossRow) => (crossRow.filters.length > 0))

    doAccountMetricChart.request({
      url: '/account-summary-charts',
      payload: {
        profile_id: String(getProfile?.profileId),
        aggregate: 'Orders',
        account_type: getProfile?.accountInfo?.type,
        start_date: formatDate(dateSelected[0]),
        end_date: formatDate(dateSelected[1])
        // ...(crossFiltersData.length > 0 && { user_filters: crossFiltersData })
      }
    });
  };

  useEffect(() => {
    if (report_id != null) {
      const accountSummaryNew = process.env.REACT_APP_ACCOUNT_SUMMARY_ENABLE !== '1';
      switch (report_id) {
        case 'account':
          setTableTitle(TABLE_GROUP_VALUES.parent_tabs[0].title_aggregate);
          setParentIndex(0);
          setSubTableRes(TABLE_GROUP_VALUES.parent_tabs[0].prefer_tables);
          setCrossFilter([]);
          break;

        // case 'all-campaigns':
        //   setTableTitle(TABLE_GROUP_VALUES.parent_tabs[accountSummaryNew ? 0 : 1].title_aggregate);
        //   setParentIndex(accountSummaryNew ? 0 : 1);
        //   setSubTableRes(TABLE_GROUP_VALUES.parent_tabs[accountSummaryNew ? 0 : 1].prefer_tables);
        //   setCrossFilter([]);
        //   break;

        case 'targeting':
          setTableTitle(TABLE_GROUP_VALUES.parent_tabs[accountSummaryNew ? 0 : 1].title_aggregate);
          setParentIndex(accountSummaryNew ? 0 : 1);
          setSubTableRes(TABLE_GROUP_VALUES.parent_tabs[accountSummaryNew ? 0 : 1].prefer_tables);
          setCrossFilter([]);
          break;

        case 'search-term':
          setTableTitle(TABLE_GROUP_VALUES.parent_tabs[accountSummaryNew ? 1 : 2].title_aggregate);
          setParentIndex(accountSummaryNew ? 1 : 2);
          setSubTableRes(TABLE_GROUP_VALUES.parent_tabs[accountSummaryNew ? 1 : 2].prefer_tables);
          setCrossFilter([]);
          break;

        case 'placement':
          setTableTitle(TABLE_GROUP_VALUES.parent_tabs[accountSummaryNew ? 2 : 3].title_aggregate);
          setParentIndex(accountSummaryNew ? 2 : 3);
          setSubTableRes(TABLE_GROUP_VALUES.parent_tabs[accountSummaryNew ? 2 : 3].prefer_tables);
          setCrossFilter([]);
          break;

        case 'advertised-product':
          setTableTitle(TABLE_GROUP_VALUES.parent_tabs[accountSummaryNew ? 3 : 4].title_aggregate);
          setParentIndex(accountSummaryNew ? 3 : 4);
          setSubTableRes(TABLE_GROUP_VALUES.parent_tabs[accountSummaryNew ? 3 : 4].prefer_tables);
          setCrossFilter([]);
          break;

        default:
          break;
      }
    }
  }, [report_id]);

  useEffect(() => {
    const { loading, data } = doListCustomTables;

    if (!loading && data && Object.keys(data).length > 0) {
      const response = { ...data };
      if (response && response.data && Array.isArray(response.data)) {
        const copyTable = TABLE_GROUP_VALUES.parent_tabs[parentIndex].prefer_tables[0];
        setCustomTables(
          response.data.map((rowData) => ({
            ...copyTable,
            custom: 1,
            table_name: rowData.table_name,
            table_id: rowData.table_id,
            payload: { ...copyTable.payload, aggregate: 'Custom', table_id: rowData.table_id, table_name: rowData.table_name },
            table_json: {
              ...copyTable.table_json,
              data_id: 'label_id',
              aggregate: 'Custom',
              columns: getCoulmnsAPi(rowData.table_name, 'label_id', 'any', 'label_name')
            },
            customDetails: rowData
          }))
        );
      }
    } else if (!loading && data && Object.keys(data).length > 0) {
    }
  }, [parentIndex, doListCustomTables.loading]);

  useEffect(() => {
    if (report_id == 'search-term' || report_id == 'targeting') {
      setCustomTables([]);
      doListCustomTables.request({
        url: '/list-custom-table',
        payload: {
          token: User.token,
          profile_id: String(getProfile?.profileId),
          account_type: getProfile?.accountInfo?.type,
          role: getProfile?.accessLevel ? getProfile?.accessLevel : 'Admin',
          reports: report_id
        }
      });
    } else {
      setCustomTables([]);
    }
  }, [report_id]);
  
  useEffect(() => {
    if (report_id == 'advertised-product') {
      doListAsinCustomTables.request({
        url: '/custom-column/list-custom-column',
        payload: {
          profile_id: String(getProfile?.profileId),
        }
      });
    } else {
      setAsinCustomTables([])
    }
  }, [report_id]);

  useEffect(() => {
    if(report_id == 'advertised-product') {
      const { loading, data } = doListAsinCustomTables;
      if (!loading && data && Object.keys(data).length > 0) {
        const response = { ...data };
        if (response && response.data && Array.isArray(response.data)) {
          const copyTable = TABLE_GROUP_VALUES.parent_tabs[parentIndex].prefer_tables[0];
          setAsinCustomTables(
            response.data.map((rowData) => ({
              ...copyTable,
              custom: 1,
              customColumn: 1,
              table_name: rowData.column_name,
              table_id: rowData.column_id,
              column_name: rowData.column_name,
              payload: { ...copyTable.payload, column_name: rowData.column_name, aggregate: 'CustomLabel', table_id: rowData.column_id, table_name: rowData.table_name },
              table_json: {
                ...copyTable.table_json,
                expand_row: "disabled",
                search: "disabled",
                pagination: "disabled",
                data_id: rowData.object_id,
                aggregate: rowData.object_value,
                columns: getCoulmnsAPi(rowData.column_name, rowData.object_id, 'any', rowData.object_value)
              },
              customDetails: rowData
            }))
          );
        }
      } else if (!loading && data && Object.keys(data).length > 0) {
        setAsinCustomTables([])
      }  
    }
  }, [report_id, parentIndex, doListAsinCustomTables.loading]);





  useEffect(() => {
    console.log('parentIndex', parentIndex);
    setSubTableRes(TABLE_GROUP_VALUES.parent_tabs[parentIndex].prefer_tables);
    setCrossFilter([]);
  }, [parentIndex]);

  useEffect(() => {
    if (report_id === 'account') {
      // AccountSummaryMetricWidget()
    } else {
      if (!doMetricList.loading) {
        MerticList();
        console.log('defaultDateForPicker-->', defaultDateForPicker);
        setDefaultDateForPicker([{ startDate: dateFormat(dateSelected[0]), endDate: dateFormat(dateSelected[1]) }]);
      }
    }
  }, [dateSelected, tableTitle, crossFilter]);

  useEffect(() => {
    if (report_id == 'account') {
      // AccountSummaryMetricWidget()
    } else {
      if (selectedMetrics.length > 0 && !doMetricChart.loading) {
        MerticWidget();
      }
    }
  }, [selectedMetrics, dateSelected, crossFilter]);

  useEffect(() => {
    const { data, loading } = doMetricList;
    console.log('doMetricList--data-->', data);

    if (!loading && data && data?.success === true && data?.data) {
      setDefaultMetrics(defaultMetrics.length > 0 ? updateDefaultMetrics(data?.data) : data.data.slice(0, 4));
      let getDefaultMetrics = defaultMetrics.length > 0 ? defaultMetrics.map((data) => data.label) : data.data.map((data) => data.label);
      setMetrics(data.data);
      if (selectedMetrics.length === 0) {
        setSelectedMetrics(getDefaultMetrics.slice(0, 4));
      }
    } else if (!loading && data && data?.success === false) {
    }
  }, [doMetricList.loading]);

  const updateDefaultMetrics = (data) => {
    // const updatedMetrics = data.filter((metric) => defaultMetrics.some((dataItem) => dataItem.label === metric.label));
    const updatedMetrics = defaultMetrics.map((defaultMetric) => data.find((metric) => metric.label === defaultMetric.label));
    console.log('defaultMetricsdefaultMetrics--', updatedMetrics);

    return updatedMetrics;
  };

  console.log('defaultMetricsdefaultMetrics', defaultMetrics);

  useEffect(() => {
    const { data, loading } = doMetricChart;
    console.log('doMetricChart--data--->', data);

    if (!loading && data && data?.success === true && data?.data) {
      setDashboardQuery({ categories: data.data.categories, series: data.data.series });
      setSeriesUpdate(data.data.series);
    } else if (!loading && data && data?.success === false) {
      setDashboardQuery({ categories: [], series: [] });
    }
  }, [doMetricChart.loading]);

  const MetricsSelected = (metrics) => {
    console.log('matrics', metrics);
    const updateData = defaultMetrics.concat([metrics]);
    setDefaultMetrics(updateData);
    setAddmetric(false);
  };

  const EditMetrics = (edittId) => {
    console.log('editMetrics', edittId);

    if (selectedMetrics[0] === edittId || selectedMetrics[1] === edittId || selectedMetrics[2] === edittId) {
      console.log('selectedMetrics------->', selectedMetrics);
      setSelectedMetrics(selectedMetrics.filter((label) => label !== edittId));
    }
    setDefaultMetrics(defaultMetrics.filter((rowOf) => rowOf.label !== edittId));
  };

  const IndexUpdate = (matricRow) => {
    console.log('matricRow', matricRow);
    console.log('selectedMetrics', selectedMetrics);
    let selectedMetricsCopy = JSON.parse(JSON.stringify(selectedMetrics));
    console.log('selectedMetricsCopy-->1', selectedMetricsCopy);

    if (selectedMetricsCopy.indexOf(matricRow.label) > -1) {
      selectedMetricsCopy = selectedMetricsCopy.filter((_metric) => _metric !== matricRow.label);
    } else if (selectedMetricsCopy.indexOf(matricRow.label) === -1) {
      if (selectedMetricsCopy.length < 4) {
        selectedMetricsCopy = selectedMetricsCopy.concat([matricRow.label]);
      } else {
        selectedMetricsCopy[0] = matricRow.label;
      }
    }
    setSelectedMetrics(selectedMetricsCopy);
  };

  const selectedDropDownMetrics = (rowData, get_index, classes) => {
    console.log('selectedDropDownMetrics---2', rowData, get_index, classes);

    if (classes === 'active_one') {
      setSelectedMetrics((prev) => prev.map((item, index) => (index === 0 ? rowData.label : item)));
    } else if (classes === 'active_two') {
      setSelectedMetrics((prev) => prev.map((item, index) => (index === 1 ? rowData.label : item)));
    } else if (classes === 'active_three') {
      setSelectedMetrics((prev) => prev.map((item, index) => (index === 2 ? rowData.label : item)));
    } else if (classes === 'active_four') {
      setSelectedMetrics((prev) => prev.map((item, index) => (index === 3 ? rowData.label : item)));
    }
    // else {
    //   setSelectedMetrics(prev => prev.map((item, index) => index === get_index ? rowData.label : item))
    // }

    let filterData = metrics.find((data) => data.label === rowData.label);
    setDefaultMetrics((prev) => prev.map((item, index) => (index === get_index ? filterData : item)));
  };

  const overlayDate = (rowData, index) => {
    const { sort_label, label } = rowData;
    console.log('rowData', rowData);
    setselecteddate(false);

    if (sort_label === 'Today') {
      setDateSelected([
        addDays(new Date(), -1).toLocaleString('en-us', { timeZone: getProfile?.timezone }),
        addDays(new Date(), -1).toLocaleString('en-us', { timeZone: getProfile?.timezone })
      ]);
      setSelectedRange({ index: index, label: label });
      setDatePickerOpen(false);
      setShowcustomrange(true);
      console.log('ddd', [
        addDays(new Date(), -1).toLocaleString('en-us', { timeZone: getProfile?.timezone }),
        addDays(new Date(), -1).toLocaleString('en-us', { timeZone: getProfile?.timezone })
      ]);
    } else if (sort_label === 'Yesterday') {
      setDateSelected([
        addDays(new Date(), -2).toLocaleString('en-us', { timeZone: getProfile?.timezone }),
        addDays(new Date(), -2).toLocaleString('en-us', { timeZone: getProfile?.timezone })
      ]);
      setSelectedRange({ index: index, label: label });
      setDatePickerOpen(false);
      setShowcustomrange(true);
      console.log('ddd', [
        addDays(new Date(), -2).toLocaleString('en-us', { timeZone: getProfile?.timezone }),
        addDays(new Date(), -2).toLocaleString('en-us', { timeZone: getProfile?.timezone })
      ]);
    } else if (sort_label === 'Thisweek') {
      setDateSelected([
        startOfWeek(new Date()).toLocaleString('en-us', { timeZone: getProfile?.timezone }),
        addDays(new Date(), -1).toLocaleString('en-us', { timeZone: getProfile?.timezone })
      ]);
      setSelectedRange({ index: index, label: label });
      setDatePickerOpen(false);
      setShowcustomrange(true);
      // console.log("ddd", [startOfWeek(new Date(), -1).toLocaleString('en-us', { timeZone: getProfile?.timezone }), addDays(new Date(), -1).toLocaleString('en-us', { timeZone: getProfile?.timezone })])
    } else if (sort_label === 'Last7Days') {
      setDateSelected([
        subDays(new Date(), 6, -1).toLocaleString('en-us', { timeZone: getProfile?.timezone }),
        addDays(new Date(), -1).toLocaleString('en-us', { timeZone: getProfile?.timezone })
      ]);
      setSelectedRange({ index: index, label: label });
      setDatePickerOpen(false);
      setShowcustomrange(true);
    } else if (sort_label === 'Last30Days') {
      setDateSelected([
        subDays(new Date(), 29, -1).toLocaleString('en-us', { timeZone: getProfile?.timezone }),
        addDays(new Date(), -1).toLocaleString('en-us', { timeZone: getProfile?.timezone })
      ]);
      setSelectedRange({ index: index, label: label });
      setDatePickerOpen(false);
      setShowcustomrange(true);
      console.log('ddd', [
        subDays(new Date(), 29, -1).toLocaleString('en-us', { timeZone: getProfile?.timezone }),
        addDays(new Date(), -1).toLocaleString('en-us', { timeZone: getProfile?.timezone })
      ]);
    } else if (sort_label === 'ThisMonth') {
      setDateSelected([
        startOfMonth(new Date()).toLocaleString('en-us', { timeZone: getProfile?.timezone }),
        addDays(new Date(), -1).toLocaleString('en-us', { timeZone: getProfile?.timezone })
      ]);
      setSelectedRange({ index: index, label: label });
      setDatePickerOpen(false);
      setShowcustomrange(true);
      console.log('ddd', [
        startOfMonth(new Date()).toLocaleString('en-us', { timeZone: getProfile?.timezone }),
        addDays(new Date(), -1).toLocaleString('en-us', { timeZone: getProfile?.timezone })
      ]);
    } else if (sort_label === 'LastMonth') {
      setDateSelected([
        startOfMonth(addMonths(new Date(), -1)).toLocaleString('en-us', { timeZone: getProfile?.timezone }),
        endOfMonth(addMonths(new Date(), -1)).toLocaleString('en-us', { timeZone: getProfile?.timezone })
      ]);
      setSelectedRange({ index: index, label: label });
      setDatePickerOpen(false);
      setShowcustomrange(true);
      console.log('ddd', [
        startOfMonth(addMonths(new Date(), -1)).toLocaleString('en-us', { timeZone: getProfile?.timezone }),
        endOfMonth(addMonths(new Date(), -1)).toLocaleString('en-us', { timeZone: getProfile?.timezone })
      ]);
    } else if (sort_label === 'Thisyear') {
      setDateSelected([
        new Date(new Date().getFullYear(), 0, 1).toLocaleString('en-us', { timeZone: getProfile?.timezone }),
        addDays(new Date(), -1).toLocaleString('en-us', { timeZone: getProfile?.timezone })
      ]);
      setSelectedRange({ index: index, label: label });
      setDatePickerOpen(false);
      setShowcustomrange(true);
      console.log('ddd', [
        new Date(new Date().getFullYear(), 0, 1).toLocaleString('en-us', { timeZone: getProfile?.timezone }),
        addDays(new Date(), -1).toLocaleString('en-us', { timeZone: getProfile?.timezone })
      ]);
    } else if (sort_label === 'Lastyear') {
      setDateSelected([
        new Date(new Date().getFullYear() - 1, 0, 1).toLocaleString('en-us', { timeZone: getProfile?.timezone }),
        new Date(new Date().getFullYear(), 0, 0).toLocaleString('en-us', { timeZone: getProfile?.timezone })
      ]);
      setSelectedRange({ index: index, label: label });
      setDatePickerOpen(false);
      setShowcustomrange(false);
      console.log('ddd', [
        new Date(new Date().getFullYear() - 1, 0, 1).toLocaleString('en-us', { timeZone: getProfile?.timezone }),
        new Date(new Date().getFullYear(), 0, 0).toLocaleString('en-us', { timeZone: getProfile?.timezone })
      ]);
    } else if (sort_label === 'Custom') {
      console.log('Custom');
      setSelectedRange({ index: index, label: label });
      setDatePickerOpen(true);
    }
  };

  const GetSelectedData = (e) => {
    console.log('getSelectedData', e);
    setDateSelected(e);
    // setDatePickerOpen(false)
  };

  const GetDatePickerValue = ([item]) => {
    console.log('item', item.selection);
    if (item.selection) {
      setDefaultDateForPicker([
        { startDate: dateFormat(item.selection.startDate), endDate: dateFormat(item.selection.endDate), key: 'selection' }
      ]);
    } else if (item.range1) {
      setDefaultDateForPicker([
        { startDate: dateFormat(item.range1.startDate), endDate: dateFormat(item.range1.endDate), key: 'selection' }
      ]);
    }
  };

  const DateSumbit = () => {
    console.log('defalut', [defaultDateForPicker.map((data) => data.startDate), defaultDateForPicker.map((data) => data.endDate)]);
    setDateSelected([defaultDateForPicker.map((data) => data.startDate), defaultDateForPicker.map((data) => data.endDate)]);
    setShowdatepicker(false);
    setselecteddate(false);
    setShowcustomrange(false);
  };

  const TableTabChange = (index, url, parent_row) => {
    console.log('index---', index);
    if (parentIndex === index) {
      return;
    } else {
      setParentIndex(index);
      setSubTableRes([]);
      history.push(`/summary${window.location.href.indexOf('summary-new') > -1 ? '-new' : ''}${url}`);
    }
  };

  const updateReportColumns = () => {
    if (report_id === 'account') {
      console.log('COOKIES-ACC', cookies['account-report-columns']);
      if (!cookies['account-report-columns']) {
        setCookie('account-report-columns', DEFAULT_ACCOUNT_REPORT_COLUMNS);
        setColumnsData(DEFAULT_ACCOUNT_REPORT_COLUMNS);
        setReportColumns(DEFAULT_ACCOUNT_REPORT_COLUMNS);
      } else {
        setColumnsData(cookies['account-report-columns']);
        setReportColumns(cookies['account-report-columns']);
      }
    } else if (report_id !== 'account') {
      console.log('COOKIES-AMS', cookies['ams-report-columns']);
      if (!cookies['ams-report-columns']) {
        setCookie('ams-report-columns', DEFAULT_AMS_REPORT_COLUMNS);
        setColumnsData(DEFAULT_AMS_REPORT_COLUMNS);
        setReportColumns(DEFAULT_AMS_REPORT_COLUMNS);
      } else {
        setColumnsData(cookies['ams-report-columns']);
        setReportColumns(cookies['ams-report-columns']);
      }
    }
  };

  const saveColumnsToReport = () => {
    setReportColumns(JSON.parse(JSON.stringify(columnsData)));
    if (report_id === 'account') {
      setCookie('account-report-columns', columnsData);
    } else if (report_id !== 'account') {
      setCookie('ams-report-columns', columnsData);
    }
    setDynamicColumn(!dynamicColumn);
  };

  useEffect(() => {
    updateReportColumns();
  }, [report_id]);

  // const chartTypeFilter = (label) => {
  //   console.log("chartlabel", label)
  //   // ChartTypeSubmit(label)
  // }

  // const ChartTypeSubmit = () => {
  //   console.log("updateChartType-->", updateChartType)
  //   console.log("seriesUpdate", seriesUpdate)

  //   let getSeries = seriesUpdate.filter((data) => data.name === updateChartType.label)
  //   console.log("dashboardQuery", dashboardQuery)
  //   let seriesupdate = getSeries.map((data) => ({ name: data.name, type: updateChartType.type, data: data.data }))
  //   console.log("seriesupdate", seriesupdate)
  //   // setDashboardQuery(prev => ({...prev, series: seriesUpdate }))
  // }

  const makeFriendly = (num, label) => {
    if (num) return Number(num) >= 10 ? parseInt(num) + '%' : (Math.round(num * 100) / 100).toFixed(2) + '%';
    if (!num) return 0;
  };

  const getValue = (label, value) => {
    const formats = [
      { column_type: 'number', label: 'Spend', param: 'Spend', prefix_sign: 'currency' },
      { column_type: 'number', label: 'Revenue', param: 'Revenue', prefix_sign: 'currency' },
      { column_type: 'number', label: 'Orders', param: 'Orders' },
      { column_type: 'number', label: 'Impressions', param: 'Impressions' },
      { column_type: 'number', label: 'Clicks', param: 'Clicks' },
      { column_type: 'decimal', label: 'CPA', param: 'CPA', prefix_sign: 'currency' },
      { column_type: 'decimal', label: 'ROAS', param: 'ROAS' },
      { column_type: 'decimal', label: 'AOV', param: 'AOV', prefix_sign: 'currency' },
      { column_type: 'percentage', label: 'CTR', param: 'CTR' },
      { column_type: 'decimal', label: 'CPM', param: 'CPM' },
      { column_type: 'decimal', label: 'CPC', param: 'CPC' },
      { column_type: 'percentage', label: 'ACOS', param: 'ACOS', postFix_sign: 'percentage' },
      { column_type: 'number', label: 'RPC', param: 'RPC' },
      { column_type: 'number', label: 'CPO', param: 'CPO', prefix_sign: 'currency' },
      { column_type: 'percentage', label: 'Spend %', param: 'Spend_perc', postFix_sign: 'percentage' },
      { column_type: 'percentage', label: 'Revenue %', param: 'Revenue_perc', postFix_sign: 'percentage' },
      { column_type: 'percentage', label: 'CR %', param: 'CR_per', postFix_sign: 'percentage' },
      { column_type: 'number', label: 'CC', param: 'CC' },
      { column_type: 'percentage', label: 'Orders %', param: 'Orders_perc' }
    ];

    const intlFormat = (num) => {
      return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
    };

    const getFormat = formats.find((metric) => metric.label === label);
    if (getFormat) {
      const { column_type, prefix_sign } = getFormat;
      let sign = '';
      if (prefix_sign) sign = icons[getProfile?.currencyCode]?.symbol;

      if (!value) return 0;
      if (column_type === 'percentage')
        return Number(value) >= 10 ? parseInt(value) + '%' : (Math.round(value * 100) / 100).toFixed(2) + '%';
      if (column_type === 'decimal' && prefix_sign === 'currency')
        return value >= 10
          ? icons[getProfile?.currencyCode]?.symbol + Number(parseFloat(value)).toLocaleString('en-US')
          : icons[getProfile?.currencyCode]?.symbol + parseFloat(value);
      if (column_type === 'decimal')
        return value >= 10 ? Number(parseFloat(value)).toLocaleString('en-IN') : Number(value).toLocaleString('en-IN');
      if (column_type === 'number')
        // if (value >= 1000000000) return intlFormat(value / 1000000000) + 'B'; //intlFormat(value / 1000000000) + 'B';
        // if (value >= 1000000) return intlFormat(value / 1000000) + 'M'; //intlFormat(value / 1000000) + 'M';
        return sign + Number(value).toLocaleString('en-IN');
    }
  };

  const currencyIconFormat = (label) => {
    const formats = [
      { column_type: 'number', label: 'Spend', param: 'Spend', prefix_sign: 'currency' },
      { column_type: 'number', label: 'Revenue', param: 'Revenue', prefix_sign: 'currency' },
      { column_type: 'number', label: 'Orders', param: 'Orders' },
      { column_type: 'number', label: 'Impressions', param: 'Impressions' },
      { column_type: 'number', label: 'Clicks', param: 'Clicks' },
      { column_type: 'decimal', label: 'CPA', param: 'CPA', prefix_sign: 'currency' },
      { column_type: 'decimal', label: 'ROAS', param: 'ROAS' },
      { column_type: 'decimal', label: 'AOV', param: 'AOV', prefix_sign: 'currency' },
      { column_type: 'percentage', label: 'CTR', param: 'CTR' },
      { column_type: 'decimal', label: 'CPM', param: 'CPM', prefix_sign: 'currency' },
      { column_type: 'decimal', label: 'CPC', param: 'CPC' },
      { column_type: 'value', label: 'ACOS', param: 'ACOS' },
      { column_type: 'number', label: 'RPC', param: 'RPC' },
      { column_type: 'number', label: 'CPO', param: 'CPO', prefix_sign: 'currency' },
      { column_type: 'value', label: 'Spend %', param: 'Spend_perc' },
      { column_type: 'value', label: 'Rev%', param: 'Revenue_perc' },
      { column_type: 'value', label: 'CR %', param: 'CR_per' },
      { column_type: 'number', label: 'CC', param: 'CC' }
    ];

    const getFormat = formats.find((metric) => metric.label === label);
    if (getFormat) {
      const { prefix_sign } = getFormat;
      if (prefix_sign === 'currency') {
        let geticon = icons[getProfile.currencyCode];
        return geticon?.symbol + ' ';
      } else {
        return '';
      }
    }
  };

  const routeChange = () => {
    history.push('/addaccount');
  };

  const [typeOfMetric, setTypeOfMetric] = useState("")
  const [selectedSeries, setSelectedSeries] = useState([])
  const [noColor, setNoColor] = useState(0)

  if (
    !getProfile ||
    (getProfile && Object.keys(getProfile).length === 0) ||
    (getProfile && getProfile.seller.connected === false && report_id === 'account')
  ) {
    if (!getProfile || (getProfile && Object.keys(getProfile).length === 0)) {
      return <EmptyMessage message={'dashboard'} />;
    } else if (getProfile && getProfile.seller.connected === false && report_id === 'account') {
      return <EmptyMessage message={'accountSummary'} />;
    }
  }

  if(getProfile && getProfile.ams && getProfile.ams.connected === true && 'dataAvailable' in getProfile && getProfile.dataAvailable === false) {
      return <WaitingMessage dataNotAvailable={getProfile.dataAvailable === false} />
  }

  if(report_id !== 'account' && getProfile && 
    getProfile.ams && 
    getProfile.ams.connected === true && 
    'billing' in getProfile.ams && Array.isArray(getProfile.ams.billing) && getProfile.ams.billing.length > 0 && 'billingStartTime' in getProfile.ams.billing[0] && 
    getProfile.ams.billing[0].billingStartTime != ''
    ) {
      const apiTime = new Date(getProfile.ams.billing[0].billingStartTime)
      // .toLocaleString("en-IN", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
      const currentTime = new Date();
      const timeDifferenceHours = (currentTime - apiTime) / (1000 * 60 * 60);
      console.log("CALL_HOURS", {
        time: getProfile.ams.billing[0].billingStartTime,
        hour: timeDifferenceHours
      }) 
      if(timeDifferenceHours < 2) return <WaitingMessage />
  }

  if(report_id === 'account' && getProfile && 
    getProfile.seller && 
    getProfile.seller.connected === true && 
    'billing' in getProfile.seller && Array.isArray(getProfile.seller.billing) && getProfile.seller.billing.length > 0 && 'billingStartTime' in getProfile.seller.billing[0] && 
    getProfile.seller.billing[0].billingStartTime != ''
    ) {
      const apiTime = new Date(getProfile.seller.billing[0].billingStartTime)
      // .toLocaleString("en-IN", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
      const currentTime = new Date();
      const timeDifferenceHours = (currentTime - apiTime) / (1000 * 60 * 60);
      console.log("CALL_HOURS", {
        time: getProfile.seller.billing[0].billingStartTime,
        hour: timeDifferenceHours
      }) 
      if(timeDifferenceHours < 2) return <WaitingMessage from={"seller"} />
  }


  return (
    <div>
      {/* {activeProfile?.length > 0 &&
        <div> */}
      {report_id != 'account' && (
        <section className="position-relative">
          {/* INITIAL CARD SECTION */}
          <section className="metrics-row position-relative">
            <Row>
              {defaultMetrics.map((MarticsShow, index) => {
                return (
                  <Col md={2} key={'metrics_' + index} className="pr-0">
                    <CardBox
                      stackscard
                      arrowKey={MarticsShow.growth}
                      upcount={makeFriendly(MarticsShow.percentage)}
                      classes={colors[selectedMetrics?.indexOf(MarticsShow.label)]}
                      name={MarticsShow.label}
                      count={getValue(
                        MarticsShow.label,
                        metrics.length > 0 ? metrics.find((metricRow) => metricRow.label === MarticsShow.label).value : 0
                      )}
                      callback={() => IndexUpdate(MarticsShow)}
                      // closeCallBack={() => EditMetrics(MarticsShow.label)}
                      close={1}
                      metrics={metrics}
                      metricItem={metrics.length > 0 ? metrics.find((metricRow) => metricRow.label === MarticsShow.label) : {}}
                      selectedDropDownMetrics={selectedDropDownMetrics}
                      index={index}
                      defaultMetrics={defaultMetrics}
                    />
                  </Col>
                );
              })}

              {doMetricList.loading ? (
                <Col
                  sm={12}
                  className="d-flex align-items-center justify-content-center text-dark f-w-700 h-100 position-absolute z-2500 inset-0 chart-overlay text-dark"
                >
                  <Loader />
                  Loading...
                </Col>
              ) : (
                ''
              )}
              {!doMetricList.loading && defaultMetrics.length === 4 && (
                <Col md={1}>
                  <CardBox addmore>
                    <h5 className="f-17 f-w-500 collapse-btn">
                      <Link
                        to="#"
                        onClick={() => setAccordionKey(accordionKey !== 1 ? 1 : 0)}
                        aria-controls="accordion1"
                        aria-expanded={accordionKey === 1}
                        className="d-flex align-items-center metrics-collapse"
                      >
                        <p className={` ${accordionKey == 1 ? 'gg-chevron-down' : 'gg-chevron-right'}`} />
                      </Link>
                    </h5>

                    <Collapse in={accordionKey === 1}>
                      <div className="w-100 extd-crd themebg"></div>
                    </Collapse>
                  </CardBox>
                </Col>
              )}

              {/* <Collapse in={accordionKey === 1}>
                    <div className="connect-card"></div>
                  </Collapse> */}

              {/* {addmetric && <div className="connect-card"></div>} */}
            </Row>
          </section>
          {/* INITIAL CARD SECTION */}

          {/* GROUP CARD CONTAINER */}
          <Collapse in={accordionKey === 1}>
            <section>
              {doMetricChart.loading && (
                <div className="d-flex align-items-center justify-content-center chart-overlay p-3 position-absolute w-100 z-2500 inset-0 h-100 f-w-700 text-dark">
                  {' '}
                  <Loader /> Loading...
                </div>
              )}
              {/* CHART SECTION */}
              {selectedMetrics && selectedMetrics.length > 0 && (
                <section className="bg-white mb-4 border-right border-bottom border-left">
                  <Row className="hide">
                    <Col>
                      <MetricsChart 
                      typeOfMetric={typeOfMetric} 
                      selectedSeries={selectedSeries}
                      noColor={noColor}
                      options={dashboardQuery} selectedMetrics={selectedMetrics} />
                    </Col>
                  </Row>
                </section>
              )}
              {/* CHART SECTION */}
            </section>
          </Collapse>

          {/* GROUP CARD CONTAINER */}

          {/* {doMetricChart.loading && (
          <div className="d-flex align-items-center justify-content-center hei-650 chart-overlay position-absolute w-100 top-0 f-w-700">
            {' '}
            <img src={chartloader} alt="loader" className="mr-1 wid-30 hei-30" /> Loading...
          </div>
        )}
        {selectedMetrics && selectedMetrics.length > 0 && (
          <Row className="hide">
            <Col>
              <Card>
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-between">
                    <Card.Title as="h5">Metrics Chart</Card.Title>
                  </div>
                </Card.Header>
                <Card.Body>
                  <MetricsChart options={dashboardQuery} selectedMetrics={selectedMetrics} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )} */}
        </section>
      )}

      {/* ACCOUNT SUMMARY */}
      {/* {report_id == 'account' && <AccountCards />} */}
      {report_id == 'account' && <AccountSummary dateSelected={dateSelected} crossFilter={crossFilter} />}
      {/* ACCOUNT SUMMARY */}
      {/* TAB SECTION */}
      <section className="tree-tab px-3 mt-3">
        <Tab.Container defaultActiveKey="home">
          <Row>
            <Col
              sm={12}
              className={`bg-white mildshadow p-3 position-relative w-100 ${
                doMetricList.loading || doMetricChart.loading ? 'pe-none cursor-notallowed' : ''
              }`}
            >
              {/* FILTER TAB */}
              <section className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center main-btn themegray">
                  {tabsInfo.parent_tabs.map((parent_row, index) => {
                    return (
                      <div key={'tabs_' + index}>
                        <div
                          className={`p-2 position-relative rounded-50 cursor-pointer f-w-600 f-13 ${
                            index === parentIndex ? 'themeactive themetxt active-tag' : ' text-dark'
                          } mr-2`}
                          onClick={() => TableTabChange(index, parent_row.url_update, parent_row.title_aggregate)}
                        >
                          {parent_row.title}
                          {/* {index !== 0 && index === parentIndex && <img src={closeBlue} alt="close-tab" className="close-btn" />} */}
                        </div>
                      </div>
                    );
                  })}
                  {/* <RButton name="All" classes="btn-yellow txt-blue mr-3"/>
                    <RButton name="Sponsored Products" classes="btn-blue mr-3 text-light"/>
                    <RButton name="Sponsored Brands" classes="btn-blue mr-3 text-light"/>
                    <RButton name="Sponsored Displays" classes="btn-blue mr-3 text-light"/> */}
                </div>
                <section className="d-flex align-items-center position-relative">
                  {process.env.REACT_APP_GRAPH_MODE == 1 ? <Form>
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="custom-switch"
                      label="Graph Mode"
                      className="f-13 lab-fix mr-2"
                    />
                  </Form> : null}
                  {dynamicColumn ? <div className="cat-outclick" onClick={() => setDynamicColumn(!dynamicColumn)}></div> : null}
                  <div
                    className="themegray border d-flex align-items-center justify-content-center mr-2 rounded-50 h-100 p-10 cursor-pointer"
                    onClick={() => {
                      if (report_id === 'account') {
                        setColumnsData(cookies['account-report-columns']);
                      } else if (report_id !== 'account') {
                        setColumnsData(cookies['ams-report-columns']);
                      }
                      setDynamicColumn(!dynamicColumn);
                    }}
                  >
                    <Settings size={17} color="#969696" />
                  </div>
                  {dynamicColumn && (
                    <div className="setting-modal bg-white">
                      <div className={`d-flex align-items-center justify-content-end mb-3`}>
                        <Button
                          name="Close"
                          classes="secondary-btn mr-2 f-12 text-white px-3 py-1 rounded-50"
                          action={() => {
                            setDynamicColumn(!dynamicColumn);
                          }}
                          disabled={false}
                        />{' '}
                        &nbsp;
                        <Button
                          name="Apply"
                          classes="primary-btn mr-2 f-12 text-white px-3 py-1 rounded-50"
                          action={saveColumnsToReport}
                          disabled={columnsData.filter((row) => row.checked === true).length < 5}
                        />
                      </div>
                      <RLDD items={columnsData} itemRenderer={tableContent} onChange={handleRLDDChange} />
                    </div>
                  )}
                  <DatePicker
                    dateSelected={dateSelected}
                    defaultDateForPicker={defaultDateForPicker}
                    GetDatePickerValue={GetDatePickerValue}
                    closeDatepicker={closeDatepicker}
                    DateSumbit={DateSumbit}
                    formatDateTwo={formatDateTwo}
                  />
                </section>
              </section>
              {/* <p className="f-13 txt-theme mt-2 text-right f-w-600">Selected Date: <span className="text-dark selected-date">{formatDate(dateSelected[0])} (to) {formatDate(dateSelected[1])}</span> </p> */}
            </Col>

            {/* <Col sm={12} className="bg-white mildshadow p-3 position-relative w-100">
              <SearchBar />
            </Col> */}

            <Col sm={12} className={`px-4 bg-white ${doMetricList.loading || doMetricChart.loading ? 'pe-none cursor-notallowed' : ''}`}>
              {/* TABLE SECTION */}
              <section>
                {
                  // tabsInfo.parent_tabs[parentIndex] &&
                  //   tabsInfo.parent_tabs[parentIndex].is_group === 0 &&
                  //   tabsInfo.parent_tabs[parentIndex].prefer_tables &&
                  // tabsInfo.parent_tabs[parentIndex].prefer_tables.map((table_row, index) => {
                  subTableRes.concat(customTables).concat(report_id === 'advertised-product' ? asinCustomTables : []).map((table_row, index) => {
                    return (
                      <UsersTable
                        keyPrefix={`${table_row.table_id}-${index}`}
                        key={`${table_row.table_id}-${index}`}
                        title={table_row.table_name}
                        tableInfo={table_row}
                        columnsData={reportColumns}
                        flipkartNodeAPI={0}
                        fromHeatmap={tabsInfo.parent_tabs[parentIndex]?.heatMapEnabled === 1 ? 'heat_map' : ''}
                        selectedDate={dateSelected}
                        crossFilterFunction={CrossFilter}
                        crossFilter={crossFilter}
                        classes={`${table_row?.asinInfoCard === 1 ? 'asin_col' : ''}`}
                        // classes={`${table_row.table_name === 'Testing table' ? 'head-table' : ''} ${
                        //   table_row.table_name === 'Brand'
                        //     ? 'brand-table'
                        //     : table_row.table_name === 'Category'
                        //     ? 'brand-table'
                        //     : table_row.table_name === 'Sub Category'
                        //     ? 'brand-table'
                        //     : ''
                        // }`}
                      />
                    );
                  })}                
              </section>
              {/* TABLE SECTION */}
            </Col>
          </Row>
        </Tab.Container>
      </section>
      {/* TAB SECTION */}
    </div>
  );
}

export default Dashboard;
