import React from 'react';

const DownChevron = ({ up, ...rest }) => {
  return (
    <svg width="29" height="17" viewBox="0 0 29 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M2 2L14.5 14.5L27 2" stroke="currentColor" strokeWidth="3.75" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const UpChevron = ({ fillColor = 'white', ...rest }) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M0.827591 3.31034L6 8.48276L11.1724 3.31034"
        stroke={fillColor}
        strokeWidth="1.55172"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const RightChevron = ({ fillColor = 'black', ...rest }) => {
  return (
    <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M0.156988 0.930564L4.76799 5.61556C4.86912 5.71779 4.92584 5.85577 4.92584 5.99956C4.92584 6.14336 4.86912 6.28134 4.76799 6.38356L0.157988 11.0686C0.0569124 11.1714 0.000276071 11.3099 0.000276065 11.4541C0.000276059 11.5983 0.0569124 11.7367 0.157988 11.8396C0.207365 11.8902 0.266381 11.9305 0.331558 11.9579C0.396735 11.9854 0.466754 11.9996 0.537488 11.9996C0.608223 11.9996 0.678241 11.9854 0.743418 11.9579C0.808595 11.9305 0.867611 11.8902 0.916988 11.8396L5.52699 7.15556C5.82991 6.84704 5.99963 6.43194 5.99963 5.99956C5.99963 5.56719 5.82991 5.15209 5.52699 4.84356L0.916988 0.159564C0.867596 0.108762 0.808518 0.0683801 0.743249 0.0408053C0.677979 0.0132306 0.607843 -0.000976798 0.536988 -0.000976801C0.466133 -0.000976804 0.395998 0.0132306 0.330729 0.0408053C0.265459 0.0683801 0.206381 0.108762 0.156988 0.159564C0.0559125 0.26242 -0.000723856 0.400858 -0.000723863 0.545064C-0.000723869 0.689271 0.0559125 0.827709 0.156988 0.930564Z" fill={fillColor} fill-opacity="0.7" />
    </svg>
  );
};

export const UpChevronIcon = ({ ...rest }) => {
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" { ...rest }>
      <path d="M1.175 6L5 2.28841L8.825 6L10 4.85175L5 0L0 4.85175L1.175 6Z" fill="currentColor" fill-opacity="0.7" />
    </svg>
  )
};


export default DownChevron;
