import React, { createContext, useReducer, useContext } from 'react';

// Initial state for the context
const initialState = {
  planInfo: {},
}

// Define the reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_PLAN_INFO':
      return { ...state, planInfo: action.payload };
    default:
      return state;
  }
};

// Create the context
const BillingContext = createContext();

// Create a custom hook to use the context
const useBillingContext = () => {
  const context = useContext(BillingContext);
  if (!context) {
    throw new Error('useBillingContext must be used within a BillingProvider');
  }
  return context;
};

// Create the provider component
const BillingProvider = ({ children }) => {
  const [billingState, billingDispatch] = useReducer(reducer, initialState);

  return (
    <BillingContext.Provider value={{ billingState, billingDispatch }}>
      {children}
    </BillingContext.Provider>
  );
};

export { BillingProvider, useBillingContext };