import React, {useContext} from 'react'
import { addDays, startOfWeek, subDays, endOfDay, startOfDay, startOfMonth, endOfWeek, endOfMonth, addMonths } from 'date-fns'
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import { Popover, Whisper, Button, Toggle } from 'rsuite';
import Calender from 'src/assets/images/common/calender.svg'
import CalenderLight from 'src/assets/images/common/calender-white.svg'
import { ThemeContext } from "src/providers/themeProvider";
import { format } from 'date-fns';
const DatePickerPopover = React.forwardRef(({defaultDateForPicker, GetDatePickerValue, closeDatepicker, DateSumbit, closePicker, ...props }, ref) => {
  console.log("defaultDateForPicker===", props)
    return (
      <Popover ref={ref} {...props}>
        <div className="">
                    <DateRangePicker
                      onChange={item => GetDatePickerValue([item])}
                      moveRangeOnFirstSelection={false}
                      editableDateInputs={true}
                      months={2}
                      ranges={defaultDateForPicker}
                      direction="horizontal"
                      maxDate={addDays(new Date(), -1)}
                      staticRanges={createStaticRanges([
                        // {
                        //   label: 'Today',
                        //   range: () => ({
                        //     startDate: addDays(new Date(), -1),
                        //     endDate: addDays(new Date(), -1),
                        //   }),
                        // },
                        {
                          label: 'Yesterday',
                          range: () => ({
                            startDate: addDays(new Date(), -1),
                            endDate: addDays(new Date(), -1),
                          }),
                        },
                        {
                          label: 'This Week',
                          range: () => ({
                            startDate: startOfWeek(new Date()),
                            endDate: addDays(new Date(), -1),
                          }),
                        },
                        {
                          label: 'Last 7 Days',
                          range: () => ({
                            startDate: subDays(new Date(), 7, -1),
                            endDate: addDays(new Date(), -1),
                          }),
                        },
                        {
                          label: 'Last 30 Days',
                          range: () => ({
                            startDate: subDays(new Date(), 30, -1),
                            endDate: addDays(new Date(), -1),
                          }),
                        },
                        {
                          label: 'This Month',
                          range: () => ({
                            startDate: startOfMonth(new Date()),
                            endDate: addDays(new Date(), -1),
                          }),
                        },
                        {
                          label: 'Last Month',
                          range: () => ({
                            startDate: startOfMonth(addMonths(new Date(), -1)),
                            endDate: endOfMonth(addMonths(new Date(), -1))
                          }),
                        },
                        {
                          label: 'This year',
                          range: () => ({
                            startDate: new Date(new Date().getFullYear(), 0, 1),
                            endDate: addDays(new Date(), -1),
                          }),
                        },
                        {
                          label: 'Last year',
                          range: () => ({
                            startDate: new Date(new Date().getFullYear() - 1, 0, 1),
                            endDate: new Date(new Date().getFullYear(), 0, 0),
                          }),
                        },
                      ])}
                    // inputRanges={[]}
                    />
                    <div className="dat-pic-close bg-white">
                      <p className="secondary-btn text-light f-13 py-1 px-3 mr-2 rounded-8 cursor-pointer" onClick={() => {closeDatepicker(); closePicker()}}>Close</p>
                      <p className="primary-btn text-light f-13 py-1 px-3 rounded-8 m-0 cursor-pointer" onClick={() => {DateSumbit(); closePicker()}} >Submit</p>
                    </div>
                  </div>
      </Popover>
    );
  });

  const DatePickerComponent = ({ placement, loading, children, dateSelected, defaultDateForPicker, GetDatePickerValue, closeDatepicker, DateSumbit, formatDateTwo, closePicker }) => {
    const [theme] = useContext(ThemeContext)
    const triggerRef = React.useRef();
    const closeDate = () => triggerRef.current.close();

    return(
    <Whisper
      trigger="click"
      ref={triggerRef}
      placement={placement}
      controlId={`control-id-${placement}`}
      speaker={
        loading ? (
          <div>Loading</div>
        ) : (
          <DatePickerPopover defaultDateForPicker={defaultDateForPicker} GetDatePickerValue={GetDatePickerValue} closeDatepicker={closeDatepicker} DateSumbit={DateSumbit} closePicker={closeDate} />
        )
      }
    >
      <div className="main-btn le-20 f-14 px-3 py-2 text-dark f-w-600 cursor-pointer themegray">
        <img src={theme.theme === 'DARK' ? CalenderLight : Calender} alt="calender" className="cal-icn mr-3" />
        <span>{format(new Date(dateSelected[0]), 'dd MMM, yyyy') } - {format(new Date(dateSelected[1]), 'dd MMM, yyyy')}</span>
      </div>
    </Whisper>
    )
}

export default function DatePicker({dateSelected, defaultDateForPicker, GetDatePickerValue, closeDatepicker, DateSumbit, formatDateTwo}) {
    console.log("dateSelected", dateSelected)
    return (
            <DatePickerComponent placement="autoVerticalEnd" dateSelected={dateSelected} defaultDateForPicker={defaultDateForPicker} GetDatePickerValue={GetDatePickerValue} closeDatepicker={closeDatepicker} DateSumbit={DateSumbit} formatDateTwo={formatDateTwo} />
  )
}