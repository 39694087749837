import React, { useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useAmzContext } from 'src/contexts/AmzContext';

export default function DspAccount({ list = [], level, selectedItems = [], updateSelectedItems }) {
  const { amzState } = useAmzContext();
  const selectedProfile = JSON.parse(localStorage.getItem('selectedProfileId'));
  const selectedProfileData = amzState?.amazonProfiles?.filter((profile) => profile?.profileId == selectedProfile?.profileId);
  const [selectedList, setSelectedList] = useState('');
  useEffect(() => {
    setSelectedList(selectedItems?.find((item) => item.level == level)?.keywords || '');
  }, [selectedItems]);

  const handleSelect = (value) => {
    const updatedSelectedItems = [...selectedItems];
    const levelIndex = updatedSelectedItems.findIndex(item => item.level === level);
    updatedSelectedItems[levelIndex].keywords = value
    setSelectedList(value)
    updateSelectedItems(updatedSelectedItems)
  }

  return (
    <React.Fragment>
      <h2 className="f-12 f-w-500 mb-2 f-101828">Ad Accounts</h2>
      <RadioGroup row value={selectedList} onChange={(e) => handleSelect(e.target.value)}>
        <ul className="global-filter-tabs-list-items">
          {list?.map((adAccount, index) => {
            const filterData = selectedProfileData?.length > 0 && selectedProfileData[0]?.dsp?.find((item) => item?.dspId === adAccount?.value);
            return (
              <li key={adAccount?.value} className="d-flex align-items-center">
                <div className="d-flex align-items-center gap-10 ml-3">
                  <FormControlLabel
                    value={adAccount?.value}
                    control={<Radio />}
                    label={
                      <div className="d-flex flex-column ml-3">
                        <h2 className="f-12 f-w-500 d-flex align-items-center gap-4 f-101828">{adAccount.label} <span style={{opacity: 0.5}}>{filterData?.amazonManaged === "No" && `(Neon)`}</span></h2>
                        <span className="f-12 f-w-500 f-10182880 l-20 pt-2">{adAccount.value}</span>
                      </div>
                    }
                  />
                </div>
              </li>
            );
          })}
          {list?.length == 0 ? (
            <li className="text-dark f-10 f-w-500 hei-70 d-flex align-items-center justify-content-center no-wrap">
              No Vendor Account found
            </li>
          ) : null}
        </ul>
      </RadioGroup>
    </React.Fragment>
  );
}
