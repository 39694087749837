import React, { createContext, useReducer, useContext } from 'react';

// Initial state for the context
const initialState = {
    dropdown_filters: {
      asin_filter: [],
      product_filter: [],
    },
    applied_dropdown_filters: {
      asin_filter: [],
      product_filter: [],
    },
};

// Define the reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_DROPDOWN_FILTERS':
      return { ...state, dropdown_filters: {
        ...state.dropdown_filters,
        ...action.payload
      } };  
    case 'APPLY_DROPDOWN_FILTERS':
      return { ...state, applied_dropdown_filters: {
        ...state.applied_dropdown_filters,
        ...action.payload
      } }; 

    default:
      return state;
  }
};

// Create the context
const SnapShotContext = createContext();

// Create a custom hook to use the context
const useSnapShotContext = () => {
  const context = useContext(SnapShotContext);
  if (!context) {
    throw new Error('useSnapShotContext must be used within a SnapShotProvider');
  }
  return context;
};

// Create the provider component
const SnapShotProvider = ({ children }) => {
  const [snapShotState, snapShotDispatch] = useReducer(reducer, initialState);

  return <SnapShotContext.Provider value={{ snapShotState, snapShotDispatch }}>{children}</SnapShotContext.Provider>;
};

export { SnapShotProvider, useSnapShotContext };
