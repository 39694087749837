import React from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

const RadioButton = ({ id, radioname, title, checked, classes, action, value, radioSize, fontSize, marginLeft, fontWeight }) => {
  return (
    // <label htmlFor={id} title={title} className={`d-flex align-items-center no-wrap cursor-pointer ${classes}`}>
    //   <input type="radio" value={value} id={id} name={radioname} checked={checked} onChange={action ? action : () => { }} />
    //     {title}
    // </label>
    <div className={`custom_radio_group ${classes}`}>
      <FormControlLabel
        id={id}
        value={id}
        control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: radioSize ? radioSize : '20px' } }} />}
        label={title}
        checked={checked}
        size="small"
        onChange={action ? action : () => { }}
        sx={{ 
          '& .MuiFormControlLabel-label': { 
            fontSize: fontSize ? fontSize : '13px',
            fontWeight: fontWeight? fontWeight : '400',
            whiteSpace: 'nowrap',
            marginLeft: marginLeft? marginLeft : '5px',
            marginRight: '0px'
          }
        }}
      />
    </div>
  );
};

export default RadioButton;
