import { useEffect, useRef, useState } from "react";

const SearchableDropdown = ({
  options,
  label,
  id,
  selectedVal,
  handleChange,
  placeholder,
  disabled,
  classes
}) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef(null);

  // const [defaultValue, setDefaultValue] = useState(placeholder)

  // useEffect(() => {
  //   setDefaultValue(placeholder)
  // }, [placeholder]) 

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  const selectOption = (option) => {
    setQuery("");
    handleChange(option[label]);
    setIsOpen((isOpen) => !isOpen);
  };

  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current);
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (selectedVal) return selectedVal;

    return "";
  };

  const filter = (options) => {
    // if (options?.length) {
      console.log("filterOptions", {options, label, query})
      return options.filter(
        (option) => option[label].toLowerCase().indexOf(query.toLowerCase()) > -1
      );
    // }
  };

  return (
    <div className={`dropdown ${classes}`}>
      <div className="control">
        <div className="selected-value">
          <input
            ref={inputRef}
            type="text"
            value={getDisplayValue()}
            placeholder= {placeholder}
            disabled={disabled}
            // name="searchTerm"
            onChange={(e) => {
              setQuery(e.target.value);
              handleChange(null);
            }}
            onClick={toggle}
          />
        </div>
        <div className={`arrow ${isOpen ? "open" : ""}`}></div>
      </div>
      {/* {options.length && */}
        <div className={`options ${isOpen ? "open" : ""}`}>
          {
            filter(options).map((option, index) => {
              return (
                <div
                  onClick={() => selectOption(option)}
                  className={`option ${option[label] === selectedVal ? "selected" : ""
                    }`}
                  key={`${id}-${index}`}
                >
                  {option[label]}
                </div>
              );
            })
          }
        </div>
      {/* } */}
    </div>
  );
};

export default SearchableDropdown;
