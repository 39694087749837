import React, { useEffect, useState } from 'react'
import CheckBox from 'src/components/Form/checkbox';

export default function AdAccount({list = [], level, selectedItems = [], updateSelectedItems}) {
  const [selectedList, setSelectedList] = useState(selectedItems?.find((item) => item.level == level)?.keywords || [])
  
  useEffect(() => {
    setSelectedList(selectedItems?.find((item) => item.level == level)?.keywords || [])
  }, [selectedItems])

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    updateSelectedItems(event, level, 'selectAll');
  };

  const handleIndividualSelect = (event, adAccount) => {
    updateSelectedItems(event, level, adAccount);
  };

  return (
    <React.Fragment>
      <h2 className='f-12 f-w-500 mb-2 f-101828'>Ad Accounts</h2>
      
      {list?.length > 0 ? (
        <div className='mt-3' style={{paddingLeft: '2px'}}>
          <CheckBox
            id="select_all_adaccount"
            label="Select All"
            classes="f-12 f-w-500 check-lg f-101828"
            checked={selectedList.length === list.length}
            action={handleSelectAll}
          />
        </div>
      ) : null}
      
      <ul className='global-filter-tabs-list-items'>
        {list?.map((adAccount, index) => {
          return (
            <li key={adAccount?.value} className='d-flex align-items-center'>
              <div className='d-flex align-items-center gap-10'>
                <CheckBox
                  id={adAccount.value}
                  classes="check-lg"
                  checked={selectedList?.indexOf(adAccount.value) > -1}
                  action={(event) => handleIndividualSelect(event, adAccount)}
                />
                <div className='d-flex flex-column'>
                  <h2 className='f-12 f-w-500 d-flex align-items-center gap-4 f-101828'>
                    {adAccount.label}
                  </h2>
                  <span className='f-12 f-w-500 f-10182880 l-20 pt-2'>{adAccount.value}</span>
                </div>
              </div>
            </li>
          )
        })}
        {list?.length == 0 ? <li className="text-dark f-10 f-w-500 hei-70 d-flex align-items-center justify-content-center no-wrap">No Ad Account found</li> : null}
      </ul>
    </React.Fragment>
  )
}