// Tsales vs AdSales vs OSales | TOrders vs AdOrders vs Oorders
// TACOS vs ACOS | Spend vs AdSales
// TCPA vs AdCPA | CR% vs AdCR%

const BRAND_TAB_GROUP = {
    "details": [
        {
            "title": "Tsales vs AdSales vs OSales | TOrders vs AdOrders vs Oorders",
            "id": 1,
            "charts": [
                {
                    "id": `${["Tsales", "Ad sales", "O Sales"].join('')}`,
                    "value": 1,
                    "endpoint": "/account-summary-charts",
                    "payload": {
                        "metrics": [
                            "TSales",
                            "AdSales",
                            "OSales"
                        ]                
                    },
                    "logScale": 0,
                    "chart_type": {
                        "chart": {
                            "type": 'line',
                        }
                    },
                    "colors": [
                        '#22B1C2',
                        '#8EC63F',
                        '#F07276',
                    ],
                    "categories": [],
                    "series": []
                },
                {
                    "id": `${["TOrders", "Ad Orders", "O Orders"].join('')}`,
                    "value": 2,
                    "endpoint": "/account-summary-charts",
                    "payload": {
                        "metrics": [
                            "TOrders",
                            "AdOrders",
                            "Oorders"
                        ]
                    },
                    "logScale": 0,
                    "chart_type": {
                        "chart": {
                            "type": 'line',
                        }
                    },
                    "colors": [
                        '#942B70',
                        '#D7BE60',
                        '#F37725'
                    ],
                    "categories": [],
                    "series": []
                },
            ]
        },
        {
            "title": "TCPA vs AdCPA | CR% vs AdCR%",
            "id": 2,
            "charts": [
                {
                    "id": `${["TCPA", "AdCPA"].join('')}`,
                    "value": 1,
                    "endpoint": "/account-summary-charts",
                    "payload": {
                        "metrics": [
                            "TCPA",
                            "AdCPA"
                        ]                                   
                    },
                    "logScale": 0,
                    "chart_type": {
                        "chart": {
                            "type": 'line',
                        }
                    },
                    "colors": [
                        '#22B1C2',
                        '#8EC63F',
                        '#F07276',
                    ],
                    "categories": [],
                    "series": []
                },
                {
                    "id": `${["CR%", "AdCR%"].join('')}`,
                    "value": 2,
                    "percentage": 1,
                    "endpoint": "/account-summary-charts",
                    "payload": {
                        "metrics": [
                            "CR%",
                            "AdCR%"
                        ]
                    },
                    "logScale": 0,
                    "chart_type": {
                        "chart": {
                            "type": 'line',
                        }
                    },
                    "colors": [
                        '#942B70',
                        '#D7BE60',
                        '#F37725'
                    ],
                    "categories": [],
                    "series": []
                },
            ]
        },
        {
            "title": "TACOS vs ACOS | Spend vs AdSales",
            "id": 3,
            "charts": [
                {
                    "id": `${["TACOS", "ACOS"].join('')}`,
                    "value": 1,
                    "endpoint": "/account-summary-charts",
                    "payload": {
                        "metrics": [
                            "TACOS",
                            "ACOS"
                        ]                                      
                    },
                    "logScale": 0,
                    "chart_type": {
                        "chart": {
                            "type": 'line',
                        }
                    },
                    "colors": [
                        '#22B1C2',
                        '#8EC63F',
                        '#F07276',
                    ],
                    "categories": [],
                    "series": []
                },
                {
                    "id": `${["Spend", "AdSales"].join('')}`,
                    "value": 2,
                    "endpoint": "/account-summary-charts",
                    "payload": {
                        "metrics": [
                            "Spend",
                            "AdSales"
                        ]
                    },
                    "logScale": 0,
                    "chart_type": {
                        "chart": {
                            "type": 'line',
                        }
                    },
                    "colors": [
                        '#942B70',
                        '#D7BE60',
                        '#F37725'
                    ],

                    "categories": [],
                    "series": []
                },
            ]
        },
    ],
}

export default BRAND_TAB_GROUP