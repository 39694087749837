export const validateConfig = (config) => {
    // Helper function to check if a string is non-empty
    const isNonEmptyString = (str) => typeof str === 'string' && str.trim() !== '';

    // Helper function to check if an array is non-empty
    const isNonEmptyArray = (arr) => Array.isArray(arr) && arr.length > 0;

    // Helper function to validate objects within arrays
    const validateArrayObjects = (arr, props) => {
        return arr.every(item => {
            return props.every(prop => isNonEmptyString(item[prop]));
        });
    };

    // Check string properties
    const stringProps = ['audienceName', 'audienceDescription', 'start_date', 'end_date'];
    for (let prop of stringProps) {
        if (!isNonEmptyString(config[prop])) {
            return false;
        }
    }

    // Check configuration array
    if (!isNonEmptyArray(config.configuration) || !validateArrayObjects(config.configuration, ['audience', 'condition', 'value'])) {
        return false;
    }

    // Check other arrays
    const arrayProps = ['st_include_matchType', 'st_include', 'st_exclude_matchType', 'st_exclude'];
    for (let prop of arrayProps) {
        if (!isNonEmptyArray(config[prop])) {
            return false;
        }
    }

    // If all checks pass
    return true;
}

export const AUDIENCE_CONFIG_ITEMS = ['Impressions', 'Clicks', 'Add to Cart', 'Purchase']

export const ALLOWED_PROFILE = ['1288029722961222', '1759501029452459', '636275261266501', '588132471052651', '301885780703978', '590024133047433', '590024133047433', '1288029722961222',
  '1759501029452459',
  '636275261266501',
  '588132471052651',
  '16200505713475300',
  '301885780703978',
  '3503292530088021',
  '2463926349133293',
  '798748144904056',
  '3185239956696633',
  '3807295342610054',
  '671016979642807',
  '2756532615668855',
  '3529049338140207',
  '4493177189649733',
  '2731425524310874',
  '2581344036288579',
  '2817727814705709',
  '1196065942299818',
  '3262472003001896',
  '2833452175165141',
  '226003183061995'
]

export const CHATGPT_PROFILES = [
    // '1614527843836050',
    // '1754428400844006',
    // '3807295342610054',
    // '1288029722961222',
    // '2351879915712439',
    // '981848979107390',
    // '636275261266501'
]


export const DropdownsList = [
    {
      title: 'Type of Audience',
      apiInfo: {
        url: '',
        method: 'post',
        payload: {}
      },
      type: 'dropdown',
      storeParam: 'audience_type',
    },
    {
        title: 'User',
        apiInfo: {
          url: '',
          method: 'post',
          payload: {}
        },
        type: 'dropdown',
        storeParam: 'user',
      },
      {
        title: 'Updated Audience',
        apiInfo: {
          url: '',
          method: 'post',
          payload: {}
        },
        type: 'dropdown',
        storeParam: 'updated_audience',
      },
      {
        title: 'Status',
        apiInfo: {
          url: '',
          method: 'post',
          payload: {}
        },
        type: 'dropdown',
        storeParam: 'status',
      },
  ]

  export const AdvertiserList = [
    { 
      label: "IN - SF - Campus Shoe", 
      value: "in-sf-campusshoe"  
    },
    { 
      label: "Campus Shoes", 
      value: "campus-shoes"  
    }
  ];
  

  export const AudienceType = [
    { id: 'most-similar', value: 'mostSimilar', title: 'Most Similar', info: 'Audience size will be between <b>300K - 1M</b> depending on relevance and audience geography' },
    { id: 'similar', value: 'similar', title: 'Similar', info: 'Audience size will be between 450K - 3M depending on relevance and audience geography and will include Most Similar lookalike population ' },
    { id: 'balanced', value: 'balanced', title: 'Balanced', info: 'Audience size will be between 300K - 1M depending on relevance and audience geography' },
    { id: 'broad', value: 'broad', title: 'Broad', info: 'Audience size will be between 450K - 3M depending on relevance and audience geography and will include Most Similar lookalike population ' },
    { id: 'most-broad', value: 'mostBroad', title: 'Most Broad', info: 'Audience size will be between 300K - 1M depending on relevance and audience geography' }
];
