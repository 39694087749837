import { isSameDay } from "date-fns";
import { getRandomAsin } from "src/api/demo";
import AwarenessIcon from "src/assets/icons/AwarenessIcon";
import ConsiderationIcon from "src/assets/icons/ConsiderationIcon";
import ConversionsIcon from "src/assets/icons/ConversionsIcon";

export const isAmazon = window.location.href.indexOf('/amazon') > -1;

export const isFlipkart = window.location.href.indexOf('/flipkart') > -1;

const selectedProfileId = isAmazon
  ? JSON.parse(localStorage.getItem('selectedProfileId'))
  : isFlipkart
  ? JSON.parse(localStorage.getItem('flipkartProfile'))
  : {};

export const isAllowAdminOrManager = () => {
  if (!isAmazon) return false;
  return selectedProfileId?.accessLevel?.toLowerCase() === 'admin' || selectedProfileId?.accessLevel?.toLowerCase() === 'manager';
};

export const yetToOnboarded = () => {
  let selectedProfileId = isAmazon
    ? JSON.parse(localStorage.getItem('selectedProfileId'))
    : isFlipkart
    ? JSON.parse(localStorage.getItem('flipkartProfile'))
    : {};
  return (selectedProfileId && Object.keys(selectedProfileId).length === 0) || !selectedProfileId;
};

export const isAllowEdit = selectedProfileId?.accessLevel?.toLowerCase() == 'admin';

export const BILLING_LIMIT_EXCEED_MESSAGE_FOR_USER = `As per the #planName# plan you have exceeded the user limit, so we request you to upgrade the access limits to continue this operation`;

export const BILLING_LIMIT_EXCEED_MESSAGE_FOR_AD_ACCOUNT = `As per the #planName# plan you have exceeded the ad account limit, so we request you to upgrade the access limits to continue this operation`;

function formatNumberWithMaxDecimals(number) {
  const numberString = String(number);

  if (numberString && numberString.includes('.')) {
    const [integerPart, decimalPart] = numberString.split('.');
    const formattedDecimalPart = decimalPart.length > 3 ? decimalPart.slice(0, 3) : decimalPart;
    const formattedNumber = `${integerPart}.${formattedDecimalPart}`;
    return parseFloat(formattedNumber);
  }

  // If the number has no decimal point, return it as is
  return number;
}

export const getCalcValue = (cValue, typeOfBulkUpdate) => {
  const currentValue = Number(cValue);

  if (typeOfBulkUpdate.type === 'set_inc_bid_amount') {
    const calc = Number(currentValue) + typeOfBulkUpdate.value;
    return formatNumberWithMaxDecimals(calc);
  } else if (typeOfBulkUpdate.type === 'set_dec_bid_amount') {
    const calc = Number(currentValue) - typeOfBulkUpdate.value;
    return formatNumberWithMaxDecimals(calc);
  } else if (typeOfBulkUpdate.type === 'set_bid_or_budget') {
    return typeOfBulkUpdate.value;
  } else if (typeOfBulkUpdate.type === 'set_inc_bid_or_budget_perc') {
    const decimalPercentage = typeOfBulkUpdate.value / 100;
    const increaseAmount = Number(currentValue) * decimalPercentage;
    return formatNumberWithMaxDecimals(Number(currentValue) + increaseAmount);
  } else if (typeOfBulkUpdate.type === 'set_dec_bid_or_budget_perc') {
    const decimalPercentage = typeOfBulkUpdate.value / 100;
    const decreaseAmount = Number(currentValue) * decimalPercentage;
    return formatNumberWithMaxDecimals(Number(currentValue) - decreaseAmount);
  }
};

export const getProceedStatus = (cValue, typeOfBulkUpdate) => {
  const currentValue = Number(cValue);
  
  let updateValue = ''

  if (typeOfBulkUpdate.type === 'set_inc_bid_amount') {
    const calc = Number(currentValue) + typeOfBulkUpdate.value;
    updateValue = formatNumberWithMaxDecimals(calc);
  } else if (typeOfBulkUpdate.type === 'set_dec_bid_amount') {
    const calc = Number(currentValue) - typeOfBulkUpdate.value;
    updateValue = formatNumberWithMaxDecimals(calc);
  } else if (typeOfBulkUpdate.type === 'set_bid_or_budget') {
    updateValue = typeOfBulkUpdate.value;
  } else if (typeOfBulkUpdate.type === 'set_inc_bid_or_budget_perc') {
    const decimalPercentage = typeOfBulkUpdate.value / 100;
    const increaseAmount = Number(currentValue) * decimalPercentage;
    updateValue = formatNumberWithMaxDecimals(Number(currentValue) + increaseAmount);
  } else if (typeOfBulkUpdate.type === 'set_dec_bid_or_budget_perc') {
    const decimalPercentage = typeOfBulkUpdate.value / 100;
    const decreaseAmount = Number(currentValue) * decimalPercentage;
    updateValue = formatNumberWithMaxDecimals(Number(currentValue) - decreaseAmount);
  }

  const updateType = String(typeOfBulkUpdate?.label).toLowerCase()?.indexOf('set') > -1 ? 'set' : String(typeOfBulkUpdate?.label).toLowerCase()?.indexOf('inc') > -1 ? 'inc' : String(typeOfBulkUpdate?.label).toLowerCase()?.indexOf('dec') > -1 ? 'dec' : null

  const proceedStatus = updateType == 'set' ? (updateValue >= typeOfBulkUpdate.floor && updateValue <= typeOfBulkUpdate.cap) : updateType == 'inc' ? updateValue <= typeOfBulkUpdate.cap : updateType == 'dec' ? updateValue >= typeOfBulkUpdate.floor : false

  console.log("proceedStatus", {cValue, updateType, typeOfBulkUpdate, proceedStatus})
  
  return proceedStatus

  
};



export const getProductLink = (country, productId) => {
  let amazonUrl = 'https://www.amazon.com'; // Default to the US Amazon URL

  let newProductId = productId
  
  const authUser = JSON.parse(localStorage.getItem('User'))
  if(process.env.REACT_APP_DEMO_EMAIL && process.env.REACT_APP_DEMO_EMAIL.indexOf(authUser?.email) > -1) {
    newProductId = getRandomAsin()
  }

  // Check the country code and update the URL accordingly
  if (country === 'CA') {
    amazonUrl = 'https://www.amazon.ca';
  } else if (country === 'UK') {
    amazonUrl = 'https://www.amazon.co.uk';
  } else if (country === 'IN') {
    amazonUrl = 'https://www.amazon.in';
  } else if (country === 'US') {
    amazonUrl = 'https://www.amazon.com';
  } else if (country === 'BR') {
    amazonUrl = 'https://www.amazon.com.br';
  } else if (country === 'MX') {
    amazonUrl = 'https://www.amazon.com.mx';
  } else if (country === 'ES') {
    amazonUrl = 'https://www.amazon.com.br';
  } else if (country === 'FR') {
    amazonUrl = 'https://www.amazon.com.br';
  } else if (country === 'NL') {
    amazonUrl = 'https://www.amazon.nl';
  } else if (country === 'DE') {
    amazonUrl = 'https://www.amazon.com.br';
  } else if (country === 'IT') {
    amazonUrl = 'https://www.amazon.com.br';
  } else if (country === 'SE') {
    amazonUrl = 'https://www.amazon.se';
  } else if (country === 'PL') {
    amazonUrl = 'https://www.amazon.pl';
  } else if (country === 'EG') {
    amazonUrl = 'https://www.amazon.eg';
  } else if (country === 'TR') {
    amazonUrl = 'https://www.amazon.com.tr';
  } else if (country === 'SA') {
    amazonUrl = 'https://www.amazon.sa';
  } else if (country === 'AE') {
    amazonUrl = 'https://www.amazon.ae';
  } else if (country === 'SG') {
    amazonUrl = 'https://www.amazon.sg';
  } else if (country === 'AU') {
    amazonUrl = 'https://www.amazon.com.au';
  } else if (country === 'JP') {
    amazonUrl = 'https://www.amazon.co.jp';
  }

  // Append the product ID to the URL
  amazonUrl += '/dp/' + String(newProductId).toUpperCase();
  return amazonUrl;
}

export const getSearchKeywordLink = (country, productId) => {
  let amazonUrl = 'https://www.amazon.com'; // Default to the US Amazon URL

  // Check the country code and update the URL accordingly
  if (country === 'CA') {
    amazonUrl = 'https://www.amazon.ca';
  } else if (country === 'UK') {
    amazonUrl = 'https://www.amazon.co.uk';
  } else if (country === 'IN') {
    amazonUrl = 'https://www.amazon.in';
  } else if (country === 'US') {
    amazonUrl = 'https://www.amazon.com';
  } else if (country === 'BR') {
    amazonUrl = 'https://www.amazon.com.br';
  } else if (country === 'MX') {
    amazonUrl = 'https://www.amazon.com.mx';
  } else if (country === 'ES') {
    amazonUrl = 'https://www.amazon.com.br';
  } else if (country === 'FR') {
    amazonUrl = 'https://www.amazon.com.br';
  } else if (country === 'NL') {
    amazonUrl = 'https://www.amazon.nl';
  } else if (country === 'DE') {
    amazonUrl = 'https://www.amazon.com.br';
  } else if (country === 'IT') {
    amazonUrl = 'https://www.amazon.com.br';
  } else if (country === 'SE') {
    amazonUrl = 'https://www.amazon.se';
  } else if (country === 'PL') {
    amazonUrl = 'https://www.amazon.pl';
  } else if (country === 'EG') {
    amazonUrl = 'https://www.amazon.eg';
  } else if (country === 'TR') {
    amazonUrl = 'https://www.amazon.com.tr';
  } else if (country === 'SA') {
    amazonUrl = 'https://www.amazon.sa';
  } else if (country === 'AE') {
    amazonUrl = 'https://www.amazon.ae';
  } else if (country === 'SG') {
    amazonUrl = 'https://www.amazon.sg';
  } else if (country === 'AU') {
    amazonUrl = 'https://www.amazon.com.au';
  } else if (country === 'JP') {
    amazonUrl = 'https://www.amazon.co.jp';
  }

  // Append the product ID to the URL
  amazonUrl += '/s?k=' + productId;
  return amazonUrl;
}

export const isAsinOrExpandedAsin = (displayValue) => {
  const isAsin = String(displayValue).indexOf('asin="') > -1 || String(displayValue).indexOf('asin-expanded="') > -1
  return isAsin
}

export const allEmptyValues = (obj)  => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== '') {
      return false; // If any non-empty value is found, return false
    }
  }
  return true; // If no non-empty values are found, return true
}


export const filterNonEmptyValues = (obj) => {
  return Object.keys(obj).filter(key => obj[key] !== '').reduce((acc, key) => {
    acc[key] = obj[key];
    return acc;
  }, {});
}

export const validateEmail = (email) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export const TIMESPEND_FILTER = [
  {
    group_key: 'all',
    group_name: 'All'
  },
  {
    group_key: 'clients',
    group_name: 'Clients'
  },
  {
    group_key: 'internal',
    group_name: 'Internal Team'
  }
]

export const createProductUrl = (productId) => {
  const selectedProfileId = JSON.parse(localStorage.getItem('selectedProfileId'))
  const country = selectedProfileId?.countryCode;

  let amazonUrl = 'https://www.amazon.com'; // Default to the US Amazon URL

  // Check the country code and update the URL accordingly
  if (country === 'CA') {
    amazonUrl = 'https://www.amazon.ca';
  } else if (country === 'UK') {
    amazonUrl = 'https://www.amazon.com.uk';
  } else if (country === 'IN') {
    amazonUrl = 'https://www.amazon.in';
  } else if (country === 'US') {
    amazonUrl = 'https://www.amazon.com';
  } else if (country === 'BR') {
    amazonUrl = 'https://www.amazon.com.br';
  } else if (country === 'MX') {
    amazonUrl = 'https://www.amazon.com.mx';
  } else if (country === 'ES') {
    amazonUrl = 'https://www.amazon.com.br';
  } else if (country === 'FR') {
    amazonUrl = 'https://www.amazon.com.br';
  } else if (country === 'NL') {
    amazonUrl = 'https://www.amazon.nl';
  } else if (country === 'DE') {
    amazonUrl = 'https://www.amazon.com.br';
  } else if (country === 'IT') {
    amazonUrl = 'https://www.amazon.com.br';
  } else if (country === 'SE') {
    amazonUrl = 'https://www.amazon.se';
  } else if (country === 'PL') {
    amazonUrl = 'https://www.amazon.pl';
  } else if (country === 'EG') {
    amazonUrl = 'https://www.amazon.eg';
  } else if (country === 'TR') {
    amazonUrl = 'https://www.amazon.com.tr';
  } else if (country === 'SA') {
    amazonUrl = 'https://www.amazon.com.sa';
  } else if (country === 'AE') {
    amazonUrl = 'https://www.amazon.ae';
  } else if (country === 'SG') {
    amazonUrl = 'https://www.amazon.sg';
  } else if (country === 'AU') {
    amazonUrl = 'https://www.amazon.com.au';
  } else if (country === 'JP') {
    amazonUrl = 'https://www.amazon.co.jp';
  }

  // Append the product ID to the URL
  amazonUrl += '/dp/' + productId;
  return amazonUrl;
}
// 'SearchTerm',  - Accidentally not being used much or irrevant keywords/asins are negatived, targeted
export const BULK_SELECT_ALL_TABLES = ['CampaignName', 'Targeting', 'AdGroupName', 'AdvertisedASINs', 'SearchTerm']

export const BULK_SELECT_LABELS = {
  'SearchTerm' : 'search terms', 
  'CampaignName': 'campaigns', 
  'Targeting': 'targets', 
  'AdGroupName': 'ad groups',
  'AdvertisedASINs': 'products'
}

export const GOALS_AND_OPTIMIZATION = [
  {
    id: 1,
    icon: <AwarenessIcon />,
    title: "Awareness",
    subTitle: "Expose your brand or product to more potential customers",
    kpi: [
      {
        id: 1,
        title: "Reach",
        key: "REACH",
        subTitle: "The number of unique users exposed from campaign start",
        subComponent: <></>
      }
    ]
  },
  {
    id: 2,
    icon: <ConsiderationIcon />,
    title: "Consideration",
    subTitle: "Expose your brand or product to more potential customers",
    kpi: [
      {
        id: 1,
        title: "CTR",
        key: "CLICK_THROUGH_RATE",
        subTitle: "The number of click throughs relative to the number of impression",
        kpiTargetPrefix:"%",
        subComponent: <></>
      },
      {
        id: 2,
        title: "CPC",
        key: "COST_PER_CLICK",
        subTitle: "The average cost for each click on an ad",
        kpiTargetPrefix:"₹",
        subComponent: <></>
      },
      {
        id: 3,
        title: "Cost per video compilation",
        key: "COST_PER_VIDEO_COMPLETION",
        subTitle: "The number of click throughs relative to the number of impression",
        kpiTargetPrefix:"₹",
        subComponent: <></>
      },
      {
        id: 4,
        title: "Video Compilation Rate",
        key: "VIDEO_COMPLETION_RATE",
        subTitle: "The number of click throughs relative to the number of impression",
        kpiTargetPrefix:"%",
        subComponent: <></>
      },
      {
        id: 5,
        title: "Cost per detail page view",
        key: "COST_PER_DETAIL_PAGE_VIEW",
        subTitle: "The number of click throughs relative to the number of impression",
        kpiTargetPrefix:"₹",
        subComponent: <></>
      },
      {
        id: 6,
        title: "Detail page view rate",
        key: "DETAIL_PAGE_VIEW_RATE",
        subTitle: "The number of click throughs relative to the number of impression",
        kpiTargetPrefix:"%",
        subComponent: <></>
      },
    ]
  },
  {
    id: 3,
    icon: <ConversionsIcon />,
    title: "Conversions",
    subTitle: "Expose your brand or product to more potential customers",
    kpi: [
      {
        id: 1,
        title: "Return on ad spend",
        key: "RETURN_ON_AD_SPEND",
        subTitle: "The number of click throughs relative to the number of impression",
        kpiTargetPrefix:"",
        subComponent: <></>
      },
      {
        id: 2,
        title: "Total ROAS",
        key: "TOTAL_RETURN_ON_AD_SPEND",
        subTitle: "The average cost for each click on an ad",
        kpiTargetPrefix:"",
        subComponent: <></>
      },
      {
        id: 3,
        title: "Cost per action",
        key: "COST_PER_ACTION",
        subTitle: "The number of click throughs relative to the number of impression",
        kpiTargetPrefix:"₹",
        subComponent: <></>
      },
      {
        id: 4,
        title: "Combined ROAS",
        key: "COMBINED_RETURN_ON_AD_SPEND",
        subTitle: "The number of click throughs relative to the number of impression",
        kpiTargetPrefix:"",
        subComponent: <></>
      },
      {
        id: 5,
        title: "Cost per sign up",
        key: "COST_PER_SIGN_UP",
        subTitle: "The number of click throughs relative to the number of impression",
        kpiTargetPrefix:"₹",
        subComponent: <></>
      },
      {
        id: 6,
        title: "Cost per first app open",
        key: "COST_PER_FIRST_APP_OPEN",
        subTitle: "The number of click throughs relative to the number of impression",
        kpiTargetPrefix:"₹",
        subComponent: <></>
      },
    ]
  }
]

export const getKeywordCount = (selectedRows) => selectedRows.filter(item => item.SearchTerm?.indexOf('b0') === -1).length;
export const getAsinCount = (selectedRows) => selectedRows.filter(item => item.SearchTerm?.indexOf('b0') > -1).length;

export const todayIsActive = ({ startDate, endDate }) => {
  const today = new Date();
  return startDate && endDate && isSameDay(startDate, endDate) && isSameDay(startDate, today);
};