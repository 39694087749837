import React from 'react'

function DArrow({ color = "#134563", ...rest }) {
  return (
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" width='20' height='20' enable-background="new 0 0 64 64" viewBox="0 0 64 64" id="arrow" {...rest}><path fill={color} d="m-218.7-308.6 2-2 11.7 11.8 11.7-11.8 2 2-13.7 13.7-13.7-13.7" transform="translate(237 335)"></path></svg>
    </div>
  )
}

export default DArrow