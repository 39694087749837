import axios from "axios";

import {Signer} from '@aws-amplify/core';
import { getUserIdentity } from "./identity";
import { reverseReplaceString } from "./demo";

const convertToFormData = (payload) => {
    const formData = new FormData();
    for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
            formData.append(key, payload[key]);
        }
    }
    return formData;
};


const getSignRequest = async (path, method, payload, isNode) => {   
    // const aws = new AwsClient({ accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY + path, secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY, region: process.env.REACT_APP_AWS_REGION })
    // return aws.fetch(`https://api-v2.hectorai.live/login`, {method: method, body: JSON.stringify(payload) })
  const authToken = JSON.parse(localStorage.getItem('User')) || {}
  const getProfile = JSON.parse(localStorage.getItem('selectedProfileId')) || {};
  const masterFilter = JSON.parse(localStorage.getItem('master-account-filters')) || {}
  console.log("getSignRequest-masterFilter", masterFilter)
  const includeSBSpends = localStorage.getItem("include-sb-spends") == 'yes' ? true : localStorage.getItem("include-sb-spends") == 'no' ? false : true
  if (isNode){
    // if(payload.profile_id) delete payload.profile_id
    if('role' in payload && payload.role) delete payload.role
    if(path.indexOf('verify-reset-token') === -1) {
        if('token' in payload && payload.token) delete payload.token
    }
    if('account_type' in payload && payload.account_type) delete payload.account_type
  }

  const isFormData = path.includes("upload-mapLevelsToASIN") || path.includes("upload-mapLevelsToFsn") || path.includes("upload-mapLevelsToProduct");

    let request = {         
        method: method,
        mode: 'cors',
        url: isNode === 1 ? process.env.REACT_APP_FLIPKART_API + path  : process.env.REACT_APP_HTTP_URL + path,
        data: isFormData ? convertToFormData(payload) : JSON.stringify(
            {
                ...payload, 
                ...(!isNode && authToken ? {token: authToken.token} : {}),
                ...(!isNode && getProfile && authToken && {account_type: getProfile?.accountInfo?.type ? getProfile?.accountInfo?.type : 'Admin'}),
                ...(!isNode && getProfile && authToken && {role: getProfile?.accessLevel ? getProfile?.accessLevel : 'Admin'}),
                ...(!isNode && getProfile && authToken && {profileOwner: getProfile?.profileOwner ? getProfile?.profileOwner : authToken.email}),
                ...(!isNode && getProfile && getProfile?.datasetId != '' && {datasetId: getProfile?.datasetId ? getProfile?.datasetId : ''}),
                ...(!isNode && getProfile && getProfile?.timezone != '' && {timezone: getProfile?.timezone ? getProfile?.timezone : ''}),      
                ...(!isNode && path && path.indexOf('/amazon-snapshot') > -1 && getProfile && getProfile?.currencyCode != '' && {currencyCode: getProfile?.currencyCode ? getProfile?.currencyCode : ''}),
                ...((path && (path.indexOf('/amazon') > -1)) && masterFilter && Object.keys(masterFilter).length > 0 && masterFilter[getProfile.profileId] && masterFilter[getProfile.profileId]?.filter((filterItem) => filterItem?.keywords?.length > 0)?.length > 0 ? {
                    addgroup_filters: masterFilter[getProfile.profileId]?.filter((filterItem) => filterItem.level == 'ams' && filterItem?.keywords?.length > 0),
                } : {}),
                ...((path && (path.indexOf('/masteroverview') > -1 || path.indexOf('/snapshots') > -1)) && masterFilter && Object.keys(masterFilter).length > 0 && masterFilter[getProfile.profileId] && masterFilter[getProfile.profileId]?.filter((filterItem) => filterItem?.keywords?.length > 0)?.length > 0 ? {
                    addgroup_filters: masterFilter[getProfile.profileId]?.filter((filterItem) => filterItem?.keywords?.length > 0),
                } : {}),
                ...((path && (path.indexOf('/masteroverview') > -1 || path.indexOf('/snapshots') > -1)) ? {
                    is_include_sb: includeSBSpends,
                } : {})
            }),
        ...(isNode === 1 ? {headers: {
            "content-type": isFormData ? "multipart/form-data" : "application/json",
            ...(authToken && authToken.token ? {
                "X-USER-IDENTITY": getUserIdentity(path),
                "Authorization": `Bearer ${authToken.token}`
            } : {})
        }, baseURL: process.env.REACT_APP_FLIPKART_API}: {})
    } 
    let access_info = {
        access_key: process.env.REACT_APP_AWS_ACCESS_KEY, 
        secret_key: process.env.REACT_APP_AWS_SECRET_KEY
    }
    let service_info = {
        service: 'execute-api',
        region: process.env.REACT_APP_AWS_REGION
    }

    let newPayload = request;
    if(process.env.REACT_APP_DEMO_EMAIL && process.env.REACT_APP_DEMO_EMAIL.indexOf(authToken.email) > -1) {
        const jsonString = JSON.stringify(newPayload);
        const newString = await reverseReplaceString(jsonString)
        newPayload = JSON.parse(newString)   
    }

    if (isNode) {
        const instance = axios.create();
        return instance(newPayload)
    }

    //use amplify sign()function to create the signed headers;
    let signedRequest =  Signer.sign(request,access_info,service_info)

    //remove host from header
    delete signedRequest.headers['host']
    delete signedRequest.headers['Content-Length']
    

    //I normally create an instance if I need to intercept my response or request
    const instance = axios.create();
    return instance(signedRequest)
}



const getService = ({ url, payload, nodeAPI = 1 }) => getSignRequest(url, 'GET', payload ?  payload : {}, nodeAPI);
const postService = ({ url, payload, nodeAPI = 1 }) => getSignRequest(url, 'POST', payload, nodeAPI);
const putService = ({ url, payload, nodeAPI = 1 }) => getSignRequest(url,'PUT', payload, nodeAPI);
const deleteService = ({ url, payload, nodeAPI = 1 }) => getSignRequest(url, 'DELETE', payload, nodeAPI);



const services = {
    getService,
    postService,
    putService,
    deleteService
}

export default services;
