import React, { createContext, useReducer, useContext } from 'react';


const selectedProfile = JSON.parse(localStorage.getItem('instamartProfile'))

// Initial state for the context
const initialState = {
  selectedInstamartProfile: {
    ...( selectedProfile ? { ...selectedProfile } : {} )
  },
  instamartProfiles: [],
};

// Define the reducer function

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_SELECTED_INSTAMART_PROFILE':
      return { ...state, selectedInstamartProfile: action.payload };
    case 'UPDATE_INSTAMART_PROFILES':
        return { ...state, instamartProfiles: action.payload };
        default:
      return state;
  }
};

// Create the context
const InstaMartContext = createContext();

// Create a custom hook to use the context
const useInstaMartContext = () => {
  const context = useContext(InstaMartContext);
  if (!context) {
    throw new Error('useInstaMartContext must be used within a FkProvider');
  }
  return context;
};

// Create the provider component
const InstaMartProvider = ({ children }) => {
  const [instamartState, instamartDispatch] = useReducer(reducer, initialState);

  return (
    <InstaMartContext.Provider value={{ instamartState, instamartDispatch}}>
      {children}
    </InstaMartContext.Provider>
  );
};

export { InstaMartProvider, useInstaMartContext };