import React, { useEffect, useContext } from 'react';
import Button from 'src/components/Actions/Button';
import rightIcon from 'src/assets/images/common/right-white.svg';
import { ThemeContext } from 'src/providers/themeProvider';


import WaitingLight from 'src/assets/images/common/Waiting-light.svg';
import WaitingDark from 'src/assets/images/common/Waiting-Dark.svg';

export default function WaitingMessage({ from, dataNotAvailable = false }) {

  const [themeMode, setTheme] = useContext(ThemeContext);

  const getProfile = JSON.parse(localStorage.getItem('selectedProfileId'));

  const doCheckWaitingPeriod = () => {
    if (!dataNotAvailable && !getProfile && getProfile[from === 'seller' ? 'seller' : 'ams'].billing[0].billingStartTime != '') {
      const apiTime = new Date(getProfile[from === 'seller' ? 'seller' : 'ams'].billing[0].billingStartTime);
      // .toLocaleString("en-IN", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
      const currentTime = new Date();
      const timeDifferenceHours = (currentTime - apiTime) / (1000 * 60 * 60);
      console.log('CALL_HOURS', {
        time: getProfile[from === 'seller' ? 'seller' : 'ams'].billing[0].billingStartTime,
        hour: timeDifferenceHours
      });
      if (timeDifferenceHours > 2) window.location.reload();
    }
  };

  // useEffect(() => {
  //   doCheckWaitingPeriod();

  //   const intervalId = setInterval(doCheckWaitingPeriod, 10 * 60 * 1000); // 10 minutes in milliseconds

  //   return () => clearInterval(intervalId);
  // }, []);

  return (
    <React.Fragment>
      <section className="h-fit d-flex flex-column align-items-center justify-content-center">
        <img src={themeMode.theme == 'DARK' ? WaitingDark : WaitingLight} width="220" height="220" alt="login-success" className="mb-3" />
        <h2 className="f-60 mb-4 f-w-800"> Thank you</h2>
        <p className="f-22 txt-secondary mb-4 f-w-600"> for signing up with us, it will take upto 2 more hours to setup your dashboard</p>
        {/* <p className='f-16 f-w-600 mb-4'>Note: This screen will refresh aut</p> */}
      </section>
    </React.Fragment>
  );
}
