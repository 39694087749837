import React from 'react';
import { Row, Col } from 'react-bootstrap';
import RadioButton from 'src/components/Actions/radioButton';

export default function AccountCards() {
  const options = [
    { id: 1, label: 'Option-1', checked: true },
    { id: 2, label: 'Option-2', checked: false },
    { id: 3, label: 'Option-3', checked: false },
    { id: 4, label: 'Option-4', checked: false }
  ];

  return (
    <Row className="mb-3">
      {options.map((option) => (
        <Col sm={3} key={option.id}>
          <section
            className={`bg-white px-3 py-4 d-flex align-items-center justify-content-between cursor-pointer cards-responsive position-relative ${
              option.checked ? 'border-primary' : 'border'
            }`}
          >
            <h2 className="f-w-600 text-dark f-15">{option.label}</h2>
            <RadioButton classes="mt-radio" checked={option.checked} id={option.id} radioname="acc-crd" />
          </section>
        </Col>
      ))}
    </Row>
  );
}
