import React, { createContext, useReducer, useContext } from 'react';


export const EXPORT_DROPDOWN_FILTERS = {
  ad_types: [],
  campaigns: [],
  match_types: [],
  placements: [],
  targeting: [],
  inifiniteSettings: {
    campaignSearchString: "",
    targetingSearchString: "",
    campaignSelectAll: true,
    targetingSelectAll: true
  }
}

// Initial state for the context
const initialState = {
    dropdown_filters: EXPORT_DROPDOWN_FILTERS,
    applied_dropdown_filters: EXPORT_DROPDOWN_FILTERS,
    campaign_custom_tables: [],
    targeting_custom_tables: [],
    customize_metrics: [],
    hours_list: [],
    metrics_list: [],
    dateInfo: {}
};

// Define the reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_DROPDOWN_FILTERS':
      return { ...state, dropdown_filters: {
        ...state.dropdown_filters,
        ...action.payload
      } };  
    case 'APPLY_DROPDOWN_FILTERS':
      return { ...state, applied_dropdown_filters: {
        ...state.applied_dropdown_filters,
        ...action.payload
      } }; 
    case 'UPDATE_CUSTOMIZE_METRICS':
      return { ...state, customize_metrics: action.payload };  
    case 'UPDATE_HOURS_LIST':
      return { ...state, hours_list: action.payload };  
    case 'UPDATE_METRICS_LIST':
      return { ...state, metrics_list: action.payload };  
    case 'UPDATE_CAMPAIGN_CUSTOM_TABLES':
      return { ...state, campaign_custom_tables: action.payload };
    case 'UPDATE_TARGETING_CUSTOM_TABLES':
      return { ...state, targeting_custom_tables: action.payload };
    case 'UPDATE_DEFAULT_SETTINGS':
      return { ...state, applied_dropdown_filters: action.payload, dropdown_filters: action.payload };
    case 'UPDATE_DEFAULT_DATE_INFO':
      return { ...state, dateInfo: action.payload };
    case 'UPDATE_INIFINITE_SETTINGS':
      return { ...state, dropdown_filters: {
        ...state.dropdown_filters,
        inifiniteSettings: {...state.dropdown_filters.inifiniteSettings, ...action.payload}
      } };
    case 'RESET_FILTERS':
      return { ...state, dropdown_filters: EXPORT_DROPDOWN_FILTERS, applied_dropdown_filters: EXPORT_DROPDOWN_FILTERS };
    default:
      return state;
  }
};

// Create the context
const DayPartingContext = createContext();

// Create a custom hook to use the context
const useDayPartingContext = () => {
  const context = useContext(DayPartingContext);
  if (!context) {
    throw new Error('useDayPartingContext must be used within a DayPartingProvider');
  }
  return context;
};

// Create the provider component
const DayPartingProvider = ({ children }) => {
  const [dayPartingState, dayPartingDispatch] = useReducer(reducer, initialState);

  return <DayPartingContext.Provider value={{ dayPartingState, dayPartingDispatch }}>{children}</DayPartingContext.Provider>;
};

export { DayPartingProvider, useDayPartingContext };
