import React from 'react';
import searchIcon from 'src/assets/images/common/search-black.svg';

const Search = ({ callBack, searchValue, classes, imgclass, widthClass, placeholders }) => {
  return (
    <React.Fragment>
      <div className={`position-relative ${widthClass}`}>
        <input
          type="search"
          className={`border rounded-8 f-14 py-2 p-l-35 h-40 pr-2 w-100 bg-white ${classes}`}
          placeholder={placeholders ? placeholders : "Search..."}
          value={searchValue}
          onChange={callBack}
          onPaste={callBack}
        />
        <img src={searchIcon} alt="search" className={`position-absolute wid-15 search-box ${imgclass}`} />
      </div>
    </React.Fragment>
  );
};

export default Search;
