import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <React.Fragment>
            <div className="footer">
              <div className="container">
                <div className="d-flex align-items-center justify-content-between">

                  <div className="footer-text">
                    <p>Copyright © 2020 Hector. All Rights Reserved.</p>
                  </div>

                  <ul className="footer-menu">
                    <li>
                      <Link to="auth/terms">Terms</Link>
                    </li>
                    <li>
                      <Link to="auth/support">Support</Link>
                    </li>
                    </ul>
                </div>
              </div>
            </div>
        </React.Fragment>
    )
}
export default Footer