import { useEffect, useRef } from 'react';

function useResponsiveHighcharts(config) {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current.chart;
      // Set your Highcharts options here
      if(chart) {
        chart.update(config);
      }
    }
  }, [config]);

  useEffect(() => {
    function handleResize() {
      if (chartRef.current) {
        const chart = chartRef.current.chart;
        chart.reflow();
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return chartRef;
}

export default useResponsiveHighcharts;
