import React, { useState, useContext, useEffect } from 'react';
import { ListGroup, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Search from 'src/components/Form/search';
import { SearchFilter } from 'src/helper/search';
import { ConfigContext } from 'src/contexts/ConfigContext';
import { sanitizeValue } from 'src/utils/custom-formatters';
import { useInstaMartContext } from 'src/contexts/InstamartContext';
import { useCookies } from 'react-cookie';

export default function InstamartProfiles() {

  const { instamartState,instamartDispatch } = useInstaMartContext();
  const [cookies, setCookie, removeCookie] = useCookies();
  const [showAccDropdown, setShowAccDropdown] = useState(false);
  const instmartProfile = JSON.parse(localStorage.getItem('instamartProfile'))
  const [defaultActive, setDefaultActive] = useState(instmartProfile ? instmartProfile : {})
  const [valueSearch, setValueSearch] = useState("")
  const [getProfiles, setProfiles] = useState({
    data: Array.isArray(instamartState?.instamartProfiles) ? instamartState?.instamartProfiles : [],
    filteredData: []
  })

  const configContext = useContext(ConfigContext);
  const { rtlLayout } = configContext.state;

  useEffect(() => {
    setValueSearch("")
    setProfiles({
      data: Array.isArray(instamartState?.instamartProfiles) ? instamartState?.instamartProfiles : [],
      filteredData: []
    })
  }, [instamartState?.instamartProfiles])


  useEffect(() => {
    const handleWindowFocus = () => {
      const localProfile = JSON.parse(localStorage.getItem('instamartProfile'));
      if (defaultActive && localProfile && localProfile?.Value !== defaultActive.Value) {
        
        window.location.reload()
      } else {
        console.log('handleWindowFocus Matched!, Good to Go');
      } 
    }
    window.addEventListener('focus', handleWindowFocus);
    return () => {
      window.removeEventListener('focus', handleWindowFocus);
    };
  }, [defaultActive]);


  const getTableRows = () => {
    if (valueSearch !== '') {
      return getProfiles.filteredData;
    } else {
      return getProfiles.data;
    }
  };


  const searchInputHandle = async (e) => {
    const keyword = sanitizeValue(e.target.value);
    console.log("keyword", keyword);
    const { data, filteredData } = await SearchFilter(getProfiles.data, keyword);

    if (keyword !== '') {
        setProfiles({ data, filteredData });
    }
    else {
        setProfiles({ data, filteredData });
    }
    setValueSearch(keyword);
  }

  const handleSelect = (selectedItem) => {
      setShowAccDropdown(!showAccDropdown)
      setDefaultActive(selectedItem)
      localStorage.removeItem("instamartProfile")
      localStorage.setItem('instamartProfile', JSON.stringify(selectedItem))
      document.getElementById('fullPageLoader').style.visibility = 'visible';
      setTimeout(() => {
      if(window.location.href.indexOf('plan-expired') > -1) {
        window.location.href = '/summary/instamart'
      } else {
        window.location.reload();
      }
    }, 100);
  }

  useEffect(() => {
    if (showAccDropdown) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [showAccDropdown]);

  const profilesList = valueSearch != '' ? getProfiles.filteredData : getProfiles.data;


  // if(!defaultActive?.countryCode) return null

  return (
    <ListGroup.Item as="li" bsPrefix="" className='ch_toggle'>
      <Dropdown
        alignRight={!rtlLayout}
        className="select-account"
        show={showAccDropdown}
        onToggle={() => {
          if(profilesList.length > 1) {
            setShowAccDropdown(!showAccDropdown)
          }
        }}
      >
        <Dropdown.Toggle as="li" id="dropdown-basic" onClick={() => {
          if(profilesList.length > 1) {
            setShowAccDropdown(!showAccDropdown)
          }
        }} className='cursor-pointer'>
          <div className="text-right d-flex flex-column l-base align-items-end pr-2 drop-crd">
            <div className="f-w-600 f-14 d-flex align-items-center text-left">
              <img
                alt="country-img"
                src={
                  encodeURIComponent(defaultActive?.countryCode?.toLowerCase()) === 'uk'
                  ? 'https://flagpedia.net/data/flags/w1160/gb.webp'
                  : `https://flagcdn.com/${defaultActive?.countryCode?.toLowerCase()}.svg`
                }
                className="country_flag_drop"
              />
              <div className="d-flex flex-column f-10">
                <div className='f-333843'>{defaultActive?.accountInfo?.name}</div>
                {defaultActive?.accessLevel ?
                    <span className="f-10 text-left w-100 f-w-500 spld-bder f-8F8F91">
                      Role: <b className="f-w-600 f-10 f-333843">{defaultActive?.accessLevel}</b>
                    </span>
                  : null}
              </div>
            </div>
            {/* <small className="f-10 bg-blur px-2 py-1 rounded-10 txt-theme">{defaultActive?.map((select) => select?.countryCode)}</small> */}
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu alignRight className="themebg notification notification-scroll">
          <div className="noti-head">
            <h6 className="d-inline-block m-b-0 f-w-700 f-14 text-dark">ACCOUNTS</h6>
          </div>
          <div className="px-3 pt-3 pb-1">
            <Search searchValue={valueSearch} callBack={searchInputHandle} />
          </div>
          <PerfectScrollbar className="min-hight-150 max-height-300">
            <ListGroup as="ul" bsPrefix=" " variant="flush" className="noti-body">
                {profilesList && profilesList.map((AMSprofiles, index) => (
                  <ListGroup.Item
                    as="li"
                    bsPrefix=" "
                    className={'n-title px-0 pt-2'}
                    key={index}
                    onClick={() => handleSelect(AMSprofiles)}
                  >
                    <Link
                      to="#"
                      className={`dropdown-item d-flex align-items-center justify-content-between ${
                        defaultActive?.profileId == AMSprofiles.profileId ? 'active' : ''
                      }`}
                    >
                      <section className="d-flex align-items-center" title={AMSprofiles?.accountInfo?.name}>
                        <img
                          src={
                            encodeURIComponent(AMSprofiles?.countryCode?.toLowerCase()) === 'uk'
                            ? 'https://flagpedia.net/data/flags/w1160/gb.webp'
                            : `https://flagcdn.com/${AMSprofiles?.countryCode?.toLowerCase()}.svg`
                          }
                          alt=""
                          className="wid-30 hei-25 mr-4"
                        />

                        <div>
                          <h4 className="f-w-600 f-14 d-flex align-items-center profile-name-truncate fk_myntra_width">
                            {' '}
                            {AMSprofiles?.accountInfo?.name}{' '}
                            {/* <p className={`f-w-500 f-14 ml-2 ${AMSprofiles?.accountInfo?.type === 'seller' ? 'txt-theme' : 'text-success'} `}>
                              ({AMSprofiles?.accountInfo?.type})
                            </p> */}
                          </h4>

                          {/* {AMSprofiles.accessLevel ? (
                            <p className="f-13 mb-0 f-w-500">
                              Role: &nbsp; <b className="f-w-600 pr-1">{AMSprofiles.accessLevel}</b> |{' '}
                            </p>
                          ) : (
                            ''
                          )} */}
                          {/* <p className="f-13 mb-0 f-w-500">{AMSprofiles.profileId}</p> */}
                          {/* <p className="f-12 text-secondary f-w-500 d-flex align-items-center"> */}
                        </div>
                      </section>
                    </Link>
                  </ListGroup.Item>
                ))}
                {profilesList && profilesList.length === 0 && (
                  <div className="hei-300 w-100 d-flex align-items-center justify-content-center f-w-700 txt-danger">
                    {' '}
                    No Profiles Found
                  </div>
                )}
            </ListGroup>
          </PerfectScrollbar>
          <div className="noti-footer py-2 text-left">
            <Link to="/profile" className="mb-1">
              Manage Your Accounts
            </Link>
            <br />
            <Link to="/addaccount">Add a new account</Link>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </ListGroup.Item>
  );
}
