import React, { createContext, useReducer, useContext } from 'react';

// Initial state for the context
const initialState = {
  filters: [],
  applied_filters: [],
  filter_object: {},
  selectedOption: 'OR'
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FILTERS':
      return { ...state, filters: action.payload };  
    case 'UPDATE_APPLY_FILTERS':
      return { ...state, applied_filters: action.payload }; 
    case 'APPLY_FILTER_OBJECT':
      return { ...state, filter_object: {
        ...state.filter_object,
        ...action.payload
      } };
    case 'CLEAR_FILTER_OBJECT':
      return { ...state, filter_object: action.payload};
      case 'SET_SELECTED_OPTION': 
      return { 
        ...state, 
        selectedOption: action.payload,
        filter_object: {
          ...state.filter_object,
          selectedOption: action.payload 
        }
      };
    default:
      return state;
  }
};

// Create the context
const SmartSearchContext = createContext();

// Create a custom hook to use the context
const useSmartSearchContext = () => {
  const context = useContext(SmartSearchContext);
  if (!context) {
    throw new Error('useSmartSearchContext must be used within a SmartSearchProvider');
  }
  return context;
};

// Create the provider component
const SmartSearchProvider = ({ children }) => {
  const [smartSearchState, smartSearchDispatch] = useReducer(reducer, initialState);

  return <SmartSearchContext.Provider value={{ smartSearchState, smartSearchDispatch }}>{children}</SmartSearchContext.Provider>;
};

export { SmartSearchProvider, useSmartSearchContext };
