export const CreateCrossFilter = (crossFilters) => {
  return crossFilters.map((item) => {
    if (item.rows.length > 0 && item.aggregate == 'Targeting') {
      return {
        aggregate: item.aggregate,
        ...(item.table_id !== '' && { table_id: item.table_id }),
        ...(item.column_name !== '' && { column_name: item.column_name }),
        ...(item.table_name != null && { table_name: item.table_name }),
        filters: item.rows.map((item) => {
          return {
            campaignId: item.campaignId,
            adGroupId: item.adGroupId,
            keywordId: item.keywordId,
            campaignName: item.campaignName,
            adGroupName: item.adGroupName,
            Targeting: item.Targeting,
            matchType: item.matchType,
            Type: item.Type
          };
        })
      };
    } else if (item.rows.length > 0 && item.aggregate == 'SearchTerm') {
      return {
        aggregate: item.aggregate,
        ...(item.table_id !== '' && { table_id: item.table_id }),
        ...(item.column_name !== '' && { column_name: item.column_name }),
        ...(item.table_name != null && { table_name: item.table_name }),

        filters: item.rows.map((item) => {
          return {
            campaignName: item.campaignName,
            adGroupName: item.adGroupName,
            SearchTerm: item.SearchTerm,
            Type: item.Type
          };
        })
      };
    } else if (item.rows.length > 0 && item.aggregate == 'AdvertisedASINs') {
      return {
        aggregate: item.aggregate,
        ...(item.table_id !== '' && { table_id: item.table_id }),
        ...(item.column_name !== '' && { column_name: item.column_name }),
        ...(item.table_name != null && { table_name: item.table_name }),

        filters: item.rows.map((item) => {
          return {
            campaignId: item.campaignId,
            campaignName: item.campaignName,
            adGroupName: item.adGroupName,
            adGroupId: item.adGroupId,
            AdvertisedASINs: item.AdvertisedASINs,
            Type: item.Type
          };
        })
      };
    }

    return {
        aggregate: item.aggregate,
        ...(item.table_id !== '' && { table_id: item.table_id }),
        ...(item.column_name !== '' && { column_name: item.column_name }),
        ...(item.table_name != null && { table_name: item.table_name }),
        filters: item.filters
    };
  });
};
