import { useState, useRef } from 'react';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import useAuth from './useAuth';
import { replaceString } from 'src/api/demo';

// eslint-disable-next-line
export default (apiFunc) => {

  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); 
  const dataChangeRef = useRef('')
  const { logout } = useAuth()

  const request = async (...args) => {

    const arguements = { ...args }
    const authToken = JSON.parse(localStorage.getItem('User')) || {}

    setLoading(true);
    try {
      const result = await apiFunc(...args);

      if(result?.data?.error_code === 401 || result?.data?.status_code === 401 || result?.data?.message?.toLowerCase()?.indexOf('invalid user') > -1){
        sweetAlertHandler({ type: 'alert', text: 'User session has expired.', showConfirmButton: true })
        // logout()
      }
      if(result.data.errorMessage) {
        result.data.errorMessage = result.data.errorMessage.replace('An error occurred (NotAuthorizedException) when calling the InitiateAuth operation: ', '').replace('An error occurred (UserNotFoundException) when calling the AdminGetUser operation: ', '')
      }
      if(result.data.message) {
        result.data.message = result.data.message.replace('An error occurred (NotAuthorizedException) when calling the InitiateAuth operation: ', '').replace('An error occurred (UserNotFoundException) when calling the AdminGetUser operation: ', '')
      }
      if(result && !('success' in result) && result.data.errorMessage) {
        result['success'] = false
        result.data['message'] = result.data.errorMessage ? result.data.errorMessage : 'Something went wrong'
      }
      const updateResponse = async (response) => {
        if(arguements && arguements['0'] && String(arguements['0'].url).toLowerCase().indexOf('mdb-get-profiles') === -1) {
          if(process.env.REACT_APP_DEMO_EMAIL.indexOf(authToken?.email) > -1) {
            const jsonString = JSON.stringify(response)
            const newData = JSON.parse(replaceString(jsonString))
            return newData
          } 
          return result.data ? result.data : result
        }
        return result.data ? result.data : result
      }
      await updateResponse(result.data ? result.data : result).then((results) => {
        setData(results)
      })
    } catch (err) {
      console.log("ERROR:", err)

      if(err?.response?.status === 403 && err?.response?.data && err?.response?.data?.to_billing == 1 && window.location.href.indexOf('plan-expired') == -1) {
        const message = err?.response?.data?.message || "We wanted to kindly inform you that your payment plan has expired. In order to continue accessing our application, we kindly request you to please get in touch with our administrator (support@hectorai.live). We truly appreciate your cooperation and understanding."
        localStorage.setItem('expired-message', message)
        window.location.href = `/plan-expired/${window.location.href.indexOf('amazon') > -1 ? 'amazon' : window.location.href.indexOf('flipkart') > -1 ? 'flipkart' : ''}`
      } else if(err?.response?.status === 401 || err?.response?.statusText?.toLowerCase() === "unauthorized"){
          sweetAlertHandler({ type: 'alert', text: 'User session has expired.', showConfirmButton: true })
      } else {
        const errorResponse = err.response && err.response.data ? err.response.data : err.response
        console.log("ERROR:", errorResponse)
        setData(errorResponse ? {
          ...(errorResponse && Object.keys(errorResponse).length > 0 ?  { ...errorResponse } : {}),
          success: false,
          code: 400,
          message: errorResponse ? errorResponse.message : err,
        } : err);
      }
      // setError(err.message || "Unexpected Error!");
    } finally {
      setLoading(false);
    }
  };


  const sweetAlertHandler = (alert) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: alert.text,
      type: alert.type,
      timer: alert.timer,
      showConfirmButton: alert.showConfirmButton,
      didClose: alert.action ? alert.action : () => { console.log("Actioned") },
      allowOutsideClick: false,
      showCancelButton: alert.CancelButton,
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("isConfirmed")
        logout()
      } else if (result.isDenied) {
        // Swal.fire('Changes are not saved', '', 'info')
      }
    })
  };

  return {
    data,
    error,
    loading,
    request
  };
};
