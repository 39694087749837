


const DATE_RANGE = {
    'date_range': [
        {
            "id": "1",
            "label": "Today",
            "item": "T",
            "sort_label": "Today"
        },
        {
            "id": "2",
            "label": "Yesterday",
            "item": "Y",
            "sort_label": "Yesterday"
        },
        {
            "id": "3",
            "label": "This week",
            "item": "T-W",
            "sort_label": "Thisweek"
        },
        {
            "id": "4",
            "label": "Last 7 Days",
            "item": "L-7D",
            "sort_label": "Last7Days"
        },
        {
            "id": "5",
            "label": "Last 30 Days",
            "item": "L-30D",
            "sort_label": "Last30Days"
        },
        {
            "id": "6",
            "label": "This Month",
            "item": "T-M",
            "sort_label": "ThisMonth"
        },
        {
            "id": "7",
            "label": "Last Month",
            "item": "L-M",
            "sort_label": "LastMonth"
        },
        {
            "id": "8",
            "label": "This year",
            "item": "T-Y",
            "sort_label": "Thisyear"
        },
        {
            "id": "9",
            "label": "Last year",
            "item": "L-Y",
            "sort_label": "Lastyear"
        },
        // {
        //     "id": "10",
        //     "label": "Custom",
        //     "item": "Custom",
        //     "sort_label": "Custom"
        // }
    ]
}

export default DATE_RANGE