function CalendarDays(props) {
    const firstDayOfMonth = new Date(props.day.getFullYear(), props.day.getMonth(), 1);
    const weekdayOfFirstDay = firstDayOfMonth.getDay();
    let currentDays = [];

    console.log(props.changedDates)

    const weekdayss = [1,2,3,4,5,6,0]

    for(let i=0; i< weekdayss.indexOf(weekdayOfFirstDay); i++)
    {
      currentDays.push({})
    }
  
    for (let day = 0; day < new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth()+1, 0).getDate(); day++) {

      if(day !== 0 ){
        firstDayOfMonth.setDate(firstDayOfMonth.getDate()+1);
      }
      let calendarDay = {
        currentMonth: (firstDayOfMonth.getMonth() === props.day.getMonth()),
        date: (new Date(firstDayOfMonth)),
        month: firstDayOfMonth.getMonth(),
        number: firstDayOfMonth.getDate(),
        selected: props.changedDates.currentDay || props.changedDates.endDay ? props.changedDates.currentDay.toISOString().split('T')[0] <= new Date(firstDayOfMonth).toISOString().split('T')[0] && props.changedDates.endDay.toISOString().split('T')[0] >= new Date(firstDayOfMonth).toISOString().split('T')[0] ? true : false : false ,
        year: firstDayOfMonth.getFullYear(),
        disabled: firstDayOfMonth.getDay() !== 1 && firstDayOfMonth.getDay() !== 0  ?  true: (new Date().toISOString().split("T")[0] > new Date(firstDayOfMonth).toISOString().split("T")[0]) ? new Date(firstDayOfMonth).toISOString() > new Date('2024-06-30').toISOString() ? checkWeeklybiweekly(firstDayOfMonth) ? false : true : true  : true
      }
  
      currentDays.push(calendarDay);
    }
    
  //(day.selected ? " selected" : "" + 
    return (
      <div className="table-content">
        {
          currentDays.map((day) => {
            return (
              <div style={!day.number ? {pointerEvents:'none'} :{}} className={"calendar-day" + (day.currentMonth ? " current" : "") + (day.selected ? " selected no-disabled" : "") + (day.disabled ? " disabled":" ")}
                    onClick={() => props.changeCurrentDay(day)}>
                <p>{day.number}</p>
              </div>
            )
          })
        }
      </div>
    )
  }

  function checkWeeklybiweekly (date) {
    let biWeekarr = [];
    // if(new Date(date).toISOString().split('T')[0] >= new Date('2024-07-28').toISOString().split('T')[0])
    // {
      let startDate = new Date('2024-07-01');
      let endDate = new Date();

      while (startDate <= endDate) {
        if(new Date(startDate).getMonth() === new Date().getMonth() || (new Date(startDate).getMonth() === new Date().getMonth() - 1))
        {
          biWeekarr.push(new Date(startDate).toISOString().split('T')[0]); 
          biWeekarr.push(new Date(new Date(startDate).setDate(new Date(startDate).getDate() -1)).toISOString().split('T')[0])// Add the current start date to the array
          startDate.setDate(startDate.getDate() + 7); // Move forward by 7 days (weekly)
        }
        else{
          biWeekarr.push(new Date(startDate).toISOString().split('T')[0]); 
          biWeekarr.push(new Date(new Date(startDate).setDate(new Date(startDate).getDate() -1)).toISOString().split('T')[0])// Add the current start date to the array
          startDate.setDate(startDate.getDate() + 14); // Move forward by 14 days (biweekly)
        }
        
    } 
      if(new Date(date).toISOString().split('T')[0] === new Date('2024-07-01').toISOString().split('T')[0])
        {
          return true;
        }
    let current7days = new Date(date).getMonth() === new Date().getMonth() ? new Date(date).getDay() === 0 ?  biWeekarr.includes(new Date(new Date(date).setDate(new Date(date).getDate() - 5)).toISOString().split('T')[0])  : new Date(new Date(date).setDate(new Date(date).getDate() + 7)).toISOString().split('T')[0] < new Date().toISOString().split('T')[0]: true;
    if(new Date(date).getMonth() === new Date().getMonth() ) {
      return biWeekarr.includes(new Date(new Date(date).setDate(new Date(date).getDate() + 1)).toISOString().split('T')[0]) && current7days;
    }
    return biWeekarr.includes(new Date(new Date(date).setDate(new Date(date).getDate() + 1)).toISOString().split('T')[0]);
  }
  
  export default CalendarDays;