import React from 'react'

function FilterIcon({ activeIcon = false, ...rest }) {
    return (
        <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.75 7.8125C4.75 7.66332 4.80926 7.52024 4.91475 7.41475C5.02024 7.30926 5.16332 7.25 5.3125 7.25H8.6875C8.83668 7.25 8.97976 7.30926 9.08525 7.41475C9.19074 7.52024 9.25 7.66332 9.25 7.8125C9.25 7.96168 9.19074 8.10476 9.08525 8.21025C8.97976 8.31574 8.83668 8.375 8.6875 8.375H5.3125C5.16332 8.375 5.02024 8.31574 4.91475 8.21025C4.80926 8.10476 4.75 7.96168 4.75 7.8125ZM2.5 4.4375C2.5 4.28832 2.55926 4.14524 2.66475 4.03975C2.77024 3.93426 2.91332 3.875 3.0625 3.875H10.9375C11.0867 3.875 11.2298 3.93426 11.3352 4.03975C11.4407 4.14524 11.5 4.28832 11.5 4.4375C11.5 4.58668 11.4407 4.72976 11.3352 4.83525C11.2298 4.94074 11.0867 5 10.9375 5H3.0625C2.91332 5 2.77024 4.94074 2.66475 4.83525C2.55926 4.72976 2.5 4.58668 2.5 4.4375ZM0.25 1.0625C0.25 0.913316 0.309263 0.770242 0.414753 0.664753C0.520242 0.559263 0.663316 0.5 0.8125 0.5H13.1875C13.3367 0.5 13.4798 0.559263 13.5852 0.664753C13.6907 0.770242 13.75 0.913316 13.75 1.0625C13.75 1.21168 13.6907 1.35476 13.5852 1.46025C13.4798 1.56574 13.3367 1.625 13.1875 1.625H0.8125C0.663316 1.625 0.520242 1.56574 0.414753 1.46025C0.309263 1.35476 0.25 1.21168 0.25 1.0625Z" fill={activeIcon ? "#fff" : "#8F8F91"} {...rest} />
        </svg>
    )
}

export default FilterIcon
