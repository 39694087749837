import React, { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import Group from './drag/Group';
import { arrayRemove } from 'redux-form';

export default function MetricsDrag({ metricsList, updateMetricsList }) {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    setGroups([
      {
        title: 'Metrics',
        childs: metricsList
      }
    ]);
  }, [metricsList]);

  const onDragEnd = (result) => {
    // TODO: Implement logic for handling drag and drop
    console.log('onDragEnd', result);
    const { source, destination } = result;
    if (!destination || source.droppableId !== destination.droppableId) {
      return;
    }

    const draggedIndex = source.index;
    const newIndex = destination.index;

    // Create a copy of the groups state array
    const newGroups = [...groups];

    // Get the group from which the item was dragged
    const groupIndex = parseInt(source.droppableId.split('-')[1]);
    const group = newGroups[groupIndex];

    // Remove the dragged item from its original position
    const [draggedItem] = group.childs.splice(draggedIndex, 1);

    // Insert the dragged item into the new position within the same group
    group.childs.splice(newIndex, 0, draggedItem);

    console.log('newGroups', newGroups);

    setGroups(newGroups);

    updateMetricsList(newGroups[0].childs);
  };

  const onMetricUpdate = (event, tableInfo) => {
    const { target: { checked }} = event

    if(tableInfo && Array.isArray(tableInfo)) {
      updateMetricsList(metricsList.map((item) => {
          return {
            ...item,
            show: checked ? 'yes' : 'no'
          }
      }))

    } else {

      updateMetricsList(metricsList.map((item) => {
        if(item.name === tableInfo.name) {
          return {
            ...item,
            show: checked ? 'yes' : 'no'
          }
        }
        return { ...item }
      }))

    }

    
  }

  return (
    <React.Fragment>
      <DragDropContext onDragEnd={onDragEnd}>
        {groups.map((group, index) => (
          <Group
            titleHide={true}
            key={`${group.title}_${index}`}
            title={group.title}
            checkboxes={group.childs}
            index={index}
            keyId={'name'}
            keyName={'title'}
            onMetricUpdate={onMetricUpdate}
          />
        ))}
      </DragDropContext>
    </React.Fragment>
  );
}
