import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';

import { Provider } from 'react-redux';
import { ConfigProvider } from './contexts/ConfigContext';

import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { ThemeProvider } from 'src/providers/themeProvider';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';
import {GoogleOAuthProvider} from '@react-oauth/google';

const User = JSON.parse(localStorage.getItem('User'));

if (User && Object.keys(User).length > 0 && 'email' in User && process.env.REACT_APP_GA_TRACK_ENABLED == 'YES') {
  // console.log('User', User);

  try {
    ReactGA.initialize(process.env.REACT_APP_GA_ANALYTICS, {
      debug: process.env.REACT_APP_GA_ANALYTICS_ON === 1 ? false : true,
      gaOptions: {
        userId: User && Object.keys(User).length > 0 && 'email' in User ? String(User.email).replace('@', '&') : ''
      }
    });
    // console.log('Google Analytics initialized successfully.');
    // You can perform additional actions here if needed
  } catch (error) {
    // console.error('Error initializing Google Analytics:', error);
    // Handle the error gracefully or report it to your error tracking system
  }
}

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
    <ConfigProvider>
      <ThemeProvider>
          <GoogleOAuthProvider clientId="863919414228-nuqdle9virs842uk2t8pgn0cr65fmgdd.apps.googleusercontent.com">
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </GoogleOAuthProvider>
      </ThemeProvider>
    </ConfigProvider>
    </CookiesProvider>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
