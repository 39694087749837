import React, {useState, createContext} from "react";
import { THEME_TYPE } from "src/store/theme.js";

export const ThemeContext = createContext();

export const ThemeProvider = (group) => {
  const isDarkOn = localStorage.getItem('darkMode') === "true";
  const isLight = localStorage.getItem('darkMode') === "false";
  const [theme, setTheme] = useState({
    theme: (isDarkOn) ? THEME_TYPE.DARK : ( isLight ? THEME_TYPE.LIGHT : THEME_TYPE.LIGHT),
  });

  return (
  	<ThemeContext.Provider value={[theme, setTheme]}>
  		{group.children}
	</ThemeContext.Provider>
  );
};