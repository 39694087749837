import React, { createContext, useReducer, useContext } from 'react';


const selectedProfile = JSON.parse(localStorage.getItem('flipkartProfile'))

// Initial state for the context
const initialState = {
  selectedFlipProfile: {
    ...( selectedProfile ? { ...selectedProfile } : {} )
  },
  flipkartProfiles: [],
};

// Define the reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_SELECTED_FLIPKART_PROFILE':
      return { ...state, selectedFlipProfile: action.payload };
    case 'UPDATE_FLIPKART_PROFILES':
        return { ...state, flipkartProfiles: action.payload };
        default:
      return state;
  }
};

// Create the context
const FkContext = createContext();

// Create a custom hook to use the context
const useFkContext = () => {
  const context = useContext(FkContext);
  if (!context) {
    throw new Error('useFkContext must be used within a FkProvider');
  }
  return context;
};

// Create the provider component
const FkProvider = ({ children }) => {
  const [fkState, fkDispatch] = useReducer(reducer, initialState);

  return (
    <FkContext.Provider value={{ fkState, fkDispatch }}>
      {children}
    </FkContext.Provider>
  );
};

export { FkProvider, useFkContext };