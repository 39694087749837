import React, { useState } from 'react';
import searchIcon from 'src/assets/images/common/search-black.svg';
import RadioButton from 'src/components/Actions/radioButton';
import Button from 'src/components/Actions/Button';
import Input from 'src/components/Form/Input';

export default function SearchBar() {
  const [state, setState] = useState({
    searchList: false,
    editFilter: false,
    showTags: true
  });

  const { searchList, editFilter, showTags } = state;

  const toggleSearchList = () => {
    setState((prevState) => ({ ...prevState, searchList: !prevState.searchList }));
  };

  const toggleEditFilter = () => {
    setState((prevState) => ({ ...prevState, editFilter: !prevState.editFilter }));
  };

  const toggleShowTags = () => {
    setState((prevState) => ({ ...prevState, showTags: !prevState.showTags }));
  };

  return (
    <React.Fragment>
      <section>
        <div className="bg-white p-1 rounded-50 border d-flex align-items-center">
          <img src={searchIcon} alt="search" className="wid-20 mr-3 ml-1" />

          {showTags && (
            <section className="position-relative mr-2">
              <div className="srch_field rounded-50 py-2 px-3 f-w-600 txt-theme themegray f-13 cursor-pointer" onClick={toggleEditFilter}>
                Filter-1
                <span className="ml-3 f-13 txt-secondary" onClick={toggleShowTags}>
                  &#x2715;
                </span>
              </div>
              {editFilter && (
                <section className="bg-white wid-270 filter-datatab left-0 pt-0 pb-2 top-44">
                  <div className="modfil-header">
                    <h2 className="f-w-600 f-14 pl-2">Edit Filters</h2>
                  </div>
                  <div className="mt-2">
                    <section className="d-flex align-items-center mb-2">
                      <RadioButton title="option1" id="1" radioname="editfilter" classes="mr-3" />
                      <RadioButton title="option2" id="2" radioname="editfilter" />
                    </section>
                    <Input />
                    <section className="d-flex align-items-center justify-content-end mt-2">
                      <Button name="Submit" action={toggleEditFilter} classes="primary-btn px-3 py-1 rounded-50 text-light f-12 mr-2" />
                      <Button name="cancel" action={toggleEditFilter} classes="secondary-btn px-3 py-1 rounded-50 text-light f-12 mr-2" />
                    </section>
                  </div>
                </section>
              )}
            </section>
          )}

          <section className="position-relative">
            <div className="srch_field rounded-50 p-2 themegray" onClick={toggleSearchList}>
              <input type="search" className="f-13" placeholder="Search and Filter" />
            </div>
            {searchList && (
              <section className="bg-white wid-270 filter-datatab left-0 pt-0 pb-2 top-44">
                <div className="edit-tri close_tri" onClick={toggleSearchList}>
                  <span>✕</span>
                </div>
                <ul className="mt-2">
                  <li>Option 1</li>
                  <li>Option 2</li>
                  <li>Option 3</li>
                  <li>Option 4</li>
                  <li>Option 5</li>
                </ul>
              </section>
            )}
          </section>
        </div>
      </section>
    </React.Fragment>
  );
}
