import React from 'react'
import { usePayloadContext } from 'src/contexts/PayloadContext'
import { useReportContext } from 'src/contexts/ReportContext'
import ResetIcon from 'src/assets/images/common/reset.svg';
import { ListGroup } from 'react-bootstrap';

export default function ClearCrossFilter() {
  const { payloadState, payloadDispatch } = usePayloadContext()
  const { reportState, reportDispatch } = useReportContext()
  const hasAnyApiProgress = Object.values(reportState?.reports_loader).some(value => value === true);

  return (
    <>
      {payloadState?.crossFilters?.some((cross_row) => cross_row.filters.length > 0) == true || reportState?.custom_sort_config?.length > 0 ? (
        <ListGroup.Item as="li" bsPrefix=" ">
          <div className="d-flex align-items-center gap-10 mr-2 settings">
            <section className={`position-relative ${hasAnyApiProgress ? 'disabled' : ''}`}>
              <img src={ResetIcon} alt="reset" onClick={() => {
                payloadDispatch({
                  type: 'CLEAR_CROSS_FILTERS',
                  payload: true
                })
                reportDispatch({
                  type: 'CLEAR_CUSTOM_SORT_CONFIG',
                  payload: []
                })
              }} className="cursor-pointer wid-20" />

            </section>
          </div>
        </ListGroup.Item>
      ) : null}
    </>
  )
}
