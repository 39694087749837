import React from 'react';
import WarningIcon from 'src/assets/icons/WarningIcon';

const Input = ({ placeholders, types, values, classes, onChangeCallback = () => {}, action, readonly = 0, onPasteCallback = () => {}, onlyNumber = false, min, onKeyPress = () => {}, validationError, ...rest}) => {
  if(readonly == 1) {
    return (
      <React.Fragment>
        <input
          onClick={action}
          type={types}
          placeholder={placeholders}
          value={values}
          className={`form-field ${classes}`}
          onChange={onChangeCallback}
          readOnly={1}
          {...rest}
        />
      </React.Fragment>
    );
  }

  if(types == 'number') {

    const handleKeyDown = (event) => {
      // Prevent the default behavior if the pressed key is 'e'
      if (event.key === 'e') {
        event.preventDefault();
      }
    };

    return (
      <React.Fragment>
        <input
          type={types}
          placeholder={placeholders}
          value={values}
          className={`form-field ${classes}`}
          onChange={onChangeCallback}
          onKeyDown={handleKeyDown}
          min={min}
          {...rest}
        />
      </React.Fragment>
    );
  }


  return (
    <React.Fragment>
      <div className={`w-100 ${validationError? 'warning-field' : ''}`}>
        <input
          onClick={action}
          type={types}
          placeholder={placeholders}
          value={values}
          className={`form-field ${classes} `}
          onChange={onChangeCallback}
          onKeyPress={onKeyPress}
          onPaste={onPasteCallback}
          {...rest}
        />
        {validationError && <span className="warning-icon">
          <WarningIcon height="16px" width="16px" />
        </span>}
        {validationError && <div className='pt-2 f-13 f-w-400' style={{color: '#117a8b'}}>This field is required.</div>}
      </div>
    </React.Fragment>
  );
};

export default Input;
