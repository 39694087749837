import Highcharts from 'highcharts';
import { withRouter } from 'react-router';

import { USDollar } from 'src/utils/dollar-convertor';

let Dollar = USDollar.format

const MixedOptions = {


    chart: {
        type: 'spline',
        height: 250,
        // decimals: "2"
    },

    colors: ['#00bcd4', '#4099ff', '#7759de'],
    title: {
        text: ''
    },

    xAxis: {
        categories: ["12AM", "1AM", "2AM", "3AM", "4AM", "5AM", "6AM", "7AM", "8AM", "9AM", "10AM", "11AM", "12PM", "1PM", "2PM", "3PM", "4PM", "5PM", "6PM", "7PM", "8PM", "9PM", "10PM", "11PM"],
        crosshair: true
    },

    yAxis: [
        {
            title: {
                // text: 'Units Ordered',
            },
            opposite: false,
            // labels: {
            //     formatter: function () {
            //         if (this.value > 1) return Highcharts.numberFormat(this.value / 1) + "";
            //         return Highcharts.numberFormat(this.value, 0);
            //     },
            // },
        },
        {
            title: {
                // text: 'Ordered Product Sale',
            },
            opposite: false,
            labels: {
                formatter: function () {
                    if (this.value > 1000) return Highcharts.numberFormat(this.value / 1000, 1) + "k";
                    return Highcharts.numberFormat(this.value, 0);
                },
            },
            // categories: [ "0"]

        },

        {
            title: {
                // text: 'Total Order item',
            },
            opposite: false,
            labels: {
            },
        }
    ],


    plotOptions: {
        series: {
            cropThreshold: 50,
            label: {
                connectorAllowed: false
            },
      
            marker: {
              enabled: false,
              states: {
                hover: {
                  enabled: true
                }
              }
            }
          },
    },
    series: [
        
        {
            // name: 'Units Ordered',
            data: [0, 1, 0.1, 1, 0.1, 1, 1, 1, 0.1, 1, 0.1, 0.1, 0.1, 1, 0, 0, 0.1, 0, 0, 0, 0, 0, 1, 0],
            type: "line",
            yAxis: 0,
            showInLegend: false,
            
        },
        {
            // name: "Ordered Product Sale",
            data: [0.1, 0.2, 0.4, 0.3, 0.1, 0.1, 0.2, 0.1, 0, 0, 0.4, 0.5, 0.1, 0.1, 0.2, 0.4, 0.3, 0.1, 0.1, 0.2],
            type: "line",
            yAxis: 1,
            showInLegend: false,
          },
          {
            // name: 'Total Order item',
            data: [0.1, 0.2, 0.4, 0.3, 0.1, 0.1, 0.2, 0.1, 0, 0, 0.4, 0.5, 0.1, 0.1, 0.2, 0.4, 0.3, 0.1, 0.1, 0.2, 0.1],
            type: "line",
            yAxis: 2,
            showInLegend: false,
          },
    ],

    accessibility: {
        enabled: false
    },
    responsive: {
        rules: [
            {
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }
        ]
    },


    tooltip: {
    //     Background: "white",
    //     formatter: function () {
    //         return this.points.reduce(function (s, point) {
    //             let title = ''
    //             if (point.series.name === "Units Ordered") {
    //                 title =  s + '<br/>' + point.series.name + ': ' + point.y
    //             } else if (point.series.name === "ACOS") {
    //                 title =  s + '<br/>' + point.series.name + ': ' + point.y + '%'
    //             } else if (point.series.name === "ROAS") {
    //                 title =  s + '<br/>' + point.series.name + ': ' + point.y
    //             }
    //             return title
    //         }, '<b>' + this.x + '</b>');
    //     },
    //     shared: true,
    },
}


export default MixedOptions;